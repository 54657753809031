import { Component, Vue } from 'vue-property-decorator';
import { InscriptionReplacement, TabsList, Tab, Replacement, TabProvisional, Exam } from '@/Domain/Entities';
import { InscriptionTurn, OppositionType, CriteriaTypes, OrderCriteriaType, OrderCriteriaTranslations } from '@/Domain/enum';
import { ToasterService } from '@/Services/ToasterService';
import ProcessesService from '@/Services/ProcessesService';
import ReplacementService from '@/Services/ReplacementService';
import { Toggle } from '@/Components';
import i18n from '../../lang';
import { mapState } from 'vuex';
import _ from 'lodash';
import Layout from '../Layout';
import { IReplacementCandidateIdentification } from '@/Domain/interfaces/IReplacementCandidateIdentification';

@Component({
    components: { Toggle },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' })
    }
})
export default class ListReplacementProvisional extends Vue {
    currentLanguage!: string;

    inscriptionTurn = InscriptionTurn;
    oppositionType = OppositionType;
    criteriaTypes = CriteriaTypes;
    orderCriteriaType = OrderCriteriaType;
    orderCriteriaTranslations = OrderCriteriaTranslations;

    replacementListProvisional = new Replacement({});
    turnList: TabsList<TabProvisional> = new TabsList(TabProvisional, []);
    examsList: any = [];
    examsListMandatories: number = 0;
    isEditing: boolean = false;
    turnType: number | null = null;
    loading: boolean = false;
    isDisabledSelect: boolean = false;
    isFinish: boolean = false;
    isAllInscriptionsConsecutive: boolean = false;
    showAllTurnsMessage: boolean = false;
    showAllConsecutiveMessage: boolean = false;
    showIsFirstSelectedMessage: boolean = false;
    resetCount: boolean = true;
    currentTabIndex: number = 0;
    cloneCriteria: any;
    cloneSelectedReplacementListCandidateIds: any;
    filterActiveId: string | null = null;
    showModalFilterExams: boolean = false;
    showOnlyApproved: boolean = false;
    showOrderCriteria: boolean = false;
    criteriaActive: number = 0;
    criteriaOrderSelected: number = 0;
    candidatesIdentificationsOnList: IReplacementCandidateIdentification[] = [];

    callFilterWithAllFinalists() {
        this.setFilterByExam(this.examsList[this.examsList.length - 1]);
        this.callFilter();
    }

    backToReplacementListEditOrNew() {
        if (this.isEditing) {
            this.$router.push({
                name: 'ReplacementListEdit',
                params: {
                    id: this.replacementListProvisional.id, replacement: (this.replacementListProvisional as any), cloneCriteria: this.cloneCriteria, cloneSelectedReplacementListCandidateIds: this.cloneSelectedReplacementListCandidateIds
                }
            });
        } else {
            this.$router.push({
                name: 'ReplacementNew'
            });
        }
    }

    getDataProcessExamsInscriptions() {
        ProcessesService.getDataProcessExamsInscriptions(this.replacementListProvisional.process.id).then(
            response => {
                this.examsList = response.map(exam => ({ id: exam.id, name: exam.description }));
                this.examsListMandatories = response.map(el => new Exam(el)).filter(exam => exam.isExclusive).length;
                this.examsList.push({ id: '0', name: 'lang.shared.allCriteria' });
                if (!this.isEditing) {
                    this.replacementListProvisional.criteria.criteria = CriteriaTypes.enum.EXAMID;
                }
                if (this.isEditing && this.replacementListProvisional.criteria.criteria === CriteriaTypes.enum.NOCRITERIA) {
                    this.replacementListProvisional.criteria.examId = this.examsList[0].id;
                    this.replacementListProvisional.criteria.criteria = CriteriaTypes.enum.EXAMID;
                }
                if (this.isEditing && this.replacementListProvisional.criteria.criteria === CriteriaTypes.enum.NUMEXAMPASSED) {
                    this.isDisabledSelect = true;
                }
                this.getProcessById();
            }).catch(error => console.log(error));
    }

    showModalOrderCriteria() {
        this.criteriaActive = this.criteriaOrderSelected;
        this.showOrderCriteria = true;
    }

    setOrderCriteria(orderCriteria: number) {
        this.criteriaActive = orderCriteria;
    }

    setDefaultOrderCriteria() {
        this.showOrderCriteria = false;
        this.criteriaActive = this.criteriaOrderSelected;
    }

    applyOrderCriteria() {
        this.replacementListProvisional.criteria.orderCriteria = this.criteriaActive;
        this.criteriaOrderSelected = this.criteriaActive;
        this.getProcessById();
        this.showOrderCriteria = false;
    }

    applyCriteria() {
        this.setCriteria();
        if (!Number.isInteger(this.replacementListProvisional.criteria.numExams)) {
            this.isDisabledSelect = false;
            this.replacementListProvisional.criteria.numExams = null;
            this.getProcessById(this.resetCount);
            return;
        }
        if (!Number.isInteger(this.replacementListProvisional.criteria.numExams) || (this.replacementListProvisional.criteria.numExams !== null && this.replacementListProvisional.criteria.numExams <= 0) || (this.replacementListProvisional.criteria.numExams !== null && this.replacementListProvisional.criteria.numExams > this.examsListMandatories)) {
            this.isDisabledSelect = true;
            return;
        }
        this.replacementListProvisional.criteria.showOnlyApproved = false;
        this.isDisabledSelect = true;
        this.getProcessById(this.resetCount);
    }

    setCriteria() {
        this.replacementListProvisional.criteria.numExams !== null && this.replacementListProvisional.criteria.numExams > 0 ? this.replacementListProvisional.criteria.criteria = this.criteriaTypes.enum.NUMEXAMPASSED : this.replacementListProvisional.criteria.criteria = this.criteriaTypes.enum.EXAMID;
    }

    changeSelectAll() {
        this.resetFilterTabProvisional();
        if ((this.turnList.activeTab as TabProvisional).filter.isSelectedAll) {
            this.replacementListProvisional.inscriptionsReplacement.forEach(inscription => {
                this.addIndexInInscriptionsIds(inscription);
            });
        } else {
            this.replacementListProvisional.inscriptionsReplacement.forEach(inscription => {
                this.removeIndexInInscriptionsIds(inscription);
            });
        }
        this.isAllInscriptionsConsecutive = this.replacementListProvisional.getIsAllInscriptionsConsecutive(this.currentTabIndex);
    }

    getIsSelectedAll() {
        this.isAllInscriptionsConsecutive = this.replacementListProvisional.getIsAllInscriptionsConsecutive(this.currentTabIndex);
        (this.turnList.activeTab as TabProvisional).filter.isSelectedAll = this.replacementListProvisional.getIsAllSelected();
        if ((this.turnList.activeTab as TabProvisional).filter.isSelectedAll) {
            this.resetFilterTabProvisional();
        }
    }

    applyFactorScore() {
        (this.turnList.activeTab as TabProvisional).filter.position = null;
        this.replacementListProvisional.inscriptionsReplacement.forEach(inscription => {
            if (inscription.totalScore >= Number((this.turnList.activeTab as TabProvisional).filter.score)) {
                this.addIndexInInscriptionsIds(inscription);
            } else {
                this.removeIndexInInscriptionsIds(inscription);
            }
        });
        this.getIsSelectedAll();
    }

    applyFactorPosition() {
        (this.turnList.activeTab as TabProvisional).filter.score = null;
        this.replacementListProvisional.inscriptionsReplacement.forEach(inscription => {
            if (inscription.order <= Number((this.turnList.activeTab as TabProvisional).filter.position)) {
                this.addIndexInInscriptionsIds(inscription);
            } else {
                this.removeIndexInInscriptionsIds(inscription);
            }
        });
        this.getIsSelectedAll();
    }

    resetFilterTabProvisional() {
        (this.turnList.activeTab as TabProvisional).filter.score = null;
        (this.turnList.activeTab as TabProvisional).filter.position = null;
    }

    selectInscription(inscription) {
        const requestIndex = this.replacementListProvisional.getIndexInscription(inscription.id);
        if (inscription.isSelected && requestIndex < 0) {
            this.replacementListProvisional.addInscriptionId(inscription.id);
            this.addCount();
        }
        if (!inscription.isSelected && requestIndex >= 0) {
            this.replacementListProvisional.removeInscriptionId(requestIndex);
            this.subtractCount();
        }
        (this.turnList.activeTab as TabProvisional).filter.isSelectedAll = this.replacementListProvisional.getIsAllSelected();
        this.isAllInscriptionsConsecutive = this.replacementListProvisional.getIsAllInscriptionsConsecutive(this.currentTabIndex);
        this.resetFilterTabProvisional();
    }

    addIndexInInscriptionsIds(inscription) {
        inscription.isSelected = true;
        const requestIndex = this.replacementListProvisional.getIndexInscription(inscription.id);
        if (requestIndex < 0) {
            this.replacementListProvisional.addInscriptionId(inscription.id);
            this.addCount();
        }
    }

    removeIndexInInscriptionsIds(inscription) {
        inscription.isSelected = false;
        const requestIndex = this.replacementListProvisional.getIndexInscription(inscription.id);
        if (requestIndex >= 0) {
            this.replacementListProvisional.removeInscriptionId(requestIndex);
            this.subtractCount();
        }
    }

    addCount() {
        if (this.turnType !== null) {
            const index = this.turnList.list.indexOf((this.turnList.list as any).find(e => e.type === this.turnType));
            this.turnList.list[index].addCount();
        }
    }

    subtractCount() {
        if (this.turnType !== null) {
            const index = this.turnList.list.indexOf((this.turnList.list as any).find(e => e.type === this.turnType));
            this.turnList.list[index].subtractCount();
        }
    }

    showValidateMessage() {
        this.showAllConsecutiveMessage = !this.isAllInscriptionsConsecutive;
        this.showAllTurnsMessage = !this.replacementListProvisional.isAnyTurnCountEmpty(this.turnList.list);
        this.showIsFirstSelectedMessage = this.replacementListProvisional.getIsAnySelectedAndIsNotFirstIndexSelected();
        this.isFinish = true;
    }

    submit() {
        this.isFinish = false;
        this.loading = true;
        this.createOrUpdateReplacementList();
    }

    getReplacementAction() {
        if (!this.isEditing) {
            return ReplacementService.addReplacement(this.replacementListProvisional.toServer());
        }
        return ReplacementService.updateReplacement(this.replacementListProvisional.toServer());
    }

    createOrUpdateReplacementList() {
        const action = this.getReplacementAction();
        action.then(
            response => {
                if (this.$route.name === 'ListReplacementProvisional') {
                    this.$router.push({
                        name: 'ListReplacementList',
                        params: {
                            replacementId: response.id,
                            name: response.name
                        }
                    });
                }
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                this.updateDataPlan();
                this.loading = false;
            })
            .catch(() => this.loading = false);
    }

    async updateDataPlan() {
        await (this.$parent.$parent as Layout).getSubscriptionPlan();
    }

    getProcessById(resetCount?: boolean) {
        this.loading = true;
        if (!this.isEditing || resetCount) {
            this.replacementListProvisional.removeAllInscriptionsId();
        }
        this.getTurnsByProcessId(resetCount);
        if ((this.turnList.activeTab as TabProvisional)) {
            this.resetFilterTabProvisional();
        }
    }

    copyFilterExam() {
        if (this.replacementListProvisional.isModify || this.isDisabledSelect) {
            return;
        }
        this.showModalFilterExams = true;
        this.filterActiveId = this.replacementListProvisional.criteria.examId;
        this.showOnlyApproved = this.replacementListProvisional.criteria.showOnlyApproved;
    }

    setDefaultFilter() {
        this.filterActiveId = this.replacementListProvisional.criteria.examId;
        this.showOnlyApproved = this.replacementListProvisional.criteria.showOnlyApproved;
    }

    setFilterByExam(exam: any) {
        this.filterActiveId = exam.id;
        if (this.filterActiveId === '0') {
            this.showOnlyApproved = false;
            return;
        }
        this.showOnlyApproved = true;
    }

    callFilter() {
        if (this.filterActiveId) {
            this.replacementListProvisional.criteria.examId = this.filterActiveId;
            this.replacementListProvisional.criteria.showOnlyApproved = this.showOnlyApproved;
        }
        this.getProcessById(this.resetCount);
        this.showModalFilterExams = false;
    }

    setTurnActive(turn) {
        this.currentTabIndex = this.turnList.list.map(el => el.type).indexOf(turn.type);
        this.turnList.selectTab(turn);
        this.getInscriptionByTurn(turn);
    }

    getInscriptionsReplacementProvisionalRankedByTurn() {
        ProcessesService.getInscriptionsReplacementProvisionalRankedByTurn(this.replacementListProvisional.process.id, this.turnType, this.replacementListProvisional.criteria.toServer()).then(
            response => {
                this.replacementListProvisional.inscriptionsReplacement = response.map(inscription => {
                    const inscriptionReplacement = new InscriptionReplacement(inscription);
                    this.replacementListProvisional.setInscriptionReplacementIsSelected(inscriptionReplacement);
                    return inscriptionReplacement;
                });
                this.manageReplacementCandidatesSelection();
                if (this.turnList.activeTab) {
                    this.turnList.activeTab.filter.isSelectedAll = this.replacementListProvisional.getIsAllSelected();
                }
                this.loading = false;
                if (this.isEditing) {
                    this.turnList.list.forEach(element => element.filter.isSelectedAll = false);
                }
            }).catch(error => console.log(error));
    }

    manageReplacementCandidatesSelection() {
        if (!this.candidatesIdentificationsOnList || this.candidatesIdentificationsOnList.length === 0) { return; }
        const nifs = this.candidatesIdentificationsOnList.map(candidate => candidate.nif);
        this.replacementListProvisional.inscriptionsReplacement.forEach(inscription => {
            if (nifs.includes(inscription.nif)) {
                this.replacementListProvisional.selectedInscriptionsIds.push(inscription.id);
                this.replacementListProvisional.setInscriptionReplacementIsSelected(inscription);
            }
        });
    }

    manageInitTabCount() {
        if (!this.candidatesIdentificationsOnList || this.candidatesIdentificationsOnList.length === 0) { return; }
        this.turnList.list.forEach(tab => {
            tab.count = this.candidatesIdentificationsOnList.filter(candidate => candidate.turn === tab.type).length;
        });
    }

    getInscriptionByTurn(turn: Tab | null) {
        this.replacementListProvisional.emptyInscriptionsReplacement();
        this.loading = true;
        this.turnType = turn ? turn.type : turn;
        this.getInscriptionsReplacementProvisionalRankedByTurn();
    }

    getTurnsByProcessId(resetCount?: boolean) {
        ProcessesService.getTurnsByProcessId(this.replacementListProvisional.process.id, this.replacementListProvisional.criteria.toServer()).then(list => {
            this.turnList = new TabsList(TabProvisional, list);
            this.manageInitTabCount();
            if (this.replacementListProvisional.consecutiveInscriptions.length === 0) {
                this.replacementListProvisional.setConsecutiveList(this.turnList.list);
            }
            if (resetCount) {
                this.turnList.list.forEach(el => el.resetCount());
            }
            this.getInscriptionByTurn(this.turnList.activeTab);
        })
            .catch(error => console.log(error));
    }

    initListReplacementProvisonal() {
        this.replacementListProvisional = new Replacement(this.$route.params.replacementList, this.$store.state.processConfigStore.processConfigGroups);
        this.cloneCriteria = _.cloneDeep(this.replacementListProvisional.criteria);
        this.criteriaOrderSelected = _.cloneDeep(this.replacementListProvisional.criteria.orderCriteria);
        this.cloneSelectedReplacementListCandidateIds = _.cloneDeep(this.replacementListProvisional.selectedReplacementListCandidateIds);
        if (this.replacementListProvisional.process.oppositionType === this.oppositionType.enum.TENDER) {
            this.replacementListProvisional.criteria.criteria = CriteriaTypes.enum.EXAMID;
            this.getProcessById();
        } else {
            this.getDataProcessExamsInscriptions();
        }
    }

    getCandidatesOnDefinitiveListIdentifications() {
        ReplacementService.getCandidates((this.$route.params.replacementList as any).id)
            .then(response => {
                this.candidatesIdentificationsOnList = response.candidates;
                this.initListReplacementProvisonal();
            })
            .catch(error => {
                console.log(error);
                this.initListReplacementProvisonal();
            });
    }

    manageDataLoadOnMounted() {
        const replacementList = this.$route.params.replacementList as any;
        if (!replacementList.id) {
            this.initListReplacementProvisonal();
            return;
        }
        this.isEditing = true;
        this.getCandidatesOnDefinitiveListIdentifications();
    }

    mounted() {
        this.loading = true;
        const replacementList = this.$route.params.replacementList as any;
        if (!replacementList) {
            this.$router.push({ name: 'ReplacementList' });
            return;
        }
        this.manageDataLoadOnMounted();
    }
}
