import ChartData from './ChartData';
import { MultiLanguage } from '@/Domain/Entities';
import moment from 'moment';

export default class ChartProcessesInscription extends ChartData {
    private _id: string;
    private _title: MultiLanguage;
    private _type: number;
    private _phase: number;
    private _bopDate: moment.Moment | null;

    public constructor(data: any) {
        super(data.inscriptions.pendingInscriptions, data.inscriptions.admittedInscriptions, data.inscriptions.excludedInscriptions);
        this._id = data.id;
        this._title = new MultiLanguage(data.title);
        this._type = data.type;
        this._phase = data.phase;
        this._bopDate = data.bopDate ? moment.utc(data.bopDate) : null;
        this.colors = ['#DADFE3', '#6ACF80', '#ED6870'];
        this.legend = ['lang.shared.pending', 'lang.shared.admitted', 'lang.shared.excluded'];
    }

    public get pending() {
        return Number(this.data[0]);
    }

    public get admitted() {
        return Number(this.data[1]);
    }

    public get excluded() {
        return Number(this.data[2]);
    }
    
    public get id() {
        return this._id;
    }
    
    public get title() {
        return this._title;
    }
    
    public get type() {
        return this._type;
    }
    
    public get phase() {
        return this._phase;
    }
    
    public get bopDate() {
        return this._bopDate;
    }
}
