import { PayExemption, ProcessConfigPayExemption } from '@/Domain/Entities';
import { PayExemptionTypes } from '@/Domain/enum';

export default class Fees {
    private _hasFeePayment: boolean;
    private _payExemptionTypes: number[];
    private _amount: number | null;
    private _payExemptionTypesView: PayExemption[];
    private _paymentType: number | null;
    
    public constructor(data: any, processConfigurationPayExemptions?: ProcessConfigPayExemption[]) {
        this._hasFeePayment = data.fees ? data.fees.hasFeePayment : false;
        this._payExemptionTypes = data.fees ? data.fees.payExemptions : [];
        this._amount = data.fees ? data.fees.amount : null;
        this._paymentType = data.fees ? data.fees.feePaymentType : null;
        this._payExemptionTypesView = this.setPayExemptionTypesView(data, processConfigurationPayExemptions);
    }

    public get hasFeePayment() {
        return this._hasFeePayment;
    }

    public set hasFeePayment(value: boolean) {
        this._hasFeePayment = value;
    }
    public get payExemptionTypes() {
        return this._payExemptionTypes;
    }

    public set payExemptionTypes(payExemptionTypes: number[]) {
        this._payExemptionTypes = payExemptionTypes;
    }

    public get amount() {
        return this._amount;
    }

    public set amount(amount: number | null) {
        this._amount = amount;
    }

    public get payExemptionTypesView() {
        return this._payExemptionTypesView;
    }

    public set payExemptionTypesView(value: PayExemption[]) {
        this._payExemptionTypesView = value;
    }

    public get paymentType() {
        return this._paymentType;
    }

    public set paymentType(value: number | null) {
        this._paymentType = value;
    }

    public setPayExemptionTypesView(data, processConfigurationPayExemptions) {
        return this.mapPayExemption(data.payExemptionFeeAmounts || [], data.fees ? data.fees.payExemptions : [], processConfigurationPayExemptions ? processConfigurationPayExemptions : []);
    }

    public mapPayExemption(payExemptionFeeAmounts, payExemptionValues, processConfigurationPayExemptions) {
        return processConfigurationPayExemptions.filter(payExemption => payExemption.type !== PayExemptionTypes.enum.NONE && payExemption.type !== PayExemptionTypes.enum.NOREASON)
            .map(payExemption => {
                const hasAmount = payExemptionFeeAmounts.find(payExe => payExe.payExemptionType === payExemption.type);
                return new PayExemption({
                    text: payExemption.title,
                    value: payExemptionValues.some(elem => elem === payExemption.type),
                    type: payExemption.type,
                    amount: hasAmount ? hasAmount.amount : null
                });
            });
    }

    public resetReasons() {
        if (!this.hasFeePayment) {
            this._payExemptionTypesView.forEach((element: PayExemption) => element.value = false);
            this.amount = null;
            this.paymentType = null;
        }
    }

    public setAmountToServer() {
        if (!this.hasFeePayment) {
            this.amount = null;
        }
    }

    public setPayExemptionAmountToServer() {
        this.payExemptionTypesView.forEach(payExemption => {
            if (!payExemption.value) {
                payExemption.amount = null;
            }
        });
    }

    public toServer(payExemptionsTypes: PayExemption[]) {
        return {
            hasFeePayment: this.hasFeePayment,
            payExemptions: payExemptionsTypes.map(payExemption => payExemption.type),
            amount: this.amount,
            feePaymentType: this.paymentType
        };
    }
}
