import { OepDocument } from '@/Domain/Entities';

export default class MeritOepDocument extends OepDocument {
    private _meritId: string;
    private _status: number;
    private _ratingType: number;

    public get meritId() {
        return this._meritId;
    }

    public get status() {
        return this._status;
    }

    public set status(status: number) {
        this._status = status;
    }
    
    public get ratingType() {
        return this._ratingType;
    }
    
    constructor(file: any, meritId: string, ratingType) {
        super(file);
        this._meritId = meritId;
        this._status = file.status;
        this._ratingType = ratingType;
    }
   
}
