import { Component, Prop } from 'vue-property-decorator';
import { ValidityError } from '@/Domain/enum/index';
import SdInputBase from '../SdInputBase';

@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                if (!this.$props.step) {
                    this.$emit('input', parseInt(value, 10));
                } else {
                    this.$emit('input', value);
                }
            }
        },
        isInvalid() {
            return (this.$data.dirty || this.$props.submitted) && !this.$data.focused && this.$refs.input && !(this.$refs.input as HTMLInputElement).validity.valid;
        }
    }
})
export default class SdInputNumber extends SdInputBase {
    @Prop() max?: number;
    @Prop() min?: number;
    @Prop() step?: any;
    @Prop() validatePhone?: boolean;
    ValidityError = ValidityError;

    getValidityError() {
        if (this.$refs.input && this.$refs.input.validity.rangeUnderflow && this.validatePhone) {
            return ValidityError.enum.INVALIDPHONE;
        }
        if (this.$refs.input && this.$refs.input.validity.rangeUnderflow) {
            return ValidityError.enum.RANGEUNDERFLOW;
        }
        if (this.$refs.input && this.$refs.input.validity.rangeOverflow) {
            return ValidityError.enum.RANGEOVERFLOW;
        }
        if (this.$refs.input && this.$refs.input.validity.stepMismatch) {
            return this.$props.step && !Number.isInteger(this.$props.step) ? ValidityError.enum.STEPMISMATCHWITHSTEP : ValidityError.enum.STEPMISMATCH;
        }        
    }
}
