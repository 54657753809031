import ChartData from './ChartData';

export default class ChartProgression extends ChartData {
    private _finished: number;
    private _pending: number;
    private _total: number;

    public constructor(finished: number, pending: number) {
        super([finished], [pending]);
        this._finished = finished;
        this._pending = pending;
        this._total = finished + pending;
        this.colors = ['#63a4ff', '#DADFE3'];
    }

    public get finished() {
        return this._finished;
    }
    public get pending() {
        return this._pending;
    }
    public get total() {
        return this._total;
    }
    public get legend() {
        return super.legend;
    }
    public set legend(legend: string[]) {
        super.legend = legend;
    }
}
