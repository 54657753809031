import { Process } from '.';
import { ProcessPhases } from '@/Domain/enum';
import { LanguagesStore } from '@/Store/modules/LanguagesStore';
import _ from 'lodash';
import moment from 'moment';

export default class CourtUser {
    private _id: string;
    private _name: string;
    private _firstSurname: string;
    private _secondSurname: string;
    private _nif: string;
    private _email: string;
    private _isDropped: boolean;
    private _isBlocked: boolean;
    private _processIds: string[];
    private _processList: Process[];
    private _created: moment.Moment | null;
    private _phone: number;

    public constructor(data: any) {
        this._id = data.id;
        this._name = data.name;
        this._firstSurname = data.firstSurname;
        this._secondSurname = data.secondSurname;
        this._nif = data.nif;
        this._email = data.email;
        this._isDropped = false;
        this._isBlocked = data.isBlocked;
        this._processIds = data.processIds ? data.processIds : [];
        this._processList = [];
        this._created = !data.created || moment(data.created).isSame('0001-01-01T00:00:00') ? null : moment.utc(data.created);
        this._phone = data.phone;
    }

    public get id() {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get name() {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    public get firstSurname() {
        return this._firstSurname;
    }
    public set firstSurname(value: string) {
        this._firstSurname = value;
    }

    public get secondSurname() {
        return this._secondSurname;
    }
    public set secondSurname(value: string) {
        this._secondSurname = value;
    }

    public get nif() {
        return this._nif;
    }
    public set nif(value: string) {
        this._nif = value;
    }

    public get email() {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
    }

    public get isDropped() {
        return this._isDropped;
    }

    public set isDropped(value: boolean) {
        this._isDropped = value;
    }

    public get isBlocked() {
        return this._isBlocked;
    }
    public set isBlocked(value: boolean) {
        this._isBlocked = value;
    }

    public get processIds() {
        return this._processIds;
    }
    public set processIds(values: string[]) {
        this._processIds = values;
    }

    public get processList() {
        return this._processList;
    }
    public set processList(values: Process[]) {
        this._processList = values;
    }

    public get created() {
        return this._created;
    }

    public get phone() {
        return this._phone;
    }
    public set phone(value: number) {
        this._phone = value;
    }

    public get fullName() {
        return this.firstSurname + ' ' + this.secondSurname + ', ' + this.name;
    }

    public get processListOpen() {
        return _.orderBy(this.processList.filter(element => element.phase !== ProcessPhases.enum.FINISH), [process => process.title[LanguagesStore.getters.getCurrentLanguage()] ? _.deburr(process.title[LanguagesStore.getters.getCurrentLanguage()]).toLowerCase() : process.title[LanguagesStore.getters.getCurrentLanguage()]]);
    }

    public get processListFinished() {
        return _.orderBy(this.processList.filter(element => element.phase === ProcessPhases.enum.FINISH), [process => process.title[LanguagesStore.getters.getCurrentLanguage()] ? _.deburr(process.title[LanguagesStore.getters.getCurrentLanguage()]).toLowerCase() : process.title[LanguagesStore.getters.getCurrentLanguage()]]);
    }

    setActiveProcess(process) {
        this.processIds.forEach(id => {
            if (process.id === id) {
                process.active = true;
            }
        });
    }

    resetProcessIds() {
        this.processIds = [];
    }

    validateProcessSelected() {
        return this.processIds.length > 0;
    }

    processIdToProcessList(processList) {
        processList.forEach(process => {
            if (this.processIds.some(processId => process.id === processId)) { 
                this.processList.push(process);
            }
        });
    }

    public toServer() {
        return {
            id: this.id,
            name: this.name,
            firstSurname: this.firstSurname,
            secondSurname: this.secondSurname,
            nif: this.nif,
            email: this.email,
            isBlocked: this.isBlocked,
            processIds: this.processIds,
            phone: this.phone
        };
    }

}
