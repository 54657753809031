import Enum from '../interfaces/IEnum';

export const ScoreMethods: Enum = {
    translationsOpposition: {
        0: 'lang.shared.withoutFactor',
        1: 'lang.shared.withFactor',
        2: 'lang.shared.averageExams'
    },
    translationsNomination: {
        0: 'lang.shared.sumExams',
        2: 'lang.shared.averageExam'
    },
    translationsFreeDesignation: {
        3 : 'lang.shared.orderByFirstSurname',
        4 : 'lang.shared.orderBySecondSurname',
        5 : 'lang.shared.orderByName'
    },
    enum: {
        WITHOUTFACTOR: 0,
        WITHFACTOR: 1,
        AVERAGEEXAMS: 2,
        FIRSTSURNAME: 3,
        SECONDSURNAME: 4, 
        NAME: 5
    }
};
