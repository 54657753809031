import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReplacementState, ReplacementInscriptionData, Sorter, ReplacementDocument } from '@/Domain/Entities';
import { StatusReplacement, ReplacementReasons } from '@/Domain/enum';
import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../lang';
import _ from 'lodash';
import { mapState } from 'vuex';
import DocumentService from '@/Application/Services/DocumentService';
import ReplacementStatesService from '@/Services/ReplacementStatesService';

@Component({
    computed: {
        hasDocument() {
            return this.$data.replacementStatus.status != null && (Number(this.$data.replacementStatus.status) !== StatusReplacement.enum.AVAILABLE && Number(this.$data.replacementStatus.status) !== StatusReplacement.enum.WORKING && (Number(this.$data.replacementStatus.status) !== StatusReplacement.enum.UNREACHABLE ) && (Number(this.$data.replacementStatus.status) !== StatusReplacement.enum.BUSY));
        },
        statusOrderReverse() {
            return _.orderBy(this.$data.replacementData.statusList, 'date').reverse();
        },
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'})
    }
})
export default class ReplacementStatus extends Vue {
    @Prop() replacementListCandidateId!: string;
    @Prop() replacementListModified!: string;
    dateFormat!: string;
    
    StatusReplacement = StatusReplacement;
    ReplacementReasons = ReplacementReasons;
    loading: boolean = false;
    openState: boolean = false;
    replacementStatus = null as any;
    replacementData = null as any;
    showDeleteModal: boolean = false;
    sorter = new Sorter();
    fistStatusRegister: boolean = false;
    submitted: boolean = false;

    $refs!: {
        replacementStatusForm: HTMLFormElement
    };

    setReplacementStatus() {
        this.replacementStatus.reason = null;
    }

    editingState(replacementStatusEditing) {
        this.openState = true;
        this.replacementStatus = _.cloneDeep(replacementStatusEditing);
        this.replacementStatus.isEditing = true;
    }

    createState() {
        this.openState = true;
        this.replacementStatus = new ReplacementState({});
    }

    confirmWithModal(replacementStatusToRemove: ReplacementState) {
        this.replacementStatus = replacementStatusToRemove;
        this.showDeleteModal = true;
    }

    removeState() {
        this.showDeleteModal = false;
        this.loading = true;
        ReplacementStatesService.removeReplacementState(this.$props.replacementListCandidateId, this.replacementStatus.infoToServer())
            .then(() => {
                this.initList();
                this.loading = false;
                this.openState = false;
                this.fistStatusRegister = false;
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                this.$emit('updateReplacementListIsModify');
            })
            .catch(() => this.loading = false);
    }

    cancel() {
        this.openState = false;
        this.replacementStatus = null;
        this.submitted = false;
        this.fistStatusRegister = false;
    }

    getInscriptionReplacementAction() {
        if (this.replacementStatus.isEditing) {
            return ReplacementStatesService.updateReplacementState(this.$props.replacementListCandidateId, this.replacementStatus.infoToServer());
        }
        return ReplacementStatesService.addReplacementState(this.$props.replacementListCandidateId, this.replacementStatus.infoToServer());
    }

    save() {
        this.submitted = true;
        if (this.$refs.replacementStatusForm.querySelectorAll(':invalid').length) {
            return;
        }
        this.loading = true;
        const action = this.getInscriptionReplacementAction();
        action.then(response => {
            if (!this.replacementStatus.isEditing) {
                this.replacementStatus.id = response.id;
            }
            this.manageDocuments(this.replacementStatus.documents)
                .then(() => {
                    this.openState = false;
                    this.loading = false;
                    this.submitted = false;
                    this.fistStatusRegister = false;
                    ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                    this.initList();
                    this.$emit('updateReplacementListIsModify');
                })
                .catch(() => this.loading = false);
        })
        .catch(() => this.loading = false);
    }

    manageDocuments(documents) {
        return new Promise<void>((resolve, reject) => {
            if (documents.length && (Number(this.replacementStatus.status) !== StatusReplacement.enum.AVAILABLE && Number(this.replacementStatus.status) !== StatusReplacement.enum.WORKING)) {
                const requests: any[] = [];
                documents.forEach(file => {
                    if (!file.id) {
                        requests.push(ReplacementStatesService.addFileReplacementState(this.replacementStatus, file.toServer(), this.$props.replacementListCandidateId));
                    } else if (file.delete) {
                        requests.push(ReplacementStatesService.removeFileFromReplacementState(this.replacementStatus, file.toServer(), this.$props.replacementListCandidateId));
                    }
                });
                Promise.all(requests)
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
            } else {
                resolve();
            }
        }).catch(error => console.log(error));
    }

    addFile(file) {
        if (this.replacementStatus.documents.some((doc: ReplacementDocument) => file.name === doc.fileName)) {
            return;
        }
        file.fileName = file.name;
        this.replacementStatus.addDocument(file);
    }

    removeFile(document: ReplacementDocument) {
        if (!document.id) {
            const index = this.replacementStatus.documents.indexOf(document);
            if (index >= 0) {
                this.replacementStatus.documents.splice(index, 1);
            }
            return;
        }
        document.delete = true;
    }

    getInscriptionReplacementState() {
        ReplacementStatesService.getReplacementStates(this.$props.replacementListCandidateId)
            .then(response => {
                this.replacementData = new ReplacementInscriptionData(response);
                this.loading = false;
                this.$nextTick(() => {
                    notification.initialize();
                });
            })
            .catch(() => this.loading = false);
    }

    downloadFile(file) {
        DocumentService.download(file);
    }

    initList() {
        this.loading = true;
        this.getInscriptionReplacementState();
    }

    mounted() {
        this.initList();
    }
}
