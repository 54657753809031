import Enum from '../interfaces/IEnum';

export const YesNo: Enum = {
    translations: {
        0: 'lang.shared.no',
        1: 'lang.shared.yes'
    },
    enum: {
        NO : 0,
        YES : 1
    }
};
