import moment from 'moment';

export default class JobOffer {
    private _emailSubject: string;
    private _processTitle: string;
    private _offerExpires: moment.Moment;
    private _jobDescription: string;
    private _candidates: any;
    private _replacementListId: string;
    private _receiverType: number | null = null;
    private _sendTo: number | null = null;
    private _sendFrom: number | null = null;
    private _includeCandidatesWithJobProposals: boolean = false;

    public constructor(data: any) {
        this._emailSubject = data.emailSubject;
        this._processTitle = data.processTitle;
        this._offerExpires = data.offerExpires;
        this._jobDescription = data.jobDescription;
        this._candidates = data.candidates;
        this._replacementListId = data.replacementListId;
    }

    public get emailSubject() {
        return this._emailSubject;
    }

    public set emailSubject(emailSubject: string) {
        this._emailSubject = emailSubject;
    }

    public get processTitle() {
        return this._processTitle;
    }
    public set processTitle(processTitle: string) {
        this._processTitle = processTitle;
    }

    public get offerExpires() {
        return this._offerExpires;
    }
    public set offerExpires(offerExpires: moment.Moment) {
        this._offerExpires = offerExpires;
    }

    public get jobDescription() {
        return this._jobDescription;
    }
    public set jobDescription(jobDescription: string) {
        this._jobDescription = jobDescription;
    }

    public get candidates() {
        return this._candidates;
    }
    public set candidates(candidates: any) {
        this._candidates = candidates;
    }

    public get replacementListId() {
        return this._replacementListId;
    }
    public set replacementListId(replacementListId: any) {
        this._replacementListId = replacementListId;
    }

    public get receiverType() {
        return this._receiverType;
    }
    public set receiverType(receiverType: any) {
        this._receiverType = receiverType;
    }

    public get sendTo() {
        return this._sendTo;
    }
    public set sendTo(sendTo: any) {
        this._sendTo = sendTo;
    }

    public get sendFrom() {
        return this._sendFrom;
    }
    public set sendFrom(sendFrom: any) {
        this._sendFrom = sendFrom;
    }
    
    public get includeCandidatesWithJobProposals() {
        return this._includeCandidatesWithJobProposals;
    }
    public set includeCandidatesWithJobProposals(includeCandidatesWithJobProposals: boolean) {
        this._includeCandidatesWithJobProposals = includeCandidatesWithJobProposals;
    }
        
    public toServer() {
        return {
            emailSubject: this._emailSubject,
            processTitle: this._processTitle,
            offerExpires: this._offerExpires,
            jobDescription: this._jobDescription,
            candidates: this._candidates,
            replacementListId: this._replacementListId
        };
    }
}
