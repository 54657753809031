export default class CandidateReplacement {
    private _replacementListId: string;
    private _replacementListCandidateId: string;
    private _title: string;

    public constructor(data: any) {
        this._replacementListId = data.replacementListId;
        this._replacementListCandidateId = data.replacementListCandidateId;
        this._title = data.title;
    }

    public get replacementListId() {
        return this._replacementListId;
    }

    public get replacementListCandidateId() {
        return this._replacementListCandidateId;
    }

    public get title() {
        return this._title;
    }
}
