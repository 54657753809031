export default class ValidationService {
    public static validateFormRequired(form: HTMLFormElement | HTMLElement): boolean {
        let errorInputs;
        if (form) {
            this.removeClassError(form);
            errorInputs = form.querySelectorAll(':invalid, .invalid-date, .invalid-dni, .invalid-editor, .invalid-format-file, .invalid-email');
            errorInputs.forEach((input: any) => {
                if (!input.closest('.form-element-validation').classList.contains('invalid-date')) {
                    input.closest('.form-element-validation').classList.add('error');
                    input.closest('.form-element-validation').classList.add(this.getclassByError(input));
                }
            });

        }

        return form && errorInputs ? !errorInputs.length : false;

    }

    public static removeClassError(form: HTMLFormElement | HTMLElement) {
        if (form) {
            const inputs = form.querySelectorAll('input, select');
            inputs.forEach((input: any) => {
                input.closest('.form-element-validation').classList.remove('error');
                input.closest('.form-element-validation').classList.remove('error-typeMismatch', 'error-rangeUnderflow', 'error-rangeOverflow', 'error-stepMismatchWithStep', 'error-stepMismatch', 'error-valueMissing', 'error-invalidDni');
            });
        }
    }

    public static getclassByError(input: any) {
        if (input.validity.patternMismatch) {
            return 'error-patternMismatch';
        }
        if (input.validity.typeMismatch) {
            return 'error-typeMismatch';
        }
        if (input.validity.rangeUnderflow) {
            return 'error-rangeUnderflow';
        }
        if (input.validity.rangeOverflow) {
            return 'error-rangeOverflow';
        }
        if (input.validity.stepMismatch) {
            return input.step && !Number.isInteger(input.step) ? 'error-stepMismatchWithStep' : 'error-stepMismatch';
        }
        if (input.validity.valueMissing) {
            return 'error-valueMissing';
        }
        if (input.validity.invalidDni) {
            return 'error-invalidDni';
        }
    }

    public static isValidNif(value: any) {
        if (!value) {
            return false;
        }
        value = value.toUpperCase();

        // Basic format test
        if (!value.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
            return false;
        }

        // Test NIF
        if (/^[0-9]{8}[A-Z]{1}$/.test(value)) {
            return ('TRWAGMYFPDXBNJZSQVHLCKE'.charAt(value.substring(8, 0) % 23) === value.charAt(8));
        }
        // Test specials NIF (starts with K, L or M)
        if (/^[KLM]{1}/.test(value)) {
            return (value[8] === String.fromCharCode(64));
        }

        return false;
    }

    public static isValidNie(value: any) {
        if (!value) {
            return false;
        }
        value = value.toUpperCase();

        // Basic format test
        if (!value.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
            return false;
        }

        // Test NIE
        // T
        if (/^[T]{1}/.test(value)) {
            return (value[8] === /^[T]{1}[A-Z0-9]{8}$/.test(value));
        }

        // XYZ
        if (/^[XYZ]{1}/.test(value)) {
            return (
                value[8] === 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(
                    value.replace('X', '0')
                        .replace('Y', '1')
                        .replace('Z', '2')
                        .substring(0, 8) % 23
                )
            );
        }

        return false;
    }

    public static isValidPassword(value: any): boolean {
        let isValid = true;
        // Test Password (Anything with less than eight characters OR anything with no numbers OR anything with no uppercase OR or anything with no lowercase OR anything with no special characters.
        //                If anything matches that, then it's an invalid password)
        const regexp = /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/g;

        if (value) {
            isValid = !regexp.test(value);
        }

        return isValid;
    }

    public static isValidMail(value: any): boolean {
        // Test Mail (can contain uppercase, lowercase, numbers, and special characters before the @
        //            after the @ can contain uppercase, lowercase, numbers, and hyphens followed by a dot
        //            after the dot can contain uppercase, lowercase, numbers, and hyphens and dots)
        const regexp = /^[^@\s]+@[^@\s]+\.[a-zA-Z0-9]{2,}$/;
        return regexp.test(value);
    }

    public static isValidPostCode(value: any): boolean {
        // Test Post Code (cant contain five zeros and can contain numbers)
        const regexp = /^(?!00000)\d{5}/;
        return regexp.test(value);
    }

    public static validateErrorCollapsableBox() {
        const errorCollapsable = document.querySelectorAll('.collapsable-box.error');
        errorCollapsable.forEach((box: any) => {
            box.classList.remove('error');
        });
        const inputError = document.querySelectorAll('.md-input .form-element-validation, .md-input.md-textarea.form-element-validation');
        if (inputError) {
            inputError.forEach(el => {
                const input = el.closest('.error');
                if (input) {
                    const box = input.closest('.collapsable-box');
                    if (box) {
                        box.classList.add('error');
                    }
                }
            });
        }
    }

    public static validateTextEditor(form: HTMLFormElement | HTMLElement): boolean {
        let errorTextarea;
        if (form) {
            errorTextarea = form.querySelectorAll('textarea:not(.filled)');
        }
        return form && errorTextarea.length > 0;
    }
}
