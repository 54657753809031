export default class NoticeDocument {
    private _documentId: string;
    private _receiverType: number | null = null;
    private _examId: string;

    public constructor(data: any) {
        this._documentId = data.documentId;
        this._examId = data.examId;
    }

    public get documentId() {
        return this._documentId;
    }

    public set documentId(value: string) {
        this._documentId = value;
    }

    public get receiverType() {
        return this._receiverType;
    }

    public set receiverType(value: any) {
        this._receiverType = value;
    }

    public get examId() {
        return this._examId;
    }

    public set examId(value: string) {
        this._examId = value;
    }

    public toServer() {
        return {
            documentId: this._documentId,
            receiverType: this._receiverType,
            examId: this._examId
        };
    }
}
