import Enum from '../interfaces/IEnum';

export const PaymentType: Enum = {
    translations: {
        1: 'lang.shared.creditCard',
        2: 'lang.shared.creditCardOrProof'
    },
    enum: {
        NONE: 0,
        CREDIT_CARD: 1,
        CREDIT_CARD_OR_PROOF: 2
    }
};
