export default class Security {
    private _twoFactorMandatory: boolean;
    private _timeRequest2FAAgainInDays: number;
    private _verificationCodeExpirationTimeInMinutes: number;

    public constructor(data: any) {
        this._twoFactorMandatory = data ? data.twoFactorMandatory : null;
        this._timeRequest2FAAgainInDays = data ? data.timeRequest2FAAgainInDays : null;
        this._verificationCodeExpirationTimeInMinutes = data ? data.verificationCodeExpirationTimeInMinutes : null;
    }

    public get twoFactorMandatory() {
        return this._twoFactorMandatory;
    }

    public get timeRequest2FAAgainInDays() {
        return this._timeRequest2FAAgainInDays;
    }

    public get verificationCodeExpirationTimeInMinutes() {
        return this._verificationCodeExpirationTimeInMinutes;
    }
}
