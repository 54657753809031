export default abstract class Inscription {
    protected _id: string;
    protected _nif: string;
    protected _registry: number;
    private _fullName: string;

    public constructor(data: any) {
        this._id = data.id || data.inscriptionId;
        this._nif = data.nif || data.personalData.personalDataDetails.nif;
        this._fullName = data.fullName;
        this._registry = data.registry;
    }

    public get id() {
        return this._id;
    }
    public set id(id: string ) {
        this._id = id;
    }

    public get nif() {
        return this._nif;
    }
    public set nif(nif: string ) {
        this._nif = nif;
    }

    public get fullName() {
        return this._fullName;
    }
    public set fullName(fullName: string ) {
        this._fullName = fullName;
    }

    public get registry() {
        return this._registry;
    }
    public set registry(registry: number ) {
        this._registry = registry;
    }
}
