import Enum from '../interfaces/IEnum';

export const PayExemptionTypes: Enum = {
    translations: {
        0: 'lang.shared.noConfigured',
        1: 'lang.shared.none',
        2: 'lang.shared.unemployed',
        4: 'lang.shared.disabilityExemption',
        8: 'lang.shared.generalLargeFamily',
        16: 'lang.shared.specialLargeFamily',
        32: 'lang.shared.victimTerrorism',
        64: 'lang.shared.noBenefit',
        128: 'lang.shared.noTopSMI',
        256: 'lang.shared.unemployedLongTerm'
    },
    enum: {
        NONE: 0,
        NOREASON: 1,
        UNEMPLOYED: 2,
        DISABILITY: 4,
        GENERALLARGEFAMILY: 8,
        SPECIALLARGEFAMILY: 16,
        TERRORISMVICTIM: 32,
        NOBENEFIT: 64,
        NOTOPSMI: 128,
        UNEMPLOYEDLONGTERM: 256
    }
};
