import Enum from '../interfaces/IEnum';

export const ProcessStatus: Enum = {
    translations: {
        0: 'lang.shared.hidden',
        1: 'lang.shared.inProgress'
    },
    classStyles: {
        0: 'hidden',
        1: 'progress'
    },
    enum: {
        HIDDEN: 0,
        IN_PROGRESS: 1
    }
};
