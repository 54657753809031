import Enum from '../interfaces/IEnum';

export const FileTypes: Enum = {
    translations: {
        0: 'lang.fileTypes.requirement',
        1: 'lang.fileTypes.disability',
        2: 'lang.fileTypes.payExemption',
        3: 'lang.fileTypes.feePayment',
        4: 'lang.fileTypes.merit',
        5: 'lang.fileTypes.other',
        6: 'lang.fileTypes.creditableExams'
    },
    enum: {
        REQUIREMENT: 0,
        DISABILITY: 1,
        PAY_EXEMPTION: 2,
        FEE_PAYMENT: 3,
        MERIT: 4,
        OTHER: 5,
        CREDITABLEEXAMS: 6
    }
};
