
export default class Authorization {
    private _id: number;
    private _text: string;
    private _value: boolean;

    public constructor(data: any) {
        this._text = data.text;
        this._value = data.value;
        this._id = data.id;
    }

    public get id() {
        return this._id;
    }

    public get text() {
        return this._text;
    }

    public get value() {
        return this._value;
    }
    public set value(newValue: boolean) {
        this._value = newValue;
    }

}
