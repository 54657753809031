import Enum from '../interfaces/IEnum';

export const OepDocumentType: Enum = {
    translations: {
        0: 'lang.shared.otherTypes',
        1: 'lang.shared.inscriptionModel',
        2: 'lang.shared.bases',
        3: 'lang.shared.listAdmittedExcluded',
        4: 'lang.layout.scores',
        5: 'lang.shared.court',
        6: 'lang.shared.approved'
    },
    enum: {
        OTHER: 0,
        INSCRIPTION: 1,
        RULES: 2,
        LISTS: 3,
        SCORES: 4,
        COURT: 5,
        APPROVED: 6
    }
};
