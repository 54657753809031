import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'SdToggleMultiple'
})

export default class SdToggleMultiple extends Vue {
    @Prop() value: any;
    @Prop() color?: boolean;
    @Prop() isNotNullable?: boolean;
    @Prop() labels?: object;
    @Prop() size?: string;
    statusActive = this.value;

    changeToggleMultiple(status: number) {
        const statusPrev = this.statusActive;
        if (this.isNotNullable) {
            this.$emit('input', !this.value);
            this.$emit('click');
            return;
        }
        this.statusActive = statusPrev === status ? null : status; 
        this.$emit('input', this.statusActive);
    }
}
