export const DateFormatStore = {
    namespaced: true,
    state: {
        dateFormat: ''
    },
    mutations: {
        setDateFormat(state, format: string) {
            state.dateFormat = format;
        }
    },
    getters: {
    }
};
