export default class FileService {
    public static getFileName(response) {
        let filename = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename\*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/UTF-8''/g, '');
            }
        }
        return decodeURIComponent(filename);
    }
}
