import { FilterTypes } from '@/Domain/enum';

const filters = [
    {
        type: FilterTypes.INPUT,
        label: 'lang.replacement.searchByTitleOrPosition',
        param: ['description', 'positionName'],
        icon: 'fa-search'
    }
];

export default filters;
