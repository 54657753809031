import { RuleCriteriaType } from '@/Domain/enum';

export default class RuleTieBreakCriteria {
    private _ruleTieBreakCriteriaId: string;
    private _ruleType: number | null;
    private _ruleDescription: string | null;
    private _order: number;
    private _groupPosition: number;
    private _isGroupable: boolean = false;
    private _isGrouped: boolean = false;

    public constructor(data: any, groupPosition: number) {
        this._ruleTieBreakCriteriaId = data.ruleTieBreakCriteriaId;
        this._ruleType = data.ruleType;
        this._ruleDescription = data.ruleDescription;
        this._order = data.order || 0;
        this._groupPosition = groupPosition;
    }
   
    public get ruleTieBreakCriteriaId() {
        return this._ruleTieBreakCriteriaId;
    }

    public set ruleTieBreakCriteriaId(ruleTieBreakCriteriaId: string) {
        this._ruleTieBreakCriteriaId = ruleTieBreakCriteriaId;
    }

    public get ruleType() {
        return this._ruleType;
    }

    public set ruleType(ruleType: number | null) {
        this._ruleType = ruleType;
    }

    public get ruleDescription() {
        return this._ruleDescription;
    }

    public set ruleDescription(ruleDescription: string | null) {
        this._ruleDescription = ruleDescription;
    }

    public get order() {
        return this._order;
    }

    public set order(order: number) {
        this._order = order;
    }

    public get groupPosition() {
        return this._groupPosition;
    }

    public set groupPosition(groupPosition: number) {
        this._groupPosition = groupPosition;
    }

    public get isGroupable() {
        return this._isGroupable;
    }

    public set isGroupable(isGroupable: boolean) {
        this._isGroupable = isGroupable;
    }

    public get isGrouped() {
        return this._isGrouped;
    }

    public set isGrouped(isGrouped: boolean) {
        this._isGrouped = isGrouped;
    }

    public isCriteriaExamOrMeritType() {
        return this.isCriteriaExamType() || this.isCriteriaMeritType();
    }

    public isCriteriaAgeType() {
        return this.ruleType === RuleCriteriaType.AGE;
    }

    public isCriteriaExamType() {
        return this.ruleType === RuleCriteriaType.EXAMS;
    }

    public isCriteriaMeritType() {
        return this.ruleType === RuleCriteriaType.MERITS || this.ruleType === RuleCriteriaType.MERITGROUP || this.ruleType === RuleCriteriaType.MERITSUBGROUP;
    }
    
    public toServer() {
        return {
            ruleTieBreakCriteriaId: this._ruleTieBreakCriteriaId,
            ruleType: this._ruleType,
            ruleDescription: (this._ruleDescription as string).toString(),
            order: this._order
        };
    }
}
