import TranslationService from '@/Application/Services/TranslationService';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MultiLanguage } from '@/Domain/Entities';
import { Constants } from '@/Domain/enum';
import { mapGetters } from 'vuex';

/**
 * Component to translate texts
 */
@Component({
    computed: {       
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
    }
})
export default class AutoTranslate extends Vue {
    /** Object with text to be translate */
    @Prop() contentToTranslate!: MultiLanguage;
    /** Languages availables */
    availableLanguages!: string[];
    /** Constants */
    Constants = Constants;
    loading: boolean = false;

    /**
     * @public
     * Request the translation from the service and issue the translated content
     */
    async getTranslation() {
        this.loading = true;
        if (!this.contentToTranslate[this.availableLanguages[Constants.language.primary]]) {
            this.loading = false;
            return;
        }
        const translatedContent = await TranslationService.translate(this.contentToTranslate[this.availableLanguages[Constants.language.primary]], this.availableLanguages[Constants.language.secondary]);
        this.loading = false;
        this.$emit('translatedContent', translatedContent);
    }
}
