import Enum from '../interfaces/IEnum';

export const ReplacementReasons: Enum = {
    translations: {
        0: {
            0: 'lang.reasons.rejectOffer',
            1: 'lang.reasons.notPresentSign',
            2: 'lang.reasons.notMeetRequirements',
            3: 'lang.reasons.disciplinaryFile',
            4: 'lang.reasons.notPassTrialPeriod',
            5: 'lang.filter.unreachable',
            6: 'lang.reasons.criminalRecord',
            7: 'lang.reasons.disclaimerParticipateBag',
            8: 'lang.reasons.voluntaryCessation'
        },
        200: {
            200: 'lang.reasons.unemploymentPublic',
            201: 'lang.reasons.childCustody',
            202: 'lang.reasons.genderViolence',
            203: 'lang.reasons.longtermIllness',
            204: 'lang.reasons.maternity',
            205: 'lang.reasons.dependentPerson',
            206: 'lang.reasons.marriage',
            207: 'lang.filter.working',
            208: 'lang.reasons.studies',
            209: 'lang.reasons.classroomCourse',
            210: 'lang.reasons.changeResidence',
            211: 'lang.reasons.seriousIllness',
            212: 'lang.reasons.inexcusableDuty',
            213: 'lang.reasons.deathFamily',
            214: 'lang.reasons.pendingJustification'
        }
    },
    enum: {
        0: {
            REJECTOFFER: 0,
            NOTPRESENTSIGN: 1,
            NOTMEETREQUIREMENTS: 2,
            DISCIPLINARYFILE: 3,
            NOTPASSTRIALPERIOD: 4,
            UNREACHABLE: 5,
            CRIMINALRECORD: 6,
            DISCLAIMERPARTICIPATEBAG: 7,
            VOLUNTARYCESSATION: 8
        },
        200: {
            UNEMPLOYMENTPUBLIC: 200,
            CHILDCUSTODY: 201,
            GENDERVIOLENCE: 202,
            LONGTERMILLNESS: 203,
            MATERNITY: 204,
            DEPENDENTPERSON: 205,
            MARRIAGE: 206,
            WORKING: 207,
            STUDIES: 208,
            CLASSROOMCOURSE: 209,
            CHANGERESIDENCE: 210,
            SERIOUSILLNESS: 211,
            INEXCUSABLEDUTY: 212,
            DEATHFAMILY: 213,
            PENDINGJUSTIFICATION: 214
        }
    }
};
