import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SdModal extends Vue {
    @Prop() title!: string;
    @Prop() singleButtonText!: string;
    @Prop() actionButtonText!: string;
    @Prop() secondaryButtonText!: string;
    @Prop() value!: boolean;
    @Prop() iconHeader!: string;
    @Prop() iconInfo!: string;
    @Prop() hideButtonClose!: boolean;
    @Prop() disabledActionButton!: boolean;

    close($event: any) {
        this.$emit('input', false);
        this.$emit('close', $event);
    }
}
