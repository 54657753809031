import { render, staticRenderFns } from "./BOEPEdit.html?vue&type=template&id=33165b9a&external"
import script from "./BOEPEdit.ts?vue&type=script&lang=ts&external"
export * from "./BOEPEdit.ts?vue&type=script&lang=ts&external"
import style0 from "./BOEPEdit.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports