
import { ToasterService } from '../ToasterService';
import i18n from '../../lang';

export const errorsDictionary = [{
    errorText: 'ProcessHasInscriptions',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.noDeleteProcess') as string)
}, {
    errorText: 'ReplacementContractDatesOverlapped',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.contractsOverloapped') as string)
}, {
    errorText: 'ProcessHasTPVInscriptions',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.noDeleteProcessTPV') as string)
}, {
    errorText: 'InvalidPhase',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.InvalidPhase') as string)
}, {
    errorText: 'WithoutLegalTexts',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.withoutLegalTexts') as string)
}, {
    errorText: 'RankCreated',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.rankCreated') as string)
}, {
    errorText: 'ExamHasGrades',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.examGradedError') as string)
}, {
    errorText: 'ExamBlockedForOrder',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.orderExamError') as string)
}, {
    errorText: 'ValidateTieBreaksReplacementListError',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.tieBreaksNoSolved') as string)
}, {
    errorText: 'NifOrEmailInUse',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.nifOrEmailInUse') as string)
}, {
    errorText: 'AdminNifOrEmailInUse',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.adminNifOrEmailInUse') as string, '', 'https://savia.atlassian.net/servicedesk/customer/portal/2', i18n.t('lang.toaster.openTicket') as string)
}, {
    errorText: 'InvalidInscriptionProcessHidden',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.invalidInscriptionProcessHidden') as string)
}, {
    errorText: 'rankedInscriptionsMeritsAportationPeriodStillOpen',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.meritsAportationPeriodStillOpen') as string)
}, {
    errorText: 'IncorrectRequestGrades',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.incorrectRequestGrades') as string)
}, {
    errorText: 'processConfigurationPayExemptionAlreadyExists',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.payExemptionAlreadyExists') as string)
}, {
    errorText: 'processConfigurationAuthorizationEntitiesAlreadyExists',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.processConfigAuthorizationAlreadyExists') as string)
}, {
    errorText: 'processConfigurationExamAlreadyExists',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.processConfigExamAlreadyExists') as string)
}, {
    errorText: 'processConfigurationGroupAlreadyExists',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.processConfigGroupAlreadyExists') as string)
}, {
    errorText: 'FileNotFoundInAzure',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.fileNotFoundInAzure') as string)
}, {
    errorText: 'NoCandidateInProcess',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.noCandidateInProcess') as string)
}, {
    errorText: 'NoMatchFilterCandidateInProcess',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.noMatchFilterCandidateInProcess') as string)
}, {
    errorText: 'GenderNotValid',
    toasterError: () => ToasterService.showError(i18n.t('lang.toaster.genderNotValid') as string)
}];
