import { PayExemptionTypes } from '@/Domain/enum';

export default class PayExemptionProcess {
    private _payExemptionType: number;
    private _autonomousCommunity: number | null;

    public constructor(data: any) {
        this._payExemptionType = data.payExemptionType || PayExemptionTypes.enum.NONE;
        this._autonomousCommunity = data.autonomousCommunity;
    }

    public get payExemptionType() {
        return this._payExemptionType;
    }

    public set payExemptionType(newPayExemptionType: number) {
        this._payExemptionType = newPayExemptionType;
    }

    public get autonomousCommunity() {
        return this._autonomousCommunity;
    }

    public set autonomousCommunity(newAutonomousCommunity: number | null) {
        this._autonomousCommunity = newAutonomousCommunity;
    }

    public get isExceptionPaymentFamily() {
        return this.payExemptionType === PayExemptionTypes.enum.GENERALLARGEFAMILY || this.payExemptionType === PayExemptionTypes.enum.SPECIALLARGEFAMILY;
    }

    public autonomousCommunityVissible() {
        if (!this.isExceptionPaymentFamily) {
            this.autonomousCommunity = null;
        }
        return this.isExceptionPaymentFamily;
    }

    public toServer(inscriptionId, myAutonomousCommunity) {
        return {
            inscriptionId,
            payExemptionType: this._payExemptionType,
            autonomousCommunity: myAutonomousCommunity
        };
    }

    public toServerInscription() {
        return {
            payExemptionType: this._payExemptionType,
            autonomousCommunity: this.isExceptionPaymentFamily ? this._autonomousCommunity : null
        };
    }
}
