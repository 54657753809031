import moment from 'moment';

export default class ShippingContact {
    private _id: string;
    private _inscriptionId: string;
    private _jobPositionId: string;
    private _candidateToken: string;
    private _responseDate: moment.Moment;
    private _order: number;
    private _round: number;
    private _fullName: string;
    private _nif: string;

    public constructor(data: any) {
        this._id = data.id;
        this._inscriptionId = data.inscriptionId;
        this._jobPositionId = data.jobPositionId;
        this._candidateToken = data.candidateToken;
        this._responseDate = moment.utc(data.responseDate).local();
        this._order = data.order;
        this._round = data.round;
        this._fullName = data.fullName;
        this._nif = data.nif;
    }

    public get id() {
        return this._id;
    }

    public get inscriptionId() {
        return this._inscriptionId;
    }

    public get jobPositionId() {
        return this._jobPositionId;
    }

    public get candidateToken() {
        return this._candidateToken;
    }

    public get responseDate() {
        return this._responseDate;
    }

    public get order() {
        return this._order;
    }

    public get round() {
        return this._round;
    }

    public get fullName() {
        return this._fullName;
    }

    public get nif() {
        return this._nif;
    }
}
