import moment from 'moment';
export default class ReplacementDocument {

    private _id: string;
    private _fileDate: moment.Moment;
    private _fileName: string;
    private _extension: string;
    private _size: number;
    private _type: number;
    private _formFile: any;
    private _loading: boolean;
    private _url: string;
    private _delete: boolean;
    private _uploaderName: string | '';
    private _uploaderFirstSurname: string | '';
    private _uploaderSecondSurname: string | '';
    private _allowRemove: boolean = true;

    constructor(file: any) {
        this._id = file.id;
        this._fileDate = moment.utc(file.fileDate);
        this._fileName = file.fileName;
        this._extension = file.extension;
        this._size = file.size;
        this._type = file.type || 0;
        this._formFile = file;
        this._loading = file.loading;
        this._url = process.env.VUE_APP_ROOT_API + file.url + '?origin=' + document.location.origin;
        this._delete = false;
        this._uploaderName = file.uploader ? file.uploader.name : '';
        this._uploaderFirstSurname = file.uploader ? file.uploader.firstSurname : '';
        this._uploaderSecondSurname = file.uploader ? file.uploader.secondSurname : '';
    }

    public get id() {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get fileDate() {
        return this._fileDate;
    }

    public set fileDate(value: moment.Moment) {
        this._fileDate = value;
    }

    public get fileName() {
        return this._fileName;
    }

    public set fileName(value: string) {
        this._fileName = value;
    }

    public get extension() {
        return this._extension;
    }

    public set extension(value: string) {
        this._extension = value;
    }

    public get size() {
        return this._size;
    }

    public set size(value: number) {
        this._size = value;
    }

    public get type() {
        return this._type;
    }

    public set type(value: number) {
        this._type = value;
    }

    public get formFile() {
        return this._formFile;
    }

    public set formFile(value: File) {
        this._formFile = value;
    }

    public get loading(): boolean {
        return this._loading;
    }

    public set loading(value: boolean) {
        this._loading = value;
    }

    public get url() {
        return this._url;
    }

    public set url(value: string) {
        this._url = value;
    }

    public get delete(): boolean {
        return this._delete;
    }

    public set delete(value: boolean) {
        this._delete = value;
    }
    
    public get uploaderName() {
        return this._uploaderName;
    }

    public get uploaderFirstSurname() {
        return this._uploaderFirstSurname;
    }
    
    public get uploaderSecondSurname() {
        return this._uploaderSecondSurname;
    }
    
    public get allowRemove() {
        return this._allowRemove;
    }

    public uploaderNameAdmin() {
        return `${this.uploaderName} ${this.uploaderFirstSurname} ${this.uploaderSecondSurname}`;
    }

    public hourFormat() {
        const hourFormat = 'HH:mm';
        return this.fileDate ? moment(this.fileDate.toISOString()).format(hourFormat) : '';
    }

    public toServer() {
        return {
            id: this.id,
            fileName: this.fileName,
            fileDate: this.fileDate.toISOString(),
            type: this.type || 0,
            formFile: this.formFile,
            delete: this.delete
        };
    }
}
