import { Component, Prop, Vue } from 'vue-property-decorator';
import { RuleTieBreak, RuleTieBreakCriteria } from '@/Domain/Entities';
import * as sdPanel from '../../../node_modules/saviafront/lib/js/compiled/sd-panel';
import { RuleCriteriaType, RuleCriteriaTypeDefinitions, RuleCriteriaTypeTranslations, AgeTranslations, MeritGroupType, BaseMeritsType } from '@/Domain/enum';
import { mapState } from 'vuex';
import i18n from '../../lang';

/**
 * Component to add tie break criteria
 */
@Component({
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' })
    }
})
export default class TieBreakCriteria extends Vue {
    /** Indicates the type of tiebreaker */
    @Prop() criteria!: RuleTieBreakCriteria;
    /** Indicates the tie-breaking rule */
    @Prop() rule!: RuleTieBreak;
    ruleCriteriaType = RuleCriteriaType;
    ruleCriteriaTypeTranslations = RuleCriteriaTypeTranslations;
    ruleCriteriaTypeDefinitions = RuleCriteriaTypeDefinitions;
    ageTranslations = AgeTranslations;
    meritGroupType = MeritGroupType;
    baseMeritsType = BaseMeritsType;
    showMeritsModal: boolean = false;
    showExamsModal: boolean = false;
    showAgeModal: boolean = false;
    showRuleTypesModal: boolean = false;
    currentLanguage!: string;

    getNameElement() {
        const element = this.rule.meritElements.find(meritElement => meritElement.id === this.criteria.ruleDescription);
        if (element && element.type === null) {
            return i18n.t(element.description);
        }
        return element ? element.description[this.currentLanguage] : null;
    }
    
    prepareSd() {
        this.$nextTick(() => {
            sdPanel.initialize(this.$refs.panel);
        });
    }

    showModal() {
        if (this.criteria.ruleType === RuleCriteriaType.EXAMS) {
            this.showExamsModal = true;
            return;
        }
        this.showMeritsModal = true;
    }

    /**
     * @public
     * Set the 'showAgeModal' property to true
     */
    showModalAge() {
        this.showAgeModal = true;
    }

    /**
     * @public
     * Set all modal properties to false
     */
    closeModal() {
        this.showExamsModal = false;
        this.showMeritsModal = false;
        this.showRuleTypesModal = false;
        this.showAgeModal = false;
    }
    
    cantSelectRuleType() {
        return this.rule.ruleTieBreakCriteriaGroups[this.criteria.groupPosition].ruleTieBreakCriterias.length > 1 && this.criteria.isCriteriaExamOrMeritType();
    }

    canDeleteCriteria() {
        return this.rule.getCriterias().length > 1 && !this.cantSelectRuleType();
    }

    showRuleTypes() {
        this.showRuleTypesModal = true;
    }
}
