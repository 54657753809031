import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Constants } from '@/Domain/enum';

@Component({
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' })
    }
})

export default class LegalWarning extends Vue {
    currentLanguage!: string;

    constants = Constants;
}
