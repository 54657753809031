import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
    }
})

export default class BoxHeader extends Vue {
    @Prop() icon: any;
    @Prop() value: any;
    @Prop() title: any;
}
