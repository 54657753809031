import { Component, Prop, Vue } from 'vue-property-decorator';
import { MeritGroup, Process } from '@/Domain/Entities';
import { Modal, EditNewGroupMerit, BaseMerits } from '@/Components';
import { FormMode, MeritGroupType, MeritRatingType, BaseMeritsType, YesNo, Constants } from '@/Domain/enum';
import MeritGroupsService from '@/Services/MeritGroupsService';
import { OrderService } from '@/Application/Services/OrderService';
import { mapState } from 'vuex';

@Component({
    components: {
        Modal,
        EditNewGroupMerit,
        BaseMerits
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        publishAndUsed() {
            return this.$props.value.isUsed || this.$props.value.stateToggle;
        }
    }
})
export default class Merits extends Vue {
    @Prop() value!: Process;

    currentLanguage!: string;
    publishAndUsed!: boolean;
    formMode = FormMode;
    meritRatingType = MeritRatingType;
    meritGroupType = MeritGroupType;
    baseMeritsType = BaseMeritsType;
    constants = Constants;
    yesNo = YesNo;
    showDeleteModalGroupMerit = false;
    meritGroupToRemove: MeritGroup = {} as MeritGroup;
    meritGroupsToEdit: any = null;
    newMeritGroup: MeritGroup = new MeritGroup({});
    meritGroupLimit: number = 9;
    orderService: any;

    $refs!: {
        newMeritGroupsForm: HTMLFormElement,
        newMeritForm: HTMLFormElement
    };

    hideGroupMeritForms() {
        if (!this.orderService.parentId) {
            this.orderService.parentId = this.value.id;
        }
        this.orderService.arrayToManage = this.value.meritGroups;
        this.resetGroupMeritForms();
    }

    resetGroupMeritForms() {
        this.value.isCreatingMeritGroup = false;
        this.value.isEditingMeritGroup = false;
        this.$emit('noError');
    }

    goToEditMeritGroup(meritGroup: MeritGroup) {
        this.value.isEditingMeritGroup = false;
        this.value.isCreatingMeritGroup = false;
        this.value.editingMeritGroup = meritGroup;
        this.value.editingMeritGroup.editingMeritSubGroup = null;
        this.value.editingMeritGroup.isEditingMeritSubGroup = false;
        this.value.editingMeritGroup.isCreatingMerit = false;
    }

    removeMeritGroups() {
        this.showDeleteModalGroupMerit = false;
        MeritGroupsService.removeMeritGroups(this.value.id, this.meritGroupToRemove)
            .then(response => {
                const meritGroupIndex = this.value.meritGroups.indexOf(this.meritGroupToRemove);
                this.value.meritGroups.splice(meritGroupIndex, 1);
                this.value.meritGroups.forEach((element, index) => element.order = index);
            })
            .catch(error => console.log(error));
    }

    confirmWithModalMeritGroup(meritGroup: MeritGroup) {
        if (this.publishAndUsed) {
            return;
        }
        this.meritGroupToRemove = meritGroup;
        this.showDeleteModalGroupMerit = true;
    }

    closeListShown() {
        this.value.meritGroups.forEach(meritGroup => meritGroup.isDropped = false);
    }

    getMeritGroups() {
        MeritGroupsService.getMeritGroups(this.$route.params.id)
        .then((responseMeritGroup: MeritGroup[]) => {
            this.value.meritGroups = responseMeritGroup.map(element => new MeritGroup(element));
            this.orderService = new OrderService(this.$route.params.id, this.value.meritGroups, MeritGroupsService.setMeritGroupsOrder);
        })
        .catch(error => console.log(error));
    }

    mounted() {
        if (this.$route.params.id) {
            this.getMeritGroups();
        } else {
            if (this.value.meritGroups.length === 0) {
                this.meritGroupsToEdit = {};
            }
            this.orderService = new OrderService('', this.value.meritGroups, MeritGroupsService.setMeritGroupsOrder);
        }
    }
}
