import { Candidate } from '@/Domain/Entities';

export const CandidateStore = {
    namespaced: true,
    state: {
        candidateInfo: null
    },
    mutations: {
        setCandidateInfo(state, candidateInfo: Candidate | null) {
            state.candidateInfo = candidateInfo;
        }
    },
    getters: {
        getCandidateInfo(state) {
            return state.candidateInfo;
        }
    }
};
