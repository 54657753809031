import ProcessConfig from './ProcessConfig';

export default class ProcessConfigPayExemption extends ProcessConfig {
    
    constructor(data: any) {
        super(data);
        this.type = data.payExemptionType;
        this.filterMinimumValidType = 1;
    }

    public toServer() {
        return {
            id: this.id,
            payExemptionType: this.type,
            title: this.title
        };
    }
}
