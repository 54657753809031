import { Exam, Merit, MeritGroup, MeritSubGroup, Requirement } from '@/Domain/Entities';

type ObjectTypeToManage = Exam | Merit | MeritGroup | MeritSubGroup | Requirement;

export class OrderService {
    private _parentId: string;
    private _arrayToManage: any;
    private _serviceToCall: any;
    private _objectToManage!: ObjectTypeToManage;
    private _indexElementToMove!: number;
    private _indexOldElement!: number;

    constructor(parentId: string, arrayToManage: any, serviceToCall: any) {
        this._parentId = parentId;
        this._arrayToManage = arrayToManage;
        this._serviceToCall = serviceToCall;
    }

    public get parentId() {
        return this._parentId;
    }
    public set parentId(parentId: string) {
        this._parentId = parentId;
    }
    public get arrayToManage() {
        return this._arrayToManage;
    }
    public set arrayToManage(arrayToManage: any) {
        this._arrayToManage = arrayToManage;
    }

    public get objectToManage() {
        return this._objectToManage;
    }
    public set objectToManage(objectToManage: ObjectTypeToManage) {
        this._objectToManage = objectToManage;
    }
    public get indexElementToMove() {
        return this._indexElementToMove;
    }
    public set indexElementToMove(indexElementToMove: number) {
        this._indexElementToMove = indexElementToMove;
    }
    public get indexOldElement() {
        return this._indexOldElement;
    }
    public set indexOldElement(indexOldElement: number) {
        this._indexOldElement = indexOldElement;
    }

    goTop(element) {   
        this.manageElementToMove(element);
        this.indexElementToMove--;
        this.moveElement();
        this.saveNewOrder();
    }

    goDown(element) {
        this.manageElementToMove(element);
        this.indexElementToMove++;
        this.moveElement();
        this.saveNewOrder();
    }

    manageElementToMove(objectToManage) {
        this.objectToManage = objectToManage;
        this.indexElementToMove = this.arrayToManage.findIndex( element => element.order === this.objectToManage.order);
        this.indexOldElement = this.indexElementToMove;
    }

    moveElement() {
        this.arrayToManage.splice(this.indexElementToMove, 0, this.arrayToManage.splice(this.indexOldElement, 1)[0]);
        this.arrayToManage.forEach((element, index) => element.order = index);
    }

    saveNewOrder() {
        const orderArray: string[] =  this.arrayToManage.map( (el: ObjectTypeToManage) => el.id);
        this._serviceToCall(this.parentId, orderArray, this.objectToManage)
        .catch(error => {
            this.arrayToManage.splice(this.indexOldElement, 0, this.arrayToManage.splice(this.indexElementToMove, 1)[0]);
            this.arrayToManage.forEach((element, index) => element.order = index);
        });
    }
}
