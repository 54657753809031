export default class PanelService {
    public static panelsClose() {
        if (document.querySelectorAll('.sd-panel__show').length > 0) {
            const panelsToClose = Array.from(document.querySelectorAll('.sd-panel__show'));
            panelsToClose.forEach(el => {
                el.classList.remove('sd-panel__show');
            });
        }
    }
}
