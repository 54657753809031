import { OepDocument, MultiLanguage, OnlinePaymentInfo, ProcessConfigPayExemption } from '@/Domain/Entities';
import { FileTypes } from '@/Domain/enum';

export default class ProcessRequisites {
    private _id: string;
    private _title: MultiLanguage;
    private _statusBool: boolean;
    private _reason: MultiLanguage;
    private _documents: OepDocument[];
    private _allowsDocument: boolean;
    private _type: number;
    private _onlinePaymentInfo: OnlinePaymentInfo | null;
    private _payExemptionType: number | null;
    private _payExemptionTitle: MultiLanguage;
    private _isOptional: boolean;

    public get id() {
        return this._id;
    }
    public get title() {
        return this._title;
    }
    public set title(title: MultiLanguage) {
        this._title = title;
    }
    public get allowsDocument() {
        return this._allowsDocument;
    }
    public get documents() {
        return this._documents;
    }
    public set documents(documents: OepDocument[]) {
        this._documents = documents;
    }

    public get statusBool() {
        return this._statusBool;
    }
    public set statusBool(statusBool: boolean) {
        this._statusBool = statusBool;
    }

    public get reason() {
        return this._reason;
    }

    public set reason(reason: MultiLanguage) {
        this._reason = reason;
    }

    public get type() {
        return this._type;
    }
    public set type(type: number) {
        this._type = type;
    }

    public get onlinePaymentInfo() {
        return this._onlinePaymentInfo;
    }

    public set onlinePaymentInfo(onlinePaymentInfo: OnlinePaymentInfo | null) {
        this._onlinePaymentInfo = onlinePaymentInfo;
    }

    public get payExemptionType() {
        return this._payExemptionType;
    }

    public get payExemptionTitle() {
        return this._payExemptionTitle;
    }

    public get isOptional() {
        return this._isOptional;
    }
    public set isOptional(isOptional: boolean) {
        this._isOptional = isOptional;
    }

    constructor(data: any, processConfigurationPayExemptions: ProcessConfigPayExemption[]) {
        this._id = data.id;
        this._title = data.title ? new MultiLanguage(data.title) : new MultiLanguage({});
        this._documents = data.files;
        this._statusBool = data.statusBool;
        this._reason = data.reason;
        this._allowsDocument = data.allowsDocument;
        this._reason = new MultiLanguage(data.rejectionReason);
        this._type = data.type;
        this._onlinePaymentInfo = data.onlinePaymentInfo ? new OnlinePaymentInfo(data.onlinePaymentInfo) : null;
        this._payExemptionType = data.payExemption ? new ProcessConfigPayExemption(data.payExemption).type : null;
        this._payExemptionTitle = data.payExemption ? processConfigurationPayExemptions.filter(payExemption => payExemption.type === this.payExemptionType)[0].title : new MultiLanguage({});
        this._isOptional = data.isOptional;
    }

    public isExcluded(): boolean {
        return this.statusBool === false || this.type === FileTypes.enum.OTHER && this.documents.length > 0;
    }

    public toServer() {
        return {
            id: this.id,
            statusBool: this._statusBool,
            rejectionReason: this.reason,
            isOptional: this.isOptional
        };
    }

}
