
export default class ChartData {
    private _data: number[][];
    private _colors: string[];
    private _legend: string[];

    public constructor(...args: number[][]) {
        this._data = [];
        this._colors = [];
        this._legend = [];
        args.forEach(arg => {
            this._data.push(arg);
        });
    }

    public get data() {
        return this._data;
    }

    protected get legend() {
        return this._legend;
    }

    protected set legend(value: string[]) {
        this._legend = value;
    }

    protected get colors() {
        return this._colors;
    }

    protected set colors(value: string[]) {
        this._colors = value;
    }

    protected get dataIsEmpty() {
        return this._data.reduce((previous, current) => Array.isArray(current) ? previous + current.reduce((pre, cur) => pre + cur , 0) : previous + current , 0) === 0;
    }
}
