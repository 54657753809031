import Enum from '../interfaces/IEnum';

export const Languages: Enum = {
    translations: {
        'es-ES': 'lang.shared.castellano',
        'ca-ES': 'lang.shared.catalan',
        'gl-ES': 'lang.shared.gallego',
        'eu-ES': 'lang.shared.euskera',
        'pt-PT': 'lang.shared.valenciano'
    },
    flags: {
        'es-ES': 'bandera_espana.svg',
        'ca-ES': 'bandera_catalunya.svg',
        'gl-ES': 'bandera_galicia.svg',
        'eu-ES': 'bandera_pvasco.svg',
        'pt-PT': 'bandera_valencia.svg'
    }
};
