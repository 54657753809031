import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProcessConfig } from '@/Domain/Entities';
import { mapGetters, mapState } from 'vuex';
import MasterDataService from '@/Services/MasterDataService';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../../lang';
import { ProcessConfigurationTypes, Constants } from '@/Domain/enum';
import ProcessConfigFactory from '@/Domain/Entities/ProcessConfiguration/ProcessConfigFactory';

@Component({
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
    }
})
export default class ProcessConfigCreator extends Vue {
    @Prop() processConfigType!: number;

    newProcessConfig: ProcessConfig | null = null;
    currentLanguage!: string;
    availableLanguages!: string[];

    processConfigurationTypes = ProcessConfigurationTypes;
    constants = Constants;
    submittedNew: boolean = false;
    tabActiveLanguage: string = '';
    invalidForm: any;

    $refs!: {
        processConfigCreatorForm: HTMLFormElement
    };

    mounted() {
        this.tabActiveLanguage = this.currentLanguage;
    }

    saveNewProcessConfig() {
        this.submittedNew = true;
        this.invalidForm = this.$refs.processConfigCreatorForm.querySelectorAll(':invalid').length > 0 || this.$refs.processConfigCreatorForm.querySelectorAll('.error').length > 0;

        if (this.invalidForm || this.newProcessConfig === null) {
            return;
        }
        this.newProcessConfig = ProcessConfigFactory.create(this.processConfigType, this.newProcessConfig);
        MasterDataService.addProcessConfiguration(this.processConfigType, this.newProcessConfig)
            .then(() => {
                this.handlesAddProcessConfigOnSuccess();
            }).catch(() =>  {});
    }

    createNewProcessConfig() {
        this.newProcessConfig = ProcessConfigFactory.create(this.processConfigType, {});
        this.resetActiveLanguage();
    }

    removeNewProcessConfig() {
        this.newProcessConfig = null;
        this.submittedNew = false;
    }

    private handlesAddProcessConfigOnSuccess() {
        ToasterService.showSuccess(i18n.t('lang.toaster.saveSuccess') as string);
        this.newProcessConfig = null;
        this.submittedNew = false;
        this.$emit('getProcessConfig');
    }

    private resetActiveLanguage() {
        this.tabActiveLanguage = this.availableLanguages[0];
    }
}
