import Enum from '../interfaces/IEnum';

export const ProcessConfigurationTypes: Enum = {
    translations: {
        0: 'lang.shared.exams',
        1: 'lang.shared.payExemptions',
        2: 'lang.shared.authorizations',
        3: 'lang.shared.groupSubgroup'
    },
    enum: {
        EXAMS: 0,
        PAYEXEMPTIONS: 1,
        AUTHORIZATIONS: 2,
        GROUPSUBGROUP: 3
    },
    createText: {
        0: 'lang.shared.createExamType',
        1: 'lang.shared.createPayExemption',
        2: 'lang.shared.createAuthorization',
        3: 'lang.shared.createGroupSubgroup'
    },
    addText: {
        0: 'lang.shared.addExamType',
        1: 'lang.shared.addPayExemption',
        2: 'lang.shared.addAuthorization',
        3: 'lang.shared.addGroupSubgroup'
    }
};
