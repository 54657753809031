import { Doughnut } from 'vue-chartjs';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  watch: {
    data(newVal, oldVal) {
      if (_.isEqual(newVal, oldVal)) { return; }
      (this as DoughnutChart).renderDoughnutChart();
    },
    labels(newVal, oldVal) {
      if (_.isEqual(newVal, oldVal)) { return; }
      (this as DoughnutChart).renderDoughnutChart();
    }
  }
})

export default class DoughnutChart extends Mixins(Doughnut) {
  @Prop() labels: any;
  @Prop() data: any;
  @Prop() colors: any;
  @Prop() textCenter: any;

  renderDoughnutChart() {
    // Overwriting base render method with actual data.
    this.$nextTick(() => {
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          backgroundColor: this.colors,
          data: this.data
        }
      ]
    },
      {
        legend: {
          display: false
        },
        cutoutPercentage: this.textCenter ? 75 : 50,
        tooltips: {
          enabled: !this.textCenter
        }
      });
    });
  }

  setTextCenter(value: any) {
    this.addPlugin({
      id: 'doughnutlabel',
      beforeDraw(chart: any) {
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;

        ctx.restore();
        ctx.font = '30px sans-serif';
        ctx.textBaseline = 'middle';

        const text = value[0] + '%';
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    });
  }
  
  mounted() {
    if (this.textCenter) { 
      this.setTextCenter(this.data);
    }
    this.renderDoughnutChart();
  }
}
