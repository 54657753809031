export default class PayExemption {
    private _type: number;
    private _text: string;
    private _value: boolean;
    private _amount: number | null;

    public constructor(data: any) {
        this._text = data.text;
        this._value = data.value;
        this._type = data.type;
        this._amount = data.amount;
    }

    public get type() {
        return this._type;
    }

    public get text() {
        return this._text;
    }

    public get value() {
        return this._value;
    }
    public set value(newValue: boolean) {
        this._value = newValue;
    }
    public get amount() {
        return this._amount;
    }
    public set amount(newamount: number | null) {
        this._amount = newamount;
    }
    public toServer() {
        return {
            payExemptionType: this._type,
            amount: this._amount
        };
    }
}
