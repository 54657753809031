import { Component, Prop, Vue } from 'vue-property-decorator';
import { Process, Requirement } from '@/Domain/Entities';
import { ProcessType, FormMode, Constants } from '@/Domain/enum';
import { EditNewRequeriment, Modal } from '@/Components';
import RequirementService from '@/Services/RequirementService';
import { OrderService } from '@/Application/Services/OrderService';
import { mapState } from 'vuex';

@Component({
    components: {
        EditNewRequeriment,
        Modal
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        publishAndUsed() {
            return this.$props.value.isUsed || this.$props.value.stateToggle;
        }
    }
})
export default class Requirements extends Vue {
    @Prop() value!: Process;

    processType = ProcessType;
    formMode = FormMode;
    constants = Constants;
    newRequirement: Requirement = new Requirement({});
    requirementToRemove: Requirement = {} as Requirement;
    showDeleteModal = false;
    orderService: any;

    $refs!: {
        requirementList: HTMLElement
    };

    hideRequirementForms() {
        if (!this.orderService.parentId) {
            this.orderService.parentId = this.value.id;
        }
        this.orderService.arrayToManage = this.value.requirements;
        this.value.isCreatingRequirement = false;
        this.value.isEditingRequirement = false;
    }

    confirmWithModal(requirement: Requirement) {
        this.requirementToRemove = requirement;
        this.showDeleteModal = true;
    }

    removeRequirement() {
        this.showDeleteModal = false;     
        RequirementService.removeRequirement(this.value.id, this.requirementToRemove)
            .then(() => {
                const requirementIndex = this.value.requirements.indexOf(this.requirementToRemove);
                this.value.requirements.splice(requirementIndex, 1);
                this.value.requirements.forEach((element, index) => element.order = index);
                this.$emit('updateProcess', this.value.requirements.some(requirement => requirement.isOptional));
            })
            .catch(error => console.log(error));
    }

    getRequirement() {
        RequirementService.getRequirement(this.$route.params.id)
        .then(responseRequirement => {
            this.value.requirements = responseRequirement.map(result => new Requirement(result));
            this.orderService = new OrderService(this.$route.params.id, this.value.requirements, RequirementService.setRequirementOrder);
        })
        .catch(error => console.log(error));
    }

    created() {
        if (this.$route.params.id) {
            this.getRequirement();
        } else {
            this.orderService = new OrderService('', this.value.requirements, RequirementService.setRequirementOrder);
        }
    }

}
