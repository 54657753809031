import ChartData from './ChartData';

export default class ChartProcessesReplacementList extends ChartData {
    public constructor(data: any) {
        super(data.totalCalls, data.totalBags, data.totalPostProvisions, data.totalReplacementLists);
        this.colors = ['#1976d2', '#f7ca6f', '#ed6870' , '#6acf80'];
        this.legend = ['lang.layout.calls', 'lang.layout.bags', 'lang.layout.postProvision', 'lang.layout.replacementList'];
    }

    public get totalCalls() {
        return Number(this.data[0]);
    }

    public get totalBags() {
        return Number(this.data[1]);
    }

    public get totalPostProvisions() {
        return Number(this.data[2]);
    }

    public get totalReplacementLists() {
        return Number(this.data[3]);
    }

    public get totalProcesses() {
        return this.totalCalls + this.totalBags + this.totalPostProvisions + this.totalReplacementLists;
    }
}
