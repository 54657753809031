import Enum from '../interfaces/IEnum';

export const BaseMeritsType: Enum = {
    translations: {
        0: 'lang.shared.mertis',
        1: 'lang.shared.meritSubGroup',
        2: 'lang.shared.groupsMerits'
    },
    enum: {
        MERIT: 0,
        MERITSUBGROUP: 1,
        MERITGROUP: 2
    }
};
