import { Bar } from 'vue-chartjs';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import i18n from '../../lang';
import _ from 'lodash';

@Component({
  computed: {
    invalidData() {
      return this.$props.legend.length !== this.$props.data.length;
    },
    datasets() {
      if (this.$data.invalidData) { return []; }
      return this.$props.legend.map((legend, index) => ({
        label: i18n.t(legend),
        backgroundColor: this.$props.colors[index],
        data: this.$props.data[index]
      }));
    }
  },
  watch: {
    data(newVal, oldVal) {
      if (_.isEqual(newVal, oldVal)) { return; }
      (this as BarChart).renderBarChart();
    },
    labels(newVal, oldVal) {
      if (_.isEqual(newVal, oldVal)) { return; }
      (this as BarChart).renderBarChart();
    }
  }
})
export default class BarChart extends Mixins(Bar) {
  /*
  *
  * The labels for the X-axis.
  */
  @Prop() labels!: string[];
  /*
  *
  * The legend for Chart
  */
  @Prop() legend!: string[];
  /*
  * The data to render 
  */
  @Prop() data!: number[][];
  /*
  * The color to style it 
  */
  @Prop() colors!: string[];
  invalidData!: boolean;
  datasets!: any[];

  /**
   * @public
   * Render the bar chart using Chart.js and emit the change
   */
  renderBarChart() {
    this.renderChart({
      labels: this.labels,
      datasets: this.datasets
    },
      {
        scales: {
          yAxes: [{
            display: false
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          mode: 'index',
          intersect: false
        }
      }
    );
  }

  /**
   * @public
   * Life-cycle component method
   */
  mounted() {
    this.renderBarChart();
  }
}
