import { MultiLanguageTabs, MdCheckbox, SdModal, SdButton, SdSelect, SdFileImporter, SdInputText, SdTextarea, SdInputDatepicker, SdInputNumber, SdInputNif, SdRadio, SdTag, SdSlideOver, SdDocumentViewer, SdToggleMultiple, AutoTranslate, Tooltip } from '@/Components';

const componentsGlobal = {
    'multiLanguageTabs': MultiLanguageTabs,
    'md-checkbox': MdCheckbox,
    'sd-modal': SdModal,
    'sd-button': SdButton,
    'sd-select': SdSelect,
    'sd-file-importer': SdFileImporter,
    'sd-input-text': SdInputText,
    'sd-textarea': SdTextarea,
    'sd-input-datepicker': SdInputDatepicker,
    'sd-input-number': SdInputNumber,
    'sd-input-nif': SdInputNif,
    'sd-radio': SdRadio,
    'sd-tag': SdTag,
    'sd-slide-over': SdSlideOver,
    'sd-document-viewer': SdDocumentViewer,
    'sd-toggle-multiple': SdToggleMultiple,
    'auto-translate': AutoTranslate,
    'tooltip': Tooltip
};

export default componentsGlobal;
