import { Component, Vue } from 'vue-property-decorator';
import { InscriptionTurn, InscriptionStatus, ProcessType, OppositionType, ProcessPhases, FileTypes, Languages, ManagementTabs, PayExemptionTypes, ProcessConfigurationTypes, Constants } from '@/Domain/enum';
import { ProcessInscription, InputFilterElement, ProcessRequisites, Sorter, OepDocument, TabsHashWithActiveTab, ClientInfo, ProcessConfigPayExemption, ProcessConfigFactory, Candidate } from '@/Domain/Entities';
import {Toggle, MdInput, MdTextarea, DoughnutChart, FileViewer, Modal } from '@/Components';
import { ToasterService } from '@/Services/ToasterService';
import ProcessesService from '@/Services/ProcessesService';
import InscriptionService from '@/Services/InscriptionService';
import ValidationService from '@/Application/Services/ValidationService';
import MasterDataService from '@/Services/MasterDataService';
import ClientService from '@/Services/ClientService';

import * as dropdown from '../../../node_modules/saviafront/lib/js/compiled/dropdown';
import * as sdPanel from '../../../node_modules/saviafront/lib/js/compiled/sd-panel';
import moment from '../../../node_modules/moment';
import i18n from '../../lang';
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import DocumentService from '@/Application/Services/DocumentService';

@Component({
    components: {Toggle, MdInput, MdTextarea, DoughnutChart, FileViewer, Modal },
    computed: {
        inscriptionFiltered() {
            return this.$data.inscriptionList.filter(inscription => inscription.status === this.$data.ElementsOnTabs.activeTab && this.$data.searcherFilter.showElement(inscription));
        },
        checkSelectedStatus(): number {
            if (!this.$data.requerimentList.length) { return -1; }

            const pendingToReview = this.$data.requerimentList.some(requeriment => requeriment.statusBool === null);
            if (pendingToReview) {
                return InscriptionStatus.enum.PENDING;
            }
            const anyAnnulled = this.$data.requerimentList.some(requeriment => requeriment.statusBool === false && !requeriment.isOptional);
            if (anyAnnulled) {
                return InscriptionStatus.enum.EXCLUDED;
            }

            return InscriptionStatus.enum.ADMITTED;
        },
        somePeopleAccepted() {
            return this.$data.inscriptionList.some(inscription => inscription.status === InscriptionStatus.enum.ADMITTED);
        },
        isProcessDataOppositionTypeFreeDesignation() {
            return this.$data.processData.oppositionType !== OppositionType.enum.FREE_DESIGNATION;
        },
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        ...mapGetters('candidateStore', { candidateInfo: 'getCandidateInfo' })
    },
    watch: {
        'candidateInfo'() {
            (this as ListManagementEdit).searcherFilter.filterString = '';
        },
        '$route.params': {
            handler() {
                (this as ListManagementEdit).processId = this.$route.params.id;
                (this as ListManagementEdit).fromPage = this.$route.params.fromPage;
                (this as ListManagementEdit).setTabActive();
                (this as ListManagementEdit).initList();
            },
            deep: true
        }
    }
})
export default class ListManagementEdit extends Vue {
    dateFormat!: string;
    currentLanguage!: string;
    candidateInfo!: Candidate | null;

    searcherFilter = new InputFilterElement({ param: ['nameSurname', 'surnameName', 'nif'] });
    inscriptionList: ProcessInscription[] = [];
    loading = true;
    loadingRequeriments = false;
    requerimentList: ProcessRequisites[] = [];
    inscriptionSelected: ProcessInscription | null = null;
    ElementsOnTabs = {
        admittedInscriptions: 0,
        excludedInscriptions: 0,
        pendingInscriptions: 0,
        activeTab: 0
    };
    otherReason = false;
    processData: any = '';
    fromPage: string = '';
    sorter = new Sorter();
    InscriptionStatus = InscriptionStatus;
    InscriptionTurn = InscriptionTurn;
    ProcessType = ProcessType;
    OppositionType = OppositionType;
    ProcessPhases = ProcessPhases;
    ManagementTabs = ManagementTabs;
    PayExemptionTypes = PayExemptionTypes;
    FileTypes = FileTypes;
    Languages = Languages;
    Constants = Constants;
    chartLabels = ['Completados', 'Restantes'];
    chartColors = ['#63a4ff', '#DADFE3'];
    waitingResponse: boolean = false;
    sortKey = 'surnameName';
    viewingFile: any = null;
    filesToViewer: OepDocument[] = [];
    nationalities: any[] = [];
    showModalPhase: boolean = false;
    noPeople: number = 0;
    submitted = false;
    tabActive: string = '';
    processId: string = '';
    clientInfo: ClientInfo = new ClientInfo({});
    showModalFinish: boolean = false;
    processConfigurationPayExemptions: ProcessConfigPayExemption[] = [];
    showModalAdmitCandidate: boolean = false;
    showModalAdmitCandidateAdvice: boolean = false;
    excludedInscriptionToAdmit: ProcessInscription | null = null;

    $refs!: {
        requerimentsStatusForm: HTMLFormElement,
        csvDownloadPanel: HTMLElement,
        panelCSV: HTMLElement,
        panelRequirements: HTMLElement
    };

    openAdmitCandidateModal(inscription: ProcessInscription) {
        this.excludedInscriptionToAdmit = inscription;
        this.showModalAdmitCandidate = true;
    }

    closeCandidateAdviceModal() {
        this.showModalAdmitCandidate = false;
        this.excludedInscriptionToAdmit = null;
    }

    openAdmitCandidateAdviceModal() {
        this.showModalAdmitCandidateAdvice = true;
        this.showModalAdmitCandidate = false;
    }

    closeAdmitCandidateAdviceModal() {
        this.showModalAdmitCandidateAdvice = false;
        this.showModalAdmitCandidate = true;
    }

    admitExcludedInscription() {
        if (!this.excludedInscriptionToAdmit || !this.excludedInscriptionToAdmit.readmitanceReason) { 
            return;
        }
        InscriptionService.updateReadmitanceReason(this.excludedInscriptionToAdmit.id, this.excludedInscriptionToAdmit.readmitanceReason)
            .then(() => {
                this.showModalAdmitCandidateAdvice = false;
                this.initList(true);
            })
            .catch(error => console.log(error));
    }

    getRequeriments(inscription: ProcessInscription) {
        if (this.processData.phase !== ProcessPhases.enum.INSCRIPTIONS) {
            return;
        }

        this.requerimentList = [];
        this.filesToViewer = [] as OepDocument[];
        this.inscriptionSelected = inscription;
        this.loadingRequeriments = true;
        InscriptionService.getInscriptionsManagementData(inscription.id).then(
            response => {
                this.requerimentList = response.map(requeriment => new ProcessRequisites(requeriment, this.processConfigurationPayExemptions));
                this.requerimentList.forEach(element => {
                    element.documents = element.documents.map(document => new OepDocument(document));
                    this.filesToViewer = this.filesToViewer.concat(element.documents);
                });
                this.requerimentList.forEach(requeriment => {
                    requeriment.documents = _.orderBy(requeriment.documents, 'fileDate', 'desc');
                });
                this.requerimentList = _.orderBy(this.requerimentList, 'type');
                this.loadingRequeriments = false;
                this.$nextTick(() => {
                    dropdown.initialize(this.$refs.panelRequirements);
                });
            })
            .catch(error => this.loadingRequeriments = false);
    }

    putRequeriments() {
        this.submitted = true;
        if (this.waitingResponse || !this.inscriptionSelected || !ValidationService.validateFormRequired(this.$refs.requerimentsStatusForm)) {
            return;
        }

        this.waitingResponse = true;
        const requiaitesToSend = this.requerimentList.map(element => element.toServer());
        const objectToSend = { status: (this as any).checkSelectedStatus, evaluableConditions: requiaitesToSend };

        InscriptionService.putInscriptionsManagementData(this.inscriptionSelected.id, objectToSend).then(
            () => {
                this.initList(true);
                const inscriptions = this.inscriptionList.filter(inscription => this.inscriptionSelected && inscription.status === this.inscriptionSelected.status);
                Promise.all([ProcessesService.getProcessInscriptions(this.$route.params.id)]).then(() =>  {
                    if (this.inscriptionSelected && (this as any).checkSelectedStatus !== this.inscriptionSelected.status) {
                        const index = inscriptions.findIndex(inscription => this.inscriptionSelected ? inscription.id === this.inscriptionSelected.id : false);
                        if (index === inscriptions.length - 1) {
                            this.requerimentList = [];
                            this.inscriptionSelected = null;
                        } else {
                            this.inscriptionSelected = inscriptions[index + 1];
                            this.getRequeriments(this.inscriptionSelected);
                        }
                    }
                    ToasterService.showSuccess(i18n.t('lang.toaster.inscriptionSave') as any);
                    this.waitingResponse = false;
                    this.submitted = false;
                    this.viewingFile = null;
                })
                .catch(error => console.log(error));
            })
            .catch(error => this.waitingResponse = false);
    }

    downloadDocument(url: string) {
        location.href = url;
    }

    sortBy(key, isUpdate?, keySecundary?) {
        this.inscriptionList = this.sorter.sortBy(this.inscriptionList, key, null, isUpdate, keySecundary);
        this.sortKey = key;
    }

    selectTab(tab: number) {
        this.requerimentList = [];
        this.inscriptionSelected = null;
        this.ElementsOnTabs.activeTab = tab;
        this.$router.push({ name: 'ListManagementEdit', hash:  this.ManagementTabs.enum[tab] });  
    }

    openModalStart() {
        this.showModalPhase = true;
    }

    openModalFinish() {
        this.showModalFinish = true;
    }

    closeModal() {
        this.showModalPhase = false;
        this.showModalFinish = false;
    }

    finishProcess() {
        ProcessesService.finishEmptyProcess(this.$route.params.id).then(
            response => {
                this.showModalFinish = false;
                this.initList();
        }).catch(error => console.log(error));
    }

    startPhaseOne() {
        ProcessesService.startProccess(this.$route.params.id).then(
            response => {
                this.showModalPhase = false;
                this.initList();
        }).catch(error => console.log(error));
    }

    initList(isUpdate?) {
        ProcessesService.getDataProcess(this.$route.params.id).then((response: any) => {
            this.processData = response;
            this.processData.endDate = moment(this.processData.endDate);
            this.processData.isFinishedEndDate = moment(this.processData.endDate).isBefore(moment(), 'day');
            this.processData.isFinishedClaimsEndDate = this.processData.claimsEndDate ? moment(this.processData.claimsEndDate).isBefore(moment(), 'day') : null;
        }).catch(error => console.log(error));

        ProcessesService.getProcessInscriptions(this.$route.params.id).then( response => {
            if (!isUpdate) {
                this.sorter.sortBy([], '');
            }
            if (!response.length) {
                this.$router.push({ name: 'ListManagementList', params: this.$route.params });
            }
            this.inscriptionList = response.map(inscription => new ProcessInscription(inscription, this.nationalities));
            this.loading = false;
            this.sortBy(this.sortKey, isUpdate, 'surnameName');
        }).catch(error => this.loading = false);

        ProcessesService.getElementsInTabs(this.$route.params.id).then( response => {
            this.ElementsOnTabs.admittedInscriptions = response[0].admittedInscriptions;
            this.ElementsOnTabs.excludedInscriptions = response[0].excludedInscriptions;
            this.ElementsOnTabs.pendingInscriptions = response[0].pendingInscriptions;
        }).catch(error => console.log(error));
    }

    downloadCSVList() {
        if (this.$data.inscriptionList.some(inscription => inscription.status === InscriptionStatus.enum.PENDING)) {
            ToasterService.showWarning(i18n.t('lang.toaster.inscriptionUserPending') as any);
            return;
        }
        ProcessesService.getCSVToDownload(this.$route.params.id);
    }

    downloadCSVAuthorization() {
        ProcessesService.getCSVAuthorizationToDownload(this.$route.params.id);
    }

    downloadCSVMeritOficio() {
        ProcessesService.getCSVMeritsOficio(this.$route.params.id, '');
    }
    
    downloadCSVContactData() {
        ProcessesService.getCSVContactDataToDownload(this.$route.params.id);
    }

    downloadCSVDisability() {
        ProcessesService.getCSVDisabilityToDownload(this.$route.params.id);
    }

    downloadCSVPaymentFees() {
        ProcessesService.getCSVPaymentFees(this.$route.params.id);
    }

    downloadCSVSubmissionRequest() {
        ProcessesService.getCSVSubmissionRequest(this.$route.params.id);
    }

    downloadCSVDraftInscriptions() {
        ProcessesService.getCSVDraftInscriptions(this.$route.params.id);
    }

    downloadCSVTestRequirementsIsncriptions() {
        ProcessesService.getCSVTestRequirementsIsncriptions(this.$route.params.id);
    }

    getKeepAlive() {
        ClientService.getKeepAlive(this.clientInfo.getClientId())
            .then(() => {})
            .catch(error => console.log(error));
    }

    showInFileViewer(doc: OepDocument) {
        this.getKeepAlive();
        DocumentService.viewFile(doc)
        .then(docUrl => {
            doc.url = window.URL.createObjectURL(docUrl);
            this.viewingFile = doc;
        })
        .catch(error => {
            console.log(error);
        });
    }
    
    getNationalities() {
        MasterDataService.getNationalities().then((response: any) => {
            this.nationalities = response;
            this.initList();
        }).catch(error => console.log(error));
    }
    
    setTabActive() {
        const myTab = new TabsHashWithActiveTab();
        if (this.$route.hash) {
            this.ElementsOnTabs.activeTab = myTab.selectElementWithRouteHash(this.$route);
        }
        if (this.$route.params.hash) {
            this.ElementsOnTabs.activeTab = myTab.selectElementWithParamsHash(this.$route, this.$router);
        }
    }

    getProcessConfigurationPayExemptionsList() {
        MasterDataService.getProcessesConfiguration(ProcessConfigurationTypes.enum.PAYEXEMPTIONS)
            .then((response: any[]) => {
                this.processConfigurationPayExemptions = response.map(processConfig => ProcessConfigFactory.create(ProcessConfigurationTypes.enum.PAYEXEMPTIONS, processConfig));
                this.processConfigurationPayExemptions = this.processConfigurationPayExemptions.filter(processConfig => processConfig.type >= processConfig.filterMinimumValidType);
            }).catch(error => console.log(error));
    }
    
    initClientInfo() {
        MasterDataService.getClientInfo()
            .then(responseMasterData => {
                this.clientInfo = new ClientInfo(responseMasterData);
            })
            .catch(error => console.log(error));
    }

    mounted() {
        this.initClientInfo();
        this.getProcessConfigurationPayExemptionsList();
        this.setTabActive();
        this.getNationalities();
        sdPanel.initialize(this.$refs.panelCSV);
        this.tabActive = i18n.locale;
        this.processId = this.$route.params.id;
        this.fromPage = this.$route.params.fromPage;
        if (this.candidateInfo) {
            this.searcherFilter.filterString = this.candidateInfo.nif;
        }
    }
}
