import { CandidateProcess, CandidateReplacement } from '@/Domain/Entities';

export default class Candidate {
    private _nif: string;
    private _name: string;
    private _firstSurname: string;
    private _secondSurname: string;
    private _processes: CandidateProcess[];
    private _replacementLists: CandidateReplacement[];
    private _hasPassedExams: boolean;

    public constructor(data: any) {
        this._nif = data.nif;
        this._name = data.name;
        this._firstSurname = data.firstSurname;
        this._secondSurname = data.secondSurname;
        this._processes = data.processes ? data.processes.map((process: CandidateProcess) => new CandidateProcess(process)) : [];
        this._replacementLists = data.replacementLists ? data.replacementLists.map((replacementList: CandidateReplacement) => new CandidateReplacement(replacementList)) : [];
        this._hasPassedExams = data.hasPassedExams;
    }

    public get nif() {
        return this._nif;
    }

    public get name() {
        return this._name;
    }

    public get firstSurname() {
        return this._firstSurname;
    }

    public get secondSurname() {
        return this._secondSurname;
    }

    public get processes() {
        return this._processes;
    }

    public get replacementLists() {
        return this._replacementLists;
    }

    public get hasPassedExams() {
        return this._hasPassedExams;
    }

    public get fullName() {
        return this.firstSurname + ' ' + this.secondSurname + ', ' + this.name;
    }
}
