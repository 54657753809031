import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/processes';

export default class MeritGroupsService {

    public static async getMeritGroups(processId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(processId + '/MeritGroups', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async editMeritGroups(processId: string, meritGroups: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/MeritGroups/' + meritGroups.id, meritGroups, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addMeritGroups(processId: string, meritGroups: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(processId + '/MeritGroups', meritGroups, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeMeritGroups(processId: string, meritGroups: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(processId + '/MeritGroups/' + meritGroups.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async setMeritGroupsOrder(processId: string, meritGroupsOrder: string[]): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/MeritGroups', meritGroupsOrder, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
