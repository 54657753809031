import { StatusExam } from '@/Domain/enum';
import { MultiLanguage } from '@/Domain/Entities';

export default class Exam {
    private _id: string;
    private _processId: string;
    private _order: number;
    private _description: MultiLanguage;
    private _gradeType: number;
    private _examType: number;
    private _minScore: number;
    private _maxScore: number;
    private _isExclusive: boolean;
    private _status: number;
    private _gradedApplicant: number;
    private _totalApplicant: number;
    private _isEditing: boolean;
    private _active: boolean;
    private _blocked: boolean;
    private _locked: boolean;
    private _creditable: boolean;
    private _creditableDescription: MultiLanguage;
    private _percentage: number;
    private _isRequirementTest: boolean;

    constructor(data: any) {
        this._id = data.id;
        this._processId = data.processId;
        this._order = data.order;
        this._description = new MultiLanguage(data.description);
        this._gradeType = data.gradeType;
        this._examType = data.examType;
        this._minScore = data.minScore;
        this._maxScore = data.maxScore;
        this._isExclusive = data.isExclusive;
        this._status = data.status;
        this._gradedApplicant = data.gradedApplicants;
        this._totalApplicant = data.totalApplicants;
        this._isEditing = false;
        this._active = this._status === StatusExam.enum.INPROCESS;
        this._blocked = this._status === StatusExam.enum.PENDING;
        this._locked = data.blocked;
        this._creditable = data.creditable;
        this._creditableDescription = new MultiLanguage(data.creditableDescription);
        this._percentage = data.percentage ? data.percentage : 0;
        this._isRequirementTest = data.isRequirementTest;
    }

    public get id() {
        return this._id;
    }
    public set id(id: string) {
        this._id = id;
    }
    public get processId() {
        return this._processId;
    }
    public set processId(processId: string) {
        this._processId = processId;
    }
    public get order() {
        return this._order;
    }
    public set order(value: number) {
        this._order = value;
    }
    public get description() {
        return this._description;
    }
    public set description(value: MultiLanguage) {
        this._description = value;
    }
    public get gradeType() {
        return this._gradeType;
    }
    public set gradeType(value: number) {
        this._gradeType = value;
    }
    public get blocked() {
        return this._blocked;
    }
    public set blocked(blocked: boolean) {
        this._blocked = blocked;
    }
    public get examType() {
        return this._examType;
    }
    public set examType(value: number) {
        this._examType = value;
    }
    public get minScore() {
        return this._minScore;
    }
    public set minScore(value: number) {
        this._minScore = value;
    }
    public get active() {
        return this._active;
    }
    public set active(active: boolean) {
        this._active = active;
    }
    public get maxScore() {
        return this._maxScore;
    }
    public set maxScore(value: number) {
        this._maxScore = value;
    }

    public get isExclusive() {
        return this._isExclusive;
    }
    public set isExclusive(value: boolean) {
        this._isExclusive = value;
    }
    public get status() {
        return this._status;
    }
    public set status(value: number) {
        this._status = value;
    }
    public get gradedApplicant() {
        return this._gradedApplicant;
    }
    public set gradedApplicant(value: number) {
        this._gradedApplicant = value;
    }
    public get totalApplicant() {
        return this._totalApplicant;
    }
    public set totalApplicant(value: number) {
        this._totalApplicant = value;
    }
    public get isEditing() {
        return this._isEditing;
    }
    public set isEditing(isEditing: boolean) {
        this._isEditing = isEditing;
    }
    public get locked() {
        return this._locked;
    }
    public set locked(locked: boolean) {
        this._locked = locked;
    }
    public get creditable() {
        return this._creditable;
    }
    public set creditable(creditable: boolean) {
        this._creditable = creditable;
    }
    public get creditableDescription() {
        return this._creditableDescription;
    }
    public set creditableDescription(creditableDescription: MultiLanguage) {
        this._creditableDescription = creditableDescription;
    }
    public get percentage() {
        return this._percentage;
    }
    public set percentage(percentage: number) {
        this._percentage = percentage;
    }
    public get isRequirementTest() {
        return this._isRequirementTest;
    }
    public set isRequirementTest(value: boolean) {
        this._isRequirementTest = value;
    }
    public toServerPercentage() {
        return {
            id: this._id,
            percentage: this._percentage ? this._percentage : 0
        };
    }
    public toServer() {
        return {
            id: this._id,
            processId: this._processId,
            order: this._order,
            description: this._description,
            gradeType: this._gradeType,
            examType: this._examType,
            minScore: this._minScore,
            maxScore: this._maxScore,
            isExclusive: this._isExclusive,
            status: this._status,
            gradedApplicants: this._gradedApplicant,
            totalApplicants: this._totalApplicant,
            creditable: this._creditable,
            creditableDescription: this._creditableDescription,
            percentage: this._percentage,
            isRequirementTest: this._isRequirementTest
        };
    }
}
