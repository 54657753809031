export default class CookiesService {
    public static manageCookies(): void {
        if (!this.hasAcceptedAnalysisCookies()) {
            return;
        }

        this.loadHotjarScript();
        this.loadGoogleAnalyticsScript();
    }

    private static _analysisCookiesName = 'AcceptedAnalysisCookies';
    private static _hotjarScript = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3254189,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;
    private static _googleAnalyticsScriptTagId = 'google-analytics-script';

    private static hasAcceptedAnalysisCookies(): boolean {
        const cookies = document.cookie.split(';');
        return cookies.some(cookie => cookie.trim().startsWith(this._analysisCookiesName));
    }

    private static loadHotjarScript(): void {
        const hotjarScriptTag = document.createElement('script');   
        hotjarScriptTag.innerHTML = this._hotjarScript;
        document.head.appendChild(hotjarScriptTag);
    }

    private static loadGoogleAnalyticsScript(): void {
        const googleAnalyticsScriptTag = document.getElementById(this._googleAnalyticsScriptTagId) || null;

        if (googleAnalyticsScriptTag === null) {
            return ;
        }
        
        googleAnalyticsScriptTag.classList.remove('d-none');
    }

}
