import moment from 'moment';

export default class Contract {
    private _id: string;
    private _position: string;
    private _department: string;
    private _reason: string;
    private _replacedPersonName: string;
    private _replacedPersonNif: string;
    private _start: moment.Moment | null;
    private _end: moment.Moment | null;
    private _staffType: number;
    private _workingPercentage: number;
    private _isFinished: boolean;
    private _isEditing: boolean;
    private _order: number;
    private _contractWorkedDays: number;
    private _sendJobPositions: boolean;

    public constructor(data: any) {
        this._id = data.id;
        this._position = data.position;
        this._department = data.department;
        this._reason = data.reason;
        this._replacedPersonName = data.replacedPersonName;
        this._replacedPersonNif = data.replacedPersonNif;
        this._start = data.start ? moment.utc(data.start).local() : null;
        this._end = data.end ? moment.utc(data.end).local() : null;
        this._staffType = data.staffType;
        this._workingPercentage = data.workingPercentage;
        this._isFinished = data.isFinished;
        this._isEditing = false;
        this._order = 0;
        this._contractWorkedDays = data.contractWorkedDays;
        this._sendJobPositions = data.sendJobPositions ? data.sendJobPositions : false;
    }

    public get id() {
        return this._id;
    }
    public set id(id: string) {
        this._id = id;
    }

    public get position() {
        return this._position;
    }
    public set position(position: string) {
        this._position = position;
    }

    public get department() {
        return this._department;
    }
    public set department(department: string) {
        this._department = department;
    }

    public get reason() {
        return this._reason;
    }
    public set reason(reason: string) {
        this._reason = reason;
    }

    public get replacedPersonName() {
        return this._replacedPersonName;
    }
    public set replacedPersonName(replacedPersonName: string) {
        this._replacedPersonName = replacedPersonName;
    }

    public get replacedPersonNif() {
        return this._replacedPersonNif;
    }
    public set replacedPersonNif(replacedPersonNif: string) {
        this._replacedPersonNif = replacedPersonNif;
    }

    public get start() {
        return this._start;
    }

    public set start(start: moment.Moment | null) {
        this._start = start;
    }

    public get end() {
        return this._end;
    }

    public set end(end: moment.Moment | null) {
        this._end = end;
    }

    public get staffType() {
        return this._staffType;
    }
    public set staffType(staffType: number) {
        this._staffType = staffType;
    }

    public get workingPercentage() {
        return this._workingPercentage;
    }
    public set workingPercentage(workingPercentage: number) {
        this._workingPercentage = workingPercentage;
    }

    public get isFinished() {
        return this._isFinished;
    }
    public set isFinished(isFinished: boolean) {
        this._isFinished = isFinished;
    }

    public get isEditing() {
        return this._isEditing;
    }
    public set isEditing(isEditing: boolean) {
        this._isEditing = isEditing;
    }

    public get order() {
        return this._order;
    }
    public set order(order: number) {
        this._order = order;
    }

    public get contractWorkedDays() {
        return this._contractWorkedDays;
    }

    public get sendJobPositions() {
        return this._sendJobPositions;
    }
    public set sendJobPositions(sendJobPositions: boolean) {
        this._sendJobPositions = sendJobPositions;
    }

    public toServer() {
        return {
            id: this._id,
            position: this._position,
            department: this._department,
            reason: this._reason,
            replacedPersonName: this._replacedPersonName,
            replacedPersonNif: this._replacedPersonNif,
            start: this._start ? this._start.toISOString() : null,
            end: this._end ? this._end.toISOString() : null,
            staffType: this._staffType,
            workingPercentage: this._workingPercentage,
            isFinished: this._isFinished,
            sendJobPositions: this._sendJobPositions
        };
    }

}
