import { Component, Vue } from 'vue-property-decorator';
import { SdFilter, DoughnutChart } from '@/Components';
import { Filter, SorterNew, ShippingHistory } from '@/Domain/Entities';
import filters from './ShippingHistoryList.filter';
import ReplacementService from '@/Services/ReplacementService';
import { mapState } from 'vuex';

@Component({
    components: {
        SdFilter, DoughnutChart
    },
    computed: {
        filteredData() {
            return (this as ShippingHistoryList).sorter.sortBy((this as ShippingHistoryList).filter.filteredData);
        },
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'})
    }
})

export default class ShippingHistoryList extends Vue {
    dateFormat!: string;
    
    filter = new Filter();
    loading: boolean = false;
    currentTabType = null as any;
    replacementListName: string = '';
    replacementListId: string = '';
    shippingHistoryList: any = [];
    sorter = new SorterNew(['sendDate'], ['desc']);
    chartColors = ['#1976D2', '#E5F0F9'];

    $ref!: {
        sdFilter: SdFilter
    };

    goToShippingHistoryReceivers(shippingHistory: ShippingHistory) {
        if (!shippingHistory.isCompleted) {
            return;
        }
        this.$router.push({ name: 'ShippingHistoryReceivers', params: { id: this.replacementListId, shippingHistoryName: shippingHistory.positionName, shippingHistoryId: shippingHistory.id, hash: this.currentTabType } });
    }

    getAllShippingHistory() {
        ReplacementService.getShippingHistoryList(this.replacementListId)
        .then(response => {
            this.shippingHistoryList = response.map(shippingHistory => new ShippingHistory(shippingHistory));
            this.replacementListName = this.shippingHistoryList.length > 0 ? this.shippingHistoryList[0].replacementListName : this.$route.params.name;
            this.filter.setData(this.shippingHistoryList);
            this.filter.setFilterElements(filters);
            this.loading = false;
        }).catch(error => console.log(error));
    }

    initList() {
        this.loading = true;
        this.replacementListId = this.$route.params.id;
        this.currentTabType = this.$route.params.hash;
        this.getAllShippingHistory();
    }
    
    mounted() {
        this.initList();
    }
}
