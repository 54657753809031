import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/replacementlistcandidate';

export default class ReplacementStatesService {
    public static async getReplacementStates(replacementListCandidateId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(replacementListCandidateId + '/ReplacementStates', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addReplacementState(replacementListCandidateId: string, ReplacementState: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(replacementListCandidateId + '/ReplacementStates', ReplacementState, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateReplacementState(replacementListCandidateId: string, ReplacementState: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(replacementListCandidateId + '/ReplacementStates/' + ReplacementState.id, ReplacementState, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeReplacementState(replacementListCandidateId: string, ReplacementState: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(replacementListCandidateId + '/ReplacementStates/' + ReplacementState.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addFileReplacementState(ReplacementState: any, data: any, replacementListCandidateId: string): Promise<any[]> {
        const formData = new FormData();
        Object.entries(data).forEach(key => formData.append(key[0], (key[1] as any)));
        return new Promise((resolve, reject) => {
            axios.post(replacementListCandidateId + '/ReplacementStates/' + ReplacementState.id + '/files', formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeFileFromReplacementState(ReplacementState: any, data: any, replacementListCandidateId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(replacementListCandidateId + '/ReplacementStates/' + ReplacementState.id + '/files/' + data.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
