export default class ReplacementInscriptionInfo {

    private _fullName: string;
    private _replacementListName: string;
    private _email: string;
    private _phoneNumber1: number;
    private _phoneNumber2: number | null;

    constructor(data: any) {
        this._fullName = data.fullName;
        this._replacementListName = data.replacementListName;
        this._email = data.email;
        this._phoneNumber1 = data.phoneNumber1;
        this._phoneNumber2 = data.phoneNumber2 || null;
    }

    public get fullName() {
        return this._fullName;
    }

    public get replacementListName() {
        return this._replacementListName;
    }

    public get email() {
        return this._email;
    }

    public get phoneNumber1() {
        return this._phoneNumber1;
    }

    public get phoneNumber2() {
        return this._phoneNumber2;
    }
}
