import PayExemptionFeeAmount from './PayExemptionFeeAmount';

export default class PayFeeAmount {
    private _generalAmount: number;
    private _payExemptionFeeAmounts: PayExemptionFeeAmount[];

    public constructor(data: any) {
        this._generalAmount = data.generalAmount;
        this._payExemptionFeeAmounts = data.payExemptionFeeAmounts.map(payExemptionFeeAmount => new PayExemptionFeeAmount(payExemptionFeeAmount));
    }

    public get generalAmount(): number {
        return this._generalAmount;
    }

    public get payExemptionFeeAmounts(): PayExemptionFeeAmount[] {
        return this._payExemptionFeeAmounts;
    }
}
