import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/candidate/';
import csvService from '@/Services/CsvService';

export default class CandidateService {

    public static async getCandidateInfo(nif: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`${nif}`, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getCandidateEnrolledProcessIds(nif: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`${nif}/processes`, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getCSVCandidatePassedExams(nif: string): Promise<any> {
        return csvService.getCsv(baseURL, `candidate/${nif}/passedExamsCsv`);
    }
}
