import moment from 'moment';
import { MultiLanguage, ProcessConfigGroup } from '..';
import { ProcessPhases } from '@/Domain/enum';

export default class ProcessInfo {
    private _id: string;
    private _title: MultiLanguage;
    private _entityOepId: string;
    private _entityName: string;
    private _type: number;
    private _status: number;
    private _bopDate: moment.Moment;
    private _total: any;
    private _pendingInscriptions: any;
    private _admittedInscriptions: any;
    private _excludedInscriptions: any;
    private _tooltipShown: boolean;
    private _inscriptionPeriodOpen: boolean;
    private _claimsPeriodOpen: boolean;
    private _totalScale: any;
    private _pendingInscriptionsScale: number;
    private _scaleInscriptionsScale: number;
    private _noEvaluableInscriptions: number;
    private _oppositionType: number;
    private _phase: number;
    private _year: number;
    private _active: boolean;
    private _group: ProcessConfigGroup;
    private _groupName: MultiLanguage;
    private _isDesert: boolean;
    private _canBeDesert: boolean;
    private _hasAnyCandidate: boolean;
    private _hasRequirementWithTest: boolean;

    constructor(data: any, processConfigGroups?: ProcessConfigGroup[]) {
        this._id = data.id;
        this._title = new MultiLanguage(data.title);
        this._entityOepId = data.entityOepId;
        this._entityName = '';
        this._type = data.type;
        this._status = data.status;
        this._bopDate = moment.utc(data.bopDate);
        this._total = data.inscriptions ? data.inscriptions.total : 0;
        this._pendingInscriptions = data.inscriptions ? data.inscriptions.pendingInscriptions : 0;
        this._admittedInscriptions = data.inscriptions ? data.inscriptions.admittedInscriptions : 0;
        this._excludedInscriptions = data.inscriptions ? data.inscriptions.excludedInscriptions : 0;
        this._tooltipShown = false;
        this._inscriptionPeriodOpen = data.inscriptionPeriodOpen;
        this._claimsPeriodOpen = data.claimsPeriodOpen;
        this._totalScale = data.scaleInscriptions ? data.scaleInscriptions.total : 0;
        this._pendingInscriptionsScale = data.scaleInscriptions ? data.scaleInscriptions.pendingInscriptions : 0;
        this._scaleInscriptionsScale = data.scaleInscriptions ? data.scaleInscriptions.scaleInscriptions : 0;
        this._noEvaluableInscriptions = data.scaleInscriptions ? data.scaleInscriptions.noEvaluableInscriptions : 0;
        this._oppositionType = data.oppositionType;
        this._phase = data.phase;
        this._year = data.year;
        this._group = processConfigGroups
            ? processConfigGroups.find(processConfigGroup =>
                data.group && (data.group.type || data.group.type === 0) ? processConfigGroup.type === data.group.type : processConfigGroup.type === data.group)
            || new ProcessConfigGroup({})
            : new ProcessConfigGroup({});
        this._groupName = this.group && this.group.title ? this.group.title : new MultiLanguage('');
        this._active = false;
        this._hasAnyCandidate = data.hasAnyCandidate;
        this._isDesert = this.phase === ProcessPhases.enum.FINISH && !this._hasAnyCandidate;
        this._canBeDesert = this.setCanBeDesert(data.claimsEndDate, data.endDate);
        this._hasRequirementWithTest = data.hasRequirementWithTest;
    }

    public get id() {
        return this._id;
    }
    public get title() {
        return this._title;
    }
    public get entityOepId() {
        return this._entityOepId;
    }
    public get type() {
        return this._type;
    }
    public get status() {
        return this._status;
    }
    public get bopDate() {
        return this._bopDate;
    }
    public get total() {
        return this._total;
    }
    public get pendingInscriptions() {
        return this._pendingInscriptions;
    }
    public get admittedInscriptions() {
        return this._admittedInscriptions;
    }
    public get excludedInscriptions() {
        return this._excludedInscriptions;
    }
    public get inscriptionPeriodOpen() {
        return this._inscriptionPeriodOpen;
    }
    public get claimsPeriodOpen() {
        return this._claimsPeriodOpen;
    }
    public get tooltipShown() {
        return this._tooltipShown;
    }
    public get totalScale() {
        return this._totalScale;
    }
    public get pendingInscriptionsScale() {
        return this._pendingInscriptionsScale;
    }
    public get scaleInscriptionsScale() {
        return this._scaleInscriptionsScale;
    }
    public get noEvaluableInscriptions() {
        return this._noEvaluableInscriptions;
    }
    public get oppositionType() {
        return this._oppositionType;
    }
    public get phase() {
        return this._phase;
    }
    public set phase(phase: number) {
        this._phase = phase;
    }
    public get entityName() {
        return this._entityName;
    }
    public set entityName(entityName: string) {
        this._entityName = entityName;
    }
    public get active() {
        return this._active;
    }
    public set active(active: boolean) {
        this._active = active;
    }
    public get group() {
        return this._group;
    }
    public set group(group: ProcessConfigGroup) {
        this._group = group;
    }
    public get year() {
        return this._year;
    }
    public get groupName() {
        return this._groupName;
    }
    public get hasAnyCandidate() {
        return this._hasAnyCandidate;
    }
    public get isDesert() {
        return this._isDesert;
    }
    public get canBeDesert() {
        return this._canBeDesert;
    }

    public get hasRequirementWithTest() {
        return this._hasRequirementWithTest;
    }

    public setEntityName(entities: any) {
        entities.forEach(entity => {
            if (entity.id === this.entityOepId) {
                this.entityName = entity.name;
            }
        });
    }

    private setCanBeDesert(claimsEndDate: string, inscriptionEndDate: string) {

        const isInscriptionPeriodExpired = moment().isAfter(inscriptionEndDate);
        let isClaimsPeriodExpired = !this.claimsPeriodOpen;

        if (claimsEndDate) {
            isClaimsPeriodExpired = moment().isAfter(claimsEndDate);
        }

        // can only be desert when inscription period has expired and claims period has expired
        return !this._isDesert && isInscriptionPeriodExpired && isClaimsPeriodExpired && !this._hasAnyCandidate;
    }
}
