export default class DisabilityData {
    private _inscriptionId: string;
    private _hasDisability: number;
    private _degree: number | null;
    private _reserveType: number| null;
    private _adaptationRequest: string | null;
    private _reason: string | null;
    private _autonomousCommunityDisability: number| null;

    constructor(data: any) {
        this._inscriptionId = data.inscriptionId ? data.inscriptionId : '';
        this._hasDisability = data.hasDisability ? Number(data.hasDisability) : 0;
        this._degree = data.degree ? data.degree : null;
        this._reserveType = data.reserveType ? data.reserveType : null;               
        this._adaptationRequest = data.adaptationRequest ? data.adaptationRequest : '';
        this._reason = data.reason ? data.reason : '';
        this._autonomousCommunityDisability = data.autonomousCommunityDisability ? data.autonomousCommunityDisability : null;
    }

    public toServer(inscriptionId, inscriptionTurn) {
        if (!this.hasDisability) {
            this.resetPropsToServer();
        }
        return {
            inscriptionId,
            inscriptionTurn: Number(inscriptionTurn),
            disabilityData: {
                hasDisability: this._hasDisability,
                degree: this._degree,
                reserveType: this._reserveType,
                adaptationRequest: this._adaptationRequest,
                reason: this._reason,
                autonomousCommunityDisability: this._hasDisability ? this._autonomousCommunityDisability : null
            }
        };
    }

    public toServerInscription() {
        return {
            hasDisability: this._hasDisability,
            degree: this._degree,
            reserveType: this._reserveType,
            adaptationRequest: this._adaptationRequest,
            reason: this._reason,
            autonomousCommunityDisability: this._hasDisability ? this._autonomousCommunityDisability : null
        };
    }

    public get inscriptionId() {
        return this._inscriptionId;
    }
    public set inscriptionId(inscriptionId: string) {
        this._inscriptionId = inscriptionId;
    }

    public get hasDisability() {
        return this._hasDisability;
    }
    public set hasDisability(hasDisability: number) {
        this._hasDisability = hasDisability;
    }

    public get degree() {
        return this._degree;
    }
    public set degree(degree: number| null) {
        this._degree = degree;
    }

    public get reserveType() {
        return this._reserveType;
    }
    public set reserveType(reserveType: number| null) {
        this._reserveType = reserveType;
    }

    public get adaptationRequest() {
        return this._adaptationRequest;
    }
    public set adaptationRequest(adaptationRequest: string | null) {
        this._adaptationRequest = adaptationRequest;
    }

    public get reason() {
        return this._reason;
    }
    public set reason(reason: string | null) {
        this._reason = reason;
    }

    public get autonomousCommunityDisability() {
        return this._autonomousCommunityDisability;
    }
    public set autonomousCommunityDisability(autonomousCommunityDisability: number| null) {
        this._autonomousCommunityDisability = autonomousCommunityDisability;
    }

    public resetPropsToServer() {
        this.degree = null;
        this.reserveType = null;
        this.adaptationRequest = null;
        this.reason = null;
        this.autonomousCommunityDisability = null;
    }

    public isValid() {
        const noDisability = 0;
        return this.hasDisability === noDisability ? true : (!isNaN(Number(this.degree)) && this.degree !== null && this.degree !== undefined);
    }
}
