export default class VacanciesAttached {
    private _freeVacancies: number;
    private _internalVacancies: number;
    private _internalDisabilityVacancies: number;
    private _freeDisabilityVacancies: number;
    private _mobilityVacancies: number;
    private _totalVacancies: number;
    
    public constructor(data: any) {
        this._freeVacancies = data.extraFree ? data.extraFree : 0;
        this._internalVacancies = data.extraInternal ? data.extraInternal : 0;
        this._internalDisabilityVacancies = data.extraInternalDisability ? data.extraInternalDisability : 0;
        this._freeDisabilityVacancies = data.extraFreeDisability ? data.extraFreeDisability : 0;
        this._mobilityVacancies = data.extraMobility ? data.extraMobility : 0;
        this._totalVacancies = data.extraTotal ? data.extraTotal : 0;
    }

    public get freeVacancies() {
        return this._freeVacancies;
    }
    public set freeVacancies(freeVacancies: number) {
        this._freeVacancies = freeVacancies ? freeVacancies : 0;
    }

    public get internalVacancies() {
        return this._internalVacancies;
    }
    public set internalVacancies(internalVacancies: number) {
        this._internalVacancies = internalVacancies ? internalVacancies : 0;
    }

    public get internalDisabilityVacancies() {
        return this._internalDisabilityVacancies;
    }
    public set internalDisabilityVacancies(internalDisabilityVacancies: number) {
        this._internalDisabilityVacancies = internalDisabilityVacancies ? internalDisabilityVacancies : 0;
    }

    public get freeDisabilityVacancies() {
        return this._freeDisabilityVacancies;
    }
    public set freeDisabilityVacancies(freeDisabilityVacancies: number) {
        this._freeDisabilityVacancies = freeDisabilityVacancies ? freeDisabilityVacancies : 0;
    }

    public get mobilityVacancies() {
        return this._mobilityVacancies;
    }
    public set mobilityVacancies(mobilityVacancies: number) {
        this._mobilityVacancies = mobilityVacancies ? mobilityVacancies : 0;
    }

    public get totalVacancies() {
        return this._totalVacancies;
    }
    public set totalVacancies(totalVacancies: number) {
        this._totalVacancies = totalVacancies;
    }

    public toServer() {
        return {
            extraFree: this.freeVacancies,
            extraInternal: this.internalVacancies,
            extraInternalDisability: this.internalDisabilityVacancies,
            extraFreeDisability: this.freeDisabilityVacancies,
            extraMobility: this.mobilityVacancies
        };
    }
}
