import { Component, Vue } from 'vue-property-decorator';
import BOEPService from '@/Services/BOEPService';
import EntityService from '@/Services/EntityService';
import { ToasterService } from '@/Services/ToasterService';
import ValidationService from '@/Application/Services/ValidationService';
import i18n from '../../lang';
import { BOEP, EntityOep } from '@/Domain/Entities';
import { Breadcrumb, MdInput, MdSelect, MdTextarea, Modal, FileImporter, MdDatepicker, Toggle, Documents } from '@/Components';
import { Languages, Constants } from '@/Domain/enum';
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';

@Component({
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
    },
    components: {
        MdInput,
        MdSelect,
        MdDatepicker,
        MdTextarea,
        FileImporter,
        Modal,
        Toggle,
        Breadcrumb,
        Documents
    }
})

export default class BOEPEdit extends Vue {
    currentLanguage!: string;
    availableLanguages!: string[];

    BOEP = new BOEP({});
    entityList: EntityOep[] = [];
    editionDocument: any = {};
    deleteDocument: any = {};
    showModal = false;
    showDeleteModal = false;
    submitted = false;
    waitingResponse: boolean = false;
    loading: boolean = false;
    loadingFile: boolean = false;
    Languages = Languages;
    Constants = Constants;
    breadcrumInfo: any = [{ title: 'lang.router.offersBoep', link: 'BOEP' }, { title: 'lang.router.newBoep' }];
    tabActive: string = '';
    yearsBoep: string[] = [];
    $refs!: {
        OepDocumentForm: HTMLFormElement,
        boepForm: HTMLFormElement
    };

    getBOEPAction() {
        if (this.BOEP.id) {
            return BOEPService.updateBOEP(this.BOEP.toServer());
        }
        return BOEPService.addBOEP(this.BOEP.toServer());
    }

    yearsToBoep() {
        const yearActual = moment().year();
        for (let i = Constants.process.YEAR_INITIAL; i <= yearActual + 1; i++) {
            this.yearsBoep.push(i.toString());
        }
    }

    submit() {
        this.submitted = true;
        if (this.waitingResponse || !ValidationService.validateFormRequired(this.$refs.boepForm)) {
            return;
        }

        this.waitingResponse = true;
        const action = this.getBOEPAction();
        action.then(response => {
            if (this.$route.name === 'BOEPNew') {
                this.$router.push({
                    name: 'BOEPList'
                });
            }

            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            this.waitingResponse = false;
        })
            .catch(error =>
                this.waitingResponse = false
            );
    }

    getEntity() {
        EntityService.getEntityList().then(response => {
            this.entityList = response;
        }).catch(error => console.log(error));
    }

    getBOEPById() {
        BOEPService.getBOEPById(this.$route.params.id).then( response => {
                this.BOEP = new BOEP(response);
            }).catch(error => console.log(error));
    }

    created() {
        this.tabActive = this.currentLanguage;
        this.loading = true;
        if (this.$route.params.id) {
            this.breadcrumInfo = [{ title: 'lang.router.offersBoep', link: 'BOEP' }, { title: 'lang.router.editBoep' }];
            this.getBOEPById();
        }
        this.yearsToBoep();
        this.getEntity();
    }
}
