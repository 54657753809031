import { FilterElement, CheckboxFilterElement, DatepickerFilterElement, InputFilterElement } from '@/Domain/Entities';
import { FilterTypes } from '@/Domain/enum';

export class FilterFactory {

    public createFilterElement(data: any, metadata?: any): FilterElement {
        switch (data.type) {
            case FilterTypes.CHECKBOX:
                return new CheckboxFilterElement(data, metadata);
            case FilterTypes.DATE:
                return new DatepickerFilterElement(data);
            case FilterTypes.INPUT:
                return new InputFilterElement(data);
            default:
                return {} as FilterElement;
        }
    }
}
