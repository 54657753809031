export default class LegalConsent {
    private _hasAccepted: boolean;
    
    public constructor() {
        this._hasAccepted = true;
    }
    
    public get hasAccepted() {
        return this._hasAccepted;
    }

    public toServer() {
        return {
            hasAccepted: this._hasAccepted
        };
    }
}
