export default class PaymentPlan {
    private _paymentPlan: number;
    private _maxProcessForPlan: number;
    private _maxCandidatesForPlan: number;
    private _processTotal: number;
    private _levelPlanProcess: number;
    private _percentagePlanProcess: number;
    private _inscriptionsTotal: number;
    private _levelPlanInscriptions: number;
    private _percentagePlanInscriptions: number;

    public constructor(data: any) {
        this._paymentPlan = data.paymentPlan;
        this._maxProcessForPlan = data.maxProcessForPlan;
        this._maxCandidatesForPlan = data.maxCandidatesForPlan;
        this._processTotal = data.processTotal;
        this._levelPlanProcess = data.levelPlanProcess;
        this._percentagePlanProcess = data.percentagePlanProcess;
        this._inscriptionsTotal = data.inscriptionsTotal;
        this._levelPlanInscriptions = data.levelPlanInscriptions;
        this._percentagePlanInscriptions = data.percentagePlanInscriptions;
    }

    public get paymentPlan() {
        return this._paymentPlan;
    }

    public get maxProcessForPlan() {
        return this._maxProcessForPlan;
    }

    public get maxCandidatesForPlan() {
        return this._maxCandidatesForPlan;
    }

    public get processTotal() {
        return this._processTotal;
    }

    public get levelPlanProcess() {
        return this._levelPlanProcess;
    }

    public get percentagePlanProcess() {
        return this._percentagePlanProcess;
    }

    public get inscriptionsTotal() {
        return this._inscriptionsTotal;
    }

    public get levelPlanInscriptions() {
        return this._levelPlanInscriptions;
    }

    public get percentagePlanInscriptions() {
        return this._percentagePlanInscriptions;
    }
}
