import ChartData from './ChartData';

export default class ChartCandidates extends ChartData {
    public constructor(data: any) {
        super(data.onlineInscriptionsAmount, data.offlineInscriptionsAmount);
        this.colors = ['#1976d2', '#f7ca6f'];
        this.legend = ['lang.shared.online', 'lang.shared.window'];
    }

    public get totalOnline() {
        return this.data[0].reduce((a, b) => a + b, 0);
    }

    public get totalWindow() {
        return this.data[1].reduce((a, b) => a + b, 0);
    }

    public get totalCandidates() {
        return this.totalOnline + this.totalWindow;
    }
}
