import Tab from './Tab';

export default class TabCalification extends Tab {
    private _pendingTiebreakers: number;
    private _totalQualifiedInscriptions: number;
    private _totalTiebreakers: number;
    private _vacancies: number;

    constructor(data: any) {
        super(data.type);
        this._pendingTiebreakers = data.pendingTiebreakers;
        this._totalQualifiedInscriptions = data.totalQualifiedInscriptions;
        this._totalTiebreakers = data.totalTiebreakers;
        this._vacancies = data.vacancies;
    }

    public get pendingTiebreakers() {
        return this._pendingTiebreakers;
    }
    public set pendingTiebreakers(pendingTiebreakers: number) {
        this._pendingTiebreakers = pendingTiebreakers;
    }

    public get totalQualifiedInscriptions() {
        return this._totalQualifiedInscriptions;
    }
    public set totalQualifiedInscriptions(totalQualifiedInscriptions: number) {
        this._totalQualifiedInscriptions = totalQualifiedInscriptions;
    }

    public get totalTiebreakers() {
        return this._totalTiebreakers;
    }
    public set totalTiebreakers(totalTiebreakers: number) {
        this._totalTiebreakers = totalTiebreakers;
    }

    public get vacancies() {
        return this._vacancies;
    }
    public set vacancies(vacancies: number) {
        this._vacancies = vacancies;
    }
}
