import ValidationService from '@/Application/Services/ValidationService';
import { InscriptionTurn } from '@/Domain/enum';

export default class ContactData {
    private _inscriptionId: string;
    private _email: string;
    private _phoneNumber1: number;
    private _phoneNumber2: number;
    private _inscriptionTurn: number;
    private _examsLanguageSelected: string;

    constructor(data: any) {
        this._inscriptionId = data.id ? data.id : '';
        this._email = data.email ? data.email : '';
        this._phoneNumber1 = data.phoneNumber1 ? data.phoneNumber1 : null;
        this._phoneNumber2 = data.phoneNumber2 ? data.phoneNumber2 : null;
        this._inscriptionTurn = data.inscriptionTurn !== null && data.inscriptionTurn !== undefined ? data.inscriptionTurn : null;
        this._examsLanguageSelected = data.examsLanguageSelected ? data.examsLanguageSelected : '';
    }

    public get inscriptionId() {
        return this._inscriptionId;
    }

    public set inscriptionId(inscriptionId: string) {
        this._inscriptionId = inscriptionId;
    }

    public get email() {
        return this._email;
    }

    public set email(email: string) {
        this._email = email;
    }

    public get phoneNumber1() {
        return this._phoneNumber1;
    }

    public set phoneNumber1(phoneNumber1: number) {
        this._phoneNumber1 = phoneNumber1;
    }

    public get phoneNumber2() {
        return this._phoneNumber2;
    }

    public set phoneNumber2(phoneNumber2: number) {
        this._phoneNumber2 = phoneNumber2;
    }

    public get inscriptionTurn() {
        return this._inscriptionTurn;
    }
    public set inscriptionTurn(inscriptionTurn: number) {
        this._inscriptionTurn = inscriptionTurn;
    }

    public get examsLanguageSelected() {
        return this._examsLanguageSelected;
    }
    public set examsLanguageSelected(examsLanguageSelected: string) {
        this._examsLanguageSelected = examsLanguageSelected;
    }

    public get disabilityTurnSelected() {
        return this.inscriptionTurn === InscriptionTurn.enum.FREE_DISABILITY || this.inscriptionTurn === InscriptionTurn.enum.INTERNAL_DISABILITY || this.inscriptionTurn === InscriptionTurn.enum.FREE_DESIGNATION_DISABILITY;
    }

    public toServer(inscriptionId?) {
        return {
            inscriptionId,
            email: this._email,
            phoneNumber1: this._phoneNumber1,
            phoneNumber2: this._phoneNumber2,
            inscriptionTurn: this._inscriptionTurn,
            examsLanguageSelected: this._examsLanguageSelected        
        };
    }

    public isValid() {
        return ValidationService.isValidMail(this.email) && this.phoneNumber1 !== null && this.phoneNumber1 !== undefined && this.phoneNumber1.toString().length >= 1;
    }
}
