import { ChartData } from '@/Domain/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DoughnutChart } from '@/Components';

// interface CardLegendChartIcon {
//     iconClass: string;
//     colorClass: string;
//     hoverTrigger: boolean;
// }
@Component({
    components: {
        DoughnutChart
    }
})

export default class CardLegendChart extends Vue {
    @Prop() title!: string;
    @Prop() subTitle!: string;
    @Prop() noDataText!: string;
    @Prop() data!: ChartData;
    // @Prop() icon!: CardLegendChartIcon;
    // @Prop() disabled!: boolean;

    // $refs!: {
    //     icon: HTMLElement;
    //     wrapper: HTMLElement;
    // };

    // onMouseOverTitle() {
    //     if (!this.icon || !this.icon.hoverTrigger) {
    //         return;
    //     }
    //     this.$emit('onMouseOverTitle', { trigger: this.$refs.icon, wrapper: this.$refs.wrapper });
    // }

    // onMouseLeaveTitle() {
    //     if (!this.icon || !this.icon.hoverTrigger) {
    //         return;
    //     }
    //     this.$emit('onMouseLeaveTitle', { wrapper: this.$refs.wrapper });
    // }
}
