import { Merit, Process, MeritSubGroup, MeritGroup } from '@/Domain/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseMeritsType, FormMode, MeritGroupType, MeritRatingType, YesNo, Constants } from '@/Domain/enum';
import MeritsService from '@/Services/MeritsService';
import { Modal, EditNewMerit, EditNewGroupMerit, EditNewMeritSubGroup } from '@/Components';
import { mapState } from 'vuex';
import * as sdPanel from 'saviafront/lib/js/compiled/sd-panel';
import MeritSubGroupService from '@/Services/MeritSubGroupService';
import { OrderService } from '@/Application/Services/OrderService';

@Component({
  name: 'BaseMerits',
  components: {
    Modal,
    EditNewMerit,
    EditNewGroupMerit,
    EditNewMeritSubGroup
  },
  computed: {
    isMeritSubGroupEditionLayout() { 
      return this.$props.type.from === BaseMeritsType.enum.MERITSUBGROUP;
    },
    isMeritGroupEditionLayout() { 
      return this.$props.type.from === BaseMeritsType.enum.MERITGROUP;
    },
    meritSubGroupEditing() { 
      return this.$props.value.editingMeritGroup.editingMeritSubGroup;
    },
    meritGroupEditing() { 
      return this.$props.value.editingMeritGroup;
    },
    isEditingMeritGroup() { 
      return this.$props.value.isEditingMeritGroup;
    },
    isEditingMeritSubGroup() { 
      return this.$props.value.editingMeritGroup.isEditingMeritSubGroup;
    },
    isEditingMeritInGroup() {
      return this.$props.value.editingMeritGroup.isEditingMerit;
    },
    isCreatingMeritInGroup() { 
      return this.$props.value.editingMeritGroup.isCreatingMerit;
    },
    isCreatingSubGroupInGroup() { 
      return this.$props.value.editingMeritGroup.isCreatingMeritSubGroup;
    },
    isCreatingMeritInSubGroup() { 
      if (this.$props.value.editingMeritGroup.editingMeritSubGroup) {
        return this.$props.value.editingMeritGroup.editingMeritSubGroup.isCreatingMerit;
      }
    },
    publishAndUsed() {
      return this.$props.value.isUsed || this.$props.value.stateToggle;
    },
    ...mapState('languagesStore', { currentLanguage: 'current' })
  }
})
export default class BaseMerits extends Vue { 
  @Prop() value!: Process;
  @Prop() type!: any;

  meritGroupType = MeritGroupType;
  formMode = FormMode;
  meritRatingType = MeritRatingType;
  baseMeritsType = BaseMeritsType;
  yesNo = YesNo;
  constants = Constants;
  
  currentLanguage!: string;
  isMeritSubGroupEditionLayout!: boolean;
  meritGroupEditing!: MeritGroup;
  publishAndUsed!: boolean;
  meritToRemove: Merit = {} as Merit;
  meritSubGroupToRemove: MeritSubGroup = {} as MeritSubGroup;
  showDeleteModalMerit = false;
  showDeleteModalMeritSubGroup = false;
  newMerit: Merit = new Merit({}, this.value.editingMeritGroup ? this.value.editingMeritGroup.id : '');
  newMeritSubGroup: MeritSubGroup = new MeritSubGroup({});
  orderService: any;
  arrayToManage: any;
  
  $refs!: {
    panelBaseMerits: HTMLElement
  };

  editMerit(merit: Merit) {
    if (this.value.editingMeritGroup) {
      this.value.isEditingMeritGroup = false;
      this.value.editingMeritGroup.meritChilds.forEach(element => element.isEditing = false);
      this.value.editingMeritGroup.setPropsEditMerit(merit);
      if (this.value.editingMeritGroup.editingMeritSubGroup) { 
        this.value.editingMeritGroup.editingMeritSubGroup.merits.forEach(element => element.isEditing = false);
        merit.isEditing = true;
        this.value.editingMeritGroup.editingMeritSubGroup.isCreatingMerit = false;
        this.newMerit = new Merit({}, this.value.editingMeritGroup ? this.value.editingMeritGroup.id : '');
      }
    }
  }

  editMeritSubGroup(meritSubGroup: MeritSubGroup) {
    if (this.value.editingMeritGroup) { 
      this.value.isEditingMeritGroup = false;
      this.value.editingMeritGroup.isEditingMerit = false;
      this.value.editingMeritGroup.editingMeritSubGroup = meritSubGroup;
      this.value.editingMeritGroup.isCreatingMerit = false;
      this.value.editingMeritGroup.editingMeritSubGroup.merits.forEach(element => element.isEditing = false);
      this.value.editingMeritGroup.editingMeritSubGroup.isCreatingMerit = false;
    }
  }

  hideBaseMeritForms() {
    if (this.value.editingMeritGroup) {
      this.value.editingMeritGroup.isCreatingMerit = false;
      if (this.value.editingMeritGroup.editingMeritSubGroup) { 
        this.value.editingMeritGroup.editingMeritSubGroup.isCreatingMerit = false;
        this.orderService.arrayToManage = this.value.editingMeritGroup.editingMeritSubGroup.merits;
      }
      this.value.editingMeritGroup.isEditingMerit = false;
      this.value.editingMeritGroup.isCreatingMeritSubGroup = false;
      this.value.editingMeritGroup.isEditingMeritSubGroup = false;
      this.$emit('noError');
    }
    
  }

  setObjectToCreate() {
    if (this.value.editingMeritGroup) {
      if (this.type.from === this.baseMeritsType.enum.MERITSUBGROUP) {
        this.value.editingMeritGroup.isEditingMeritSubGroup = false;
        this.value.editingMeritGroup.setPropsAddMerit(this.value.editingMeritGroup.editingMeritSubGroup);
      }
      if (this.type.from === this.baseMeritsType.enum.MERITGROUP) {
        this.value.isEditingMeritGroup = false;
        this.value.editingMeritGroup.setPropsAddMerit(this.value.editingMeritGroup);
      }
    }
  }

  resetGroupMeritForms() { 
    (this.$parent as any).resetGroupMeritForms();
    if (this.value.editingMeritGroup) {
      this.orderService.arrayToManage = this.value.editingMeritGroup.meritChilds;
    }
  }

  closeListShown() {
    if (this.value.editingMeritGroup) {
      this.value.editingMeritGroup.meritChilds.filter(meritChild => meritChild.type === this.baseMeritsType.enum.MERITSUBGROUP).forEach(meritSubGroup => meritSubGroup.isDropped = false);
    }
  }

  removeMerit() {
    this.showDeleteModalMerit = false;

    MeritsService.removeMerit(this.value.id, this.meritToRemove)
      .then(response => {
          const meritIndex = this.arrayToManage.indexOf(this.meritToRemove);
          this.arrayToManage.splice(meritIndex, 1);
          this.arrayToManage.forEach((element, index) => element.order = index);
               
        })
        .catch(error => console.log(error));
  }

  arrayToSearchToManage() { 
    let arrayToReturn;
    if (this.value.editingMeritGroup) { 
      if (this.type.from === BaseMeritsType.enum.MERITGROUP) { 
        arrayToReturn = this.value.editingMeritGroup.meritChilds;
      }
  
      if (this.type.from === BaseMeritsType.enum.MERITSUBGROUP && this.value.editingMeritGroup.editingMeritSubGroup) { 
        arrayToReturn =  this.value.editingMeritGroup.editingMeritSubGroup.merits;
      }
    }
    return arrayToReturn;
  }

  removeMeritSubGroup() {
    this.showDeleteModalMeritSubGroup = false;

    MeritSubGroupService.removeMeritSubGroup(this.value.id, this.meritSubGroupToRemove)
        .then(response => {
            const meritGroupToAdd = this.value.meritGroups.find(element => element.id === this.meritSubGroupToRemove.meritGroupId);
            if (meritGroupToAdd) {
                const meritIndex = meritGroupToAdd.meritChilds.indexOf(this.meritSubGroupToRemove);
                meritGroupToAdd.meritChilds.splice(meritIndex, 1);
                meritGroupToAdd.meritChilds.forEach((element, index) => element.order = index);
            }
        })
        .catch(error => console.log(error));
  }

  confirmWithModalMerit(merit: Merit) {
    if (this.publishAndUsed) {
      return;
    }
    this.meritToRemove = merit;
    this.showDeleteModalMerit = true;
  }

  confirmWithModalMeritSubGroup(meritSubGroup: MeritSubGroup) {
    if (this.publishAndUsed) {
      return;
    }
    this.meritSubGroupToRemove = meritSubGroup;
    this.showDeleteModalMeritSubGroup = true;
  }

  backToMeritGroups() {
    this.hideBaseMeritForms();
    this.value.editingMeritGroup = null;
    (this.$parent as any).closeListShown();
  }

  backToMeritSubGroups() {
    if (this.value.editingMeritGroup) { 
      this.value.editingMeritGroup.editingMeritSubGroup = null;
      this.value.editingMeritGroup.isEditingMeritSubGroup = false;
      this.type.from = this.baseMeritsType.enum.MERITGROUP;
    }
    this.$nextTick(() => {
      this.closeListShown();
      sdPanel.initialize([this.$parent.$refs.panelBaseMerits]);
    });
  }

  goDownDisabled(children) {
    if (this.isMeritSubGroupEditionLayout && this.meritGroupEditing.editingMeritSubGroup) {
      return  children.order === this.meritGroupEditing.editingMeritSubGroup.merits.length - 1;  
    } else {
      return children.order === this.meritGroupEditing.meritChilds.length - 1;
    }
  }

  mounted() {
    this.arrayToManage = this.arrayToSearchToManage();
    if (this.type.from === this.baseMeritsType.enum.MERITGROUP) { 
      this.$nextTick(() => {
        sdPanel.initialize([this.$refs.panelBaseMerits]); 
      });
      this.orderService = new OrderService(this.value.id, this.arrayToManage, MeritsService.setMeritsOrder);
    } else {
      this.orderService = new OrderService(this.value.id, this.arrayToManage, MeritSubGroupService.setMeritsOrder);
    }
  }
}
