import { ReplacementCandidate } from '@/Domain/Entities';
import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/replacementlistcandidates';

export default class ReplacementCandidatesService {
    public static async getReplacementCandidate(replacementListCandidateId: string) {
        return new Promise((resolve, reject) => {
            axios.get(replacementListCandidateId, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async editReplacementCandidate(replacementCandidate: ReplacementCandidate) {
        return new Promise((resolve, reject) => {
            axios.put(replacementCandidate.replacementListCandidateId, replacementCandidate.toServer(), { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
