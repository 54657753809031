import { Component, Prop, Vue } from 'vue-property-decorator';
import { Process } from '@/Domain/Entities';
import { mapState } from 'vuex';
import _ from 'lodash';

@Component({
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        authorizationsTypesViewOrdered() {
            return _.orderBy(this.$props.value.authorizations, [authorizationTypeView => _.deburr(authorizationTypeView.text[(this as Authorizations).currentLanguage]).toLowerCase()]);
        }
    }
})
export default class Authorizations extends Vue {
    @Prop() value!: Process;
    currentLanguage!: string;
}
