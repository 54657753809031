import {ProcessMerit, ProcessMeritSubGroup} from '@/Domain/Entities';
import {TabsStatusScaling, MeritRatingType, BaseMeritsType } from '@/Domain/enum';

export default class ProcessMeritGroup {

    private _id: string;
    private _groupType: number;
    private _maxScore: number;
    private _order: number;
    private _total: number;
    private _merits: ProcessMerit[];
    private _active: boolean;
    private _status: number;
    private _meritChilds: Array<ProcessMeritSubGroup | ProcessMerit>;
    private _isDropped: boolean;

    public constructor(data: any, availableLanguages: string[]) {
        this._id = data.id;
        this._groupType = data.groupName != null ? data.groupName : data.groupType;
        this._maxScore = data.maxScore;
        this._order = data.order;
        this._total = data.total;
        this._merits = data.merits ? data.merits.map(merit => new ProcessMerit(merit, availableLanguages)) : [];
        this._meritChilds = data.meritChilds ? data.meritChilds.map(baseMerit => baseMerit.type === BaseMeritsType.enum.MERIT ? new ProcessMerit(baseMerit, availableLanguages) : new ProcessMeritSubGroup(baseMerit, availableLanguages)) : [];
        this._status = this.manageStatus(availableLanguages);
        this._active = false;
        this._isDropped = false;
        this.manageTotal();
    }

    public get id() {
        return this._id;
    }

    public set id(id: string ) {
        this._id = id;
    }

    public get groupType() {
        return this._groupType;
    }

    public set groupType(groupType: number ) {
        this._groupType = groupType;
    }

    public get active(): boolean {
        return this._active;
    }

    public set active(active: boolean ) {
        this._active = active;
    }

    public get status(): number {
        return this._status;
    }

    public setstatus(availableLanguages) {
        this._status = this.manageStatus(availableLanguages);
    }

    public get maxScore() {
        return this._maxScore;
    }

    public set maxScore(maxScore: number ) {
        this._maxScore = maxScore;
    }

    public get order() {
        return this._order;
    }

    public set order(order: number ) {
        this._order = order;
    }

    public get total() {
        return this._total;
    }

    public set total(total: number ) {
        this._total = total;
    }

    public get merits() {
        return this._merits;
    }

    public set merits(merits: ProcessMerit[] ) {
        this._merits = merits;
    }

    public get meritChilds() {
        return this._meritChilds;
    }

    public set meritChilds(meritChilds: Array<ProcessMeritSubGroup | ProcessMerit> ) {
        this._meritChilds = meritChilds;
    }

    public get anyMeritIsPending() {
        return this.meritChilds.some(children => {
            if (children.type === BaseMeritsType.enum.MERIT) {
                return (children as ProcessMerit).oficialScore === null;
            }
            if (children.type === BaseMeritsType.enum.MERITSUBGROUP) {
                return (children as ProcessMeritSubGroup).merits.some(merit => {
                    return (merit as ProcessMerit).oficialScore === null;
                });
            }
        });
    }

    public get isDropped() {
        return this._isDropped;
    }
    public set isDropped(isDropped: boolean) {
        this._isDropped = isDropped;
    }

    public manageTotal() {
        let total = 0;
        this.meritChilds.forEach(element => {
            total += element.getTotal();
        });

        if ( this.maxScore != null && total > this.maxScore ) {
            this._total = Number(this.maxScore);
        } else {
            this._total = Number(total.toFixed(6));
        }
        this.getAllMerits().forEach(merit => merit.setIsMaximumScoreReached());
    }

    public toServer() {
        const meritToServe = [] as  any;

        this.meritChilds.forEach(children => {
            if (children.type === BaseMeritsType.enum.MERIT) {
                meritToServe.push(children.toServer());
            }
            if (children.type === BaseMeritsType.enum.MERITSUBGROUP) {
                (children as ProcessMeritSubGroup).merits.forEach(merit => {
                    merit.meritGroupId = this._id;
                    meritToServe.push((merit as ProcessMerit).toServer());
                });
            }
        });
        return{
            id: this._id,
            merits: meritToServe
        };
    }

    firstChildMerit() {
        const firstElement = 0;
        return this.meritChilds[firstElement].type === BaseMeritsType.enum.MERIT ? this.meritChilds[firstElement] : (this.meritChilds[firstElement] as ProcessMeritSubGroup).merits[firstElement];
    }

    public meritGroupContainsThisMerit(processMeritId: string) {
        const allMerits = this.getAllMerits();
        return allMerits.find((merit: ProcessMerit) => merit.id === processMeritId);
    }

    public findChildrenMeritGroup(processMeritClone: ProcessMerit) {
        if (processMeritClone.meritSubGroupId) {
            const subGroup = this.meritChilds.find(children => children.id === processMeritClone.meritSubGroupId);
            ((subGroup as ProcessMeritSubGroup).findMerit(processMeritClone));
        } else {
            (this.meritChilds.find(element => element.id === processMeritClone.id) as ProcessMerit).setRejectionReason(processMeritClone.rejectionReason);
        }
    }

    hasGroupAnyMerit() {
        return this.meritChilds.some(children => children.type === BaseMeritsType.enum.MERIT || (children as ProcessMeritSubGroup).merits.length > 0);
    }

    hasGroupAnyMeritValid() {
        const subGroups = this.meritChilds.filter(element => element.type === BaseMeritsType.enum.MERITSUBGROUP);
        const merits = this.meritChilds.filter(element => element.type === BaseMeritsType.enum.MERIT);
        if  (subGroups.length && !merits) {
            return subGroups.some(subGroup => (subGroup as ProcessMeritSubGroup).merits.findIndex(element => !(element as ProcessMerit).isValid) === -1) ;
        }
        if  (subGroups.length && merits) {
            const hasMeritValidSubGroup = subGroups.some(subGroup => (subGroup as ProcessMeritSubGroup).merits.findIndex(element => !(element as ProcessMerit).isValid) === -1);
            const hasMeritValid = this.meritChilds.filter(element => element.type === BaseMeritsType.enum.MERIT).findIndex(element => !(element as ProcessMerit).isValid) === -1;
            return hasMeritValidSubGroup && hasMeritValid;
        }
        
        return this.meritChilds.findIndex(element => !(element as ProcessMerit).isValid) === -1;
    }

    setActiveAllMerits() {
        this.meritChilds.forEach(children => {
            if (children.type === BaseMeritsType.enum.MERIT) {
                children.setActive();
            } else {
                (children as ProcessMeritSubGroup).merits.forEach(merit => (merit as ProcessMerit).setActive());
            }
        });
    }

    getAllMerits() {
        const allMerits: ProcessMerit[] = [];
        
        this.meritChilds.forEach(children => {
            if (children instanceof ProcessMerit) {
                allMerits.push(children);
            }
            if (children instanceof ProcessMeritSubGroup) {
                children.merits.forEach(merit => {
                    allMerits.push((merit as ProcessMerit));
                });
            }
        });

        return allMerits;
    }
    
    setActiveMeritById(meritIdSelected: string) {
        const merit = this.getAllMerits().find(element => element.id === meritIdSelected);
        (merit as ProcessMerit).active = true;
    }

    rejectNonAssesedMerits() {
        this.getUnscoredMerits().forEach(merit => {
            merit.rejectAllDocuments();
            merit.markMeritAsMaximumScoreReached();
        });
    }

    maxScoreHasBeenReached() {
        let score = 0;
        const scoredMerits = this.getAllMerits().filter(merit => merit.oficialScore);
        scoredMerits.forEach(merit => score += Number(merit.oficialScore));
        return score >= this.maxScore;
    }

    isAnyUnScoredMerit() {
        return this.getUnscoredMerits().length > 0;
    }

    isMoreThanOneMerit() {
        return this.getAllMerits().length > 1;
    }

    isMaxScoreSubGroupEqualToMaxScore(subGroup: ProcessMeritSubGroup) {
        return subGroup.maxScore === this.maxScore;
    }
    
    markAllUnScoredMeritsToUnReachedMaxScore() {
        this.getUnscoredMerits().forEach(merit => {
           merit.markMeritToUnReachedMaxScore();
        });
    }

    isSomeMeritIsMarkedAsReachedMaxScore() {
        return this.getAllMerits().some(merit => merit.isMaximumScoreReached);
    }

    private hasOficialScore(element) {
        return (element as ProcessMerit).oficialScore as any || (element as ProcessMerit).oficialScore as any === 0;
    }

    private hasOficialScoreDistinctUserScoreNumber(element) {
        return Number((element as ProcessMerit).oficialScore) !== Number((element as ProcessMerit).userScore);
    }

    private hasOficialScoreDistinctUserScore(element) {
        return Number((element as ProcessMerit).oficialScore) !== (element as ProcessMerit).userScore;
    }

    private hasOficialScoreDistinctZero(element) {
        return Number((element as ProcessMerit).oficialScore) !== 0;
    }

    private hasOficialScoreHigherMaxScore(element) {
        return Number((element as ProcessMerit).maxScore) && Number((element as ProcessMerit).oficialScore) > Number((element as ProcessMerit).maxScore);
    }

    private hasOficialScoreLessZero(element) {
        return Number((element as ProcessMerit).oficialScore) < 0;
    }

    private getUnscoredMerits() {
        return this.getAllMerits().filter(merit => merit.oficialScore as any === '');
    }

    private manageStatus(availableLanguages) {
        if (!this.hasGroupAnyMerit()) {
            return TabsStatusScaling.enum.DISABLED;
        } 

        if (this.hasGroupAnyMeritValid()) {
            return TabsStatusScaling.enum.SUCCESS;
        }

        if (this.meritChilds.some(element => element.type === BaseMeritsType.enum.MERIT && (element as ProcessMerit).ratingType !== MeritRatingType.enum.FIXED_SCORE ?
            this.hasOficialScore(element) && this.hasOficialScoreDistinctUserScoreNumber(element) && !(element as ProcessMerit).rejectionReason.allHasValue(availableLanguages) || this.hasOficialScore(element) && (this.hasOficialScoreHigherMaxScore(element) || this.hasOficialScoreLessZero(element)) :
            this.hasOficialScore(element) && ((this.hasOficialScore(element) && this.hasOficialScoreDistinctZero(element)) && (this.hasOficialScore(element) && this.hasOficialScoreDistinctUserScore(element)) || element.type === BaseMeritsType.enum.MERIT && (this.hasOficialScoreDistinctUserScoreNumber(element) && !(element as ProcessMerit).rejectionReason.allHasValue(availableLanguages))))) {
            return TabsStatusScaling.enum.ERROR;
        }

        if (this.meritChilds.some(children => children.type === BaseMeritsType.enum.MERITSUBGROUP && (children as ProcessMeritSubGroup).merits.some(element => element.type === BaseMeritsType.enum.MERIT && (element as ProcessMerit).ratingType !== MeritRatingType.enum.FIXED_SCORE ?
            this.hasOficialScore(element) && this.hasOficialScoreDistinctUserScoreNumber(element) && !(element as ProcessMerit).rejectionReason.allHasValue(availableLanguages) || this.hasOficialScore(element) && (this.hasOficialScoreHigherMaxScore(element) || this.hasOficialScoreLessZero(element)) :
            this.hasOficialScore(element) && ((this.hasOficialScore(element) && this.hasOficialScoreDistinctZero(element)) && (this.hasOficialScore(element) && this.hasOficialScoreDistinctUserScore(element)) || element.type === BaseMeritsType.enum.MERIT && (this.hasOficialScoreDistinctUserScoreNumber(element) && !(element as ProcessMerit).rejectionReason.allHasValue(availableLanguages)))))) {
                return TabsStatusScaling.enum.ERROR;
        }
        
        return TabsStatusScaling.enum.DEFAULT;
    }
}
