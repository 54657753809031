import { ProcessConfigurationTypes } from '@/Domain/enum';
import ProcessConfigPayExemption from './ProcessConfigPayExemption';
import ProcessConfigAuthorization from './ProcessConfigAuthorization';
import ProcessConfigExam from './ProcessConfigExam';
import ProcessConfigGroup from './ProcessConfigGroup';
import IProcessConfig from '@/Domain/interfaces/IProcessConfig';

export default abstract class ProcessConfigFactory {
    static create(type: number, data: any): IProcessConfig {
        const processConfig = this._processConfigTypes[type];
        return new processConfig(data);
    }
    
    private static _processConfigTypes = {
        [ProcessConfigurationTypes.enum.PAYEXEMPTIONS]: ProcessConfigPayExemption,
        [ProcessConfigurationTypes.enum.AUTHORIZATIONS]: ProcessConfigAuthorization,
        [ProcessConfigurationTypes.enum.EXAMS]: ProcessConfigExam,
        [ProcessConfigurationTypes.enum.GROUPSUBGROUP]: ProcessConfigGroup
    };
}
