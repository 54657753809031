import { ProcessMerit, ProcessMeritGroup, ProcessMeritSubGroup } from '@/Domain/Entities';
import { Languages, MeritRatingType, ProcessPhases, Constants } from '@/Domain/enum';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

/**
 * Component that allows you to view and edit information related to the merit 
 * of a qualification process
 */
@Component({
   computed: {
      ...mapState('featureFlagsStore', ['multiLanguage']),
      ...mapState('languagesStore', { currentLanguage: 'current' }),
      ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
   }
})
export default class ScalingMerit extends Vue {
   currentLanguage!: string;
   availableLanguages!: string[];

   /** merit to handle */
   @Prop({}) merit!: ProcessMerit;
   /** meritGroup to handle */
   @Prop({}) meritGroup!: ProcessMeritGroup; 
   /** meritSubGroup to handle */
   @Prop({}) meritSubGroup!: ProcessMeritSubGroup; 
   /** Property where the process data is */
   @Prop({}) processData!: any;
   /** Indicates the active tab */
   @Prop({}) tabActive!: string;
   /** Indicates if the form has been submitted */
   @Prop({}) submitted!: boolean;

   ProcessPhases = ProcessPhases;
   MeritRatingType = MeritRatingType;
   Languages = Languages;
   Constants = Constants;
   isReasonVisible: boolean = false;

   /**
    * @public
    * Raise 'setTabActive' event to set the active tab
    * @param {string} language - Selected language
    */
   setTabActive(language: string) {
      this.$emit('setTabActive', language);
   }

   /** 
    * @public
    * Raise 'setMeritActive' event to set the active merit
    */
   setMeritActive() {
      this.$emit('setMeritActive', this.merit);
   }

   /**
    * @public
    * Toggle the value of isReasonVisible to set whether or not the rejectionReason is displayed
    */
   toggleReason() {
      this.isReasonVisible = !this.isReasonVisible;
   }

   /** 
    * @public
    * Manage calculations related to merit
    */
   manageCalcs() {
      this.merit.manageIsValid(this.availableLanguages);
      if (this.meritSubGroup) {
         this.meritSubGroup.manageTotal();
      }
      this.meritGroup.manageTotal();
      if (Number(this.merit.oficialScore) === this.merit.userScore) {
         this.isReasonVisible = true;
      }
      this.hasBeenReachedMaxScore();
   }

   /**
    * @public
    * Method called when user click out on oficialScore input. 
    * emit to parent (ScalingEdit.ts) who has reached the maximum score,returned value can be 
    * ProcessMeritGroup or ProcessMeritSubGroup data type
    */
   hasBeenReachedMaxScore() {
      if (this.shouldExitIfGroupReachedMaxScore()) {
         return;
      }
      this.meritGroup.markAllUnScoredMeritsToUnReachedMaxScore();
      if (this.checkMaxScoreMeritSubgroup()) {
         this.$emit('hasBeenReachedMaxScore', this.meritSubGroup);
      }
      if (this.checkMaxScoreMeritGroup()) {
         this.$emit('hasBeenReachedMaxScore', this.meritGroup);
      }
   }

   /**
    * @public
    * Checks if MeritSubgroup has been reached maxScore and must show the modal to inform the user
    */
   checkMaxScoreMeritSubgroup() {
      return this.meritSubGroup && this.meritSubGroup.isMoreThanOneMerit() && 
         this.meritSubGroup.isAnyUnScoredMerit() && this.meritSubGroup.maxScoreHasBeenReached();
   }

   /**
    * @public
    * Checks if MeritGroup has been reached maxScore and must show the modal to inform the user
    */
   checkMaxScoreMeritGroup() {
      return this.meritGroup.maxScore && this.meritGroup.isMoreThanOneMerit() && 
         this.meritGroup.isAnyUnScoredMerit() && this.meritGroup.maxScoreHasBeenReached();
   }

   /**
    * @public
    * Checks if should calc the max score of meritGroup or  meritSubgroup
    */
   shouldExitIfGroupReachedMaxScore() {
      return  (this.merit.oficialScore) as any !== '' && this.meritGroup.isSomeMeritIsMarkedAsReachedMaxScore() && 
         (this.checkMaxScoreMeritGroup() || this.checkMaxScoreMeritSubgroup());
   }

   /**
    * @public
    * Copy the this.merit.userScore 
    * on this.merit.oficialScore when this.merit.userScore is defined
    * Then manage the total points of the meritGroup.
    * If meritSubGroup is defined and merit.meritSubGroupId too, 
    * manage the total points of the meritSubGroup
    */
   copyScore() {
      this.merit.oficialScore = this.merit.userScore ? this.merit.userScore : 0;
      this.merit.manageIsValid(this.availableLanguages);
      this.meritGroup.manageTotal();

      if (this.merit.meritSubGroupId && this.meritSubGroup) {
         this.meritSubGroup.manageTotal();
      }
   }

   /**
    * @public
    * Emit to parent (ScalingEdit.ts) that data has been changed
    */
   dataChanged() {
      this.$emit('dataChanged');
   }

   /**
    * @public
    * Method called when the component has been mounted. 
    * Set the initial value of isReasonVisible
    */
   mounted() {
      this.isReasonVisible = this.merit && this.merit.rejectionReason.allHasValue(this.availableLanguages) ? false : true;
   }
}
