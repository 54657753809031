import _ from 'lodash';
import { MultiLanguage } from '..';
import { LanguagesStore } from '@/Store/modules/LanguagesStore';

export default class SorterNew {
    private _keys: string[];
    private _reverse: Array<('desc' | 'asc')>;

    constructor(keys: string[], reverse: Array<('desc' | 'asc')>) {
        this._keys = keys;
        this._reverse = reverse;
    }

    public get keys() {
        return this._keys;
    }

    public sortBy(array: any[]) {
        const currentLanguage = LanguagesStore.getters.getCurrentLanguage();
        const iteratees = this._keys.map((key: string) => {
            if (array.some(elem => typeof elem[key] === 'string')) {
                return element => _.deburr(element[key]).toLowerCase();
            }
            if (array.some(elem => elem[key] instanceof MultiLanguage) && currentLanguage) {
                return element => _.deburr(element[key][currentLanguage]).toLowerCase();
            }
            return key;
        });
        return _.orderBy(array, iteratees, this._reverse);
    }

    public updateKeys(keys: string[]) {
        if (this.areEquals(this._keys, keys)) {
            this._reverse = this._reverse.map(reverse => reverse === 'desc' ? 'asc' : 'desc');
        } else if (keys) {
            this._keys = keys;
            this._reverse = keys.map(() => 'asc');
        }
    }

    private areEquals(a, b) {
        if (!a || !b) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }
}
