import Enum from '../interfaces/IEnum';

export const GradeType: Enum = {
    translations: {
        0: 'lang.gradeType.score',
        1: 'lang.gradeType.noScore'
    },
    enum: {
        SCORE: 0,
        NOSCORE: 1
    }
};
