import moment from 'moment';
import { Inscription, InscriptionFile, MeritGroup, Exam, JustificationDocument, PersonalDataDetails, Address, ContactData, AuthorizationProcess, DisabilityData, PayExemptionProcess, LegalConsent } from '@/Domain/Entities';
import { EditionType, FileTypes, InscriptionRegistry, InscriptionStatus, OriginDocument, PayExemptionTypes, YesNo } from '@/Domain/enum';
import _ from 'lodash';
import { CryptoSignature } from './CryptoSignature';

export default class InscriptionCandidate extends Inscription {
    private _processId: string;
    private _status: number;
    // Personal Data
    private _personalDataDetails: PersonalDataDetails;
    // Adress
    private _address: Address;
    // Contact Data;
    private _contactData: ContactData;
    // Authorization
    private _authorization: AuthorizationProcess;
    // Disability Data
    private _disabilityData: DisabilityData;
    // Legal Consent
    private _legalConsent: LegalConsent;
    // Requirements
    private _requirements: any[];
    // Merits
    private _meritGroups: MeritGroup[];
    // Documents
    private _documents: InscriptionFile[];
    private _justificationDocuments: JustificationDocument[];
    // Payment
    private _payExemption: PayExemptionProcess;
    private _paymentMethod: number;
    private _inscriptionDate: moment.Moment;
    // Exams
    private _creditableExams: Exam[];
    // Process
    private _hasAuthorizationProcess: boolean | null;
    // CryptoSignature
    private _cryptoSignature: CryptoSignature | null;

    constructor(data: any) {
        super(data);
        this._processId = data.processId;
        this._personalDataDetails = new PersonalDataDetails(data.personalData.personalDataDetails);
        this._address = new Address(data.personalData.address);
        this._contactData = new ContactData(data.personalData.contactData);
        this._authorization = new AuthorizationProcess(data.personalData.authorization);
        this._disabilityData = new DisabilityData(data.personalData.disabilityData);
        this._hasAuthorizationProcess = null;
        this._legalConsent = new LegalConsent();
        this._requirements = data.requirements || [];
        this._meritGroups = data.meritGroups ? data.meritGroups.map(meritGroup => new MeritGroup(meritGroup)) : [];
        this._documents = data.documents.map((file: any) => new InscriptionFile(file)) || [];
        this._justificationDocuments = data.justificationDocuments.map((file: any) => new JustificationDocument(file)) || [];
        if (!this._justificationDocuments.length) {
            this._justificationDocuments.push(new JustificationDocument({ origin: OriginDocument.enum.JUSTIFY }));
        }
        this._payExemption = new PayExemptionProcess(data.payExemption);
        this._paymentMethod = data.paymentMethod;
        this._inscriptionDate = data.inscriptionDate ? data.inscriptionDate : moment();
        this._creditableExams = data.creditableExams || [];
        this._status = data.inscriptionStatus;
        this._cryptoSignature = data.cryptoSignature ? new CryptoSignature(data.cryptoSignature) : null;
    }

    public toServer() {
        const meritGroupsToServe = this.meritGroups.map(element => element.toServerToInscription());
        const justificationDocument = this._justificationDocuments.filter((doc: JustificationDocument) => doc.origin === OriginDocument.enum.JUSTIFY)[0];

        return {
            inscriptionId: this._id,
            processId: this._processId,
            personalDataDetails: this._personalDataDetails.toServerInscription(),
            address: this._address.toServerInscription(),
            contactData: this._contactData.toServer(),
            authorization: this._hasAuthorizationProcess ? this._authorization.toServer() : {},
            disabilityData: this._disabilityData.toServerInscription(),
            legalConsent: this._legalConsent.toServer(),
            requirements: this._requirements,
            meritGroups: meritGroupsToServe,
            paymentMethod: this._paymentMethod,
            payExemption: this._payExemption.toServerInscription(),
            inscriptionDate: this._inscriptionDate,
            creditableExams: this._creditableExams,
            justificationDocument: justificationDocument ? justificationDocument.info() : null
        };
    }

    public get processId() {
        return this._processId;
    }
    public set processId(processId: string) {
        this._processId = processId;
    }

    public get personalDataDetails() {
        return this._personalDataDetails;
    }
    public set personalDataDetails(personalDataDetails: PersonalDataDetails) {
        this._personalDataDetails = personalDataDetails;
    }

    public get address() {
        return this._address;
    }
    public set address(address: Address) {
        this._address = address;
    }

    public get contactData() {
        return this._contactData;
    }
    public set contactData(contactData: ContactData) {
        this._contactData = contactData;
    }

    public get authorization() {
        return this._authorization;
    }
    public set authorization(authorization: AuthorizationProcess) {
        this._authorization = authorization;
    }

    public get hasAuthorizationProcess() {
        return this._hasAuthorizationProcess;
    }
    public set hasAuthorizationProcess(hasAuthorizationProcess: boolean | null) {
        this._hasAuthorizationProcess = hasAuthorizationProcess;
    }

    public get disabilityData() {
        return this._disabilityData;
    }
    public set disabilityData(disabilityData: DisabilityData) {
        this._disabilityData = disabilityData;
    }

    public get legalConsent() {
        return this._legalConsent;
    }
    public set legalConsent(legalConsent: LegalConsent) {
        this._legalConsent = legalConsent;
    }

    public get requirements() {
        return this._requirements;
    }
    public set requirements(requirements: any[]) {
        this._requirements = requirements;
    }

    public get meritGroups() {
        return this._meritGroups;
    }
    public set meritGroups(meritGroups: any[]) {
        this._meritGroups = meritGroups;
    }

    public get documents() {
        return this._documents;
    }
    public set documents(documents: InscriptionFile[]) {
        this._documents = documents;
    }

    public get justificationDocuments() {
        return this._justificationDocuments;
    }
    public set justificationDocuments(justificationDocuments: JustificationDocument[]) {
        this._justificationDocuments = justificationDocuments;
    }

    public get payExemption() {
        return this._payExemption;
    }
    public set payExemption(payExemption: PayExemptionProcess) {
        this._payExemption = payExemption;
    }

    public get paymentMethod() {
        return this._paymentMethod;
    }
    public set paymentMethod(paymentMethod: number) {
        this._paymentMethod = paymentMethod;
    }

    public set inscriptionDate(inscriptionDate: moment.Moment) {
        this._inscriptionDate = inscriptionDate;
    }

    public get inscriptionDate() {
        return this._inscriptionDate;
    }

    public get creditableExams() {
        return this._creditableExams;
    }
    public set creditableExams(creditableExams: any[]) {
        this._creditableExams = creditableExams;
    }

    public get status() {
        return this._status;
    }
    public set status(status: number) {
        this._status = status;
    }

    public get isOnlineInscription() {
        return this.registry === InscriptionRegistry.enum.ONLINE && this.status !== InscriptionStatus.enum.DRAFT;
    }

    public get isOnlineInscriptionAndDocumentDisability() {
        return this.registry === InscriptionRegistry.enum.ONLINE && this.disabilityData.hasDisability === YesNo.enum.YES && this.documents.some((doc: InscriptionFile) => doc.evaluableConditionType === FileTypes.enum.DISABILITY && doc.editionType === EditionType.enum.APPLICANTINSCRIPTION);
    }

    public get isDisabilityAndExemptionDisability() {
        return this.disabilityData.hasDisability === YesNo.enum.YES && this.payExemption.payExemptionType === PayExemptionTypes.enum.DISABILITY;
    }

    public get hasDocumentOfApplicant() {
        return this.documents.some((file: InscriptionFile) => file.editionType === EditionType.enum.APPLICANTINSCRIPTION || file.editionType === EditionType.enum.APPLICANTCORRECTION);
    }

    public get hasCorrectJustificationDocument() {
        return this.justificationDocuments.some(file => file.origin === OriginDocument.enum.CORRECTION);
    }

    public get orderedJustifyDoc() {
        return _.orderBy(this.justificationDocuments, 'origin');
    }

    public get totalPoints() {
        const totalPoints = this.meritGroups.reduce((acc, sub) => {
            return acc + sub.totalGroupScore();
        }, 0);
        return Number(totalPoints.toFixed(6));
    }

    public get cryptoSignature() {
        return this._cryptoSignature;
    }

    public filterDocuments(conditionType) {
        const orderDocuments = this.documents.filter((doc: InscriptionFile) => doc.evaluableConditionType === conditionType);
        return _.orderBy(orderDocuments, ['fileDate'], ['desc']);
    }

    public addDocument(file: any) {
        this.documents.push(new InscriptionFile(file));
    }

    public removeDocument(index, deleteCount) {
        this.documents.splice(index, deleteCount);
    }

    public changeHasdisability() {
        if (this.status !== InscriptionStatus.enum.DRAFT && !this.contactData.disabilityTurnSelected) {
            return;
        }
        this.disabilityData.hasDisability = this.contactData.disabilityTurnSelected ? YesNo.enum.YES : YesNo.enum.NO;
    }

    public findMeritInInscription(groupId, meritId, meritSubGroupId?) {
        const meritGroupFind = this.meritGroups.find(meritGroup => meritGroup.id === groupId);
        return meritGroupFind.findMerit(meritId, meritSubGroupId);
    }

    public removeJustifyFile(justificationDocument: JustificationDocument) {
        const index = this.orderedJustifyDoc.indexOf(justificationDocument);
        this.orderedJustifyDoc[index].document = null;
    }
}
