export default class MultiLanguage {

    public static getTranslationToFilter(multiLanguage: MultiLanguage | string, currentLanguage: string): string {
        if (typeof multiLanguage === 'string') {
            return multiLanguage;
        }
        if (!currentLanguage || !multiLanguage.hasKey(currentLanguage)) {
            return '';
        }

        return multiLanguage[currentLanguage];
    }

    public constructor(data) {
        // Se crea dinamicamente las propiedades de la clase MultiLanguage independientemente del número de idiomas que nos lleguen en la llamada
        // en data nos llega un objeto cuya clave es el idioma en formato "**-ES" y el value es el texto a traducir

        if (typeof data !== 'string') {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    this[key] = data[key];
                }
            }
        }
    }

    public hasKey(key: string): boolean {
        return this.hasOwnProperty(key);
    }

    public valueIsNullOrEmpty(key: string): boolean {
        return !this[key];
    }

    public maxCharacter(lang: string, long: number): boolean {
        return this[lang].replace(/<[^>]*>?/g, '').replace(/&nbsp;/g, ' ').length > long;
    }

    public anyHasValue(languages: string[]): boolean {
        let hasValue = false;
        languages.forEach(element => {
            hasValue = hasValue || !this.valueIsNullOrEmpty(element);
        });
        return hasValue;
    }

    public allHasValue(languages: string[]): boolean {
        const hasAllValue = languages.some(element => {
            return this.valueIsNullOrEmpty(element);
        });
        return !hasAllValue;
    }

    public toString() {
        return JSON.stringify(this);
    }

}
