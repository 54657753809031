import axios from 'axios';
import fileService from '@/Services/FileService';
import { saveAs } from 'file-saver';

export default class CsvService {

    public static async getCsv(baseURL, url: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(url, { baseURL, responseType: 'blob' })
                .then(response => {
                    const fileType = response.headers['content-type'];
                    const blob = new Blob([response.data], { type: fileType });
                    saveAs(blob, fileService.getFileName(response));
                    resolve(response.data);
                })
                .catch(error => reject());
        });
    }

    public static async getCsvFromBase64(csvBase64: string, nameToFile: string) {
        const decodedData = atob(csvBase64);
        const blob = new Blob([decodedData], { type: 'text/csv' });
        saveAs(blob, nameToFile);
    }
}
