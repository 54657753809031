import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

/**
 * Component to view documents and perform actions on them
 */
@Component({
    computed: {
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'})
    }
})

export default class SdDocumentViewer extends Vue {
    dateFormat!: string;

    /** Documents to be managed */
    @Prop() documentsToManage?: any[];

    /**
     * @public
     * Raise 'removeFile' event to delete a document
     * @param {any} document - Document to be removed
     */
    removeFile(document: any) {
        this.$emit('removeFile', document);
    }

    /**
     * @public
     * Raise 'downloadFile' event to download a document
     * @param {any} document - Document to be downloaded
     */
    downloadFile(document: any) {
        this.$emit('downloadFile', document);
    }

    /**
     * @public
     * Set the file time with specific format
     * @param {any} document - Document to modify time to view
     */
    hourFormat(document: any) {
        const hourFormat = 'HH:mm';
        return document.fileDate ? moment(document.fileDate.toISOString()).format(hourFormat) : '';
    }
}
