import { OepDocument, MultiLanguage } from '@/Domain/Entities';

export default class BOEP {
    private _id: string;
    private _url: string;
    private _title: MultiLanguage;
    private _description: MultiLanguage;
    private _entityOepId: string;
    private _year: number;
    private _documents: OepDocument[];
    private _entityName: string;

    public get id() {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get url() {
        return this._url;
    }
    public set url(value: string) {
        this._url = value;
    }

    public get title() {
        return this._title;
    }
    public set title(value: MultiLanguage) {
        this._title = value;
    }

    public get description() {
        return this._description;
    }
    public set description(value: MultiLanguage) {
        this._description = value;
    }

    public get entityOepId() {
        return this._entityOepId;
    }
    public set entityOepId(value: string) {
        this._entityOepId = value;
    }

    public get year() {
        return this._year;
    }
    public set year(value: number) {
        this._year = value;
    }

    public get documents() {
        return this._documents;
    }
    public set documents(documents: OepDocument[]) {
        this._documents = documents;
    }

    public get entityName() {
        return this._entityName;
    }
    public set entityName(entityName: string) {
        this._entityName = entityName;
    }

    public constructor(data: any) {
        this._id = data.id;
        this._url = 'boep/';
        this._title = new MultiLanguage(data.title);
        this._description = new MultiLanguage(data.description);
        this._entityOepId = data.entityOepId;
        this._year = data.year;
        this._documents = data.documents || [];
        this._entityName = '';
    }

    public addDocument(document: any): void {
        this._documents.push(
            new OepDocument(document)
        );
    }

    public removeDocument(document: OepDocument): void {
        const documentIndex = this.documents.indexOf(document);
        if (documentIndex !== -1) {
            this._documents.splice(documentIndex, 1);
        }
    }

    public setEntityName(entities: any) {
        entities.forEach(entity => {
            if (entity.id === this.entityOepId) {
                this.entityName = entity.name;
            }
        });
    }

    public toServer() {
        return {
            id: this._id,
            url: this._url,
            title: this._title,
            description: this._description,
            entityOepId: this._entityOepId,
            year: this._year
        };
    }
}
