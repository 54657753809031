import Enum from '../interfaces/IEnum';

export const MassiveNoticeDocumentReceiversType: Enum = {
    translations: {
        0: 'lang.document.sendEmailRegisteredCandidates',
        1: 'lang.document.sendEmailFinalistsCandidates',
        2: 'lang.document.sendEmailReachExam',
        3: 'lang.document.sendEmailAdmittedCandidates',
        4: 'lang.document.sendEmailExcludedCandidates'
    },
    enum: {
        REGISTERED: 0,
        FINALISTS: 1,
        EXAMS: 2,
        Admitted: 3,
        Excluded: 4
    }
};
