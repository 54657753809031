import { Component, Vue } from 'vue-property-decorator';
import { OppositionType, ProcessPhases, RatingsTabs, RuleCriteriaType } from '@/Domain/enum';
import { RuleTieBreak } from '@/Domain/Entities';
import ProcessesService from '@/Services/ProcessesService';
import { mapState } from 'vuex';
import ExamsEdit from '@/Views/Rating/ExamsEdit.vue';
import ScalingEdit from '@/Views/Rating/ScalingEdit.vue';
import RankingEdit from '@/Views/Rating/RankingEdit.vue';
import { SecurityService } from '@/Application/Services/SecurityService';

@Component({
    components: { ExamsEdit, ScalingEdit, RankingEdit },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('featureFlagsStore', ['undoCalificationPhase']),
        canUndoEvaluationPhase() {                                                                                                                                                                            
            return (this as RatingsEdit).undoCalificationPhase && (this as RatingsEdit).isAdminRole && (this as RatingsEdit).processData.phase < ProcessPhases.enum.RANKING;
        },
        canUndoRankingPhase() {                                                                                                                                                                    
            return !(this as RatingsEdit).loading && !(this as RatingsEdit).processData.needTieBreak && (this as RatingsEdit).tabActive === RatingsTabs.enum.LISTS && (this as RatingsEdit).processData.phase === ProcessPhases.enum.RANKING;
        },
        canUndoFinish() {
            return !(this as RatingsEdit).loading && (this as RatingsEdit).tabActive === RatingsTabs.enum.LISTS && (this as RatingsEdit).processData.phase === ProcessPhases.enum.FINISH;
        },
        canFinish() {
            return !(this as RatingsEdit).loading && !(this as RatingsEdit).processData.needTieBreak && (this as RatingsEdit).tabActive === RatingsTabs.enum.LISTS && (this as RatingsEdit).processData.phase === ProcessPhases.enum.RANKING;
        },
        showTieBreak() {
            return !(this as RatingsEdit).loading && (this as RatingsEdit).processData.needTieBreak && (this as RatingsEdit).tabActive === RatingsTabs.enum.LISTS && (this as RatingsEdit).processData.phase === ProcessPhases.enum.RANKING;
        },
        showCreateRuleTieBreakButton() {
            return !(this as RatingsEdit).ruleTieBreakToManage 
                || ((this as RatingsEdit).ruleTieBreakToManage && !(this as RatingsEdit).ruleTieBreakToManage.ruleId 
                && (this as RatingsEdit).ruleTieBreakToManage.ruleId !== RuleCriteriaType.FIRSTSURNAME);
        },
        showManualTieBreakButton() {
            return !(this as RatingsEdit).ruleTieBreakToManage || !(this as RatingsEdit).ruleTieBreakToManage.ruleId;
        },
        isExamsTabVissible() {
            return this.$data.processData.oppositionType === OppositionType.enum.TENDER && this.$data.processHasRequirementWithTest || this.$data.processData.oppositionType !== OppositionType.enum.TENDER && this.$data.processData.oppositionType !== OppositionType.enum.FREE_DESIGNATION;
        },
        isMeritsTabVissible() {
            return this.$data.processData.oppositionType === OppositionType.enum.TENDER || this.$data.processData.oppositionType === OppositionType.enum.OPPOSITION;
        },
        isListsTabDisabled() {
            return this.$data.processData.oppositionType !== OppositionType.enum.FREE_DESIGNATION && this.$data.isRankBlocked;
        }
    },
    watch: {
        '$route.params.id': {
            handler(oldId, newId) {
                if (newId !== oldId) {
                    (this as RatingsEdit).loadRouterParams();
                    (this as RatingsEdit).getDataProcess();
                    (this as RatingsEdit).getIsRankBlocked();
                }
            },
            deep: true
        }
    }
})
export default class RatingsEdit extends Vue {
    showCreateRuleTieBreakButton!: boolean;
    showManualTieBreakButton!: boolean;
    undoCalificationPhase!: boolean;
    isListsTabDisabled!: boolean;

    inscriptionsTotals: number = 0;
    inscriptionsFiltered: number = 0;
    processData: any = '';
    showModalEvaluationPhase: boolean = false;
    showModalRankingPhase: boolean = false;
    showModalFinish: boolean = false;
    showModalUndoFinish: boolean = false;
    undoEvaluationLoading: boolean = false;
    showModalFinishWithEmptyVacancies: boolean = false;
    nonActiveTab: number = -1;
    tabActive: number = this.nonActiveTab;
    fromPage: string = '';
    isAdminRole: boolean = false;
    isRankBlocked: boolean = true;
    mgr = new SecurityService();
    ruleTieBreakToManage: any = null;
    loading: boolean = true;
    isManualTieBreak: boolean = false;
    processHasRequirementWithTest: boolean = false;
    
    OppositionType = OppositionType;
    RatingsTabs = RatingsTabs;
    ProcessPhases = ProcessPhases;
    RuleCriteriaType = RuleCriteriaType;

    $refs!: {
        rankingEdit: RankingEdit
    };
    
    getIsSomeTurnWithTiesPending() {
        this.$refs.rankingEdit.getIsSomeTurnWithTiesPending();
    }

    getIsDisabledButtonValidate() {
        this.$refs.rankingEdit.getIsDisabledButtonValidate();
    }

    showSlideOver() {
        this.$refs.rankingEdit.showSlideOver();
    }

    setCriteriaManual() {
        this.ruleTieBreakToManage = new RuleTieBreak({
            name: 'Manual Criteria',
            ruleTieBreakCriteriaGroups: [{
                position: 0,
                ruleTieBreakCriterias: [{
                    ruleType: RuleCriteriaType.MANUAL,
                    ruleDescription: ''
                }]
            }]
        });
        ProcessesService.createRuleTieBreak(this.processData.id, this.ruleTieBreakToManage.toServer()).catch(error => console.log(error));
    }

    manageManualTieBreakResolution() {
        this.isManualTieBreak = true;
        this.$refs.rankingEdit.isManualTieBreak = this.isManualTieBreak;
        this.$refs.rankingEdit.calcTies();
        this.setCriteriaManual();
    }

    cancelManageManualTieBreakResolution() {
        this.isManualTieBreak = false;
        this.$refs.rankingEdit.isManualTieBreak = this.isManualTieBreak;
        ProcessesService.removeRuleTieBreak(this.processData.id).then(() => {
            this.ruleTieBreakToManage = null;
            this.$refs.rankingEdit.ruleTieBreakToManage = null;
            this.$refs.rankingEdit.ruleTieBreakToManageCopy = null;
            this.$refs.rankingEdit.initList();
        }).catch(error => console.log(error));
    }

    showModalDeleteRule() {
        this.$refs.rankingEdit.showModalDeleteRule();
    }
    
    validateTieBreaks() {
        this.$refs.rankingEdit.validateTieBreaks();
    }

    updateRuleTieBreakToManage(ruleTieBreakToManage: any) {
        this.ruleTieBreakToManage = ruleTieBreakToManage;
        this.isManualTieBreak =  this.ruleTieBreakToManage && this.ruleTieBreakToManage.name === 'Manual Criteria';
    }
    
    getIsRankBlocked() {
        ProcessesService.isRankBlocked(this.$route.params.id).then(
            reponse => {
                this.isRankBlocked = reponse;
                this.onComponentMountSetTabActive();
            }
        ).catch(error => console.log(error));
    }

    setTabActive(currentTab: number, nameRoute: string) {
        if (this.tabActive === currentTab || (currentTab === RatingsTabs.enum.LISTS && this.isListsTabDisabled)) {
            return;
        }
        this.loading = true;
        this.tabActive = currentTab;
        this.$router.push({name: nameRoute, params: { tabActive: this.tabActive.toString() }});
    }

    setInscriptionProperties(inscriptionsTotal: number, inscriptionsFiltered: number) {
        this.inscriptionsTotals = inscriptionsTotal;
        this.inscriptionsFiltered = inscriptionsFiltered;
    }

    getDataProcess() {
        ProcessesService.getDataProcess(this.$route.params.id).then((response: any) => {
            this.processData = response;
        }).catch(error => console.log(error));
    }

    goToUndoEvaluationPhase() {
        if (!this.undoCalificationPhase) {
           return;
        }
        this.showModalEvaluationPhase = false;
        this.undoEvaluationLoading = true;
        ProcessesService.undoEvaluationPhase(this.$route.params.id).then(response => {
            this.undoEvaluationLoading = false;
            this.$router.push({
                name: 'RatingsList'
            });
        });
    }

    showModalUndoEvaluationPhase() {
        this.showModalEvaluationPhase = true;
    }

    showModalUndoRankingPhase() {
        this.showModalRankingPhase = true;
    }

    showModalFinishPhase() {
        if (this.$refs.rankingEdit.isAnyVacancieOnTurns) {
            this.showModalFinishWithEmptyVacancies = true;
            return;
        }
        this.showModalFinish = true;
    }

    showModalUndoFinishPhase() {
        this.showModalUndoFinish = true;
    }

    processToUndo() {
        this.showModalRankingPhase = false;
        this.$refs.rankingEdit.processToUndo();
    }

    setPropertiesProcessData(processData: any) {
        this.$set(this.processData, 'hasReplacementListCreated', processData.hasReplacementListCreated);
        this.$set(this.processData, 'needTieBreak', processData.needTieBreak);
        this.$set(this.processData, 'phase', processData.phase);
    }

    loadingData() {
        this.loading = false;
    }

    processToFinish() {
        this.showModalFinish = false;
        this.$refs.rankingEdit.processToFinish();
    }

    processToUndoFinish() {
        this.showModalUndoFinish = false;
        this.$refs.rankingEdit.processToUndoFinish();
    }
    
    getUserRole() {
        this.mgr.getRole().then(role => {
            this.isAdminRole = role  === 'Admin';
        }).catch(err => {
            console.log(err);
        });
    }

    onComponentMountSetTabActive() {
        const routeNames = {
            0: 'ExamsEdit',
            1: 'ScalingEdit',
            2: 'RankingEdit'
        };
        
        const tab = this.isValidTabValue() ? Number(this.$route.params.tabActive) : null;
        if (tab && tab !== RatingsTabs.enum.LISTS || tab && tab === RatingsTabs.enum.LISTS && !this.isListsTabDisabled) {
           this.setTabActive(tab, routeNames[tab]);
           return;
        }

        if (this.processData.oppositionType === OppositionType.enum.TENDER && !this.processHasRequirementWithTest) {
            this.setTabActive(RatingsTabs.enum.MERTIS, routeNames[RatingsTabs.enum.MERTIS]);
            return;
        }

        this.setTabActive(RatingsTabs.enum.EXAMS, routeNames[RatingsTabs.enum.EXAMS]);
    }

    loadRouterParams() {
        this.fromPage = this.$route.params.fromPage;
        this.processHasRequirementWithTest = Boolean(this.$route.params.hasRequirementWithTest);
    }

    isValidTabValue() {
        return !isNaN(Number(this.$route.params.tabActive)) && Number(this.$route.params.tabActive) !== this.nonActiveTab;
    }

    mounted() {
        this.loadRouterParams();
        this.getDataProcess();
        this.getIsRankBlocked();
        this.getUserRole();
    }
}
