import Vue from 'vue';
import Vuex from 'vuex';
import { FeatureFlagsStore } from './modules/FeatureFlagsStore';
import { LanguagesStore } from './modules/LanguagesStore';
import { DateFormatStore } from './modules/DateFormatStore';
import { ProcessConfigStore } from './modules/ProcessConfigStore';
import { CandidateStore } from './modules/CandidateStore';
import { ListReplacementListStore } from './modules/ListReplacementListStore';
import { ProcessListStore } from './modules/ProcessListStore';
import { ReplacementListStore } from './modules/ReplacementListStore';
import { BoepListStore } from './modules/BoepListStore';
import { RatingsListStore } from './modules/RatingsListStore';
import { ListManagementListStore } from './modules/ListManagementListStore';
import { CourtListStore } from './modules/CourtListStore';
// import { UserStore } from './modules/UserStore';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    featureFlagsStore: FeatureFlagsStore,
    languagesStore: LanguagesStore,
    dateFormatStore: DateFormatStore,
    processConfigStore: ProcessConfigStore,
    candidateStore: CandidateStore,
    listReplacementListStore: ListReplacementListStore,
    processListStore: ProcessListStore,
    replacementListStore: ReplacementListStore,
    boepListStore: BoepListStore,
    ratingsListStore: RatingsListStore,
    listManagementListStore: ListManagementListStore,
    courtListStore: CourtListStore
    // userStore: UserStore
  },
  strict: true
});
