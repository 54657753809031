import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Tooltip extends Vue {
  @Prop() title!: string;
  @Prop() icon?: string;
  @Prop() subtitle?: string;
  @Prop() positionClass?: string;
  @Prop({default: 'tooltip__no-arrow'}) arrowPosition!: string;
}
