// tslint:disable-next-line: no-namespace
export namespace ToasterService {
    export const notificationList: Array<{ text: string, title: string, type: string, url: string, urlText: string }> = [];

    export function showSuccess(text: string, title?: string, url?: string, urlText?: string) {
        ToasterService.addNotification({ text, title: title || '', type: 'success', url: url || '', urlText: urlText || '' });
    }
    export function showError(text: string, title?: string, url?: string, urlText?: string) {
        ToasterService.addNotification({ text, title: title || '', type: 'error', url: url || '', urlText: urlText || '' });
    }
    export function showWarning(text: string, title?: string, url?: string, urlText?: string) {
        ToasterService.addNotification({ text, title: title || '', type: 'warning', url: url || '', urlText: urlText || '' });
    }
    export function showInfo(text: string, title?: string, url?: string, urlText?: string) {
        ToasterService.addNotification({ text, title: title || '', type: 'info', url: url || '', urlText: urlText || '' });
    }

    export function addNotification(data: { text: string, title: string, type: string, url: string, urlText: string }) {
        ToasterService.notificationList.splice(0, ToasterService.notificationList.length);
        setTimeout(() => {
            ToasterService.notificationList.push(data);
            setTimeout(() => {
                const index = ToasterService.notificationList.indexOf(data);
                if (index !== -1) {
                    ToasterService.notificationList.splice(index, 1);
                }
            }, 5000);
        }, 0);
    }

    export function removeNotification(data: { text: string, title: string, type: string, url: string, urlText: string }) {
        const index = ToasterService.notificationList.indexOf(data);
        ToasterService.notificationList.splice(index, 1);
    }
}
