import { Component, Prop, Vue } from 'vue-property-decorator';
import { Exam, MeritGroup, RuleTieBreak } from '@/Domain/Entities';
import * as sdPanel from '../../../node_modules/saviafront/lib/js/compiled/sd-panel';
import { TieBreakCriteria } from '@/Components';

@Component({
    components: { TieBreakCriteria },
    computed: {
        ruleTieBreakCriteriaGroupCreationIsDisabled() {
            return (this as SdRule).rule.anyRuleTieBreakCriteriaIsEmpty() || (this as SdRule).rule.allTypeSelectIsUsed();
        }
    }
})
export default class SdRule extends Vue {
    @Prop() rule!: RuleTieBreak;
    @Prop() exams!: Exam[];
    @Prop() meritsGroups!: MeritGroup[];
    submitted: boolean = false;

    initializeSdPanel() {
        this.$nextTick(() => {
            sdPanel.initialize();
        });
    }

    mounted() {
        this.initializeSdPanel();
        this.rule.orderCriteriaGroups();
    }

    created() {
        this.rule.meritGroups = this.meritsGroups;
        this.rule.exams = this.exams;
    }
}
