import { Component, Prop, Vue } from 'vue-property-decorator';
import { StatusDocuments, MeritRatingType } from '@/Domain/enum';
import DocumentService from '@/Application/Services/DocumentService';
import { mapState } from 'vuex';

@Component({
    computed: {
        file: {
            get() {
                this.$data.activeZoom = false;
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
})
export default class FileViewer extends Vue {
    @Prop() list!: any[];
    @Prop() value: any;
    @Prop() status!: boolean;
    @Prop() editStatus!: any;
    /*
    * Value to manage if pdf can be expanded
    */
    @Prop({ default: false }) isExpandable!: boolean;

    meritRatingType = MeritRatingType;
    statusDocuments = StatusDocuments;
    host = process.env.VUE_APP_ROOT_API;
    origin = document.location.origin;
    activeZoom = false;
    isFileExpanded = false;
    expandedFile!: any;

    $refs!: {
        panel: HTMLElement
    };

    next() {
        const index = this.list.indexOf(this.value);
        if (index < this.list.length - 1) {
            this.$emit('showInFileViewer', this.list[index + 1]);
        } else if (index === this.list.length - 1) {
            this.$emit('showInFileViewer', this.list[0]);
        }
        this.activeZoom = false;
    }

    download(file) {
        DocumentService.download(file);
    }

    previous() {
        const index = this.list.indexOf(this.value);
        const lastIndex = this.list.length - 1;

        if (index === 0) {
            this.$emit('showInFileViewer', this.list[lastIndex]);
        } else if (index > 0) {
            this.$emit('showInFileViewer', this.list[index - 1]);
        }
        this.activeZoom = false;
    }

    showFile(file) {
        this.$emit('showInFileViewer', file);
    }

    imageZoom() {
        this.$nextTick(() => {
            this.activeZoom = !this.activeZoom;
        });
    }

    expandFile() {
        this.isFileExpanded = true;
        this.expandedFile = (this as any).file;
    }

    closeExpandedFile() {
        this.expandedFile = null;
        this.isFileExpanded = false;
    }

    setStatusSelected(status: number) {
        (this as any).file.status = status;
        this.$emit('setStatusSelectedDocMerit', (this as any).file);
    }

    copyFileOtherMerit() {
        this.$emit('copyFileOtherMerit');
    }
}
