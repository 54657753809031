import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
    @Prop() title!: string;
    @Prop() confirmationText!: string;
    @Prop() value!: boolean;
    @Prop() disabledAccept?: boolean;
    @Prop() hideButtons?: boolean;
    @Prop() hideHeader?: boolean;
    @Prop() showIconSearch?: boolean;

    close($event: any) {
        this.$emit('input', false);
        this.$emit('close', $event);
    }
}
