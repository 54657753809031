import { Component, Prop, Vue } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import { mapState } from 'vuex';
import moment from 'moment';
import i18n from '../../lang/index';

@Component({
    components: { DatePicker },
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                if (!value) {
                    this.$emit('input', null);
                    return;
                }
                const date = moment.utc(value.getDate() + '/' + (value.getMonth() + 1) + '/' + value.getFullYear(), 'DD/MM/YYYY');
                this.$emit('input', date.isValid() ? date : null);
            }
        },
        $error() {
            return this.$props.required && this.$data.dirty && !this.$props.value;
        },
        datepickerFormat() {
            const l = 'L';
            return (moment().locale(this.$i18n.locale).localeData() as any)._longDateFormat[l];
        },
        ...mapState('languagesStore', { currentLanguage: 'current' })
    }
})
export default class MdDatepicker extends Vue {
    @Prop() value: any;
    @Prop() label?: string;
    @Prop() required?: string;
    @Prop() minDate?: moment.Moment | null;
    @Prop() maxDate?: moment.Moment | null;
    @Prop() disabled?: boolean;
    @Prop() isInvalidDate?: boolean;
    @Prop() messageErrorRetirementAge?: boolean;
    @Prop() messageErrorUnderSixteen?: boolean;

    dirty: boolean = false;
    focused: boolean = false;
    momentFormat: any = {
        // Date to String
        stringify(value) {
            return value && i18n.locale ? moment(value).format('L') : '';
        },
        // String to Date
        parse(value) {
            return value ? moment(value, 'L').toDate() : null;
        }
    };

    $refs!: {
        datePicker;
    };

    onBlur(actualPannel) {
        if (actualPannel === 'NONE') {
            this.focused = false;
            this.dirty = true;
        }
    }
}
