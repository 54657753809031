import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/processes';

export default class MeritSubGroupService {
    public static async editMeritSubGroup(processId: string, meritSubGroup: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/MeritGroups/' + meritSubGroup.meritGroupId + '/meritSubGroups/' + meritSubGroup.id, meritSubGroup, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addMeritSubGroup(processId: string, meritsubGroup: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(processId + '/MeritGroups/' + meritsubGroup.meritGroupId + '/meritSubGroups', meritsubGroup, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeMeritSubGroup(processId: string, meritSubGroup: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(processId + '/MeritGroups/' + meritSubGroup.meritGroupId + '/meritSubGroups/' + meritSubGroup.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async setMeritsOrder(processId: string, meritsOrder: string[], merit: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/MeritGroups/' + merit.meritGroupId + '/meritSubGroups/' + merit.meritSubGroupId + '/merits', meritsOrder, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

}
