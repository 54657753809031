import { Component, Prop, Vue } from 'vue-property-decorator';
import { Languages } from '@/Domain/enum';
import { mapGetters } from 'vuex';

@Component({
    name: 'MultiLanguageTabs',
    computed: {
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
})

export default class MultiLanguageTabs extends Vue {
    @Prop() value!: [];
    @Prop() requireds!: [];
    @Prop() optionals!: [];
    @Prop() submitted!: boolean;
    @Prop() nameComponent!: string;
    availableLanguages!: string[];
    Languages = Languages;

    haveErrorReq(lang: string) {
        return this.$props.requireds.filter(req => req.valueIsNullOrEmpty(lang)).length > 0;
    }
    haveErrorOpt(lang: string) {
        return this.$props.optionals.filter(opt => opt.anyHasValue(this.availableLanguages) && opt.valueIsNullOrEmpty(lang)).length > 0;
    }

}
