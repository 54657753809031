import moment from 'moment';
import { YesNo, InscriptionRegistry, InscriptionTurn } from '../../enum';
import { Inscription } from '@/Domain/Entities';
import _ from 'lodash';
import i18n from '../../../lang';

export default class ProcessInscription extends Inscription {
    private _name: string;
    private _firstSurname: string;
    private _secondSurname: string;
    private _age: number;
    private _nationality: string;
    private _authorizes: any;
    private _turn: number;
    private _status: number;
    private _totalRequisites: number;
    private _requisitesChecked: number;
    private _inscriptionDate: moment.Moment;
    private _nationalityCode: number;
    private _autoScore: number;
    private _oficialScore: number;
    private _scaleStatus: number;
    private _oppositionType: number;
    private _fullNameToView: string;
    private _passed: boolean;
    private _readmitanceDate!: moment.Moment | null;
    private _readmitanceReason: string;
    private _readmitanceUser: any;

    constructor(data: any, nationalities: any[]) {
        super(data);
        this._name = data.name;
        this._firstSurname = data.firstSurname;
        this._secondSurname = data.secondSurname;
        this._age = data.age;
        this._nationality = nationalities.length ? nationalities.find(element => element.key === data.nationalityCode).value : '';
        this._nationalityCode = data.nationalityCode;
        this._authorizes = data.authorizes === null ? data.authorizes : YesNo.translations[this.isAuthorizesNumber(data.authorizes)];
        this._turn = data.turn;
        this._registry = InscriptionRegistry.translations[data.registry];
        this._status = data.status;
        this._totalRequisites = data.totalEvaluableConditions;
        this._requisitesChecked = data.checkedEvaluableConditions;
        this._inscriptionDate = moment.utc(data.inscriptionDate);
        this._autoScore = data.autoScore;
        this._oficialScore = data.oficialScore;
        this._scaleStatus = data.scaleStatus;
        this._oppositionType = data.oppositionType;
        this._fullNameToView = this.setFullName();
        this._passed = data.passed;
        this._readmitanceDate = moment.utc(data.readmitanceDate);
        this._readmitanceReason = data.readmitanceReason ? data.readmitanceReason : '';
        this._readmitanceUser = data.readmitanceUser;
    }

    public get name() {
        return this._name;
    }
    public get firstSurname() {
        return this._firstSurname;
    }
    public get secondSurname() {
        return this._secondSurname;
    }
    public get age() {
        return this._age;
    }
    public get nationality() {
        return this._nationality;
    }
    public get authorizes() {
        return this._authorizes;
    }
    public get turn() {
        return this._turn;
    }
    public get status() {
        return this._status;
    }
    public get totalRequisites() {
        return this._totalRequisites;
    }
    public get requisitesChecked() {
        return this._requisitesChecked;
    }
    public get autoScore() {
        return this._autoScore;
    }
    public get oficialScore() {
        return this._oficialScore;
    }
    public set oficialScore(oficialScore: number) {
        this._oficialScore = oficialScore;
    }

    public get nameSurname() {
        return _.deburr(this._name + ' ' + this._firstSurname + ' ' + this._secondSurname).toLowerCase();
    }

    public get surnameName() {
        return _.deburr(this._firstSurname + ' ' + this._secondSurname + ', ' + this._name).toLowerCase();
    }

    public get turnTranslate() {
        return i18n.t(InscriptionTurn.translations[this.turn]);
    }

    public get inscriptionDate() {
        return this._inscriptionDate;
    }

    public get nationalityCode() {
        return this._nationalityCode;
    }

    public set nationalityCode(nationalityCode: number) {
        this._nationalityCode = nationalityCode;
    }

    public get scaleStatus() {
        return this._scaleStatus;
    }

    public set scaleStatus(scaleStatus: number) {
        this._scaleStatus = scaleStatus;
    }

    public get oppositionType() {
        return this._oppositionType;
    }

    public get fullNameToView() {
        return this._fullNameToView;
    }

    public get passed() {
        return this._passed;
    }

    public get readmitanceDate() {
        return this._readmitanceDate;
    }

    public set readmitanceDate(readmitanceDate: moment.Moment | null) { 
        this._readmitanceDate = readmitanceDate;
    }

    public get readmitanceReason() {
        return this._readmitanceReason;
    }

    public set readmitanceReason(readmitanceReason: string) { 
        this._readmitanceReason = readmitanceReason;
    }

    public get readmitanceUser() {
        return this._readmitanceUser;
    }

    public set readmitanceUser(readmitanceUser: any) { 
        this._readmitanceUser = readmitanceUser;
    }

    public isAuthorizesNumber(authorizes) {
        return authorizes ? 1 : 0;
    }

    public setFullName() {
        const surnames = this.firstSurname + ' ' + this.secondSurname;
        return surnames.trim() + ', ' + this.name;
    }

}
