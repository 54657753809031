import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/EntityOep';

export default class EntityService {
    public static async getEntityList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getEntityAndLegalTextList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('/legalTexts', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async editEntityAndLegalTextList(legalText: object): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put('/legalTexts', legalText, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
