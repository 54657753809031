import { Exam, Requirement, MeritGroup, PayFeeAmount, MultiLanguage, ProcessConfigPayExemption } from '@/Domain/Entities';
import { InscriptionTurn } from '@/Domain/enum';

export default class InscriptionData {
    private _requirements: Requirement[];
    private _meritsGroup: MeritGroup[];
    private _turnTypes: any;
    private _payExemptionTypes: any;
    private _hasAuthorization: boolean;
    private _hasFeePayment: boolean;
    private _entityName: string;
    private _creditableExams: Exam[];
    private _payFeeAmount: PayFeeAmount | null;
    private _refuseText: MultiLanguage | null;
    private _hasMultiLanguageExams: boolean;
    
    public get requirements() {
        return this._requirements;
    }
    public set requirements(requirements: Requirement[]) {
        this._requirements = requirements;
    }

    public get meritsGroup() {
        return this._meritsGroup;
    }
    public set meritsGroup(meritsGroup: MeritGroup[]) {
        this._meritsGroup = meritsGroup;
    }

    public get turnTypes() {
        return this._turnTypes;
    }
    public set turnTypes(turnTypes: any) {
        this._turnTypes = turnTypes;
    }

    public get payExemptionTypes() {
        return this._payExemptionTypes;
    }
    public set payExemptionTypes(payExemptionTypes: any) {
        this._payExemptionTypes = payExemptionTypes;
    }

    public get hasAuthorization() {
        return this._hasAuthorization;
    }
    public set hasAuthorization(hasAuthorization: boolean) {
        this._hasAuthorization = hasAuthorization;
    }

    public get hasFeePayment() {
        return this._hasFeePayment;
    }
    public set hasFeePayment(hasFeePayment: boolean) {
        this._hasFeePayment = hasFeePayment;
    }

    public get entityName() {
        return this._entityName;
    }
    public set entityName(entityName: string) {
        this._entityName = entityName;
    }
    public get creditableExams() {
        return this._creditableExams;
    }
    public set creditableExams(creditableExams: Exam[]) {
        this._creditableExams = creditableExams;
    }

    public get refuseText() {
        return this._refuseText;
    }
    public set refuseText(refuseText: MultiLanguage | null) {
        this._refuseText = refuseText;
    }

    public get hasMultiLanguageExams() {
        return this._hasMultiLanguageExams;
    }
    public set hasMultiLanguageExams(value: boolean) {
        this._hasMultiLanguageExams = value;
    }

    public get payFeeAmount() {
        return this._payFeeAmount;
    }
    public set payFeeAmount(value: PayFeeAmount | null) {
        this._payFeeAmount = value;
    }

    constructor(data: any, processConfigurationPayExemptions: ProcessConfigPayExemption[]) {
        const AuthorizationsType: number = 0;
        const RequirementType: number = 2;
        const MeritGroupType: number = 3;
        const PayExemptionType: number = 4;
        const CreditableExamType: number = 5;
        const authorizations = data.structure.find(group => group.type === AuthorizationsType);
        const requirements = data.structure.find(group => group.type === RequirementType);
        const meritsGroups = data.structure.find(group => group.type === MeritGroupType);
        const creditableExams = data.structure.find(group => group.type === CreditableExamType);
        const payExemptions = data.structure.find(group => group.type === PayExemptionType);
        if (requirements) {
            this._requirements = requirements.steps.map(requirement => new Requirement(requirement));
        } else {
            this._requirements = [];
        }
        if (meritsGroups) {
            this._meritsGroup = meritsGroups.steps.map(meritGroup => new MeritGroup(meritGroup));
        } else {
            this._meritsGroup = [];
        }
        if (creditableExams) {
            this._creditableExams = creditableExams.steps[0].creditableExams.map(creditableExam => new Exam(creditableExam));
        } else {
            this._creditableExams = [];
        }
        if (payExemptions) {
            this._payFeeAmount = new PayFeeAmount(payExemptions.steps.find(step => step.type === 8));
        } else {
            this._payFeeAmount = null;
        }
        this._turnTypes = data.turnTypes.map(turn => ({ id: turn, name: InscriptionTurn.translations[turn] }));
        this._hasFeePayment = data.hasFeePayment;
        if (data.hasFeePayment && data.payExemptionTypes) {
            this._payExemptionTypes = processConfigurationPayExemptions.filter(processConfigurationPayExemption => data.payExemptionTypes.includes(processConfigurationPayExemption.type) )
            .map(payExemption => {
                return {
                    id: payExemption.type,
                    name: payExemption.title
                };
            });
        }
        this._hasAuthorization = data.hasAuthorization;
        this._refuseText = this._hasAuthorization ? new MultiLanguage(authorizations.steps[3].refuseText) : null;
        this._entityName = data.entityName;
        this._hasMultiLanguageExams = authorizations.steps[2].hasMultiLanguageExams;
    }

    public findMeritGroupInStructure(meritGroupId) {
        return this.meritsGroup.find(meritGroupStructure => meritGroupStructure.id === meritGroupId);
    }
}
