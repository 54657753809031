import moment from 'moment';
import { StatusReplacement } from '@/Domain/enum';
import ReplacementDocument from './ReplacementDocument';

export default class ReplacementState {
    private _id: string;
    private _status: number | null;
    private _reason: number | null;
    private _date: moment.Moment;
    private _documents: ReplacementDocument[];
    private _isEditing: boolean;

    public constructor(data: any) {
        this._id = data.id;
        this._status = data.status >= 0 ? data.status : null;
        this._reason = data.reason >= 0 ? Number(data.reason) : null;
        this._date = data.date ? moment.utc(data.date).local() : moment.utc();
        this._isEditing = false;
        this._documents = data.documents ? data.documents.map(doc => new ReplacementDocument(doc)) : [];
    }

    public get id() {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get status() {
        return this._status;
    }

    public set status(status: number | null) {
        this._status = status;
    }

    public get reason() {
        return this._reason;
    }

    public set reason(reason: number | null) {
        this._reason = reason;
    }

    public get date() {
        return this._date;
    }
    public set date(date: moment.Moment) {
        this._date = date;
    }

    public get isEditing() {
        return this._isEditing;
    }

    public set isEditing(isEditing: boolean ) {
        this._isEditing = isEditing;
    }

    public get documents() {
        return this._documents;
    }

    public addDocument(document: any): void {
        document.loading = true;
        this._documents.push(
            new ReplacementDocument(document)
        );
    }

    public infoToServer() {
        return {
            id: this.id,
            status: Number(this.status),
            reason: this.manageReason()
        };
    }

    private manageReason() {
        return Number(this.status) !== StatusReplacement.enum.EXCLUDED && Number(this.status) !== StatusReplacement.enum.NOTAVAILABLE ? null : this.reason;
    }
}
