import moment from 'moment';

export default class OnlinePaymentInfo {
    private _paymentOrder: string;
    private _amount: number;
    private _notificationLocalDate: string;
    
        constructor(data: any) {
            this._paymentOrder = data.paymentOrder;
            this._amount = data.amount;
            this._notificationLocalDate = data.notificationLocalDate;
        }

    public get paymentOrder() {
        return this._paymentOrder;
    }

    public set paymentOrder(paymentOrder: string) {
        this._paymentOrder = paymentOrder;
    }

    public get amount() {
        return this._amount;
    }

    public set amount(amount: number) {
        this._amount = amount;
    }

    public get notificationLocalDate() {
        return moment(this._notificationLocalDate).format('L') + ' ' + moment(this._notificationLocalDate).format('HH:mm');
    }

    public set notificationLocalDate(notificationLocalDate: string) {
        this._notificationLocalDate = notificationLocalDate;
    }

}
