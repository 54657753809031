import moment from 'moment';

export default class ReplacementListRelated {
    private _relatedListId: string;
    private _replacementListName: string;
    private _startDateContract: moment.Moment;
    private _endDateContract: moment.Moment | null;
    private _workingPercentage: number;

    public constructor(data: any) {
        this._relatedListId = data.relatedListId;
        this._replacementListName = data.replacementListName;
        this._startDateContract = moment.utc(data.startDateContract).local();
        this._endDateContract = data.endDateContract ? moment.utc(data.endDateContract).local() : null;
        this._workingPercentage = data.workingPercentage;
    }

    public get relatedListId() {
        return this._relatedListId;
    }

    public get replacementListName() {
        return this._replacementListName;
    }

    public get startDateContract() {
        return this._startDateContract;
    }

    public get endDateContract() {
        return this._endDateContract;
    }

    public get workingPercentage() {
        return this._workingPercentage;
    }
}
