import { Component, Prop, Vue } from 'vue-property-decorator';
import { FilterTypes } from '@/Domain/enum';
import { FilterElement, Filter } from '@/Domain/Entities';
import _ from 'lodash';

@Component
export default class SdFilter extends Vue {
    @Prop() value!: Filter;
    FilterTypes = FilterTypes;
    filterCopy: any = null;

    createCopyValue() {
        this.filterCopy = _.cloneDeep(this.$props.value);
    }

    setFilter(filter: FilterElement) {
        filter.isDropped = false;
        this.emitUpdate();
    }

    emitUpdate() {
        this.$emit('input', _.cloneDeep(this.filterCopy));
    }

    removeFilter(filter: FilterElement) {
        filter.reset();
        this.emitUpdate();
    }

    created() {
        this.createCopyValue();
    }
}
