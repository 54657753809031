import Enum from '../interfaces/IEnum';

export const OppositionType: Enum = {
    translations: {
        0: 'lang.shared.contest',
        1: 'lang.shared.oppositionContest',
        2: 'lang.shared.opposition',
        3: 'lang.shared.freeDesignation'
    },
    enum: {
        TENDER: 0,
        OPPOSITION: 1,
        NOMINATION: 2,
        FREE_DESIGNATION: 3
    }
};
