import { FilterElement, InputFilterElement } from '@/Domain/Entities';
import { FilterTypes } from '@/Domain/enum';

export const ListReplacementListStore = {
    namespaced: true,
    state: {
        filters: [
            new InputFilterElement({
                type: FilterTypes.INPUT,
                label: 'lang.listManagement.search',
                param: ['fullName', 'nif'],
                icon: 'fa-search'
            })
        ],
        seeInscriptionsExcludedOrResignation: false
    },
    mutations: {
        setFilters(state, filters: FilterElement[]) {
            state.filters = filters;
        },
        setSeeInscriptionsExcludedOrResignation(state, seeInscriptionsExcludedOrResignation: boolean) {
            state.seeInscriptionsExcludedOrResignation = seeInscriptionsExcludedOrResignation;
        }
    },
    getters: {
        getFilters(state) {
            return state.filters;
        },
        getSeeInscriptionsExcludedOrResignation(state) {
            return state.seeInscriptionsExcludedOrResignation;
        }
    }
};
