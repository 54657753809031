import { Component, Prop, Vue } from 'vue-property-decorator';
import { MultiLanguage } from '@/Domain/Entities';

/**
 * Text area component
 */
@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        $error() {
            return this.$props.required && this.$data.dirty && !this.$props.value;
        },
        length(): number {
            return this.$props.value ? this.$props.value.length : 0;
        }
    }
})
export default class MdTextarea extends Vue {
    @Prop() value: any;
    @Prop() label?: string;
    @Prop() required?: string;
    @Prop() max?: number;
    @Prop() disabled?: boolean;
    /** Implements the translate button */
    @Prop() hasAutoTranslate?: boolean;
    /** Multilanguage object to translate */
    @Prop() contentToTranslate?: MultiLanguage;
    dirty: boolean = false;
    focused: boolean = false;
    
    /**
     * @public
     * Fill the input with the translated content
     * @param {$event} - Translated content
     */
    translatedContent($event) {
        let content = $event;
        if (!this.isValidTranslatedContentLength($event)) {
            content = this.truncateTranslatedContent($event);
        }
        this.$emit('input', content);
    }

    isValidTranslatedContentLength(contentTranslated: string) {
        const hasMaxDefined = this.max !== undefined;
        return hasMaxDefined && contentTranslated.length <= Number(this.max);
    }

    truncateTranslatedContent(contentTranslated: string) {
        return contentTranslated.substring(0, this.max);
    }
}
