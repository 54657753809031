export default class Tab {
    private _type: number;
    private _active: boolean;

    constructor(data: number) {
        this._type = data;
        this._active = false;
    }

    public get type() {
        return this._type;
    }
    public set type(type: number) {
        this._type = type;
    }

    public get active() {
        return this._active;
    }
    public set active(active: boolean) {
        this._active = active;
    }
}
