import IProcessConfig from '@/Domain/interfaces/IProcessConfig';
import MultiLanguage from '../MultiLanguage';

export default abstract class ProcessConfig implements IProcessConfig {
   id: string;
   title: MultiLanguage;
   isEditable: boolean;
   type!: number;
   maxLength: number | undefined;
   filterMinimumValidType: number;

   public constructor(data: any) {
      this.id = data.id;
      this.title = new MultiLanguage(data.title);
      this.isEditable = data.isEditable;
      this.filterMinimumValidType = 0;
   }

   toServer() {
      return {};
   }
}
