import moment from 'moment';

export class CryptoSignature {
    private _authenticationDate: string;
    private _name: string;
    private _firstSurname: string;
    private _secondSurname: string;
    private _nif: string;
    private _identityProvider: string;
    private _certificateType: string;
    private _securityLevel: string;
    private _ip: string;
    private _sessionId: string;
    private _authenticationSystem: string;

    constructor(data) {
        this._authenticationDate = moment.utc(data.authenticationDate).local().format('LLL');
        this._name = data.name;
        this._firstSurname = data.firstSurname;
        this._secondSurname = data.secondSurname;
        this._nif = data.nif;
        this._identityProvider = data.identityProvider;
        this._certificateType = data.certificateType;
        this._securityLevel = data.securityLevel;
        this._ip = data.ip;
        this._sessionId = data.sessionId;
        this._authenticationSystem = data.authenticationSystem;
    }

    get authenticationDate(): string {
        return this._authenticationDate;
    }

    get name(): string {
        return this._name;
    }

    get firstSurname(): string {
        return this._firstSurname;
    }

    get secondSurname(): string {
        return this._secondSurname;
    }

    get nif(): string {
        return this._nif;
    }

    set nif(value: string) {
        this._nif = value;
    }

    get identityProvider(): string {
        return this._identityProvider;
    }

    get certificateType(): string {
        return this._certificateType;
    }

    get securityLevel(): string {
        return this._securityLevel;
    }

    get ip(): string {
        return this._ip;
    }

    get sessionId(): string {
        return this._sessionId;
    }

    get authenticationSystem(): string {
        return this._authenticationSystem;
    }
}
