
import { Component, Vue } from 'vue-property-decorator';
import { Constants, CookiesType } from '@/Domain/enum';
import { mapState } from 'vuex';
import Layout from '../Layout';
import i18n from '../../lang';
import MasterDataService from '@/Services/MasterDataService';
import { ClientInfo } from '@/Domain/Entities';

@Component({
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        timeRequest2FAAgainInDays() {
            return ((this as CookiesPolicy).$parent as Layout).$data.security.timeRequest2FAAgainInDays > 1 ? ((this as CookiesPolicy).$parent as Layout).$data.security.timeRequest2FAAgainInDays + ' ' + i18n.t('lang.cookies.days') 
            : ((this as CookiesPolicy).$parent as Layout).$data.security.timeRequest2FAAgainInDays + ' ' + i18n.t('lang.cookies.day');
        }
    }
})

export default class CookiesPolicy extends Vue {
    currentLanguage!: string;
    constants = Constants;
    cookiesType = CookiesType;
    clientInfo = {};
    clientHost: string = '';
    twofaRememberedDeviceXXXX: string = '2faRememberedDeviceXXXX';

    getData() {
        MasterDataService.getClientInfo()
            .then(responseMasterData => { 
                this.clientInfo = new ClientInfo(responseMasterData);
                this.clientHost = (this.clientInfo as ClientInfo).getHost();
            })
            .catch(error => console.log(error));
    }

    mounted() {
        this.getData();
    }
}
