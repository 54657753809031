import { Component, Prop, Vue } from 'vue-property-decorator';
import { ToasterService } from '@/Services/ToasterService';

@Component
export default class Toaster extends Vue {
    @Prop() message!: any;

    close() {
        ToasterService.removeNotification(this.message);
    }
}
