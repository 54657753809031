export enum RuleCriteriaType {
    FIRSTSURNAME,
    SECONDSURNAME,
    NAME,
    EXAMS,
    MERITS,
    MERITGROUP,
    MERITSUBGROUP,
    GENDER,
    AGE,
    MANUAL
}

export enum RuleCriteriaTypeTranslations {
    FIRSTSURNAME = 'lang.tiebreak.byFirstSurname',
    SECONDSURNAME = 'lang.tiebreak.bySecondSurname',
    NAME = 'lang.tiebreak.byFirstName',
    EXAMS = 'lang.tiebreak.byExams',
    MERITS = 'lang.tiebreak.byMerits',
    MERITGROUP = 'lang.tiebreak.byMeritGroup',
    MERITSUBGROUP = 'lang.tiebreak.byMeritSubGroup',
    GENDER = 'lang.tiebreak.byGender',
    AGE = 'lang.tiebreak.byAge',
    MANUAL = 'lang.tiebreak.byManual'
}

export enum RuleCriteriaTypeDefinitions {
    FIRSTSURNAME = 'lang.tiebreak.firstSurnameStarts',
    SECONDSURNAME = 'lang.tiebreak.secondSurnameStarts',
    NAME = 'lang.tiebreak.nameStarts',
    EXAMS = 'lang.tiebreak.selectExam',
    MERITS = 'lang.tiebreak.selectMerit',
    MERITGROUP = 'lang.tiebreak.selectMeritGroup',
    MERITSUBGROUP = 'lang.tiebreak.selectMeritSubGroup',
    GENDER = 'lang.tiebreak.gender',
    AGE = 'lang.tiebreak.selectAge',
    MANUAL = 'lang.tiebreak.selectManual'
}
