import { Component } from 'vue-property-decorator';
import SdInputBase from '../SdInputBase';

@Component
export default class SdInputTimepicker extends SdInputBase  {
    focused: boolean = false;

    setFocusedOn() {
        this.focused = true;
    }

    setFocusedOff() {
        this.focused = false;
    }
}
