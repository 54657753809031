import FilterElement from './FilterElement';
import moment from 'moment';

export default class DatepickerFilterElement extends FilterElement {
    private _start: moment.Moment | null;
    private _end: moment.Moment | null;
    private startOfTime = moment('0001-01-01', 'YYYY-MM-DD');
    private endOfTime = moment(1e15);

    public get start() {
        return this._start;
    }
    public set start(start: moment.Moment | null) {
        this._start = start;
    }

    public get end() {
        return this._end;
    }
    public set end(end: moment.Moment | null) {
        this._end = end;
    }

    constructor(data: any) {
        super(data);
        this._start = data.start;
        this._end = data.end;
    }
    public reset() {
        this._start = null;
        this._end = null;
    }

    public get hasFilterValue(): boolean {
        return this._start !== null || this._end !== null;
    }

    public showElement(element: any): boolean {
        if (!element[this.param]) {
            return !this._start && !this._end; // filter no dirty
        }
        return element[this.param].isBetween(this._start || this.startOfTime, this._end || this.endOfTime, 'day', '[]');
    }
}
