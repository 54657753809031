import InscriptionRanked from './Inscription/InscriptionRanked';
import {TieTypes} from '../enum/TieTypes';

export default class TieBreak {
    private _isEditing: boolean;
    private _ties: InscriptionRanked[];
    private _firtsTieId: string;
    private _newOrder: any[];

    public constructor(data: any) {
        this._ties = data;
        this._firtsTieId = data ? data[0].id : null;
        this._isEditing = this._ties[0].tieType === TieTypes.enum.TIEPENDDING;
        this._newOrder = data;
    }

    public get isEditing() {
        return this._isEditing;
    }

    public set isEditing(isEditing: boolean ) {
        this._isEditing = isEditing;
    }

    public get ties() {
        return this._ties;
    }

    public set ties(ties: InscriptionRanked[] ) {
        this._ties = ties;
    }

    public get firtsTieId() {
        return this._firtsTieId;
    }

    public set firtsTieId(firtsTieId: string ) {
        this._firtsTieId = firtsTieId;
    }

    public get newOrder() {
        return this._newOrder;
    }

    public set newOrder(newOrder: any[] ) {
        this._newOrder = newOrder;
    }

}
