import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import i18n from '../../lang';
import { mapGetters } from 'vuex';
import { Languages } from '@/Domain/enum';
import { MultiLanguage } from '@/Domain/Entities';

/**
 * Text area component
 */
@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isRequired() {
            return this.$props.required && !this.$props.value && !this.$data.focused && (this.$data.dirty || this.$props.submitted);
        },
        length(): number {
            return this.$props.value ? this.$props.value.length : 0;
        },
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
    }
})
export default class SdTextarea extends Vue {
    /** Textarea value */
    @Prop() value: any;
    /** Textarea label */
    @Prop() label?: string;
    /** Textarea placeholder */
    @Prop() placeholder?: string;
    /** Indicate if required */
    @Prop() required?: string;
    /** Indicate if disabled */
    @Prop() disabled?: boolean;
    /** indicates whether the form has been submitted */
    @Prop() submitted?: boolean;
    /** Maximum number of characters */
    @Prop() max?: number;
    /** Indicates the tab active  */
    @Prop() tabActive?: string;
    /** Indicates if it is multiLanguage  */
    @Prop() multiLanguage?: boolean;
    /** Array of required texts  */
    @Prop() requireds?: [];
    /** Array of optional texts  */
    @Prop() optionals?: [];
    /** Implements the translate button */
    @Prop() hasAutoTranslate?: boolean;
    /** Multilanguage object to translate */
    @Prop() contentToTranslate?: MultiLanguage;
    /** Dirty value */
    dirty: boolean = false;
    /** Focused value */
    focused: boolean = false;
    /** Optional text */
    optionalText: TranslateResult | string = '';
    /** Availables languages */
    availableLanguages!: string[];
    /** Languages */
    Languages = Languages;

    $refs!: {
        input: HTMLInputElement
    };

    /**
     * @public
     * Returns in required language, if there is a value and it is null or empty 
     * @param {string} lang - Language
     */
    haveErrorReq(lang: string) {
        return this.$props.requireds.filter(req => req.valueIsNullOrEmpty(lang)).length > 0;
    }

    /**
     * @public
     * Returns in optional language, if there is a value and it is null or empty 
     * @param {string} lang - Language
     */
    haveErrorOpt(lang: string) {
        return this.$props.optionals.filter(opt => opt.anyHasValue(this.availableLanguages) && opt.valueIsNullOrEmpty(lang)).length > 0;
    }

    /**
     * @public
     * Issues the language in which the active tab has to be set 
     * @param {string} language - Language
     */
    setTabActive(language: string) {
        this.$emit('setTabActive', language);
    }
    
    /**
     * @public
     * Emit the blur event when is dirty and there is no focus 
     * @param {Event} - Event
     */
    onBlur($event: Event) {
        this.dirty = true;
        this.focused = false;
        this.$emit('blur', $event);
    }

    /**
     * @public
     * Emit the change event on change
     * @param {Event} - Event
     */
    onChange($event: Event) {
        this.$emit('change', $event);
    }

    /**
     * @public
     * Emit the focus event on focused
     * @param {Event} - Event
     */
    onFocus($event: Event) {
        this.focused = true;
        this.$emit('focus', $event);
    }

    /**
     * @public
     * Fill the input with the translated content
     * @param {$event} - Translated content
     */
    translatedContent($event: string) {
        let content = $event;
        if (!this.isValidTranslatedContentLength($event)) {
            content = this.truncateTranslatedContent($event);
        }
        this.$emit('input', content);
    }
    /**
     * @public
     * Validate the length of the translated content
     * if is necessary
     */    
    isValidTranslatedContentLength(contentTranslated: string) {
        const hasMaxDefined = this.max !== undefined;
        return hasMaxDefined && contentTranslated.length <= Number(this.max);
    }
    /**
     * @public
     * Returns the translated content truncated
     */  
    truncateTranslatedContent(contentTranslated: string) {
        return contentTranslated.substring(0, this.max);
    }
    
    /**
     * @public
     * Set if it is optional when mounting
     */
    mounted() {
        this.setOptionalText();
    }

    /**
     * @private
     * Set if optional
     */
    private setOptionalText(): void {
        this.optionalText = !this.$props.required && this.$props.label !== undefined ? i18n.t('lang.input.optional') : '';
    }
}
