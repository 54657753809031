import Enum from '../interfaces/IEnum';

export const PaymentMethod: Enum = {
    translations: {
        1: 'lang.shared.online',
        2: 'lang.shared.bank'
    },
    enum: {
        NONE: 0,
        ONLINE: 1,
        OFFLINE: 2
    }
};
