import Tab from './Tab';

export default class TabsList<T extends Tab> {
    private _list: T[];
    private _activeTab: T | null = null;

    constructor(tabType: (new (tab: any) => T), data: any[], activeTab?: number) {
        this._list = data.map(o => new tabType(o));
        if (this._list.length) {
            this._activeTab = activeTab ? this._list[activeTab] : this._list[0];
            this._activeTab.active = true;
        }
    }

    public get list() {
        return this._list;
    }

    public get activeTab() {
        return this._activeTab;
    }

    public selectTab(tab: T): void {
        this._list.forEach(element => {
            element.active = element.type === tab.type;
        });
        this._activeTab = tab;
    }
}
