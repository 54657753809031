import { Component, Vue } from 'vue-property-decorator';
import EntityService from '@/Services/EntityService';
import { ToasterService } from '@/Services/ToasterService';
import ValidationService from '@/Application/Services/ValidationService';
import i18n from '../../lang';
import { EntityOep, MultiLanguage } from '@/Domain/Entities';
import { MdTextarea, TextEditor } from '@/Components';
import { Languages, LegalTextTypes, Constants } from '@/Domain/enum';
import { mapState, mapGetters } from 'vuex';
import * as notification from '@/../node_modules/saviafront/lib/js/compiled/notification';

@Component({
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),

        requiredsAuthorizations() {
            if (this.$data.entityOepLegalTexts.length > 0) {
                const requireds: MultiLanguage[] = [];
                this.$data.entityOepLegalTexts.forEach(entity => {
                    requireds.push(entity.authorizationLegalText);
                    requireds.push(entity.refuseAuthorizationText);
                });
                return requireds;
            }
        },
        hasErrorTabTerms() {
            return  (this as LegalTexts).hasErrortab('legalConsentText', Constants.sharedLengths.THREE_THOUSAND);
        },
        hasErrorTabProtection() {
            return (this as LegalTexts).hasErrortab('dataProtectionText', Constants.sharedLengths.THREE_THOUSAND);
        },
        hasErrorTabAuthorizations() {
            return (this as LegalTexts).hasErrortab('authorizationLegalText', Constants.sharedLengths.ONE_THOUSAND) || (this as LegalTexts).hasErrortab('refuseAuthorizationText', Constants.sharedLengths.ONE_THOUSAND);
        }
    },
    components: {
        MdTextarea,
        TextEditor
    }
})

export default class LegalTexts extends Vue {
    availableLanguages!: string[];
    submitted = false;
    entityOepLegalTexts: EntityOep[] = [];
    loading: boolean = true;
    invalidForm: boolean = false;
    Languages = Languages;
    LegalTextTypes = LegalTextTypes;
    Constants = Constants;
    errorLegalText: any[] = [];
    hasNotification: boolean = false;
    tabActive: string = '';
    tabActiveEntity: any = null;

    $refs!: {
        legalTextsForm: HTMLFormElement,
        legalTextsBody: HTMLElement
    };

    hasErrortab(propertyToCheck: string, maxCharacter: number): boolean {
        let entityOEP;
        let hasError = false;
        (this as LegalTexts).availableLanguages.forEach(lang => {
            entityOEP = this.$data.entityOepLegalTexts.some(entity => {
                const result =  entity[propertyToCheck].valueIsNullOrEmpty(lang) || entity[propertyToCheck].maxCharacter(lang, maxCharacter);
                if (result) {
                    hasError = true;
                }
                return hasError;
            });
        });
        return entityOEP;
    }

    resetNotification() {
        this.hasNotification = false;
    }

    setActiveTabEntity(tabActive: number) {
        this.tabActiveEntity = tabActive;
        this.$refs.legalTextsBody.scrollTo(0, 0);
    }

    submit() {
        this.submitted = true;
        this.invalidForm = ValidationService.validateTextEditor(this.$refs.legalTextsForm);

        if (this.invalidForm) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.hasNotification = true;
            return;
        }

        const entities = this.entityOepLegalTexts.map(entity => {
            return entity.toServer();
        });

        EntityService.editEntityAndLegalTextList({entities})
        .then(() => {
            this.resetNotification();
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as string, i18n.t('lang.toaster.goodJob') as string);
        })
        .catch(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.hasNotification = true;
        });
    }

    getEntityAndLegalTextList() {
        EntityService.getEntityAndLegalTextList()
            .then((response: EntityOep[]) => {
                this.entityOepLegalTexts = response.map(entity => new EntityOep(entity));
                this.tabActiveEntity = LegalTextTypes.enum.TERMS;
                this.loading = false;
            }).catch(error => console.log(error));
    }

    mounted() {
        this.tabActive = this.$i18n.locale;
        this.getEntityAndLegalTextList();
        this.$nextTick(() => {
            notification.initialize();
        });
    }
}
