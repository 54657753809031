import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/';

export default class ClientService {

    public static async getSubscriptionPlan(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`subscription-plan/verify`, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getKeepAlive(clientId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`clients/${clientId}/keepalive/keep`, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
