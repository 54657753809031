import _ from 'lodash';
import { MultiLanguage } from '@/Domain/Entities';

export default class Sorter {
    public get key() {
        return this._key;
    }
    public get reverse() {
        return this._reverse;
    }
    private _key: string;
    private _reverse: boolean;

    constructor() {
        this._key = '';
        this._reverse = false;
    }

    public sortBy(array: any[], key: string, currentLanguage?: string | null, isUpdate?, keySecundary?) {
        if ((this._key === key || this.areEquals(this._key, key)) && !isUpdate) {
            this._reverse = !this._reverse;
        } else if (this._key !== key || !this.areEquals(this._key, key)) {
            this._reverse = false;
        }
        this._key = key;

        if (array.every(elem => typeof elem[key] === 'string')) {
            return _.orderBy(array, [element => _.deburr(element[key]).toLowerCase(), element => _.deburr(element[keySecundary]).toLowerCase()], [this._reverse ? 'desc' : 'asc', 'asc']);
        }
        if (array.every(elem => elem[key] instanceof MultiLanguage) && currentLanguage) {
            return _.orderBy(array, [element => _.deburr(element[key][currentLanguage]).toLowerCase()], this.reverse ? 'desc' : 'asc');
        }
        return _.orderBy(array, [key, element => _.deburr(element[keySecundary]).toLowerCase()], [this._reverse ? 'desc' : 'asc', 'asc']);
    }

    private areEquals(a, b) {
        if (!a || !b) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }
}
