import Enum from '../interfaces/IEnum';

export const InscriptionStatus: Enum = {
    translations: {
        0: 'lang.shared.pendingUser',
        1: 'lang.shared.admitted',
        2: 'lang.shared.excluded'
    },
    enum: {
        PENDING: 0,
        ADMITTED: 1,
        EXCLUDED: 2,
        DRAFT: 3
    }
};
