import { CheckboxFilterElement, InputFilterElement } from '@/Domain/Entities';
import { Constants, FilterTypes } from '@/Domain/enum';
import _ from 'lodash';
import moment from 'moment';

function generateYears() {
    const yearsAll: any = [];
    const yearActual = moment().year();
    for (let i = Constants.process.YEAR_INITIAL; i <= yearActual + 1; i++) {
        yearsAll.push({ value: i, label: i.toString(), checked: false });
    }
    return yearsAll.reverse();
}

export const BoepListStore = {
    namespaced: true,
    state: {
        filters: [
            new InputFilterElement({
                type: FilterTypes.INPUT,
                label: 'lang.listManagement.searchProcess',
                param: ['title'],
                icon: 'fa-search'
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.entity',
                param: 'entityOepId',
                options: [],
                metadata: 'entities'
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.year',
                param: 'year',
                options: generateYears()
            })]
    },
    mutations: {
        setFilters(state, filters) {
            state.filters = filters;
        }
    },
    getters: {
        getFilters(state) {
            return state.filters;
        }
    },
    actions: {
        setEntities({ commit, state }, entities: Array<{id: string, name: string}>) {
            const entitiesFilterIndex = state.filters.findIndex(filterElement => filterElement.param === 'entityOepId');
            if (state.filters[entitiesFilterIndex].options.length) {
                return;
            }
            const filters = _.cloneDeep(state.filters);
            const checkboxOptions = entities.map(entitie => {
                return { value: entitie.id, label: entitie.name, checked: false };
            });
            filters[entitiesFilterIndex] = new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.entity',
                param: 'entityOepId',
                options: checkboxOptions,
                metadata: 'entities'
            });

            commit('setFilters', filters);
        }
    }
};
