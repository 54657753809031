import Enum from '../interfaces/IEnum';

export const ManagementTabs: Enum = {
    name: {
        '#pending': 0,
        '#admitted': 1,
        '#excluded': 2
    },
    enum: {
        0: '#pending',
        1: '#admitted',
        2: '#excluded'
    }
};
