import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  computed: {
    styleVars() {
      return {
        '--custom-height': (this as SdSlideOver).$props.customHeight
      };
    }
  }
})
export default class SdSlideOver extends Vue {
  @Prop({ default: '120px' }) customHeight!: string;
  hasFooter: boolean = false;

  setOverflowHiddenToBody() {
    const body = document.querySelector('body');
    body!.style.overflow = 'hidden';
  }

  setOverflowAutoToBody() {
    const body = document.querySelector('body');
    body!.style.overflow = 'auto';
  }

  mounted() {
    this.hasFooter = this.$slots.footer !== undefined;
    this.setOverflowHiddenToBody();
  }
}
