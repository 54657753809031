import { CheckboxFilterElement, DatepickerFilterElement, FilterElement, InputFilterElement, ProcessConfigGroup } from '@/Domain/Entities';
import { Constants, FilterTypes } from '@/Domain/enum';
import moment from 'moment';
import _ from 'lodash';

function generateYears() {
    const yearsAll: any = [];
    const yearActual = moment().year();
    for (let i = Constants.process.YEAR_INITIAL; i <= yearActual + 1; i++) {
        yearsAll.push({ value: i, label: i.toString(), checked: false });
    }
    return yearsAll.reverse();
}

export const ProcessListStore = {
    namespaced: true,
    state: {
        filters: [
            new InputFilterElement({
                type: FilterTypes.INPUT,
                label: 'lang.listManagement.searchProcess',
                param: ['title'],
                icon: 'fa-search'
            }),
            new CheckboxFilterElement(
                {
                    type: FilterTypes.CHECKBOX,
                    label: 'lang.shared.process',
                    param: 'type',
                    options: [{ value: 0, label: 'lang.layout.calls', checked: false }, { value: 1, label: 'lang.layout.bags', checked: false }, { value: 2, label: 'lang.layout.postProvision', checked: false }]
                }
            ),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.processType',
                param: 'oppositionType',
                options: [{ value: 0, label: 'lang.shared.contest', checked: false }, { value: 1, label: 'lang.shared.oppositionContest', checked: false }, { value: 2, label: 'lang.shared.opposition', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.evaluationPhase',
                param: 'phase',
                options: [{ value: 0, label: 'lang.shared.inscriptionsPhase', checked: false }, { value: 1, label: 'lang.shared.evaluationPhaseFilter', checked: false }, { value: 2, label: 'lang.shared.rankingPhase', checked: false }, { value: 3, label: 'lang.shared.finished', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.year',
                param: 'year',
                options: generateYears()
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.visible',
                param: 'status',
                options: [{ value: 0, label: 'lang.shared.hidden', checked: false }, { value: 1, label: 'lang.shared.visible', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.groupSubgroup',
                param: 'groupType',
                options: []
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.entity',
                param: 'entityOepId',
                options: [],
                metadata: 'entities'
            }),
            new DatepickerFilterElement({
                type: FilterTypes.DATE,
                label: 'lang.shared.deadlinePresentation',
                param: 'startDate',
                icon: 'fa-calendar-alt',
                start: null,
                end: null
            }),
            new DatepickerFilterElement({
                type: FilterTypes.DATE,
                label: 'lang.shared.deadlineClaim',
                param: 'claimsStartDate',
                icon: 'fa-calendar-alt',
                start: null,
                end: null
            })
        ]
    },
    mutations: {
        setFilters(state, filters: FilterElement[]) {
            state.filters = filters;
        }
    },
    getters: {
        getFilters(state) {
            return state.filters;
        }
    },
    actions: {
        setCheckboxGroupsSubgroups({ commit, state }, options: { processConfigGroups: ProcessConfigGroup[], currentLanguage: string }) {
            const groupTypeFilterIndex = state.filters.findIndex(filterElement => filterElement.param === 'groupType');
            if (state.filters[groupTypeFilterIndex].options.length) {
                return;
            }
            const filters = _.cloneDeep(state.filters);
            const checkboxOptions = options.processConfigGroups.map(group => {
                return { value: group.type, label: group.title[options.currentLanguage], checked: false };
            });
            filters[groupTypeFilterIndex] = new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.groupSubgroup',
                param: 'groupType',
                options: checkboxOptions
            });

            commit('setFilters', filters);
        },
        setEntities({ commit, state }, entities: Array<{id: string, name: string}>) {
            const entitiesFilterIndex = state.filters.findIndex(filterElement => filterElement.param === 'entityOepId');
            if (state.filters[entitiesFilterIndex].options.length) {
                return;
            }
            const filters = _.cloneDeep(state.filters);
            const checkboxOptions = entities.map(entitie => {
                return { value: entitie.id, label: entitie.name, checked: false };
            });
            filters[entitiesFilterIndex] = new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.entity',
                param: 'entityOepId',
                options: checkboxOptions,
                metadata: 'entities'
            });

            commit('setFilters', filters);
        }
    }
};
