import '../../../public/tinymce/tinymce';
import '../../../public/tinymce/themes/silver';
import '../../../public/tinymce/icons/default';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import i18n from '../../lang';
import { MultiLanguage } from '@/Domain/Entities';

@Component({
    components: {
        Editor
    },
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        $error() {
            return this.$props.required && this.$data.dirty && !this.$props.value && !this.$data.focused;
        },
        $errorSubmitted() {
            return !this.$props.value && this.$props.submitted && !this.$data.focused;
        }
    }
})
export default class TextEditor extends Vue {
    @Prop() value!: string;
    @Prop() required?: string;
    @Prop() max!: number;
    @Prop() submitted!: boolean;
    @Prop() isParagraph!: boolean;
    @Prop() hasAutoTranslate!: boolean;
    @Prop() contentToTranslate!: MultiLanguage;
    caractersCount: number = 0;
    dirty: boolean = false;
    focused: boolean = false;

    init() {
        return {
            selector: 'textarea',
            forced_root_block : this.$props.isParagraph,
            height: 300,
            branding: false,
            menubar: false,
            paste_as_text: true,
            entity_encoding : 'raw',
            language: i18n.locale.split('-')[0] === 'pt' ? 'es' : i18n.locale.split('-')[0],
            statusbar: false,
            deprecation_warnings: false,
            plugins: [
                'autolink',
                'paste'
            ],
            toolbar:
                'bold italic',
            setup: editor => {
                editor.on('GetContent', e => {
                    this.setCharactersCount();
                });
                editor.on('init', e => {
                    this.setCharactersCount();
                });
                editor.on('SelectionChange', e => {
                    this.setCharactersCount();
                });
                editor.on('focus', e => {
                    this.focused = true;
                });
                editor.on('blur', e => {
                    this.focused = false;
                    this.dirty = true;
                });
            }
        };
    }

    setCharactersCount() {
        if (this.value) {
            this.caractersCount = this.value.replace(/<[^>]*>?/g, '').replace(/&nbsp;/g, ' ').length;
        }
    }

    isValidinput() {
        return this.caractersCount <= this.max && this.caractersCount > 0;
    }

    translatedContent($event) {
        let content = $event;
        if (!this.isValidTranslatedContentLength($event)) {
            content = this.truncateTranslatedContent($event);
        }
        this.$emit('input', content);
    }

    isValidTranslatedContentLength(contentTranslated: string) {
        const hasMaxDefined = this.max !== undefined;
        return hasMaxDefined && contentTranslated.length <= Number(this.max);
    }

    truncateTranslatedContent(contentTranslated: string) {
        return contentTranslated.substring(0, this.max);
    }
}
