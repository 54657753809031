import { Component, Prop } from 'vue-property-decorator';
import SdInputBase from '../SdInputBase';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import i18n from '../../../lang/index';

@Component({
    components: { DatePicker },
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                if (!value) {
                    this.$emit('input', null);
                    return;
                }
                const date = moment.utc(value.getDate() + '/' + (value.getMonth() + 1) + '/' + value.getFullYear(), 'DD/MM/YYYY');
                this.$emit('input', date.isValid() ? date : null);
            }
        },
        datepickerFormat() {
            const l = 'L';
            return (moment().locale(this.$i18n.locale).localeData() as any)._longDateFormat[l];
        }
    }
})
export default class SdInputDatepicker extends SdInputBase {
    @Prop() minDate?: moment.Moment | null;
    @Prop() maxDate?: moment.Moment | null;
    @Prop() isInvalidDate?: boolean;
    momentFormat: any = {
        // Date to String
        stringify(value) {
            return value && i18n.locale ? moment(value).format('L') : '';
        },
        // String to Date
        parse(value) {
            return value ? moment(value, 'L').toDate() : null;
        }
    };

    onBlur(actualPannel) {
        if (actualPannel === 'NONE') {
            this.focused = false;
            this.dirty = true;
        }
    }
}
