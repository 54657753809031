import Enum from '../interfaces/IEnum';

export const GroupTypes: Enum = {
    translations: {
        0: 'lang.groupTypes.a1',
        1: 'lang.groupTypes.a2',
        2: 'lang.groupTypes.b',
        3: 'lang.groupTypes.c1',
        4: 'lang.groupTypes.c2',
        5: 'lang.groupTypes.ap',
        6: 'lang.groupTypes.i',
        7: 'lang.groupTypes.ii',
        8: 'lang.groupTypes.iii',
        9: 'lang.groupTypes.iv',
        10: 'lang.groupTypes.v',
        11: 'lang.groupTypes.vi',
        12: 'lang.groupTypes.a1a2',
        13: 'lang.groupTypes.c1c2',
        14: 'lang.groupTypes.a',
        15: 'lang.groupTypes.c',
        16: 'lang.groupTypes.e',
        17: 'lang.groupTypes.f',
        18: 'lang.groupTypes.vii',
        19: 'lang.groupTypes.viii',
        20: 'lang.groupTypes.ix'
    },
    enum: {
        A1: 0,
        A2: 1,
        B: 2,
        C1: 3,
        C2: 4,
        AP: 5,
        I: 6,
        II: 7,
        III: 8,
        IV: 9,
        V: 10,
        VI: 11,
        A1A2: 12,
        C1C2: 13,
        A: 14,
        C: 15,
        E: 16,
        F: 17,
        VII: 18,
        VIII: 19,
        IX: 20
    }
};
