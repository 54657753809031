import Enum from '../interfaces/IEnum';

export const Gender: Enum = {
    translations: {
        0: 'lang.shared.woman',
        1: 'lang.shared.man'
    },
    enum: {
        WOMAN: 0,
        MAN: 1
    }
};
