import { CheckboxFilterElement, DatepickerFilterElement, FilterElement, InputFilterElement, ProcessConfigGroup } from '@/Domain/Entities';
import { FilterTypes } from '@/Domain/enum';
import _ from 'lodash';

export const ReplacementListStore = {
    namespaced: true,
    state: {
        filters: [
            new InputFilterElement({
                type: FilterTypes.INPUT,
                label: 'lang.listManagement.searchList',
                param: ['name', 'originProcess'],
                icon: 'fa-search'
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.visible',
                param: 'isVisible',
                options: [{ value: false, label: 'lang.shared.hidden', checked: false }, { value: true, label: 'lang.shared.visible', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.groupSubgroup',
                param: 'groupType',
                options: []
            }),
            new DatepickerFilterElement({
                type: FilterTypes.DATE,
                label: 'lang.replacement.dateCreation',
                param: 'creationDate',
                icon: 'fa-calendar-alt',
                start: null,
                end: null
            }),
            new DatepickerFilterElement({
                type: FilterTypes.DATE,
                label: 'lang.replacement.validity',
                param: 'validityDate',
                icon: 'fa-calendar-alt',
                start: null,
                end: null
            })
        ]
    },
    mutations: {
        setFilters(state, filters: FilterElement[]) {
            state.filters = filters;
        }
    },
    getters: {
        getFilters(state) {
            return state.filters;
        }
    },
    actions: {
        setCheckboxGroupsSubgroups({ commit, state }, options: { processConfigGroups: ProcessConfigGroup[], currentLanguage: string }) {
            const groupTypeFilterIndex = state.filters.findIndex(filterElement => filterElement.param === 'groupType');
            if (state.filters[groupTypeFilterIndex].options.length) {
                return;
            }
            const filters = _.cloneDeep(state.filters);
            const checkboxOptions = options.processConfigGroups.map(group => {
                return { value: group.type, label: group.title[options.currentLanguage], checked: false };
            });
            filters[groupTypeFilterIndex] = new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.groupSubgroup',
                param: 'groupType',
                options: checkboxOptions
            });

            commit('setFilters', filters);
        }
    }
};
