import Enum from '../interfaces/IEnum';

export const WorkingDayType: Enum = {
    translations: {
        0: 'lang.shared.partTime',
        1: 'lang.shared.full'
    },
    enum: {
        PARTTIME : 0,
        FULL: 1
    }
};
