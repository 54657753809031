import { Component, Prop, Vue } from 'vue-property-decorator';
import { MdInput,  MdTextarea, MdSelect, Toggle } from '@/Components';
import { FormMode, Languages, ProcessStatus, ProcessPhases, Constants } from '@/Domain/enum';
import ValidationService from '@/Application/Services/ValidationService';
import RequirementService from '@/Services/RequirementService';
import { Requirement } from '@/Domain/Entities';
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';

@Component({
    components: {
        MdInput,
        MdTextarea,
        MdSelect,
        Toggle
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        requiredsProperties() {
            return this.$data.stateToggle ? [this.$data.objectToManage.title, this.$data.objectToManage.documentDescription] : [this.$data.objectToManage.title];
        },
        publishAndUsed() {
            return this.$props.process.isUsed || this.$props.process.stateToggle;
        }
    }
})

export default class EditNewRequeriment extends Vue {
    @Prop() info: any;
    @Prop() value: any;
    @Prop() mode: any;
    @Prop() process: any;

    Languages = Languages;
    FormMode = FormMode;
    ProcessStatus = ProcessStatus;
    ProcessPhases = ProcessPhases;
    Constants = Constants;
    objectToManage = _.cloneDeep(this.info);
    stateToggle: boolean = true;
    submitted = false;
    tabActive: string = '';

    currentLanguage!: string;
    availableLanguages!: string[];

    $refs!: {
        newRequirementForm: HTMLFormElement,
        editRequirementForm: HTMLFormElement
    };

    save() {
        this.submitted = true;
        this.objectToManage.allowsDocument = this.stateToggle;
        if (this.$props.mode === FormMode.enum.EDITING && ValidationService.validateFormRequired(this.$refs.editRequirementForm)) {
            this.process.isSaving = true;
            RequirementService.editRequirement(this.process.id, this.objectToManage.infoToServer())
                .then(response => {
                    const requirementIndex = this.process.requirements.indexOf(this.info);
                    this.process.requirements[requirementIndex] = new Requirement(response);
                    this.objectToManage = {};
                    this.process.isSaving = false;
                    this.$emit('editedRequeriment', this.process.requirements.some(requirement => requirement.isOptional));
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        } else if (this.$props.mode === FormMode.enum.CREATING && ValidationService.validateFormRequired(this.$refs.newRequirementForm)) {
            this.process.isSaving = true;
            RequirementService.addRequirement(this.process.id, new Requirement(this.objectToManage).infoToServer())
                .then(response => {
                    this.process.requirements.push(new Requirement(response));
                    this.objectToManage = {};
                    this.process.isSaving = false;
                    this.$emit('createdRequeriment', this.process.requirements.some(requirement => requirement.isOptional));
                })
                .catch(error => {
                    this.process.isSaving = false;
                    console.log(error);
                });
        }
    }

    cancel() {
        this.objectToManage = {};
        this.info.isEditing = false;
        this.$emit('canceledRequeriment');
    }

    mounted() {
        if (this.info.allowsDocument === false) {
            this.stateToggle = false;
        }
    }

    created() {
        this.tabActive = this.currentLanguage;
    }
}
