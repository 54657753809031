export default class Vacancies {
    private _freeVacancies: number;
    private _internalVacancies: number;
    private _internalDisabilityVacancies: number;
    private _freeDisabilityVacancies: number;
    private _mobilityVacancies: number;
    private _totalVacancies: number;
    
    public constructor(data: any) {
        this._freeVacancies = data ? data.free : null;
        this._internalVacancies = data ? data.internal : null;
        this._internalDisabilityVacancies = data ? data.internalDisability : null;
        this._freeDisabilityVacancies = data ? data.freeDisability : null;
        this._mobilityVacancies = data ? data.mobility : null;
        this._totalVacancies = data ? data.total : null;
    }

    public get freeVacancies() {
        return this._freeVacancies;
    }
    public set freeVacancies(freeVacancies: number) {
        this._freeVacancies = freeVacancies;
    }

    public get internalVacancies() {
        return this._internalVacancies;
    }
    public set internalVacancies(internalVacancies: number) {
        this._internalVacancies = internalVacancies;
    }

    public get internalDisabilityVacancies() {
        return this._internalDisabilityVacancies;
    }
    public set internalDisabilityVacancies(internalDisabilityVacancies: number) {
        this._internalDisabilityVacancies = internalDisabilityVacancies;
    }

    public get freeDisabilityVacancies() {
        return this._freeDisabilityVacancies;
    }
    public set freeDisabilityVacancies(freeDisabilityVacancies: number) {
        this._freeDisabilityVacancies = freeDisabilityVacancies;
    }

    public get mobilityVacancies() {
        return this._mobilityVacancies;
    }
    public set mobilityVacancies(mobilityVacancies: number) {
        this._mobilityVacancies = mobilityVacancies;
    }

    public get totalVacancies() {
        return this._totalVacancies;
    }
    public set totalVacancies(totalVacancies: number) {
        this._totalVacancies = totalVacancies;
    }

    public toServer() {
        return {
            free: this.freeVacancies,
            internal: this.internalVacancies,
            internalDisability: this.internalDisabilityVacancies,
            freeDisability: this.freeDisabilityVacancies,
            mobility: this.mobilityVacancies
        };
    }
}
