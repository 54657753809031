export default class Grade {
    private _id: string;
    private _nif: string;
    private _nifToShow: string;
    private _fullName: string;
    private _fullNameToShow: string;
    private _applied: boolean;
    private _score: number;
    private _passed: boolean;
    private _graded: boolean;
    private _showedTooltip: boolean;
    private _inscriptionId: string;
    private _documents: any[];
    private _activeSearch: boolean;
    private _info: any;

    public constructor(data: any) {
        this._id = data.id;
        this._nif = data.nif;
        this._nifToShow = data.nif;
        this._fullName = data.fullName;
        this._fullNameToShow = data.fullName;
        this._applied = data.applied;
        this._score = data.score;
        this._passed = data.passed;
        this._graded = data.graded;
        this._showedTooltip = false;
        this._info = null;
        this._inscriptionId = data.inscriptionId;
        this._documents = data.documents;
        this._activeSearch = false;
    }

    public get id() {
        return this._id;
    }

    public get nifToShow() {
        return this._nifToShow;
    }

    public set nifToShow(nifToShow: string) {
        this._nifToShow = nifToShow;
    }

    public get nif() {
        return this._nif;
    }

    public get fullNameToShow() {
        return this._fullNameToShow;
    }

    public set fullNameToShow(fullNameToShow: string) {
        this._fullNameToShow = fullNameToShow;
    }

    public get fullName() {
        return this._fullName;
    }

    public set fullName(fullName: string) {
        this._fullName = fullName;
    }

    public get inscriptionId() {
        return this._inscriptionId;
    }

    public get showedTooltip() {
        return this._showedTooltip;
    }

    public set showedTooltip(showedTooltip: boolean) {
        this._showedTooltip = showedTooltip;
    }

    public get graded() {
        return this._graded;
    }

    public set graded(graded: boolean) {
        this._graded = graded;
    }

    public get applied() {
        return this._applied;
    }

    public set applied(applied: boolean) {
        this._applied = applied;
    }

    public get score() {
        return this._score;
    }

    public set score(score: number) {
        this._score = score;
    }

    public get passed() {
        return this._passed;
    }

    public set passed(passed: boolean) {
        this._passed = passed;
    }

    public get documents() {
        return this._documents;
    }

    public set documents(documents: any) {
        this._documents = documents;
    }

    public get activeSearch() {
        return this._activeSearch;
    }

    public set activeSearch(activeSearch: any) {
        this._activeSearch = activeSearch;
    }

    public get info() {
        return this._info;
    }

    public set info(info: any) {
        this._info = info;
    }

    public toServer() {
        return {
            id: this._id,
            nif: this._nif,
            fullName: this._fullName,
            applied: this._applied,
            score: this._applied ? this._score : null,
            passed: this._passed,
            graded: this._graded,
            inscriptionId: this._inscriptionId,
            documents: this._documents
        };
    }

}
