export default class Turns {
    private _freeTurn: boolean;
    private _internalTurn: boolean;
    private _internalDisabilityTurn: boolean;
    private _freeDisabilityTurn: boolean;
    private _freeDesignationTurn: boolean;
    private _freeDesignationDisabilityTurn: boolean;
    
    public constructor(data: any) {
        this._freeTurn = data ? data.free : false;
        this._internalTurn = data ? data.internal : false;
        this._internalDisabilityTurn = data ? data.internalDisability : false;
        this._freeDisabilityTurn = data ? data.freeDisability : false;
        this._freeDesignationTurn = data ? data.freeDesignation : false;
        this._freeDesignationDisabilityTurn = data ? data.freeDesignationDisability : false;
    }

    public get freeTurn() {
        return this._freeTurn;
    }
    public set freeTurn(freeTurn: boolean) {
        this._freeTurn = freeTurn;
    }

    public get internalTurn() {
        return this._internalTurn;
    }
    public set internalTurn(internalTurn: boolean) {
        this._internalTurn = internalTurn;
    }

    public get internalDisabilityTurn() {
        return this._internalDisabilityTurn;
    }
    public set internalDisabilityTurn(internalDisabilityTurn: boolean) {
        this._internalDisabilityTurn = internalDisabilityTurn;
    }

    public get freeDisabilityTurn() {
        return this._freeDisabilityTurn;
    }
    public set freeDisabilityTurn(freeDisabilityTurn: boolean) {
        this._freeDisabilityTurn = freeDisabilityTurn;
    }

    public get freeDesignationTurn() {
        return this._freeDesignationTurn;
    }
    public set freeDesignationTurn(freeDesignationTurn: boolean) {
        this._freeDesignationTurn = freeDesignationTurn;
    }

    public get freeDesignationDisabilityTurn() {
        return this._freeDesignationDisabilityTurn;
    }
    public set freeDesignationDisabilityTurn(freeDesignationDisabilityTurn: boolean) {
        this._freeDesignationDisabilityTurn = freeDesignationDisabilityTurn;
    }

    public isValidTurnsChecked() {
        return this.freeTurn || this.internalTurn || this.internalDisabilityTurn || this.freeDisabilityTurn || this.freeDesignationTurn || this.freeDesignationDisabilityTurn;
    }

    public toServer() {
        return {
            free: this.freeTurn,
            internal: this.internalTurn,
            internalDisability: this.internalDisabilityTurn,
            freeDisability: this.freeDisabilityTurn,
            freeDesignation: this.freeDesignationTurn,
            freeDesignationDisability: this.freeDesignationDisabilityTurn
        };
    }
}
