import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

import { EntityOep, Process, ProcessConfigFactory, ProcessConfigGroup, VacanciesAttached } from '@/Domain/Entities';
import { ProcessType, ProcessPhases, OppositionType, Languages, StaffType, ProcessStatus, Constants, ProcessConfigurationTypes } from '@/Domain/enum';
import { MdInput, MdDatepicker, MdTextarea, MdSelect, Toggle, MdRadio } from '@/Components';

import EntityService from '@/Services/EntityService';
import MasterDataService from '@/Services/MasterDataService';

@Component({
    components: {
        MdInput,
        MdTextarea,
        MdSelect,
        MdDatepicker,
        MdRadio,
        Toggle
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        isAttachMeritsValid() {
            if (this.$props.value.meritPresentationStartDate && this.$props.value.meritPresentationEndDate) {
                return this.$props.value.meritPresentationStartDate.isBefore(this.$props.value.meritPresentationEndDate);
            }
            return true;
        },
        isAttachMeritsPeriodAfterClaimsEndDateOrEndDate() {
            if (this.$props.value.meritPresentationStartDate && this.$props.value.meritPresentationEndDate && !this.$props.value.claimsEndDate) {
                return this.$props.value.meritPresentationStartDate.isBefore(this.$props.value.meritPresentationEndDate) && this.$props.value.meritPresentationStartDate.isAfter(this.$props.value.endDate);
            }
            if (this.$props.value.claimsEndDate && this.$props.value.meritPresentationStartDate) {
                return this.$props.value.claimsEndDate.isBefore(this.$props.value.meritPresentationStartDate);
            }
            return true;
        },
        hasAttachMeritsDate() {
            return this.$props.value.meritPresentationStartDate && this.$props.value.meritPresentationEndDate;
        },
        isClaimPeriodInvalid() {
            if (this.$props.value.claimsStartDate && this.$props.value.claimsEndDate) {
                return !(this.$props.value.claimsStartDate.isBefore(this.$props.value.claimsEndDate));
            }
        },
        isClaimPeriodBeforeEndDate() {
            if (this.$props.value.endDate && this.$props.value.claimsStartDate) {
                return !(this.$props.value.endDate.isBefore(this.$props.value.claimsStartDate));
            }
        },
        addClaimDate() {
            return this.$props.value.startDate && this.$props.value.endDate;
        },
        isInscriptionPeriodInvalid() {
            if (this.$props.value.startDate && this.$props.value.endDate) {
                return !(this.$props.value.startDate.isBefore(this.$props.value.endDate));
            }
        },
        showCheckError() {
            return this.$props.submitted && !this.$props.value.turns.isValidTurnsChecked();
        },
        anyHasNoValue() {
            return !this.$props.value.bopDate && !this.$props.value.bopNumber && !this.$props.value.startDate && !this.$props.value.endDate;
        },
        processConfigurationGroupsOptions() {
            return this.$data.processConfigurationGroups.length > 0 ? _.orderBy(this.$data.processConfigurationGroups.map(process => ({
                id: process.type,
                name: process.title
            })), [processConfigurationGroup => _.deburr(processConfigurationGroup.name[(this as GeneralInfo).currentLanguage]).toLowerCase()]) : [];
        },
        showAllowPostInscriptionMeritPresentation() {
            return this.$props.value.oppositionType != null && Number(this.$props.value.oppositionType) !== this.$data.oppositionType.enum.NOMINATION && Number(this.$props.value.oppositionType) !== this.$data.oppositionType.enum.FREE_DESIGNATION;
        }
    }
})

export default class GeneralInfo extends Vue {
    @Prop() value!: Process;
    @Prop() submitted!: boolean;

    processType = ProcessType;
    processPhases = ProcessPhases;
    oppositionType = OppositionType;
    processStatus = ProcessStatus;
    staffType = StaffType;
    Languages = Languages;
    Constants = Constants;

    currentLanguage!: string;
    yearsProcess: string[] = [];
    entityList: EntityOep[] = [];
    tabActive: string = '';
    processConfigurationGroups: ProcessConfigGroup[] = [];
    oppositionTypeTranslations = this.getOppositionTypeTranslations();

    deleteAttachMeritsDate() {
        if (!this.value.allowPostInscriptionMeritPresentation) {
            this.value.meritPresentationStartDate = null;
            this.value.meritPresentationEndDate = null;
        }
    }

    yearsToProcess() {
        const yearActual = moment().year();
        for (let i = Constants.process.YEAR_INITIAL; i <= yearActual + 1; i++) {
            this.yearsProcess.push(i.toString());
        }
    }

    getEntities() {
        EntityService.getEntityList()
            .then((response: EntityOep[]) =>
                this.entityList = response
            )
            .catch(error => console.log(error));
    }

    createVacanciesAttached() {
        this.value.vacanciesAttached = new VacanciesAttached({});
    }

    deleteVacanciesAttached() {
        this.value.vacanciesAttached = null;
    }

    getProcessConfigurationGroups() {
        MasterDataService.getProcessesConfiguration(ProcessConfigurationTypes.enum.GROUPSUBGROUP)
            .then((response: any[]) => {
                this.processConfigurationGroups = response.map(processConfig => ProcessConfigFactory.create(ProcessConfigurationTypes.enum.GROUPSUBGROUP, processConfig));
            }).catch(error => console.log(error));
    }

    getOppositionTypeTranslations() {
        if (this.value.type !== ProcessType.enum.POSTPROVISION) {
            const translations = _.cloneDeep(OppositionType.translations);
            delete translations[OppositionType.enum.FREE_DESIGNATION];
            return translations;
        }
        return OppositionType.translations;
    }

    mounted() {
        this.tabActive = this.currentLanguage;
        this.getProcessConfigurationGroups();
        this.getEntities();
        this.yearsToProcess();
    }
}
