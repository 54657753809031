import Enum from '../interfaces/IEnum';

export const InscriptionRegistry: Enum = {
    translations: {
        0: 'lang.shared.online', // applicant
        1: 'lang.shared.window' // admin
    },
    enum: {
        ONLINE: 0,
        WINDOW: 1
    }
};
