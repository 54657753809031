import { Component, Prop, Vue } from 'vue-property-decorator';
import { MdInput } from '@/Components';
import Axios from 'axios';

@Component({
    components: {
        MdInput
    },
    computed: {
        searchResults() {
            if (this.$data.searchString.length <= 2) {
                return [];
            }
            return this.$data.results;
        }
    }
})
export default class MdSearcher extends Vue {
    @Prop() url!: string;
    @Prop() label?: string;
    results = [];
    searchString = '';

    search() {
        if (this.searchString.length > 2) {
            Axios.get(process.env.VUE_APP_ROOT_API + 'api/' + this.$props.url + '?filter=' + this.$data.searchString, { headers: { Authorization: localStorage.getItem('idAdmin') } })
                .then(response => this.$data.results = response.data).catch(error => console.log(error));
        } else {
            this.results = [];
        }
    }
}
