import Enum from '../interfaces/IEnum';

export const RankedInscrptionStatus: Enum = {
    translations: {
        0: 'lang.shared.no',
        1: 'lang.shared.yes',
        2: 'lang.ranking.rejected',
        3: 'lang.ranking.refused'
    },
    enum: {
        UNQUALIFIED: 0,
        QUALIFIED: 1,
        REJECTED: 2,
        REFUSED: 3
    }
};
