import { OepDocument, ReplacementDocument } from '@/Domain/Entities';
import IFile from '@/Domain/interfaces/IFile';
import { ToasterService } from '@/Services/ToasterService';
import axios from 'axios';
import i18n from '../../lang';
import * as pdfjsLib from 'pdfjs-dist';

/**
 * Service for handling documents.
 */
export default class DocumentService {

    /**
     * Downloads a file.
     * @param {ReplacementDocument | OepDocument} fileToDownload - The file to download.
     * @param {string} activeLanguge - The active language.
     */
    public static async download(fileToDownload: ReplacementDocument | OepDocument, activeLanguge: string = '') {
        const file = this.getFile(fileToDownload, activeLanguge);
        try {
            const response = await this.getFileContent(file.url);
            this.silentDownload({ fileName: file.fileName, fileUrl: window.URL.createObjectURL(response) });
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Views a file.
     * @param {ReplacementDocument | OepDocument} fileToDownload - The file to view.
     * @param {string} activeLanguge - The active language.
     * @returns {Promise<Blob>} The file content.
     */
    public static async viewFile(fileToDownload, activeLanguge: string = ''): Promise<Blob> {
        const file = this.getFile(fileToDownload, activeLanguge);
        try {
            return await this.getFileContent(file.url);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Checks if a file is a portfolio file type.
     * If document is encrypted it returns false to prevent a pdfjs library  error.
     * @param {File} file - The file to check.
     * @returns {Promise<boolean>} True if the file is a portfolio file type, false otherwise.
     */
    public static async isPdfAPortfolioFileType(file) {
        const bytes = await this.getFileAsUint8Array(file);
        const isFileEncrypted = await this.isFileEncrypted(file);
        if (isFileEncrypted) {
            return false;
        }
        return new Promise<boolean>((resolve, reject) => {
            pdfjsLib.getDocument({ data: bytes }).promise.then(pdfDoc => {
                pdfDoc.getAttachments().then(attachments => {
                    resolve(attachments !== null);
                }).catch(error => {
                    reject(error);
                });
            });
        });
    }

    /**
     * Checks if a file is encrypted.
     * @param {File | Uint8Array} file - The file to check.
     * @returns {Promise<boolean>} True if the file is encrypted, false otherwise.
     */
    public static async isFileEncrypted(file: any | Uint8Array) {
        const bytes = !(file instanceof Uint8Array) ? await this.getFileAsUint8Array(file) : file;
        const text = new TextDecoder().decode(bytes);
        return text.includes('/Encrypt');
    }

    /**
     * Gets the content of a file in a blob format.
     * @param {string} fileUrl - The URL of the file.
     * @returns {Promise<Blob>} The file content.
     * @private
     */
    private static async getFileContent(fileUrl: string): Promise<Blob> {
        try {
            const response = await axios.get(fileUrl, { responseType: 'blob' });
            return response.data;
        } catch (error) {
            ToasterService.showError(i18n.t('lang.toaster.fileNotFoundInAzure') as string);
            throw error;
        }
    }

    /**
     * Downloads a file creating a new anchor element.
     * @param {Object} fileToDownload - The file to download.
     * @private
     */
    private static silentDownload(fileToDownload) {
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = fileToDownload.fileUrl;
        a.download = fileToDownload.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(fileToDownload.fileUrl);
        document.body.removeChild(a);
    }

    /**
     * Returns a file Object that implements @returns {IFile}.
     * @param {ReplacementDocument | OepDocument} file - The file to get.
     * @param {string} activeLanguge - The active language.
     * @returns {IFile} The file.
     * @private
     */
    private static getFile(file: ReplacementDocument | OepDocument, activeLanguge: string): IFile {
        if (file instanceof OepDocument && activeLanguge !== '') {
            return { fileName: `${file.name[activeLanguge]}${file.extension}`, url: file.url };
        }

        return { fileName: `${file.fileName}`, url: file.url };
    }

    /**
     * Returns the bytes of a file.
     * @param {File} file - The file to get.
     * @returns {Promise<Uint8Array>} The file as a Uint8Array.
     * @private
     */
    private static async getFileAsUint8Array(file) {
        return new Promise<Uint8Array>(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result;
                if (result !== null && result instanceof ArrayBuffer) {
                    resolve(new Uint8Array(result));
                }
            };
            reader.readAsArrayBuffer(file);
        });
    }
}
