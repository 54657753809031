import { Component, Vue, Prop } from 'vue-property-decorator';
import { ToasterService } from '@/Services/ToasterService';
import { StaffType } from '@/Domain/enum';
import { Contract } from '@/Domain/Entities';
import i18n from '@/lang/index';
import _ from 'lodash';
import { mapState } from 'vuex';
import moment from 'moment';
import { Toggle } from '@/Components';
import ReplacementContractsService from '@/Services/ReplacementContractsService';

@Component({
    components: { Toggle },
    computed: {
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'})
    }
})
export default class ReplacementContract extends Vue {
    @Prop() replacementListCandidateId!: string;
    @Prop() replacementListModified!: string;
    dateFormat!: string;

    moment = moment;
    replacementContracts: any[] = [];
    staffType = StaffType;
    contract: Contract | null = null;
    loading: boolean = false;
    showDeleteModal = false;
    openContract: boolean = false;
    submitted: boolean = false;
    fistContractRegister: boolean = false;

    $refs!: {
        replacementContractForm: HTMLFormElement
    };

    goToCreationContract() {
        this.openContract = true;
        this.contract = new Contract({});
    }

    goToEditionContract(contract: Contract) {
        this.openContract = true;
        this.contract = _.cloneDeep(contract);
        this.contract.isEditing = true;
    }

    goToRemoveContract(replacementContractToRemove: Contract) {
        this.contract = replacementContractToRemove;
        this.showDeleteModal = true;
    }

    cancel() {
        this.openContract = false;
        this.contract = null;
        this.submitted = false;
        this.fistContractRegister = false;
    }

    removeContract() {
        this.showDeleteModal = false;
        this.loading = true;
        if (this.contract) {
            ReplacementContractsService.removeReplacementContract(this.$props.replacementListCandidateId, this.contract)
                .then(() => {
                    this.initList();
                    this.loading = false;
                    this.openContract = false;
                    this.fistContractRegister = false;
                    ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as string, i18n.t('lang.toaster.goodJob') as string);
                    this.$emit('updateReplacementListIsModify');
                })
                .catch(() => {
                    this.loading = false;
                    ToasterService.showError(i18n.t('lang.toaster.errorGeneralTitle') as string);
                });
        } else {
            this.loading = false;
            ToasterService.showError(i18n.t('lang.toaster.errorGeneralTitle') as string);
        }
    }

    saveContract() {
        this.submitted = true;
        if (this.$refs.replacementContractForm.querySelectorAll(':invalid').length || this.$refs.replacementContractForm.querySelectorAll('.error').length) {
            return;
        }
        if (this.contract) {
            this.loading = true;
            const serviceToRequest = this.contract.id ? ReplacementContractsService.updateReplacementContract : ReplacementContractsService.createReplacementContract;
            serviceToRequest(this.$props.replacementListCandidateId, this.contract).then(() => {
                this.openContract = false;
                this.loading = false;
                this.submitted = false;
                this.fistContractRegister = false;
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as string);
                this.initList();
                this.$emit('updateReplacementListIsModify');
            }).catch(error => {
                this.loading = false;
                this.openContract = false;
                console.log(error);
            });
        }
    }

    getReplacementContracts() {
        ReplacementContractsService.getReplacementContracts(this.$props.replacementListCandidateId).then(response => {
            this.replacementContracts = response.replacementContracts.map(contract => new Contract(contract));
            this.replacementContracts.forEach((contract, index) => contract.order = this.replacementContracts.length - index);
            this.loading = false;
        }).catch(error => console.log(error));  
    }

    initList() {
        this.loading = true;
        this.getReplacementContracts();
    }

    mounted() {
        this.initList();
    }
}
