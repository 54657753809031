import { Component, Prop, Vue } from 'vue-property-decorator';
import i18n from '../../lang';
import { MultiLanguage } from '@/Domain/Entities';

@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        $error() {
            return this.$props.required && this.$data.dirty && (!this.$props.value && this.$props.value !== 0);
        },
        optionsToManage() {
            if (Array.isArray(this.$props.options) && this.$props.options[0] && this.$props.options[0].name instanceof MultiLanguage) {
                return this.$props.options.map( element => ({name: element.name[this.$props.currentLanguage], id: element.id, title: element.name[this.$props.currentLanguage], disabled: element.disabled}));
            }
            if (Array.isArray(this.$props.options) && typeof this.$props.options[0] === 'object') {
                return this.$props.options.map( element => ({name: i18n.t(element.name), id: element.id, title: i18n.t(element.title) || i18n.t(element.name), disabled: element.disabled}));
            }
            if (Array.isArray(this.$props.options) && typeof this.$props.options[0] !== 'object') {
                return this.$props.options.map( element => ({name: element, disabled: element.disabled}));
            }
            if (!Array.isArray(this.$props.options)) {
                const optionsToManage: any[] = [];
                for (const element in this.$props.options) {
                    if (this.$props.options[element]) {
                        optionsToManage.push({name: i18n.t(this.$props.options[element]), id: element, title: i18n.t(this.$props.options[element]), disabled: this.$props.options[element].disabled});
                    }
                }
                return optionsToManage.sort((a, b) =>  a.name.localeCompare(b.name));
            }
        }
    }
})
export default class MdSelect extends Vue {
    @Prop() value: any;
    @Prop() label?: string;
    @Prop() options!: any | any[];
    @Prop() required?: string;
    @Prop() disabled?: boolean;
    @Prop() currentLanguage?: string;
    dirty: boolean = false;
    focused: boolean = false;
    displayed: boolean = false;
    dataReady: boolean = false;

    mounted() {
        this.$nextTick(() => {
            this.$data.dataReady = true;
        });
    }
}
