import { Contract, Inscription, ReplacementState, ReplacementListRelated, InscriptionContact } from '@/Domain/Entities';
import { WorkingDayType } from '../../enum/WorkingDayType';
import _ from 'lodash';
import { StatusReplacement } from '@/Domain/enum';

export default class InscriptionReplacement extends Inscription {
    private _order: number;
    private _hasDisability: boolean;
    private _replacementStatesDocument: boolean;
    private _degree: number;
    private _totalScore: number;
    private _gradeScore: number;
    private _scaleScore: number;
    private _daysWorked: number;
    private _lastWorkingPercentage: number;
    private _workingDayType: number | null;
    private _replacementStatus: number;
    private _round: number;
    private _processType: number;
    private _tieType: number;
    private _moveInscription: boolean;
    private _isSelected: boolean;
    private _processId: string;
    private _approvedExams: number | null;
    private _lastReplacementContract: Contract | null;
    private _lastReplacementState: ReplacementState | null;
    private _replacementListRelatedList: ReplacementListRelated[] | null;    
    private _lastReplacementContact: InscriptionContact | null;
    private _replacementContracts: Contract[];
    private _replacementListCandidateId: string;

    constructor(data: any) {
        super(data);
        this._replacementListCandidateId = data.replacementListCandidateId;
        this._order = data.order;
        this._processId = data.processId;
        this._hasDisability = data.hasDisability;
        this._replacementStatesDocument = data.replacementStatesDocument;
        this._degree = data.degree;
        this._totalScore = data.totalScore;
        this._gradeScore = data.gradeScore;
        this._scaleScore = data.scaleScore;
        this._daysWorked = data.daysWorked;
        this._lastWorkingPercentage = data.lastWorkingPercentage;
        this._workingDayType = null;
        this._replacementStatus = data.replacementStatus;
        this._round = data.round;
        this._processType = data.processType;
        this._tieType = data.tieType;
        this._moveInscription = false;
        this._isSelected = false;
        this._approvedExams = data.approvedExams;
        this._lastReplacementContract = data.replacementContracts && data.replacementContracts.length > 0 ? new Contract(data.replacementContracts[0]) : null;
        this._lastReplacementState = data.replacementStates && data.replacementStates.length > 0 ? _.orderBy(data.replacementStates.map(rs => new ReplacementState(rs)), 'date').reverse()[0] : null; // SE ORDENA AL RECIBIR LOS DATOS YA QUE FALLA EL ORDENADO - BUG 41932
        this._replacementListRelatedList = data.replacementListRelated && data.replacementListRelated.length > 0 ? data.replacementListRelated.map(replacementList => new ReplacementListRelated(replacementList)) : null;
        this._lastReplacementContact = data.lastContact ?  new InscriptionContact(data.lastContact) : null;
        this._replacementContracts = data.replacementContracts && data.replacementContracts.length > 0 ? data.replacementContracts.map(contract => new Contract(contract)) : [];
        this.calcWorkingDayType();
    }

    public get order() {
        return this._order;
    }
    public set order(order: number) {
        this._order = order;
    }

    public get processId() {
        return this._processId;
    }
    public set processId(processId: string) {
        this._processId = processId;
    }

    public get hasDisability() {
        return this._hasDisability;
    }
    public set hasDisability(hasDisability: boolean) {
        this._hasDisability = hasDisability;
    }

    public get replacementStatesDocument() {
        return this._replacementStatesDocument;
    }
    public set replacementStatesDocument(replacementStatesDocument: boolean) {
        this._replacementStatesDocument = replacementStatesDocument;
    }

    public get degree() {
        return this._degree;
    }
    public set degree(degree: number) {
        this._degree = degree;
    }

    public get totalScore() {
        return this._totalScore;
    }
    public set totalScore(totalScore: number) {
        this._totalScore = totalScore;
    }

    public get gradeScore() {
        return this._gradeScore;
    }
    public set gradeScore(gradeScore: number) {
        this._gradeScore = gradeScore;
    }

    public get scaleScore() {
        return this._scaleScore;
    }
    public set scaleScore(scaleScore: number) {
        this._scaleScore = scaleScore;
    }

    public get daysWorked() {
        return this._daysWorked;
    }
    public set daysWorked(daysWorked: number) {
        this._daysWorked = daysWorked;
    }

    public get lastWorkingPercentage() {
        return this._lastWorkingPercentage;
    }
    public set lastWorkingPercentage(value: number) {
        this._lastWorkingPercentage = value;
    }

    public get replacementStatus() {
        return this._replacementStatus;
    }

    public set replacementStatus(replacementStatus: number) {
        this._replacementStatus = replacementStatus;
    }

    public get round() {
        return this._round;
    }
    public set round(round: number) {
        this._round = round;
    }

    public get workingDayType() {
        return this._workingDayType;
    }

    public get moveInscription() {
        return this._moveInscription;
    }
    public set moveInscription(value: boolean) {
        this._moveInscription = value;
    }

    public get isSelected() {
        return this._isSelected;
    }
    public set isSelected(value: boolean) {
        this._isSelected = value;
    }

    public set processType(processType: number) {
        this._processType = processType;
    }

    public get processType() {
        return this._processType;
    }

    public set tieType(tieType: number) {
        this._tieType = tieType;
    }

    public get tieType() {
        return this._tieType;
    }

    public get approvedExams() {
        return this._approvedExams;
    }
    public set approvedExams(approvedExams: number | null) {
        this._approvedExams = approvedExams;
    }

    public get lastReplacementContract() {
        return this._lastReplacementContract;
    }

    public get lastReplacementState() {
        return this._lastReplacementState;
    }

    public get replacementListRelatedList() {
        return this._replacementListRelatedList;
    }

    public get lastReplacementContact() {
        return this._lastReplacementContact;
    }

    public get replacementContracts() {
        return this._replacementContracts;
    }
    public set replacementContracts(replacementContracts: Contract[]) {
        this._replacementContracts = replacementContracts;
    }
    
    public get replacementListCandidateId() {
        return this._replacementListCandidateId;
    }
    public set replacementListCandidateId(replacementListCandidateId: string) {
        this._replacementListCandidateId = replacementListCandidateId;
    }
    
    public canReceiveJobProposalsWhileIsWorkingOrBusy() {
        const isReplacementStatusWorkingOrBusy = this.replacementStatus === StatusReplacement.enum.WORKING || this.replacementStatus === StatusReplacement.enum.BUSY;
        const isSendJobPositionsActiveOnLasContract = this.replacementContracts.length > 0 && this.replacementContracts[0].sendJobPositions;

        return isReplacementStatusWorkingOrBusy && isSendJobPositionsActiveOnLasContract;
    }

    public calcWorkingDayType() {
        this._workingDayType = this.lastWorkingPercentage === 100 ? WorkingDayType.enum.FULL : WorkingDayType.enum.PARTTIME;
    }
}
