import Enum from '../interfaces/IEnum';

export const StatusDocuments: Enum = {
    translations: {
        0: 'lang.status.admitted',
        1: 'lang.status.rejectedPartial',
        2: 'lang.status.rejectedTotal'
    },
    enum: {
        ADMITTED: 0,
        REJECTEDPARTIAL: 1,
        REJECTEDTOTAL: 2
    }
};
