import { CheckboxFilterElement, DatepickerFilterElement, FilterElement, InputFilterElement } from '@/Domain/Entities';
import { FilterTypes } from '@/Domain/enum';
import _ from 'lodash';

export const ListManagementListStore = {
    namespaced: true,
    state: {
        filters: [
            new InputFilterElement({
                type: FilterTypes.INPUT,
                label: 'lang.listManagement.searchProcess',
                param: ['title'],
                icon: 'fa-search'
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.process',
                param: 'type',
                options: [{ value: 0, label: 'lang.layout.calls', checked: false }, { value: 1, label: 'lang.layout.bags', checked: false }, { value: 2, label: 'lang.layout.postProvision', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.processType',
                param: 'oppositionType',
                options: [{ value: 0, label: 'lang.shared.contest', checked: false }, { value: 1, label: 'lang.shared.oppositionContest', checked: false }, { value: 2, label: 'lang.shared.opposition', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.evaluationPhase',
                param: 'phase',
                options: [{ value: 0, label: 'lang.shared.inscriptionsPhase', checked: false }, { value: 1, label: 'lang.shared.evaluationPhaseFilter', checked: false }, { value: 2, label: 'lang.shared.rankingPhase', checked: false }, { value: 3, label: 'lang.shared.finished', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.visible',
                param: 'status',
                options: [{ value: 0, label: 'lang.shared.hidden', checked: false }, { value: 1, label: 'lang.shared.visible', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.entity',
                param: 'entityOepId',
                options: [],
                metadata: 'entities'
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.listManagement.inscriptionPeriod',
                param: 'inscriptionPeriodOpen',
                options: [{ value: true, label: 'lang.shared.open', checked: false }, { value: false, label: 'lang.shared.close', checked: false }]
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.listManagement.claimPeriod',
                param: 'claimsPeriodOpen',
                options: [{ value: true, label: 'lang.shared.open', checked: false }, { value: false, label: 'lang.shared.close', checked: false }]
            }),
            new DatepickerFilterElement(
                {
                    type: FilterTypes.DATE,
                    label: 'lang.listManagement.dateRef',
                    param: 'bopDate',
                    icon: 'fa-calendar-alt',
                    start: null,
                    end: null
                }
            )
        ]
    },
    mutations: {
        setFilters(state, filters: FilterElement[]) {
            state.filters = filters;
        }
    },
    getters: {
        getFilters(state) {
            return state.filters;
        }
    },
    actions: {
        setEntities({ commit, state }, entities: Array<{id: string, name: string}>) {
            const entitiesFilterIndex = state.filters.findIndex(filterElement => filterElement.param === 'entityOepId');
            if (state.filters[entitiesFilterIndex].options.length) {
                return;
            }
            const filters = _.cloneDeep(state.filters);
            const checkboxOptions = entities.map(entitie => {
                return { value: entitie.id, label: entitie.name, checked: false };
            });
            filters[entitiesFilterIndex] = new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.shared.entity',
                param: 'entityOepId',
                options: checkboxOptions,
                metadata: 'entities'
            });

            commit('setFilters', filters);
        }
    }
};
