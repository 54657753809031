import Enum from '../interfaces/IEnum';

export const StatusExam: Enum = {
    translations: {
        0: 'lang.status.pending',
        1: 'lang.status.inProcess',
        2: 'lang.status.graded'
    },
    enum: {
        PENDING: 0,
        INPROCESS: 1,
        GRADED: 2
    }
};
