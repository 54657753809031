import { CheckboxFilterElement, FilterElement, InputFilterElement } from '@/Domain/Entities';
import { FilterTypes } from '@/Domain/enum';

export const CourtListStore = {
    namespaced: true,
    state: {
        filters: [
            new InputFilterElement({
                type: FilterTypes.INPUT,
                param: ['fullName', 'nif', 'email'],
                label: 'lang.shared.searchTypes',
                icon: 'fa-search'
            }),
            new CheckboxFilterElement({
                type: FilterTypes.CHECKBOX,
                label: 'lang.court.profileLocked',
                param: 'isBlocked',
                options: [{ value: false, label: 'lang.shared.no', checked: false }, { value: true, label: 'lang.shared.yes', checked: false }]
            })
        ]
    },
    mutations: {
        setFilters(state, filters: FilterElement[]) {
            state.filters = filters;
        }
    },
    getters: {
        getFilters(state) {
            return state.filters;
        }
    }
};
