import ValidationService from '@/Application/Services/ValidationService';
import moment from 'moment';

export default class PersonalDataDetails {
    private _name: string;
    private _firstSurname: string;
    private _secondSurname: string;
    private _nif: string;
    private _nationality: string;
    private _nationalityCode: number;
    private _gender: number;
    private _dateOfBirth: moment.Moment;
    private _applyPersonalDataChangesToPreviousInscription: boolean = false;

    public constructor(data: any) {
        this._name = data.name ? data.name : '';
        this._firstSurname = data.firstSurname ? data.firstSurname  : '';
        this._secondSurname = data.secondSurname ? data.secondSurname : '';
        this._nif = data.nif ? data.nif : '';
        this._nationality = data.nationality ? data.nationality : '';
        this._nationalityCode = data.nationalityCode ? data.nationalityCode : null;
        this._gender = data.gender !== null && data.gender !== undefined ? data.gender : null;
        this._dateOfBirth = data.dateOfBirth ? data.dateOfBirth : null; 
    }

    public get name() {
        return this._name;
    }

    public set name(newName: string) {
        this._name = newName;
    }
    
    public get firstSurname() {
        return this._firstSurname;
    }

    public set firstSurname(newFirstSurname: string) {
        this._firstSurname = newFirstSurname;
    }

    public get secondSurname() {
        return this._secondSurname;
    }

    public set secondSurname(newSecondSurname: string) {
        this._secondSurname = newSecondSurname;
    }

    public get nif() {
        return this._nif;
    }

    public set nif(newNif: string) {
        this._nif = newNif;
    }

    public get nationality() {
        return this._nationality;
    }

    public set nationality(newNationality: string) {
        this._nationality = newNationality;
    }

    public get nationalityCode() {
        return this._nationalityCode;
    }

    public set nationalityCode(newNationalityCode: number) {
        this._nationalityCode = newNationalityCode;
    }

    public get gender() {
        return this._gender;
    }

    public set gender(newGender: number) {
        this._gender = newGender;
    }

    public get dateOfBirth() {
        return this._dateOfBirth;
    }

    public set dateOfBirth(newDateOfBirth: moment.Moment) {
        this._dateOfBirth = newDateOfBirth;
    }

    public get applyPersonalDataChangesToPreviousInscription() {
        return this._applyPersonalDataChangesToPreviousInscription;
    }

    public set applyPersonalDataChangesToPreviousInscription(newApplyPersonalDataChangesToPreviousInscription: boolean) {
        this._applyPersonalDataChangesToPreviousInscription = newApplyPersonalDataChangesToPreviousInscription;
    }

    public toServer(inscriptionId) {
        return {
            inscriptionId,
            PersonalDataDetails: {
                name: this._name,
                firstSurname: this._firstSurname,
                secondSurname: this._secondSurname,
                nif: this._nif,
                nationality: this._nationality,
                nationalityCode: this._nationalityCode,
                gender: this._gender,
                dateOfBirth: this._dateOfBirth
            },
            applyPersonalDataChangesToPreviousInscription: this._applyPersonalDataChangesToPreviousInscription
        };
    }

    public toServerInscription() {
        return {
            name: this._name,
            firstSurname: this._firstSurname,
            secondSurname: this._secondSurname,
            nif: this._nif,
            nationality: this._nationality,
            nationalityCode: this._nationalityCode,
            gender: this._gender,
            dateOfBirth: this._dateOfBirth
        };
    }
    
    public isValid() {
        const empty = '';
        return this._name !== empty && this._firstSurname !== empty 
            && (ValidationService.isValidNif(this.nif) || ValidationService.isValidNie(this.nif)) 
            && this._gender !== null && this._gender !== undefined && moment(this._dateOfBirth).isValid();
    }
}
