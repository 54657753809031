import { Component, Prop, Vue } from 'vue-property-decorator';
import { DoughnutChart } from '@/Components';
import { Constants, PaymentsPlanTypes } from '@/Domain/enum';
import * as sdPanel from 'saviafront/lib/js/compiled/sd-panel';
import moment from 'moment';

@Component({
    components: {
        DoughnutChart
    }
})

export default class BoxLabel extends Vue {
    @Prop() label: any;
    @Prop() planType: any;
    @Prop() selectYears: any;
    @Prop() value: any;

    paymentsPlanTypes = PaymentsPlanTypes;
    constants = Constants;

    yearSelectedProcess: number = moment().year();
    yearsAll: number[] = [];

    $refs!: {
        panel: HTMLFormElement
    };

    generateYears() {
        for (let i = Constants.process.YEAR_INITIAL_CONVOCA; i <= this.yearSelectedProcess; i++) {
            this.yearsAll.push(i);
        }
        this.yearsAll.reverse();
    }

    setYearSelected(yearSelected: number) {
        this.yearSelectedProcess = yearSelected;
        this.$emit('yearChanged', this.yearSelectedProcess);
    }

    mounted() {
        if (this.selectYears) {
            this.generateYears();
            this.$nextTick(() => {
                sdPanel.initialize(this.$refs.panel);
            });
        }
    }
}
