export default class PayExemptionFeeAmount {
    private _payExemptionType: number;
    private _amount: number | null;

    public constructor(data: any) {
        this._payExemptionType = data.payExemptionType;
        this._amount = data.amount;
    }

    public get payExemptionType() {
        return this._payExemptionType;
    }

    public get amount() {
        return this._amount;
    }
}
