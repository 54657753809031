import { MultiLanguage, ChartProgression, ChartData } from '@/Domain/Entities';
import { OppositionType } from '@/Domain/enum';
import moment from 'moment';

export default class ChartProcessesEvaluation {
    private _id: string;
    private _title: MultiLanguage;
    private _type: number;
    private _phase: number;
    private _chartEvaluation: ChartData[];
    private _bopDate: moment.Moment | null;
    private _oppositionType: number;

    public constructor(data: any) {
        this._id = data.id;
        this._title = new MultiLanguage(data.title);
        this._type = data.type;
        this._phase = data.phase;
        this._bopDate = data.bopDate ? moment.utc(data.bopDate) : null;
        this._oppositionType = data.oppositionType;
        
        this._chartEvaluation = [];
        if (this.oppositionType === OppositionType.enum.NOMINATION || this.oppositionType === OppositionType.enum.OPPOSITION) {
            const finished = data.gradedExams;
            const pending = data.totalExams - data.gradedExams;
            const chartData = new ChartProgression(finished, pending);
            chartData.legend = ['lang.shared.examsCorrected'];
            this.chartEvaluation.push(chartData);
        }
        if (this.oppositionType === OppositionType.enum.TENDER || this.oppositionType === OppositionType.enum.OPPOSITION) {
            const finished = data.scaleInscriptions.noEvaluableInscriptions + data.scaleInscriptions.scaleInscriptions;
            const pending = data.scaleInscriptions.pendingInscriptions;
            const chartData = new ChartProgression(finished, pending);
            chartData.legend = ['lang.shared.applicantScaling'];
            this.chartEvaluation.push(chartData);
        }
    }
    
    public get id() {
        return this._id;
    }
    
    public get title() {
        return this._title;
    }
    
    public get type() {
        return this._type;
    }
    
    public get phase() {
        return this._phase;
    }
    
    public get chartEvaluation() {
        return this._chartEvaluation;
    }
    
    public get bopDate() {
        return this._bopDate;
    }
    
    public get oppositionType() {
        return this._oppositionType;
    }
}
