import FilterElement from './FilterElement';

interface CheckBoxOption {
    value: string; // 0
    label: string; // 1
    checked: boolean; // 2
}

export default class CheckboxFilterElement extends FilterElement {
    private _options: CheckBoxOption[];

    constructor(data: any, metadata?: any) {
        super(data);
        if (data.metadata && metadata) {
            data.options = metadata[data.metadata].map(element => ({ value: element.id, label: element.name, checked: false }));
        }
        this._options = data.options;
    }

    public get options() {
        return this._options;
    }

    public get hasFilterValue(): boolean {
        return this._options.some(option => option.checked);
    }
    public reset() {
        this._options.forEach(option => option.checked = false);
    }

    public showElement(element: any): boolean {
        if (!this._options.some(option => option.checked)) { return true; }
        return this._options.some(option => option.checked && option.value === element[this.param]);
    }
}
