import Enum from '../interfaces/IEnum';

export const InscriptionTurn: Enum = {
    translations: {
        0: 'lang.shared.free',
        1: 'lang.shared.freeDisability',
        2: 'lang.shared.internal',
        3: 'lang.shared.internalDisability',
        4: 'lang.shared.mobility',
        5: 'lang.shared.freeDesignation',
        6: 'lang.shared.freeDesignationDisability'
    },
    enum: {
        FREE: 0,
        FREE_DISABILITY: 1,
        INTERNAL: 2,
        INTERNAL_DISABILITY: 3,
        MOBILITY: 4,
        FREE_DESIGNATION: 5,
        FREE_DESIGNATION_DISABILITY: 6
    }
};
