import Enum from '../interfaces/IEnum';

export const InscriptionModules: Enum = {
    enum: {
        NONE: 0,
        PERSONALDATA: 1,
        ADDRESS: 2,
        CONTACTDATA: 3,
        DAYOFREGISTRY: 4,
        AUTHORIZATION: 5,
        JUSTIFY: 6,
        TURNDISABILITY: 7,
        PAYEXEMPTION: 8,
        LANGUAGEEXAMS: 9
    }
};
