import { Security } from '@/Domain/Entities';

export default class ClientInfo {

    public static getInstance(data?) {
        if (!ClientInfo._instance) {
            ClientInfo._instance = new ClientInfo(data);
        }
        return ClientInfo._instance;
    }

    private static _instance: ClientInfo;

    private _clientId: string;
    private _emailSender: string;
    private _host: string;
    private _image: string;
    private _name: string;
    private _title: string;
    private _replacementListsVisible: boolean;
    private _cif: string;
    private _address: string;
    private _phoneNumber: number;
    private _email: string;
    private _dpoEmail: string;
    private _urlElectronicHeadquarters: string;
    private _hasRegistryApi: boolean;
    private _paymentPlanSelected: number;
    private _security: Security;

    constructor(data?) {
        this._clientId = data.clientId;
        this._emailSender = data.emailSender;
        this._host = data.host;
        this._image = data.image;
        this._name = data.name;
        this._title = data.title;
        this._replacementListsVisible = data.replacementListsVisible;
        this._cif = data.cif;
        this._address = data.address;
        this._phoneNumber = data.phoneNumber;
        this._email = data.email;
        this._dpoEmail = data.dpoEmail;
        this._urlElectronicHeadquarters = data.urlElectronicHeadquarters;
        this._hasRegistryApi = data.hasRegistryApi;
        this._paymentPlanSelected = 0;
        this._security = new Security(data.security);
        ClientInfo._instance = this;
    }

    public getClientId(): string {
        return this._clientId;
    }

    public getEmailSender(): string {
        return this._emailSender;
    }

    public getHost(): string {
        return this._host;
    }

    public getImage(): string {
        return this._image;
    }

    public getName(): string {
        return this._name;
    }

    public getTitle(): string {
        return this._title;
    }
    
    public getCif(): string {
        return this._cif;
    }
    
    public getAddress(): string {
        return this._address;
    }
    
    public getPhoneNumber(): number {
        return this._phoneNumber;
    }

    public getEmail(): string {
        return this._email;
    }

    public getDPOEmail(): string {
        return this._dpoEmail;
    }

    public getUrlElectronicHeadquarters(): string {
        return this._urlElectronicHeadquarters;
    }

    public getHasRegistryApi(): boolean {
        return this._hasRegistryApi;
    }
      
    public getReplacementListsVisible(): boolean {
        return this._replacementListsVisible;
    }
    
    public getPaymentPlanSelected(): number {
        return this._paymentPlanSelected;
    }
    
    public getSecurity(): Security {
        return this._security;
    }

    public setReplacementListsVisible(value: boolean): void {
        this._replacementListsVisible = value;
    }

    public setPaymentPlanSelected(value: number): void {
        this._paymentPlanSelected = value;
    }
}
