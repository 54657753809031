import { Component } from 'vue-property-decorator';
import SdInputBase from '../SdInputBase';

@Component({
    computed: {
        isInvalid() {
            return this.$props.value && this.$data.dirty && !this.$data.focused && !(this as SdInputNif).isValidNif && !(this as SdInputNif).isValidNie;
        },
        isValidNif() {
            if (!this.$props.value) {
                return false;
            }
            const nif = this.$props.value.toUpperCase();
    
            // Basic format test
            if (!nif.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
                return false;
            }
    
            // Test NIF
            if (/^[0-9]{8}[A-Z]{1}$/.test(nif)) {
                return ('TRWAGMYFPDXBNJZSQVHLCKE'.charAt(nif.substring(8, 0) % 23) === nif.charAt(8));
            }
            // Test specials NIF (starts with K, L or M)
            if (/^[KLM]{1}/.test(nif)) {
                return (nif[8] === String.fromCharCode(64));
            }
    
            return false;
        },
        isValidNie() {
            if (!this.$props.value) {
                return false;
            }
            const nie = this.$props.value.toUpperCase();
    
            // Basic format test
            if (!nie.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
                return false;
            }
    
            // Test NIE
            // T
            if (/^[T]{1}/.test(nie)) {
                return (nie[8] === /^[T]{1}[A-Z0-9]{8}$/.test(nie));
            }
    
            // XYZ
            if (/^[XYZ]{1}/.test(nie)) {
                return (
                    nie[8] === 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(
                        nie.replace('X', '0')
                            .replace('Y', '1')
                            .replace('Z', '2')
                            .substring(0, 8) % 23
                    )
                );
            }
    
            return false;
        }
    
    }
})
export default class SdInputNif extends SdInputBase {
    isValidNif!: boolean;
    isValidNie!: boolean;

    onKeyUp() {
        this.$emit('keyup', [this.isValidNif, this.isValidNie]);
    }
}
