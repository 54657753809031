import Vue from 'vue';
import VueI18n from 'vue-i18n';
import eu from '../lang/locals/eu_ES.json';
import es from '../lang/locals/es_ES.json';
import ca from '../lang/locals/ca_ES.json';
import gl from '../lang/locals/gl_ES.json';
import va from '../lang/locals/va_ES.json';

Vue.use(VueI18n);

export default new VueI18n({
  silentTranslationWarn: true,
  messages: {
    'eu-ES': {
      lang: eu
    },
    'es-ES': {
      lang: es
    },
    'ca-ES': {
      lang: ca
    },
    'gl-ES': {
      lang: gl
    },
    'pt-PT': {
      lang: va
    }
  }
});
