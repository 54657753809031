import { ProcessConfig } from '@/Domain/Entities';

export default class ProcessConfigExam extends ProcessConfig {
    constructor(data: any) {
        super(data);
        this.type = data.configurationExamType;
    }

    public toServer() {
        return {
            id: this.id,
            examType: this.type,
            title: this.title
        };
    }
}
