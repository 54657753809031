
import { ReplacementListTabs } from '@/Domain/enum';
import { TabsList, Tab, TabCalification, TabHashInterface } from '@/Domain/Entities';

export default class TabsHash implements TabHashInterface {
    selectElementWithRouteHash(elementActive, arrayToManage, list, $route, page) {
        elementActive = ReplacementListTabs.name[$route.hash];
        const index = page === 'ListReplacementList' ? arrayToManage.turns.indexOf(elementActive) : arrayToManage.indexOf(arrayToManage.find(e => e.type === elementActive));
        list = page === 'ListReplacementList' ? new TabsList(Tab, arrayToManage.turns, index) : new TabsList(TabCalification, arrayToManage, index);
        return {list, elementActive};
    }

    selectElementWithParamsHash(elementActive, arrayToManage, list, $route, $router, page) {
        elementActive = ReplacementListTabs.name[$route.params.hash];
        $router.push({ name: page, hash: $route.params.hash });
        const index = page === 'ListReplacementList' ? arrayToManage.turns.indexOf(elementActive) : arrayToManage.indexOf(arrayToManage.find(e => e.type === elementActive));
        list = page === 'ListReplacementList' ? new TabsList(Tab, arrayToManage.turns, index) : new TabsList(TabCalification, arrayToManage, index);
        return {list, elementActive};
    }

    selectElementWithoutHash(elementActive, arrayToManage, list, $router, page) {
        const index = page === 'ListReplacementList' ? arrayToManage.turns.indexOf(arrayToManage.turns[0]) : arrayToManage.indexOf(arrayToManage[0]);
        list = page === 'ListReplacementList' ? new TabsList(Tab, arrayToManage.turns, index) : new TabsList(TabCalification, arrayToManage, index);
        elementActive = page === 'ListReplacementList' ? (list.activeTab as Tab).type : list.activeTab;
        page === 'ListReplacementList' ? $router.push({ name: page, hash: ReplacementListTabs.enum[(list.activeTab as Tab).type] }) : $router.push({ name: page, hash: ReplacementListTabs.enum[elementActive.type] });
        return {list, elementActive};
    }

}
