import { ChartData } from '@/Domain/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DoughnutChart } from '@/Components';

// interface CardProgressionChartIcon {
//     iconClass: string;
//     colorClass: string;
//     hoverTrigger: boolean;
// }

@Component({
    components: {
        DoughnutChart
    }
})

export default class CardProgressionChart extends Vue {
    @Prop() title!: string;
    @Prop() subTitle!: string;
    @Prop() noDataText!: string;
    @Prop() noDataIcon!: string;
    @Prop() data!: ChartData[];
    // @Prop() disabled!: boolean;
    // @Prop() icon!: CardProgressionChartIcon;

    // $refs!: {
    //     wrapper: HTMLElement,
    //     icon: HTMLElement
    // };

    // onMouseOverTitle() {
    //     if (!this.icon || !this.icon.hoverTrigger) {
    //         return;
    //     }
    //     this.$emit('onMouseOverTitle', { wrapper: this.$refs.wrapper, trigger: this.$refs.icon });
    // }

    // onMouseLeaveTitle() {
    //     if (!this.icon || !this.icon.hoverTrigger) {
    //         return;
    //     }
    //     this.$emit('onMouseLeaveTitle', { wrapper: this.$refs.wrapper});
    // }
}
