import Enum from '../interfaces/IEnum';

export const TabsStatusScaling: Enum = {
    enum: {
        DISABLED: 0,
        DEFAULT: 1,
        SUCCESS: 2,
        ERROR: 3
    }
};
