export default class ConsecutiveInscription {
    private _isConsecutive: boolean;
    private _isFirstIndexSelected: boolean;
    private _isAnySelected: boolean;

    public constructor() {
        this._isConsecutive = true;
        this._isFirstIndexSelected = false;
        this._isAnySelected = false;
    }

    public get isConsecutive() {
        return this._isConsecutive;
    }
    public set isConsecutive(isConsecutive: boolean) {
        this._isConsecutive = isConsecutive;
    }

    public get isFirstIndexSelected() {
        return this._isFirstIndexSelected;
    }
    public set isFirstIndexSelected(isFirstIndexSelected: boolean) {
        this._isFirstIndexSelected = isFirstIndexSelected;
    }
    
    public get isAnySelected() {
        return this._isAnySelected;
    }
    public set isAnySelected(isAnySelected: boolean) {
        this._isAnySelected = isAnySelected;
    }
}
