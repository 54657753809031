import { Component, Vue } from 'vue-property-decorator';
import { CourtUser, Process, Filter, SorterNew, FilterElement } from '@/Domain/Entities';
import CourtService from '@/Services/CourtService';
import * as dropdown from 'saviafront/lib/js/compiled/dropdown';
import { OppositionType, ProcessPhases, ProcessType, GroupTypes } from '@/Domain/enum';
import { mapGetters, mapMutations, mapState } from 'vuex';
import ProcessesService from '@/Services/ProcessesService';
import { SdFilter } from '@/Components';

@Component({
    components: {
        SdFilter
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        ...mapGetters('courtListStore', { filters: 'getFilters' }),
        orderedProcess() {
            return (this as CourtList).sorter.sortBy((this as CourtList).filter.filteredData);
        }
    },
    methods: {
        ...mapMutations('courtListStore', ['setFilters'])
    },
    watch: {
        filter() {
            (this as CourtList).setFilters((this as CourtList).filter.filterElements);
        }
    }
})
export default class CourtList extends Vue {
    dateFormat!: string;
    filters!: FilterElement[];

    filter = new Filter();
    sorter = new SorterNew(['fullName'], ['asc']);
    loading: boolean = false;
    courtUsers: CourtUser[] = [];
    processList: Process[] = [];
    OppositionType = OppositionType;
    ProcessType = ProcessType;
    ProcessPhases = ProcessPhases;
    GroupTypes = GroupTypes;
    showModal: boolean = false;
    courtUserModal: CourtUser = new CourtUser({});
    processListTyped: string = '';

    setFilters!: (arg1: FilterElement[]) => void;

    $refs!: {
        dropdownCourtUser: HTMLElement
    };

    goToCreateCourt() {
        this.$router.push({
            name: 'CourtNew'
        });
    }

    openModal(courtUser: CourtUser, processListType: string) {
        this.courtUserModal = courtUser;
        this.processListTyped = processListType;
        if (!this.courtUserModal[this.processListTyped].length) {
            return;
        }
        this.showModal = true;
    }

    closeModal() {
        this.showModal = false;
    }

    sortBy(key: string) {
        this.sorter.updateKeys([key]);
    }

    getProcessList() {
        ProcessesService.getProcessList()
            .then(response => {
                this.processList = response.map((process: Process) => new Process(process, undefined, this.$store.state.processConfigStore.processConfigGroups));
                this.getCourtList();
            })
            .catch(error => console.log(error));
    }

    getCourtList() {
        CourtService.getCourtList().then(response => {
            this.courtUsers = response.map(courtUser => new CourtUser(courtUser));
            this.courtUsers.forEach(element => {
                element.processIdToProcessList(this.processList);
            });
            this.filter.setData(this.courtUsers);          
            this.filter.setFilterElements(this.filters);
            this.loading = false;
            this.$nextTick(() => {
                dropdown.initialize(this.$refs.dropdownCourtUser);
            });
        })
        .catch(() => this.loading = false);
    }

    created() {
        this.loading = true;
        this.getProcessList();
    }
}
