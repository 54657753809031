import moment from 'moment';

export default class NoticeHistory {
    private _id: string;
    private _processId: string;
    private _documentId: string;
    private _receiverType: number;
    private _totalCandidates: number;
    private _examId: string | null;
    private _sendDate: moment.Moment;

    public constructor(data: any) {
        this._id = data.id;
        this._processId = data.processId;
        this._documentId = data.documentId;
        this._receiverType = data.filterNoticeDocument;
        this._totalCandidates = data.totalCandidates;
        this._examId = data.examId;
        this._sendDate = moment.utc(data.sendDate).local();
    }

    public get id() {
        return this._id;
    }

    public get processId() {
        return this._processId;
    }

    public get documentId() {
        return this._documentId;
    }

    public get receiverType() {
        return this._receiverType;
    }
    
    public get totalCandidates() {
        return this._totalCandidates;
    }

    public get examId() {
        return this._examId;
    }
    
    public get sendDate() {
        return this._sendDate;
    }
}
