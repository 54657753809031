import { Component, Vue } from 'vue-property-decorator';
import { CourtUser, Process } from '@/Domain/Entities';
import CourtService from '@/Services/CourtService';
import { ToasterService } from '@/Services/ToasterService';
import { OppositionType, ProcessType, ProcessPhases, GroupTypes } from '@/Domain/enum';
import i18n from '../../lang';
import _ from 'lodash';
import { Breadcrumb, Toggle, SdInputMail } from '@/Components';
import { mapState } from 'vuex';
import ProcessesService from '@/Services/ProcessesService';

@Component({
    components: {
        SdInputMail,
        Toggle,
        Breadcrumb
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        orderedProcesses() {
            return _.orderBy((this as CourtEdit).processList, [process => _.deburr(process.title[(this as CourtEdit).currentLanguage]).toLowerCase()]);
        }
    }
})

export default class CourtEdit extends Vue {
    currentLanguage!: string;
    dateFormat!: string;

    courtUser: CourtUser = new CourtUser({});
    isEditing: boolean = false;
    processList: Process[] = [];
    waitingResponse: boolean = false;
    submitted: boolean = false;
    showSelectProcessError = false;
    OppositionType = OppositionType;
    ProcessType = ProcessType;
    ProcessPhases = ProcessPhases;
    GroupTypes = GroupTypes;

    $refs!: {
        CourtForm: HTMLFormElement
    };

    setProcessIds() {
        this.courtUser.resetProcessIds();
        this.processList.forEach(process => {
            if (process.active) {
                this.courtUser.processIds.push(process.id);
            }
        });
    }

    save() {
        this.submitted = true;
        if (this.waitingResponse) { return; }
        if (this.$refs.CourtForm.querySelectorAll(':invalid').length || this.$refs.CourtForm.querySelectorAll('.error').length) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        this.setProcessIds();
        if (!this.courtUser.validateProcessSelected()) {
            this.showSelectProcessError = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        const action = this.isEditing ? CourtService.updateCourt : CourtService.addCourt;
        this.showSelectProcessError = false;
        this.waitingResponse = true;
        const objectToSend = this.courtUser.toServer();
        action(objectToSend).then(() => {
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            this.waitingResponse = false;

            if (!this.isEditing) {
                this.$router.push({ name: 'CourtList'});
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .catch(() =>
            this.waitingResponse = false
        );
    }

    getCourtById() {
        CourtService.getCourtById(this.$route.params.id)
            .then(response => {
                this.courtUser = new CourtUser(response);
                this.getProcessList();
            }).catch(error => console.log(error));
    }

    getProcessList() {
        ProcessesService.getProcessList()
            .then(response => {
                this.processList = response.reduce((result, process: Process) => {
                    if (this.courtUser.processIds.length) {
                        this.courtUser.setActiveProcess(process);
                    }
                    if (!this.isEditing && process.phase !== ProcessPhases.enum.FINISH) {
                        result.push(new Process(process, undefined, this.$store.state.processConfigStore.processConfigGroups));
                    }
                    if (this.isEditing && ((process.phase === ProcessPhases.enum.FINISH && process.active) || process.phase !== ProcessPhases.enum.FINISH)) {
                        result.push(new Process(process, undefined, this.$store.state.processConfigStore.processConfigGroups));
                    }
                    return result;
                }, []);
            })
            .catch(error => console.log(error));
    }

    created() {
        if (this.$route.params.id) {
            this.isEditing = true;
            this.getCourtById();
        } else {
            this.getProcessList();            
        }
    }
}
