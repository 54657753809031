import MultiLanguage from '../MultiLanguage';

export default class CandidateProcess {
    private _inscriptionId: string;
    private _processId: string;
    private _title: MultiLanguage;
    private _type: number;
    private _phase: number;
    private _oppositionType: number;
    private _inscriptionStatus: number;

    public constructor(data: any) {
        this._inscriptionId = data.inscriptionId;
        this._processId = data.processId;
        this._title = data.title;
        this._type = data.type;
        this._phase = data.phase;
        this._oppositionType = data.oppositionType;
        this._inscriptionStatus = data.inscriptionStatus;
    }

    public get inscriptionId() {
        return this._inscriptionId;
    }

    public get processId() {
        return this._processId;
    }

    public get title() {
        return this._title;
    }

    public get type() {
        return this._type;
    }

    public get phase() {
        return this._phase;
    }

    public get oppositionType() {
        return this._oppositionType;
    }

    public get inscriptionStatus() {
        return this._inscriptionStatus;
    }
}
