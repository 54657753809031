import { Component, Prop, Vue } from 'vue-property-decorator';
import { InscriptionTurn } from '@/Domain/enum';
import { TabCalification } from '@/Domain/Entities';

/**
 * Component that displays information about a shift
 */
@Component
export default class TurnBox extends Vue {
    /** Turn to show */
    @Prop() turn?: TabCalification;
    /** Indicates whether a tiebreaker is needed */
    @Prop() needTieBreak?: boolean;
    InscriptionTurn = InscriptionTurn;

}
