import { Component } from 'vue-property-decorator';
import SdInputBase from '../SdInputBase';
import ValidationService from '@/Application/Services/ValidationService';

@Component({
    computed: {
        isInvalid() {
            return this.$props.value !== '' && this.$data.dirty && !this.$data.focused && !ValidationService.isValidMail(this.$props.value);
        }
    }
})
export default class SdInputMail extends SdInputBase {
}
