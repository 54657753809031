import { Component, Prop, Vue } from 'vue-property-decorator';
import * as imageCompressor from '../../../node_modules/saviafront/lib/js/compiled/imageCompressor';
import { Constants } from '@/Domain/enum';
import moment from 'moment';
import DocumentService from '@/Application/Services/DocumentService';

@Component({
    computed: {
        $error() {
            return this.$data.invalidFileName || this.$data.invalidExtension || this.$data.invalidSize || this.$data.isFileEncrypted || this.$data.isAPortfolioFile || (this.$props.required && this.$data.dirty && !this.$data.haveFile);
        },
        haveFile() {
            return this.$props.value as boolean;
        }
    }
})
export default class FileImporter extends Vue {
    @Prop() value?: any | null; // SI EL COMPONENTE NO TIENE V-MODEL SE COMPORTA COMO MULTI_FILE, SI LO TIENE ES SINGLE-file
    @Prop() required?: string;
    @Prop() disabled?: boolean;
    /** Indicates that the component allow to upload protected pdfs, this is only available on Documents component */
    @Prop() canUploadProtectedPdf!: boolean;
    dragging: boolean = false;
    dirty: boolean = false;
    invalidFileName: boolean = false;
    invalidExtension: boolean = false;
    loadingDocument: boolean = false;
    invalidSize: boolean = false;
    isFileEncrypted: boolean = false;
    isAPortfolioFile: boolean = false;

    $refs!: {
        importer: HTMLFormElement;
    };

    resetProps() {
        this.invalidFileName = false;
        this.invalidExtension = false;
        this.invalidSize = false;
        this.isFileEncrypted = false;
        this.isAPortfolioFile = false;
        this.dirty = false;
    }

    async onFileChange($event: any) {
        if (this.$props.disabled) {
            return;
        }
        let file;
        if ($event.dataTransfer) {
            file = $event.dataTransfer.files[0];
        } else {
            file = this.$refs.importer.files[0];
        }
        this.invalidFileName = Constants.fileImporter.INVALID_FILE_NAME.some((validFormat: string) => file.name.includes(validFormat));
        const fileExtension = '.' + file.name.split('.').pop();
        this.invalidExtension = !Constants.fileImporter.VALID_EXTENSIONS.includes(fileExtension.toLowerCase());
        this.invalidSize = file.size > Constants.fileImporter.MAX_SIZE;
        this.dragging = false;
        if (fileExtension.toLowerCase() === Constants.fileImporter.PDF && !this.canUploadProtectedPdf) {
            this.isFileEncrypted = await DocumentService.isFileEncrypted(file);
            this.isAPortfolioFile = await DocumentService.isPdfAPortfolioFileType(file);
        }
        if (this.isFileInvalid()) {
            $event.target.value = null;
            return;
        }
        this.dirty = true;
        this.loadingDocument = true;
        if (fileExtension.toLowerCase() !== Constants.fileImporter.PDF) {
            file = await imageCompressor.compress(file);
        }
        file.fileDate = moment();
        file.extension = fileExtension;
        $event.target.value = null;
        this.$emit('input', file);
    }

    isFileInvalid() {
        return this.invalidFileName || this.invalidExtension || this.invalidSize || this.isFileEncrypted || this.isAPortfolioFile;
    }
}
