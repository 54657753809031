import { Component, Prop, Vue } from 'vue-property-decorator';
import { StatusExam } from '@/Domain/enum';
import { mapState } from 'vuex';
import { Exam, Grade } from '@/Domain/Entities';

enum ExamCardStatusClasses {
    PENDING = 'exam-card__status--pending',
    INPROCESS = 'exam-card__status--process',
    GRADED = 'exam-card__status--graded'
}

enum ExamCardStatusIcons {
    PENDING = 'fas fa-stop',
    INPROCESS = 'fas fa-play',
    GRADED = 'fas fa-user-alt'
}

@Component({
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' })
    }
})

export default class ExamCard extends Vue {
    currentLanguage!: string;
    passedGrades!: string;
    /**
     * Component Prop, receives an Exam list
     */
    @Prop() exams!: Exam[];
    /**
     * Component Prop, receives an Grade list
     */
    @Prop() grades!: Grade[];

    examCardStatusClasses = Object.values(ExamCardStatusClasses);
    examCardStatusIcons = Object.values(ExamCardStatusIcons);
    statusExam = StatusExam;

    onCardClick(exam: Exam) {
        if (exam.active) {
            return;
        }
        this.updateSelectedExam(exam);
        this.$emit('onCardClick', exam);
    }
    private updateSelectedExam(exam: Exam) {
        if (exam.blocked) {
            return;
        }
        this.exams.forEach(examOnList => examOnList.id === exam.id ? this.$set(examOnList, 'active', true) : this.$set(examOnList, 'active', false));
    }
}
