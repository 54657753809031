import moment from 'moment';

export default class ShippingHistory {
    private _id: string;
    private _replacementListId: string;
    private _replacementListName: string;
    private _sendDate: moment.Moment;
    private _expirationDate: moment.Moment;
    private _candidateNumber: number;
    private _description: string;
    private _positionName: string;
    private _isExpired: boolean;
    private _acceptationPercentage: number;
    private _sended: boolean;
    private _acceptationCount: number;
    private _isCompleted: boolean = false;

    public constructor(data: any) {
        this._id = data.id;
        this._replacementListId = data.replacementListId;
        this._replacementListName = data.replacementListName;
        this._sendDate = moment.utc(data.sendDate).local();
        this._expirationDate = moment.utc(data.expirationDate).local();
        this._candidateNumber = data.candidateNumber;
        this._description = data.description;
        this._positionName = data.positionName;
        this._isExpired = data.isExpired;
        this._acceptationPercentage = data.acceptationPercentage;
        this._sended = data.sended;
        this._acceptationCount = data.acceptationCount;
        this._isCompleted = this._isExpired && this._acceptationPercentage > 0 || this._acceptationPercentage === 100;
    }

    public get id() {
        return this._id;
    }

    public get replacementListId() {
        return this._replacementListId;
    }

    public get replacementListName() {
        return this._replacementListName;
    }

    public get sendDate() {
        return this._sendDate;
    }

    public get expirationDate() {
        return this._expirationDate;
    }

    public get candidateNumber() {
        return this._candidateNumber;
    }

    public get description() {
        return this._description;
    }

    public get positionName() {
        return this._positionName;
    }

    public get isExpired() {
        return this._isExpired;
    }

    public get acceptationPercentage() {
        return this._acceptationPercentage;
    }

    public get sended() {
        return this._sended;
    }

    public get acceptationCount() {
        return this._acceptationCount;
    }

    public get isCompleted() {
        return this._isCompleted;
    }
}
