import Enum from '../interfaces/IEnum';

export const ValidityError: Enum = {
    enum: {
        TYPEMISMATCH: 0,
        RANGEUNDERFLOW: 1,
        RANGEOVERFLOW: 2,
        STEPMISMATCH: 3,
        VALUESMISSING: 4,
        INVALIDDNI: 5,
        STEPMISMATCHWITHSTEP: 6,
        INVALIDPASSWORD: 7,
        INVALIDPHONE: 8,
        INVALIDPOSTCODE: 9,
        INVALIDEMAIL: 10
    }
};
