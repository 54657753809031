import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import { Modal, Toggle, MdInput, MdTextarea, MdSelect, MdDatepicker, FileImporter, SdSlideOver } from '@/Components';
import { Exam, NoticeDocument, NoticeHistory, OepDocument } from '@/Domain/Entities';
import { OepDocumentType, ProcessStatus, Languages, MassiveNoticeDocumentReceiversType, OppositionType, ProcessPhases, Constants } from '@/Domain/enum';
import OepDocumentsService from '@/Services/OepDocumentsService';
import ValidationService from '@/Application/Services/ValidationService';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../lang';
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import ProcessesService from '@/Services/ProcessesService';

@Component({
    components: {
        MdInput,
        MdTextarea,
        MdSelect,
        MdDatepicker,
        Toggle,
        Modal,
        FileImporter
    },
    computed: {
        documentList() {
            return _.orderBy(this.$props.parent.documents, 'publicationDate', 'desc');
        },
        anyCheckRadioInputExams() {
            return (this as Documents).submitted && !(this as Documents).newNoticeDocument.examId;
        },
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat' }),
        ...mapGetters('languagesStore', { availableLenguages: 'getTranslatableLanguages' })
    }
})
export default class Documents extends Vue {
    @Prop() parent!: any;
    @Prop() typed?: boolean;
    currentLanguage!: string;
    dateFormat!: string;
    availableLenguages!: string[];

    waitingResponse: boolean = false;
    loading: boolean = false;
    loadingDocument: boolean = false;
    showDeleteModal: boolean = false;
    invalidStatus: boolean = false;
    submitted: boolean = false;
    editionDocument: any = null;
    deleteDocument: any = {};
    OepDocumentType = OepDocumentType;
    ProcessStatus = ProcessStatus;
    ProcessPhases = ProcessPhases;
    OppositionType = OppositionType;
    Constants = Constants;
    MassiveNoticeDocumentReceiversType = MassiveNoticeDocumentReceiversType;
    Languages = Languages;
    tabActive: string = '';
    currentDate = moment();
    showSlideOver: boolean = false;
    newNoticeDocument: NoticeDocument = new NoticeDocument({});
    examsList: any = [];
    myMassiveNoticeDocumentReceiversType: any = {};

    $refs!: {
        documentForm: HTMLFormElement,
        massiveNoticeDocumentForm: HTMLFormElement,
        sdSlideOver: SdSlideOver
    };

    setAllowDocumentsNotice() {
        ProcessesService.setAllowDocumentsNotice(this.parent.id, this.parent.allowDocumentsNotice)
        .then(() => {
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
        })
        .catch(() => {
            this.parent.allowDocumentsNotice = !this.parent.allowDocumentsNotice;
            ToasterService.showError(i18n.t('lang.toaster.errorGeneralTitle') as string);
        });
    }

    getHistoryNoticeDocument(document: OepDocument) {
        document.isDropped = !document.isDropped;
        if (document.isDropped) {
            ProcessesService.getHistoryNoticeDocument(this.parent.id, document.id)
            .then(response => {
                this.parent.documents.find((doc: OepDocument) => doc.id === document.id).historyNoticeDocumentList = _.orderBy(response.map(el => new NoticeHistory(el)), 'sendDate');
            })
            .catch(error => console.error(error));
        }
    }

    downloadCSVNoticeDocumentHistorial(documentId: string, noticeHistoryId: string) {
        ProcessesService.getCSVNoticeDocumentHistorial(this.parent.id, documentId, noticeHistoryId);
    }

    sendMassiveNoticeDocument() {
        this.submitted = true;

        if (this.newNoticeDocument.receiverType === null || (this.newNoticeDocument.receiverType === this.myMassiveNoticeDocumentReceiversType.enum.EXAMS && !this.newNoticeDocument.examId)) {
            return;
        }

        ProcessesService.sendMassiveNoticeDocument(this.parent.id, this.newNoticeDocument.toServer())
        .then(() => {
            ToasterService.showSuccess(i18n.t('lang.toaster.messageSent') as string);
            this.parent.documents.find((doc: OepDocument) => doc.id === this.newNoticeDocument.documentId).hasNoticeHistory = true;
            this.toggleSlideOver();
            this.$refs.sdSlideOver.setOverflowAutoToBody();
            this.submitted = false;
            this.newNoticeDocument = new NoticeDocument({});
        })
        .catch(error => console.error(error));
    }

    toggleSlideOver(documentId?: string) {
        this.showSlideOver = !this.showSlideOver;
        if (this.parent.isCreatingDocument || this.parent.isEditingDocument) {
           this.cancelNewDocument();
           this.cancelEditDocument();
        }
        if (!this.showSlideOver) {
            this.newNoticeDocument = new NoticeDocument({});
            this.submitted = false;
            return;
        }
        this.newNoticeDocument = new NoticeDocument({documentId});
        this.parent.setDefaultDroppedDocuments();
    }

    submit() {
        (this.$parent as any).submit();
    }

    cancelNewDocument() {
        this.editionDocument = null;
        this.parent.isCreatingDocument = false;
        this.submitted = false;
    }

    cancelEditDocument() {
        this.editionDocument = null;
        this.parent.isEditingDocument = false;
        this.submitted = false;
    }

    newDocument() {
        this.submitted = false;
        this.parent.isCreatingDocument = true;
        this.editionDocument = new OepDocument({ formFile: null });
    }

    editDocument(document: OepDocument) {
        this.submitted = false;
        this.parent.isEditingDocument = true;
        this.editionDocument = _.cloneDeep(document);
        this.editionDocument.url = document.url;
    }
    saveDocument(form) {
        this.submitted = true;

        if (!ValidationService.validateFormRequired(form)) {
            return;
        }
        const fileOfInscription = this.parent.documents.filter((doc: OepDocument) => doc.type === this.OepDocumentType.enum.INSCRIPTION)[0];
        if (fileOfInscription && fileOfInscription.id !== this.editionDocument.id && Number(this.editionDocument.type) === this.OepDocumentType.enum.INSCRIPTION) {
            ToasterService.showError(i18n.t('lang.toaster.noAddModel') as any, i18n.t('lang.toaster.errorGeneralTitle') as any);
            return;
        }

        this.loadingDocument = true;
        const file = this.parent.documents.filter((doc: OepDocument) => doc.id === this.editionDocument.id)[0];
        if (file) {
            this.parent.isSaving = true;
            OepDocumentsService.updateOepDocument(this.editionDocument.toServer(this.parent.url, this.parent.id))
                .then(response => {
                    file.update(response);
                    this.loadingDocument = false;
                    this.editionDocument = null;
                    this.parent.isSaving = false;
                    this.parent.isEditingDocument = false;
                })
                .catch(() => {
                    this.loadingDocument = false;
                    this.parent.isSaving = false;
                });
        } else {
            this.parent.isSaving = true;
            OepDocumentsService.addOepDocument(this.editionDocument.toServer(this.parent.url, this.parent.id))
                .then(response => {
                    this.parent.addDocument(response);
                    this.loadingDocument = false;
                    this.editionDocument = null;
                    this.parent.isSaving = false;
                    this.parent.isCreatingDocument = false;
                })
                .catch(() => {
                    this.loadingDocument = false;
                    this.parent.isSaving = false;
                });
        }
    }
    confirmWithModal(document: OepDocument) {
        if (this.typed && document.hasNoticeHistory) {
            return;
        }
        this.deleteDocument = document;
        this.showDeleteModal = true;
    }
    removeDocument() {
        if (this.waitingResponse) {
            return;
        }
        this.showDeleteModal = false;
        OepDocumentsService.removeOepDocument(this.deleteDocument.toServer(this.parent.url, this.parent.id))
            .then(() => {
                this.parent.removeDocument(this.deleteDocument);
            }).catch(error => console.log(error));

    }
    toggleView(document: OepDocument) {
        OepDocumentsService.toggleViewOepDocument(document.toServer(this.parent.url, this.parent.id))
            .catch(() => document.isVisible = !document.isVisible);
    }

    getDocuments() {
        OepDocumentsService.getOepDocuments(this.parent.url, this.parent.id)
            .then((result: OepDocument[]) => {
                this.parent.documents = [];
                result.forEach((file: any) => {
                    this.parent.addDocument(file);
                });
                if (this.typed) {
                    this.myMassiveNoticeDocumentReceiversType = _.cloneDeep(MassiveNoticeDocumentReceiversType);
                    if (this.parent.phase < ProcessPhases.enum.RANKING) {
                        delete this.myMassiveNoticeDocumentReceiversType.translations[this.myMassiveNoticeDocumentReceiversType.enum.FINALISTS];
                        delete this.myMassiveNoticeDocumentReceiversType.enum.FINALISTS;
                    }
                    if (this.parent.exams.length && this.parent.oppositionType !== OppositionType.enum.TENDER && this.parent.phase > ProcessPhases.enum.INSCRIPTIONS) {
                        this.examsList = this.parent.exams.map((exam: Exam) => ( {id: exam.id, name: exam.description}));
                    } else {
                        delete this.myMassiveNoticeDocumentReceiversType.translations[this.myMassiveNoticeDocumentReceiversType.enum.EXAMS];
                        delete this.myMassiveNoticeDocumentReceiversType.enum.EXAMS;
                    }
                }
                this.loading = false;
            }).catch(error => console.log(error));
    }

    created() {
        this.loading = true;
        this.tabActive = this.currentLanguage;
        this.getDocuments();
    }
}
