import Enum from '../interfaces/IEnum';

export const ProcessType: Enum = {
    translations: {
        0: 'lang.processType.call',
        1: 'lang.processType.bag',
        2: 'lang.processType.postProvision'
    },
    enum: {
        CALL: 0,
        BAG: 1,
        POSTPROVISION: 2
    },
    routeNew: {
        0: 'CallNew',
        1: 'BagNew',
        2: 'PostProvisionNew'
    },
    routeEdit: {
        0: 'CallEdit',
        1: 'BagEdit',
        2: 'PostProvisionEdit'
    },
    urlString: {
        0: 'calls',
        1: 'bags',
        2: 'postProvisions'
    },
    title: {
        0: 'lang.layout.calls',
        1: 'lang.layout.bags',
        2: 'lang.layout.postProvision'
    },
    dashboard: {
        0: 'lang.layout.calls',
        1: 'lang.layout.bags',
        2: 'lang.layout.postProvisions'
    },
    lowerCase: {
        0: 'lang.shared.callLower',
        1: 'lang.shared.bagsLower',
        2: 'lang.processType.postProvision'
    },
    upperCase: {
        0: 'lang.shared.call',
        1: 'lang.shared.placeProcessBags',
        2: 'lang.layout.postProvision'
    },
    icon: {
        0: 'fa-bullhorn',
        1: 'fa-suitcase',
        2: 'fa-users'
    }
};
