import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api';

export default class OepDocumentsService {

    public static async getOepDocuments(parentUrl: string, ownerId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(parentUrl + ownerId + '/files', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addOepDocument(data: any): Promise<any> {
        const formData = new FormData();
        Object.entries(data).forEach(key => formData.append(key[0], (key[1] as any)));
        return new Promise((resolve, reject) => {
            axios.post(data.parentUrl + data.ownerId + '/files', formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateOepDocument(data: any): Promise<any> {
        const formData = new FormData();
        Object.entries(data).forEach(key => formData.append(key[0], (key[1] as any)));
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: max-line-length
            axios.put(data.parentUrl + data.ownerId + '/files/' + data.id, formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async toggleViewOepDocument(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch(data.parentUrl + data.ownerId + '/files/' + data.id, data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeOepDocument(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(data.parentUrl + data.ownerId + '/files/' + data.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
