import TabsHash from './TabsHash';

export default class TabsHashExams extends TabsHash {

    selectElementWithRouteHash(elementActive, arrayToManage, list, $route, page) {
        list = this.setAllExamsInactive(list);
        const examId = $route.hash.substring(1, $route.hash.length);
        elementActive = this.setExamActive(list, examId); 
        return elementActive;
    }

    selectElementWithParamsHash(elementActive, list, $route, $router, page) {
        list = this.setAllExamsInactive(list);
        const examId = $route.params.hash.id;
        elementActive = this.setExamActive(list, examId);
        this.setRouter(page, elementActive, $router); 
        return elementActive; 
    }

    selectElementWithoutHash() {
        return {list: null, elementActive: null};
    }

    selectElementWithoutHashAndWithExamList(elementActive, list, $router, page) {
        elementActive = list.find(exam => exam.active);
        this.setRouter(page, elementActive, $router); 
        return elementActive;
    }

    selectElementWithoutHashAndExamList(elementActive, list, $router, page) {
        list[list.length - 1].active = true;
        elementActive = list[list.length - 1];
        this.setRouter(page, elementActive, $router); 
        return elementActive;
    }

    private setExamActive(list, examId) {
        const exam = list.find(examList => examList.id === examId);
        const indexExam = list.indexOf(exam);
        list[indexExam].active = true;
        return list[indexExam];
    }

    private setAllExamsInactive(list) {
        list.forEach(el => el.active = false);
        return list;
    }

    private setRouter(page, elementActive, $router) {
        $router.push({ name: page, hash: '#' + elementActive.id }); 
    }
}
