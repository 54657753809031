import Enum from '../interfaces/IEnum';

export const MeritRatingType: Enum = {
    translations: {
        0: 'lang.shared.years',
        1: 'lang.shared.fullYears',
        2: 'lang.shared.months',
        3: 'lang.shared.fullMonths',
        4: 'lang.shared.days',
        5: 'lang.shared.units',
        6: 'lang.shared.fixedScore',
        7: 'lang.shared.interview'
    },
    enum: {
        YEARS: 0,
        FULL_YEARS: 1,
        MONTHS: 2,
        FULL_MONTHS: 3,
        DAYS: 4,
        UNITS: 5,
        FIXED_SCORE: 6,
        INTERVIEW: 7
    }
};
