import Enum from '../interfaces/IEnum';

export const StaffType: Enum = {
    translations: {
        0: 'lang.shared.official',
        1: 'lang.shared.labor',
        2: 'lang.shared.civilLabor'
    },
    translationsContract: {
        0: 'lang.shared.official',
        1: 'lang.shared.labor'
    },
    enum: {
        CIVILSERVANT: 0,
        CONTRACT: 1,
        CIVIL_LABOR: 2
    }
};
