import Enum from '../interfaces/IEnum';

export const PaymentsPlanTypes: Enum = {
    plan: {
        0: 'lang.paymentPlan.small',
        1: 'lang.paymentPlan.medium',
        2: 'lang.paymentPlan.large',
        3: 'lang.paymentPlan.custom'
    },
    enum: {
        SMALL: 0,
        MEDIUM: 1,
        LARGE: 2,
        CUSTOM: 3
    }
};
