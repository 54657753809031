export const LanguagesStore = {
    namespaced: true,
    state: {
        availables: [],
        current: ''
    },
    mutations: {
        addAvailableLanguage(state, available: string) {
            state.availables.push(available);
        },
        setCurrentLanguage(state, current: string) {
            state.current = current;
        }
    },
    getters: {
        getTranslatableLanguages(state, getters, rootState, rootGetters): string[] {
            return state.availables;
        },
        getCurrentLanguage(): string {
            return LanguagesStore.state.current;
        }
    },
    actions: {
        setAvailableLanguage({ commit }, languages) {
            commit('addAvailableLanguage', languages.defaultLanguage);
            if (languages.secondLanguage) {
                commit('addAvailableLanguage', languages.secondLanguage);
            }
        }
    }
};
