import { Component, Prop } from 'vue-property-decorator';
import SdInputBase from '../SdInputBase';
import { MultiLanguage } from '@/Domain/Entities';

/**
 * Text type input component
 */
@Component({
    computed: {
        minLengthError() {
           return this.$props.value && this.$props.minlength && this.$props.value.length < this.$props.minlength;
        },
        maxLengthError() {
            return this.$props.value && this.$props.maxlength && this.$props.value.length > this.$props.maxlength;
        }
    }
})
export default class SdInputText extends SdInputBase {
    /** Maximum number of characters */
    @Prop() maxlength?: number;
    /** Minimum number of characters */
    @Prop() minlength?: number;
    /** Only letters are allowed */
    @Prop() onlyLetters?: boolean;
    /** Only letters of gender are allowed */
    @Prop() onlyGenderLetters?: boolean;
    /** Implements the translate button */
    @Prop() hasAutoTranslate?: boolean;
    /** Multilanguage object to translate */
    @Prop() contentToTranslate?: MultiLanguage;
    
    /**
     * @public
     * Fill the input with only allow letters
     * @param {Event} - Input content
     */
    onlyAllowLetters($event: any) {
        const keyCode = ($event.which ? $event.which : $event.keyCode);
        const pattern = /^[a-zA-ZñÑçÇ]$/;
        if (!pattern.test(String.fromCharCode(keyCode))) {
            $event.preventDefault();
        }
    }

    /**
     * @public
     * Fill the input with only allow gender letters
     * @param {Event} - Input content
     */
    onlyAllowGenderLetters($event: any) {
        const keyCode = ($event.which ? $event.which : $event.keyCode);
        const pattern = /^[hmHM]$/;
        if (!pattern.test(String.fromCharCode(keyCode))) {
            $event.preventDefault();
        }
    }

    /**
     * @public
     * Fill the input with the translated content
     * @param {Event} - Translated content
     */
    translatedContent($event) {
        this.$emit('input', $event);
    }

}
