import Enum from '../interfaces/IEnum';

export const CookiesType: Enum = {
    general: {
        title: 'lang.cookies.general',
        arrayCookies: [
            {
                name: '2faRememberedDeviceXXXX',
                purpose: 'lang.cookies.tecnical',
                supplier: 'logging.convoca.online',
                duration: 'lang.cookies.thirtyDays',
                description: 'lang.cookies.storesStringText'
            },
            {
                name: '.AspNetCore.Antiforgery.XXX',
                purpose: 'lang.cookies.tecnical',
                supplier: 'logging.convoca.online',
                duration: 'lang.cookies.session',
                description: 'lang.cookies.storesRandomValue'
            },
            {
                name: 'idsrv.session',
                purpose: 'lang.cookies.tecnical',
                supplier: 'logging.convoca.online',
                duration: 'lang.cookies.session',
                description: 'lang.cookies.keepUserSession'
            },
            {
                name: '.AspNetCore.Identity.Application',
                purpose: 'lang.cookies.tecnical',
                supplier: '.pasarela.convoca.online',
                duration: 'lang.cookies.session',
                description: 'lang.cookies.storesSessionData'
            },
            {
                name: '_hjSessionUser_XXXXXX',
                purpose: 'lang.cookies.analytics',
                supplier: '.hotjar.com',
                duration: 'lang.cookies.oneYear',
                description: 'lang.cookies.storesUniqueUserId'
            },
            {
                name: '_hjSession_XXXX',
                purpose: 'lang.cookies.analytics',
                supplier: '.hotjar.com',
                duration: 'lang.cookies.thirtyMinutes',
                description: 'lang.cookies.containsCurrentSessionData'
            },
            {
                name: '_hjIncludedInSessionSample_XXX',
                purpose: 'lang.cookies.analytics',
                supplier: '.hotjar.com',
                duration: 'lang.cookies.thirtyMinutes',
                description: 'lang.cookies.userIncludedSampling'
            },
            {
                name: '_hjAbsoluteSessionInProgress',
                purpose: 'lang.cookies.analytics',
                supplier: '.hotjar.com',
                duration: 'lang.cookies.thirtyMinutes',
                description: 'lang.cookies.detectFirstSession'
            },
            {
                name: '_hjFirstSeen',
                purpose: 'lang.cookies.analytics',
                supplier: '.hotjar.com',
                duration: 'lang.cookies.session',
                description: 'lang.cookies.detectUserFirstSession'
            },
            {
                name: '_ga',
                purpose: 'lang.cookies.thirdPartyAnalytics',
                supplier: 'Google',
                duration: 'lang.cookies.twoMonthsAndTwoDays',
                description: 'lang.cookies.storesAnonymousInformation'
            },
            {
                name: '_ga_XXX',
                purpose: 'lang.cookies.thirdPartyAnalytics',
                supplier: 'Google',
                duration: 'lang.cookies.twoMonthsAndTwoDays',
                description: 'lang.cookies.storesAnonymousInformation'
            },
            {
                name: '_gid',
                purpose: 'lang.cookies.thirdPartyAnalytics',
                supplier: 'Google',
                duration: 'lang.cookies.oneDay',
                description: 'lang.cookies.distinguishUsers'
            }
        ]
    }
};
