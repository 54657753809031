
import { Component, Vue } from 'vue-property-decorator';
import { ClientInfo } from '@/Domain/Entities';
import MasterDataService from '@/Services/MasterDataService';
import { mapState } from 'vuex';
import { Constants } from '@/Domain/enum';

@Component({
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' })
    }
})

export default class PrivacyPolicy extends Vue {
    currentLanguage!: string;
    constants = Constants;
    clientInfo = {};
    clientName: string = '';
    clientCif: string = '';
    clientAddress: string = '';
    clientPhoneNumber: number = 0;
    clientMail: string = '';
    clientDPOMail: string = '';
    clientUrlElectronicHeadquarters: string = '';

    getData() {
        MasterDataService.getClientInfo()
            .then(responseMasterData => { 
                this.clientInfo = new ClientInfo(responseMasterData);
                this.clientName = (this.clientInfo as ClientInfo).getName();
                this.clientCif = (this.clientInfo as ClientInfo).getCif();
                this.clientAddress = (this.clientInfo as ClientInfo).getAddress();
                this.clientPhoneNumber = (this.clientInfo as ClientInfo).getPhoneNumber();
                this.clientMail = (this.clientInfo as ClientInfo).getEmail();
                this.clientDPOMail = (this.clientInfo as ClientInfo).getDPOEmail();
                this.clientUrlElectronicHeadquarters = (this.clientInfo as ClientInfo).getUrlElectronicHeadquarters();
            })
            .catch(error => console.log(error));
    }

    mounted() {
        this.getData();
    }
}
