import ProcessConfig from './ProcessConfig';

export default class ProcessConfigGroup extends ProcessConfig {
    constructor(data: any) {
        super(data);
        this.type = data.groupType;
        this.maxLength = 5;
    }

    public toServer() {
        return {
            id: this.id,
            groupType: this.type,
            title: this.title
        };
    }
}
