import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                if (!this.$props.disabled) {
                    this.$emit('input', value);
                    (this.$refs.input as any).classList.remove('error');
                }
            }
        },
        isRequired() {
            return this.$props.required && !this.$props.value && this.$props.submitted;
        }
    }
})
export default class MdCheckbox extends Vue {
    @Prop() value?: boolean;
    @Prop() disabled?: boolean;
    @Prop() label?: string;
    @Prop() required?: boolean;
    @Prop() isNewCheckbox?: boolean;
    @Prop() submitted?: boolean;

    $refs!: {
        input: HTMLFormElement
    };
}
