import Enum from '../interfaces/IEnum';

export const StatusReplacement: Enum = {
    translations: {
        0: 'lang.filter.excluded',
        100: 'lang.filter.resignation',
        200: 'lang.filter.notAvailable',
        300: 'lang.filter.working',
        400: 'lang.filter.available',
        500: 'lang.filter.unreachable',
        600: 'lang.filter.busy'
    },
    enum: {
        EXCLUDED: 0,
        RESIGNATION: 100,
        NOTAVAILABLE: 200,
        WORKING: 300,
        AVAILABLE: 400,
        UNREACHABLE: 500,
        BUSY: 600
    }
};
