import Enum from '../interfaces/IEnum';

export const ReserveType: Enum = {
    translations: {
        0: 'lang.shared.yesGeneral',
        1: 'lang.shared.yesIntellectual',
        2: 'lang.shared.any'
    },
    enum: {
        GENERAL: 0,
        INTELECT: 1,
        NONE: 2
    }
};
