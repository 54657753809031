import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/processes';

export default class RequirementService {

    public static async getRequirement(processId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(processId + '/requirements', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addRequirement(processId: string, requirement: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.post(processId + '/requirements', requirement, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async editRequirement(processId: string, requirement: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/requirements/' + requirement.id, requirement, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeRequirement(processId: string, requirement: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(processId + '/requirements/' + requirement.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async setRequirementOrder(processId: string, requirementOrder: string[]): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/requirements', requirementOrder, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
