import MathService from '@/Application/Services/MathService';
import { MeritRatingType } from '../../enum/MeritRatingType';
import { MultiLanguage } from '@/Domain/Entities';
import { BaseMeritsType } from '../../enum';
import i18n from '../../../lang';
import _ from 'lodash';

const fixedUserScoreAmount = 1;
const maxLengthMeritSubGroup = 86;
const maxLengthMerit = 100;

export default class Merit {
    private _id: string;
    private _meritGroupId: string | null;
    private _meritSubGroupId: string;
    private _description: MultiLanguage;
    private _helpText: MultiLanguage;
    private _score: number;
    private _maxScore: number;
    private _allowCheckExOficio: boolean;
    private _order: number;
    private _ratingType: number;
    private _ratingTypeString: string;
    private _isEditing: boolean;
    private _allowUserOficio: boolean;
    private _userScore: number | null;
    private _userTotalScore: number | null;
    private _type: number;
    private _isDropped: boolean;
    private _hasMaxScore: boolean;
    private _parentOrder: number;
    private _exOficioDescription: MultiLanguage;
    private _allowExOficioDescription: boolean;
    private _userOficioComment: string | null;
    private _allowDocument: boolean;

    public constructor(data: any, meritGroupId?: string, parentOrder?: number) {
        this._id = data.id;
        this._meritGroupId = data.meritGroupId || meritGroupId || null;
        this._meritSubGroupId = data.meritSubGroupId;
        this._description = new MultiLanguage(data.description);
        this._helpText = new MultiLanguage(data.helpText);
        this._score = data.score;
        this._maxScore = data.maxScore;
        this._allowCheckExOficio = data.allowCheckExOficio;
        this._order = data.order;
        this._ratingType = Number(data.ratingType);
        this._ratingTypeString = MeritRatingType.translations[data.ratingType];
        this._isEditing = false;
        this._allowUserOficio = data.oficio || false;
        this._userScore = data.amount;
        this._userTotalScore = data.autoScore || 0;
        this._type = data.type || BaseMeritsType.enum.MERIT;
        this._isDropped = false;
        this._hasMaxScore = this.ratingType !== MeritRatingType.enum.FIXED_SCORE && this.userScore !== null && this.maxScore !== null && this.score * Number(this.userScore) > this.maxScore;
        this._parentOrder = parentOrder != null ? parentOrder : data.order;
        this._allowExOficioDescription = !_.isEmpty(data.exOficioDescription);
        this._exOficioDescription = new MultiLanguage(data.exOficioDescription);
        this._userOficioComment = data.oficioComment;
        this._allowDocument = data.allowDocument;
        this.calcFixedScore(data);
    }

    public set id(id: string) {
        this._id = id;
    }

    public get id() {
        return this._id;
    }

    public get meritGroupId() {
        return this._meritGroupId;
    }

    public set meritGroupId(meritGroupId: string | null) {
        this._meritGroupId = meritGroupId;
    }

    public get meritSubGroupId() {
        return this._meritSubGroupId;
    }

    public set meritSubGroupId(meritSubGroupId: string) {
        this._meritSubGroupId = meritSubGroupId;
    }

    public get description() {
        return this._description;
    }

    public set description(description: MultiLanguage) {
        this._description = description;
    }

    public get helpText() {
        return this._helpText;
    }

    public set helpText(helpText: MultiLanguage) {
        this._helpText = helpText;
    }

    public get score() {
        return this._score;
    }

    public set score(score: number) {
        this._score = score;
    }

    public get maxScore() {
        return this._maxScore;
    }

    public set maxScore(maxScore: number) {
        this._maxScore = maxScore;
    }

    public get allowCheckExOficio() {
        return this._allowCheckExOficio;
    }

    public set allowCheckExOficio(allowCheckExOficio: boolean) {
        this._allowCheckExOficio = allowCheckExOficio;
    }

    public get order() {
        return this._order;
    }

    public set order(order: number) {
        this._order = order;
    }

    public get ratingType() {
        return this._ratingType;
    }

    public set ratingType(ratingType: number) {
        this._ratingType = ratingType;
    }

    public get ratingTypeString(): string {
        return this._ratingTypeString;
    }

    public get isEditing() {
        return this._isEditing;
    }

    public set isEditing(isEditing: boolean) {
        this._isEditing = isEditing;
    }

    public get allowUserOficio() {
        return this._allowUserOficio;
    }
    public set allowUserOficio(allowUserOficio: boolean) {
        this._allowUserOficio = allowUserOficio;
    }

    public get userScore() {
        return this._userScore;
    }

    public set userScore(userScore: number | null) {
        this._userScore = userScore;
    }

    public get userTotalScore() {
        return this._userTotalScore;
    }

    public set userTotalScore(userTotalScore: number | null) {
        this._userTotalScore = userTotalScore;
    }

    public get type() {
        return this._type;
    }

    public set type(type: number) {
        this._type = type;
    }

    public get isDropped() {
        return this._isDropped;
    }

    public set isDropped(isDropped: boolean) {
        this._isDropped = isDropped;
    }

    public get hasMaxScore(): boolean {
        return this._hasMaxScore;
    }

    public set hasMaxScore(value: boolean) {
        this._hasMaxScore = value;
    }
    
    public get parentOrder(): number {
        return this._parentOrder;
    }
    
    public set parentOrder(value: number) {
        this._parentOrder = value;
    }

    public get exOficioDescription() {
        return this._exOficioDescription;
    }

    public set exOficioDescription(value: MultiLanguage) {
        this._exOficioDescription = value;
    }

    public get allowExOficioDescription(): boolean {
        return this._allowExOficioDescription;
    }

    public set allowExOficioDescription(value: boolean) {
        this._allowExOficioDescription = value;
    }

    public get userOficioComment(): string | null {
        return this._userOficioComment;
    }

    public set userOficioComment(userOficioComment: string | null) {
        this._userOficioComment = userOficioComment;
    }

    public get allowDocument() {
        return this._allowDocument;
    }

    public set allowDocument(allowDocument: boolean) {
        this._allowDocument = allowDocument;
    }

    public get descriptionTruncate() {
        const response = _.cloneDeep(this.description);
        for (const field of Object.keys(this.description)) {
            if (this.meritSubGroupId) {
                response[field] =  this.description[field].length > maxLengthMeritSubGroup ? this.description[field].substring(0, maxLengthMeritSubGroup) + '... (' + i18n.t('lang.shared.subGroup') + ')' : this.description[field] + ' (' + i18n.t('lang.shared.subGroup') + ')';
            }
            if (!this.meritSubGroupId && this.description[field].length > maxLengthMerit) {
                response[field] = this.description[field].substring(0, maxLengthMerit) + '...';
            }
        }
        return response;
    }

    public calcTotalScore(meritDocuments) {
        if (this.ratingType === MeritRatingType.enum.FIXED_SCORE) {
            if (this.allowUserOficio || meritDocuments.find(element => element.meritId === this.id)) {
                this.userTotalScore = this.score;
            } else {
                this.userTotalScore = 0;
            }
        } else {
            if (!this.userScore) {
                this.userTotalScore = 0;
                this.hasMaxScore = false;
            } else if (this.maxScore === null) {
                this.userTotalScore = MathService.roundToSix(this.score * Number(this.userScore));
            } else {
                if (this.score * Number(this.userScore) > this.maxScore) {
                    this.userTotalScore = this.maxScore;
                    this.hasMaxScore = true;
                } else {
                    this.userTotalScore = MathService.roundToSix(this.score * Number(this.userScore));
                    this.hasMaxScore = false;
                }
            }
        }
    }

    public calcFixedScore(merit) {
        if (merit.ratingType === MeritRatingType.enum.FIXED_SCORE && (merit.files && merit.files.length)) {
            this.userTotalScore = this.score;
        }
    }

    public infoToServer() {
        return {
            id: this.id,
            meritGroupId: this.meritGroupId,
            meritSubGroupId: this.meritSubGroupId,
            description: this.description,
            helpText: Number(this.ratingType) !== MeritRatingType.enum.UNITS ? new MultiLanguage({}) : this.helpText,
            score: this.score,
            maxScore: this.ratingType === MeritRatingType.enum.FIXED_SCORE ? null : this.maxScore,
            allowCheckExOficio: this.allowCheckExOficio,
            order: this.order,
            ratingType: this.ratingType,
            type: this.type,
            allowExOficioDescription: this.allowExOficioDescription,
            exOficioDescription: this._exOficioDescription,
            allowDocument: this._allowDocument
        };
    }

    public toServerToInscription() {
        return {
            id: this._id,
            meritGroupId: this._meritGroupId,
            meritSubGroupId: this._meritSubGroupId,
            userScore: this._userScore,
            allowUserOficio: this._allowUserOficio,            
            userOficioComment: this._userOficioComment,
            type: this._type,
            allowDocument: this._allowDocument
        };
    }

    public toServerToUpdateMerit(inscriptionId) {
        if (this.ratingType === MeritRatingType.enum.FIXED_SCORE && this.userTotalScore) {
            this.userScore = fixedUserScoreAmount;
        }

        return {
            inscriptionId,
            merit: {
                meritId: this._id,
                meritGroupId: this._meritGroupId,
                meritSubGroupId: this._meritSubGroupId,
                userScore: this.userTotalScore !== 0 ? this._userScore : 0,
                allowUserOficio: this._allowUserOficio,
                type: this._type,                
                userOficioComment: this._userOficioComment,
                allowDocument: this._allowDocument
            }
        };
    }
}
