import ValidationService from '@/Application/Services/ValidationService';

export default class Address {
    private _spainCode = 724;
    private _inscriptionId: string;
    private _streetType: string | null;
    private _streetName: string;
    private _number: number;
    private _portal: string;
    private _block: string;
    private _stair: string;
    private _floor: string;
    private _door: string;
    private _postCode: string | null;
    private _municipality: number | null;
    private _province: number | null;
    private _foreignPostCode: string | null;
    private _foreignMunicipality: string | null;
    private _foreignProvince: string | null;
    private _countryCode: number;
    private _country: number;

    constructor(data: any) {
        this._inscriptionId = data.id ? data.id : '';
        this._streetType = data.streetType ? data.streetType : '';
        this._streetName = data.streetName ? data.streetName : '';
        this._number = data.number !== undefined && data.number !== null ? data.number : null;
        this._portal = data.portal  ? data.portal : '';
        this._block = data.block ? data.block : '';
        this._stair = data.stair ? data.stair : '';
        this._floor = data.floor ? data.floor : '';
        this._door = data.door ? data.door : '';
        this._postCode = data.getFormatedPostCode ? data.getFormatedPostCode : '';
        this._foreignPostCode = data.foreignPostCode ? data.foreignPostCode : '';
        this._municipality = data.municipality !== undefined && data.municipality !== null ? data.municipality : null;
        this._province = data.province !== undefined && data.province !== null ? data.province : null;
        this._foreignProvince = data.foreignProvince ? data.foreignProvince : '';
        this._foreignMunicipality = data.foreignMunicipality ? data.foreignMunicipality : '';
        this._country = data.country !== undefined && data.country !== null ? data.country : null;
        this._countryCode = data.countryCode ? data.countryCode : null;
    }

    public toServer(inscriptionId) {
        return {
            inscriptionId,
            address: {
                streetType: this._streetType,
                streetName: this._streetName,
                number: this._number,
                portal: this._portal,
                block: this._block,
                stair: this._stair,
                floor: this._floor,
                door: this._door,
                postCode: Number(this._postCode),
                foreignPostCode: this._foreignPostCode,
                municipality: this._municipality,
                province: this._province,
                country: this._country,
                foreignProvince: this._foreignProvince,
                foreignMunicipality: this.foreignMunicipality
            }
        };
    }

    public toServerInscription() {
        return {
            streetType: this._streetType,
            streetName: this._streetName,
            number: this._number,
            portal: this._portal,
            block: this._block,
            stair: this._stair,
            floor: this._floor,
            door: this._door,
            postCode: Number(this._postCode),
            foreignPostCode: this._foreignPostCode,
            municipality: this._municipality,
            province: this._province,
            country: this._country,
            foreignProvince: this._foreignProvince,
            foreignMunicipality: this.foreignMunicipality
        };
    }

    public get inscriptionId() {
        return this._inscriptionId;
    }

    public set inscriptionId(inscriptionId: string) {
        this._inscriptionId = inscriptionId;
    }

    public get streetType() {
        return this._streetType;
    }

    public set streetType(streetType: string | null) {
        this._streetType = streetType;
    }

    public get streetName() {
        return this._streetName;
    }

    public set streetName(streetName: string) {
        this._streetName = streetName;
    }

    public get number() {
        return this._number;
    }

    public set number(number: number) {
        this._number = number;
    }

    public get portal() {
        return this._portal;
    }

    public set portal(portal: string) {
        this._portal = portal;
    }

    public get block() {
        return this._block;
    }

    public set block(block: string) {
        this._block = block;
    }

    public get stair() {
        return this._stair;
    }

    public set stair(stair: string) {
        this._stair = stair;
    }

    public get floor() {
        return this._floor;
    }

    public set floor(floor: string) {
        this._floor = floor;
    }

    public get door() {
        return this._door;
    }

    public set door(door: string) {
        this._door = door;
    }

    public get postCode() {
        return this._postCode;
    }

    public set postCode(postCode: string | null) {
        this._postCode = postCode;
    }

    public get foreignPostCode() {
        return this._foreignPostCode;
    }

    public set foreignPostCode(foreignPostCode: string | null) {
        this._foreignPostCode = foreignPostCode;
    }

    public get municipality() {
        return this._municipality;
    }

    public set municipality(municipality: number | null) {
        this._municipality = municipality;
    }

    public get province() {
        return this._province;
    }

    public set province(province: number | null) {
        this._province = province;
    }

    public get foreignProvince() {
        return this._foreignProvince;
    }

    public set foreignProvince(foreignProvince: string | null) {
        this._foreignProvince = foreignProvince;
    }

    public get foreignMunicipality() {
        return this._foreignMunicipality;
    }

    public set foreignMunicipality(foreignMunicipality: string | null) {
        this._foreignMunicipality = foreignMunicipality;
    }

    public get country() {
        return this._country;
    }

    public set country(country: number) {
        this._country = country;
    }

    public get countryCode() {
        return this._countryCode;
    }

    public set countryCode(countryCode: number) {
        this._countryCode = countryCode;
    }

    public resetAddress() {
        if (Number(this._country) === this._spainCode) {
            this.resetForeign();
        } else {
            this.resetNational();
        }
    }

    public resetForeign() {
        this._foreignMunicipality = null;
        this._foreignPostCode = null;
        this._foreignProvince = null;
    }

    public resetNational() {
        this._streetType = null;
        this._municipality = null;
        this._postCode = null;
        this._province = null;
    }

    public isValid() {
        const empty = '';
        const standardValidation = this.streetName !== empty;
        const locallyValidation = this.province !== null && this.province !== undefined && this.municipality !== null && this.municipality !== undefined && ValidationService.isValidPostCode(this.postCode);
        const foreignValidation = this.foreignProvince !== empty && this.foreignProvince !== null && this.foreignMunicipality !== empty && this.foreignMunicipality !== null && this.foreignPostCode !== empty && this.foreignPostCode !== null;
        
        return this.country === this._spainCode ? (locallyValidation && standardValidation) : (foreignValidation && standardValidation);
    }
}
