import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/BOEP';

export default class BOEPService {

    public static async getBOEPList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getBOEPById(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addBOEP(BOEP: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('', BOEP, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateBOEP(BOEP: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(BOEP.id, BOEP, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeBOEP(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
