import { Component, Vue } from 'vue-property-decorator';
import MasterDataService from '@/Services/MasterDataService';
import { ToasterService } from '@/Services/ToasterService';
import { ProcessConfig, ProcessConfigGroup } from '@/Domain/Entities';
import { ProcessConfigurationTypes, Constants } from '@/Domain/enum';
import { mapState, mapGetters, mapMutations } from 'vuex';
import i18n from '../../lang';
import _ from 'lodash';
import ProcessConfigCreator from '@/Components/ProcessConfiguration/ProcessConfigCreator/ProcessConfigCreator';
import ProcessConfigFactory from '@/Domain/Entities/ProcessConfiguration/ProcessConfigFactory';
import IProcessConfig from '@/Domain/interfaces/IProcessConfig';

@Component({
    components: {
        ProcessConfigCreator
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),
        processesConfigOrdered() {
            return _.orderBy((this as ProcessConfiguration).processesConfig, ['isEditable', processConfig => _.deburr(processConfig.title[(this as ProcessConfiguration).currentLanguage]).toLowerCase()]);
        },
        notCurrentLanguage() {
            return (this as ProcessConfiguration).availableLanguages.find(language => language !== (this as ProcessConfiguration).currentLanguage);
        }
    },
    methods: {
        ...mapMutations('processConfigStore', ['setProcessConfigGroups'])
    }
})

export default class ProcessConfiguration extends Vue {
    currentLanguage!: string;
    availableLanguages!: string[];
    ProcessConfigurationTypes = ProcessConfigurationTypes;
    constants = Constants;
    processConfigIdToDelete: string | null = '';
    processesConfig: ProcessConfig[] = [];
    tabActiveConfigType: number = ProcessConfigurationTypes.enum.EXAMS;
    tabActiveLanguage: any = null;
    processConfigToManage: any = null;
    submittedNew = false;
    submittedEdit = false;
    loading: boolean = true;
    invalidForm: boolean = false;
    showModal: boolean = false;

    setProcessConfigGroups!: (arg1: ProcessConfig[]) => void;

    $refs!: {
        processConfigCreator: ProcessConfigCreator
    };

    editProcessConfig(editProcessConfig: ProcessConfig) {
        this.resetActiveLanguage();
        this.processConfigToManage = _.cloneDeep(editProcessConfig);
    }

    cancelEditedProcessConfig() {
        this.processConfigToManage = null;
        this.submittedEdit = false;
    }

    saveEditedProcessConfig() {
        this.submittedEdit = true;
        if (!this.processConfigToManage.title.allHasValue(this.availableLanguages)) {
            return;
        }
        this.loading = true;
        MasterDataService.updateProcessConfig(this.tabActiveConfigType, this.processConfigToManage)
            .then(() => {
                this.handlesUpdateProcessConfigOnSuccess();
            }).catch(() => this.loading = false);
    }

    getProcessesConfig() {
        this.loading = true;
        MasterDataService.getProcessesConfiguration(this.tabActiveConfigType)
            .then((response: IProcessConfig[]) => {
                this.processesConfig = response.map(processConfig => ProcessConfigFactory.create(this.tabActiveConfigType, processConfig));
                this.processesConfig = this.processesConfig.filter(processConfig => processConfig.type >= processConfig.filterMinimumValidType);
                this.setToStore();
                this.loading = false;   
            }).catch(error => console.log(error));
    }

    onTabClick(tabActive: number) {
        if (tabActive === this.tabActiveConfigType) {
            return;
        }
        this.tabActiveConfigType = tabActive;
        this.processConfigToManage = null;
        this.$refs.processConfigCreator.removeNewProcessConfig();
        this.getProcessesConfig();
    }

    mounted() {
        this.getProcessesConfig();
        this.tabActiveLanguage = this.$i18n.locale;
    }

    private handlesUpdateProcessConfigOnSuccess() {
        ToasterService.showSuccess(i18n.t('lang.toaster.saveSuccess') as string);
        this.processConfigToManage = null;
        this.submittedEdit = false;
        this.getProcessesConfig();
    }

    private resetActiveLanguage() {
        this.tabActiveLanguage = this.availableLanguages[0];
    }

    private setToStore() {
        if (!(this.processesConfig[0] instanceof ProcessConfigGroup)) {
            return ;
        }
        this.setProcessConfigGroups(this.processesConfig);
    }
}
