import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

import { ClientInfo, MeritSubGroup, Process, ProcessConfigAuthorization, ProcessConfigFactory, ProcessConfigPayExemption } from '@/Domain/Entities';
import { OppositionType, InscriptionTabs, ProcessType, ProcessPhases, BaseMeritsType, ProcessStatus, ProcessConfigurationTypes } from '@/Domain/enum';

import { Breadcrumb, Modal, Documents, Toggle, Authorizations, Payment, GeneralInfo, Requirements, Exams, Merits } from '@/Components';

import { ToasterService } from '@/Services/ToasterService';
import ProcessesService from '@/Services/ProcessesService';
import ValidationService from '@/Application/Services/ValidationService';

import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import i18n from '../../lang';
import MasterDataService from '@/Services/MasterDataService';

@Component({
    components: {
        Breadcrumb,
        Modal,
        Documents,
        Authorizations,
        GeneralInfo,
        Requirements,
        Exams,
        Merits,
        Toggle,
        Payment
    },
    computed: {
        ...mapState('featureFlagsStore', ['multiLanguage']),
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' }),

        breadcrumbInfo() {
            const breadcrumb: any[] = [];

            breadcrumb.push({ title: 'lang.router.editListProcess', link: 'ProcessList' });

            if (this.$route.params.id) {
                breadcrumb.push({ title: this.$data.process.title[this.$store.state.languagesStore.current] });
            } else {
                breadcrumb.push({ title: i18n.t('lang.router.newProcess', {typeProcess: i18n.t(ProcessType.translations[this.$route.meta.type])}) });
            }

            if (this.$data.process.editingMeritGroup) {
                breadcrumb.push({ title: 'lang.shared.groupsMerits' });
            }
            return breadcrumb;
        },
        anyPayExemptionFeePaymentNotHasAmount() {
            return this.$data.process.fees.payExemptionTypesView.find(payExemption => !payExemption.amount && payExemption.amount === null && payExemption.value);
        },
        isEditAnyComponent() {
            return this.$data.process.isCreatingOrEditingProcess();
        },
        hasExamsRequirementTest() {
            return this.$data.process.exams.length && this.$data.process.exams.some(exam => exam.isRequirementTest);
        },
        hasExamsWithoutRequirementTest() {
            return this.$data.process.exams.length && this.$data.process.exams.some(exam => !exam.isRequirementTest);
        },
        hasRequirementsOptional() {
            return this.$data.process.requirements.some(requirement => requirement.isOptional);
        },
        isMeritsTabDisabled() {
            return Number(this.$data.process.oppositionType) === OppositionType.enum.NOMINATION || Number(this.$data.process.oppositionType) === OppositionType.enum.FREE_DESIGNATION;
        },
        isExamsTabDisabled() {
            return (Number(this.$data.process.oppositionType) === OppositionType.enum.TENDER && !this.$data.process.hasRequirementWithTest) || Number(this.$data.process.oppositionType) === OppositionType.enum.FREE_DESIGNATION;
        }
    }
})
export default class ProcessEdit extends Vue {

    OppositionType = OppositionType;
    ProcessType = ProcessType;
    ProcessPhases = ProcessPhases;
    InscriptionTabs = InscriptionTabs;
    ProcessStatus = ProcessStatus;

    currentLanguage!: string;
    anyPayExemptionFeePaymentNotHasAmount!: boolean;
    process: Process = null as any;
    type = null as any;
    processStatusSaved: number = 0;
    meritGroupSubGroupEmpty: any = [];
    tabSelected = InscriptionTabs.GENERAL;

    waitingResponse: boolean = false;
    invalidRequeriments: boolean = false;
    invalidMerits: boolean = false;
    examScoreError: boolean = false;
    invalidForm: boolean = false;
    invalidVacancies: boolean = false;
    meritAlreadyIncluded: boolean = false;
    meritScoreError: boolean = false;
    meritMaxScoreParentError: boolean = false;
    isInvalidScoreChildren: boolean = false;
    meritSubGroupChildrenError: boolean = false;
    subGroupMaxScoreParentError: boolean = false;
    toggleMandatoryRequirements: boolean = true;
    submitted: boolean = false;
    turnsAreCheched: boolean = true;
    hasMeritGroupSubGroupEmpties: boolean = false;
    clientInfo: ClientInfo = new ClientInfo({});
    urlApplicant: string = '';
    showModalPrice: boolean = false;
    acceptDeletePayments: boolean = false;
    processConfigurationPayExemptions: ProcessConfigPayExemption[] = [];
    processConfigurationAuthorizations: ProcessConfigAuthorization[] = [];

    $refs!: {
        processForm: HTMLFormElement,
        exams: Exams
    };

    updateProcess(value) {
        this.process.hasRequirementWithTest = value;
        this.$refs.exams.getExam();
    }

    confirmWithModalPublic() {
        this.process.stateToggle ? this.process.isCheckNeeded = true : this.submit();
    }

    closeModalPayment() {
        this.process.fees.hasFeePayment = true;
        this.showModalPrice = false;
    }

    acceptModalPayment() {
        this.process.fees.resetReasons();
        this.showModalPrice = false;
        this.acceptDeletePayments = true;
        this.submit();
    }

    goToApplicantPreview() {
        this.urlApplicant = this.clientInfo.getHost();
        window.open(this.urlApplicant + '/inscription.html?id=' + this.process.id + '&type=' + this.$route.meta.type + '&preview=true');
    }

    getProcessAction() {
        if (this.process.id) {
            return ProcessesService.updateProcess(this.process.toServer(this.process.stateToggle));
        }
        return ProcessesService.addProcess(this.process.toServer(this.process.stateToggle));
    }

    selectTab(selectedTab: InscriptionTabs) {
        this.type = Number(this.process.oppositionType);
        
        if (this.type === OppositionType.enum.FREE_DESIGNATION && (selectedTab === InscriptionTabs.EXAMS || selectedTab === InscriptionTabs.MERITS)) {
            return;
        }
        if (this.type === OppositionType.enum.NOMINATION && selectedTab === InscriptionTabs.MERITS) {
            return;
        }
        if ((this.type === OppositionType.enum.TENDER && !this.process.hasRequirementWithTest) && selectedTab === InscriptionTabs.EXAMS) {
            return;
        }
        if (this.process.type === ProcessType.enum.POSTPROVISION && selectedTab === InscriptionTabs.AUTHORIZATIONS) {
            return;
        }
        if (!this.toggleMandatoryRequirements && selectedTab === InscriptionTabs.REQUIREMENTS) {
            return;
        }

        this.process.isCreatingMeritGroup = false;
        this.process.isEditingMeritGroup = false;
        this.process.isCreatingExam = false;
        this.process.isEditingExam = false;
        this.process.isCreatingRequirement = false;
        this.process.isEditingRequirement = false;
        this.process.isCreatingDocument = false;
        this.process.isEditingDocument = false;

        if (this.process.editingMeritGroup) {
            this.process.editingMeritGroup.isCreatingMeritSubGroup = false;
            this.process.editingMeritGroup.meritChilds.forEach(element => element.isEditing = false);
            this.process.editingMeritGroup = null;
        }

        this.tabSelected = selectedTab;
    }

    areValidMerits(): boolean {
        return  this.process.meritGroups.some(group => 
                group.meritChilds.some(child => child.type === BaseMeritsType.enum.MERIT)  || 
                group.meritChilds.some(child => child.type === BaseMeritsType.enum.MERITSUBGROUP &&
                (child as MeritSubGroup).merits.length > 0) 
        );        
    }
    
    meritGroupSubGroupEmpties() {
        return this.$data.process.meritGroups.filter(group => 
            group.meritChilds.length === 0 || 
            group.meritChilds.some(child => child.type === BaseMeritsType.enum.MERITSUBGROUP &&
            (child as MeritSubGroup).merits.length === 0)
        );
    }

    cancel() {
        this.process.stateToggle = false;
        this.process.status = Number(this.process.stateToggle);
        if (!this.process.fees.hasFeePayment && this.process.fees.amount) {
            this.process.fees.hasFeePayment = true;
        }
    }

    setNoError() {
        this.meritMaxScoreParentError = false;
        this.isInvalidScoreChildren = false;
        this.meritSubGroupChildrenError = false;
        this.subGroupMaxScoreParentError = false;
        this.meritScoreError = false;
        this.meritAlreadyIncluded = false;
    }

    submit() {        
        if (!this.process.fees.hasFeePayment && this.process.canProcessBePaidOnline && this.process.fees.amount && !this.acceptDeletePayments && !this.process.isCheckNeeded) {
            this.showModalPrice = true;
            return;
        }
        if (!this.process.fees.hasFeePayment) {
            this.process.fees.resetReasons();
        }
        this.submitted = true;
        this.process.isCheckNeeded = false;
        if (this.process.oppositionType) {
            this.process.oppositionType = Number(this.process.oppositionType);
        }
        this.invalidForm = !ValidationService.validateFormRequired(this.$refs.processForm);
        this.invalidRequeriments = this.process.stateToggle && this.process.mandatoryRequirements && this.process.requirements.length < 1;
        const vacancies = Object.values(this.process.vacancies);
        this.invalidVacancies = this.process.type === ProcessType.enum.CALL && this.process.stateToggle && vacancies.every(vacant => vacant === 0);
        this.invalidMerits = this.process.oppositionType !== OppositionType.enum.NOMINATION && this.process.oppositionType !== OppositionType.enum.FREE_DESIGNATION && this.process.stateToggle && !this.areValidMerits();
        this.meritGroupSubGroupEmpty = this.meritGroupSubGroupEmpties();
        this.hasMeritGroupSubGroupEmpties = this.meritGroupSubGroupEmpty.length && this.process.stateToggle;
        if (this.$data.process.type === ProcessType.enum.BAG || this.$data.process.type === ProcessType.enum.POSTPROVISION) {
            this.turnsAreCheched = this.process.turns.isValidTurnsChecked();
        }
        if (this.waitingResponse || this.invalidForm || this.invalidRequeriments || this.invalidMerits || !this.turnsAreCheched || this.hasMeritGroupSubGroupEmpties || this.invalidVacancies) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.process.stateToggle = this.processStatusSaved > 0;
            this.process.status = this.processStatusSaved;
            return;
        }
        this.type = this.process.oppositionType;
        this.waitingResponse = true;
        this.process.status = Number(this.process.stateToggle);
        const action = this.getProcessAction();
        action.then(response => {
            if (this.$route.name === 'CallNew' || this.$route.name === 'BagNew' || this.$route.name === 'PostProvisionNew') {
                this.process = new Process(response, this.processConfigurationPayExemptions, this.$store.state.processConfigStore.processConfigGroups);
                this.process.createAutorizations(this.processConfigurationAuthorizations);
                this.$router.push({ name: ProcessType.routeEdit[this.$route.meta.type], params: { id: response.id } });
            }
            if (this.process.oppositionType === OppositionType.enum.NOMINATION) {
                this.process.meritGroups = [];
                this.process.allowPostInscriptionMeritPresentation = false;
                this.process.meritPresentationStartDate = null;
                this.process.meritPresentationEndDate = null;
            }
            if (this.process.oppositionType === OppositionType.enum.TENDER && this.process.hasRequirementWithTest) {
                this.process.exams = this.process.exams.filter(exam => exam.isRequirementTest);
            }
            if (this.process.oppositionType === OppositionType.enum.TENDER && !this.process.hasRequirementWithTest) {
                this.process.exams = [];
            }
            if (!this.process.mandatoryRequirements) {
                this.process.requirements = [];
                this.process.exams = this.process.exams.filter(exam => !exam.isRequirementTest);
            }

            this.toggleMandatoryRequirements = this.process.mandatoryRequirements;

            this.meritAlreadyIncluded = false;
            this.process.stateToggle = this.process.status > 0;
            this.processStatusSaved = this.process.status;
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            this.waitingResponse = false;
            this.acceptDeletePayments = false;
        })
            .catch(() => {
                this.waitingResponse = false;
                this.process.stateToggle = this.processStatusSaved > 0;
                this.process.status = this.processStatusSaved;
            });
    }

    setMeritScoreError() {
        this.meritScoreError = true;
    }

    setMeritMaxScoreParentError() {
        this.meritMaxScoreParentError = true;
    }

    setSubGroupMaxScoreParentError() {
        this.subGroupMaxScoreParentError = true;
    }

    setIsInvalidScoreChildren() {
        this.isInvalidScoreChildren = true;
    }

    setMeritSubGroupChildrenError() {
        this.meritSubGroupChildrenError = true;
    }

    setMeritAlreadyIncluded() {
        this.meritAlreadyIncluded = true;
    }

    getProcessById() {
        ProcessesService.getProcessById(this.$route.params.id, this.$route.meta.type)
        .then((response: Process) => {
            this.process = new Process(response, this.processConfigurationPayExemptions, this.$store.state.processConfigStore.processConfigGroups);
            ProcessesService.getIsInUse(this.process.id)
                .then((resp: boolean) => {
                    this.process.isUsed = resp;
                }).catch(error => console.log(error));
            this.process.stateToggle = this.process.status > 0;
            this.processStatusSaved = this.process.status;
            this.process.createAutorizations(this.processConfigurationAuthorizations);
            this.toggleMandatoryRequirements = this.process.mandatoryRequirements;
            this.type = this.process.oppositionType;
            this.$nextTick(() => {
                notification.initialize();
            });
        })
        .catch(error => console.log(error));
    }

    initClientInfo() {
        MasterDataService.getClientInfo()
            .then(responseMasterData => {
                this.clientInfo = new ClientInfo(responseMasterData);
            })
            .catch(error => console.log(error));
    }

    getProcessConfigurationPayExemptionsList() {
        MasterDataService.getProcessesConfiguration(ProcessConfigurationTypes.enum.PAYEXEMPTIONS)
            .then((response: any[]) => {
                this.processConfigurationPayExemptions = response.map(processConfig => ProcessConfigFactory.create(ProcessConfigurationTypes.enum.PAYEXEMPTIONS, processConfig));
            }).catch(error => console.log(error));
    }

    getProcessConfigurationAuthorizationsList() {
        MasterDataService.getProcessesConfiguration(ProcessConfigurationTypes.enum.AUTHORIZATIONS)
            .then((response: any[]) => {
                this.processConfigurationAuthorizations = response.map(processConfig => ProcessConfigFactory.create(ProcessConfigurationTypes.enum.AUTHORIZATIONS, processConfig));
            }).catch(error => console.log(error));
    }

    created() {
        this.initClientInfo();
        this.getProcessConfigurationPayExemptionsList();
        this.getProcessConfigurationAuthorizationsList();
        if (this.$route.params.id) {
            this.getProcessById();
        } else {
            this.process = new Process({ type: this.$route.meta.type });
            this.$nextTick(() => {
                notification.initialize();
            });
        }

    }
}
