import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_IDENTITY_API;

export default class UserService {
    public static async updateDoubleAuthentication(clientId: string, userId: string, hasDoubleAuthentication: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(`api/clients/${clientId}/users/${userId}/twoFactor`, { hasDoubleAuthentication }, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getLastLogin(clientId: string, userId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`api/clients/${clientId}/users/${userId}/lastLogin`, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
