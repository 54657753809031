import Enum from '../interfaces/IEnum';

export const ReplacementListTabs: Enum = {
    name: {
        '#free': 0,
        '#freeDisability': 1,
        '#internal': 2,
        '#internalDisability': 3,
        '#mobility': 4,
        '#freeDesignation': 5,
        '#freeDesignationDisability': 6

    },
    enum: {
        0: '#free',
        1: '#freeDisability',
        2: '#internal',
        3: '#internalDisability',
        4: '#mobility',
        5: '#freeDesignation',
        6: '#freeDesignationDisability'
    }
};
