import { Merit, MeritOepDocument, MultiLanguage } from '@/Domain/Entities';
import { MeritRatingType } from '../../enum/MeritRatingType';
import { StatusDocuments } from '@/Domain/enum';

export default class ProcessMerit extends Merit {
    private _oficialScore: number;
    private _documents: MeritOepDocument[];
    private _amount: number;
    private _isValid: any;
    private _rejectionReason: MultiLanguage;
    private _active: boolean;
    private _isMaximumScoreReached: boolean = false;
    private _spanishKey = 'es-ES';
    private _maximumScoreReachedRejectionReason = {
        'es-ES': 'Alcanzada la puntuación máxima no se sigue valorando',
        'eu-ES': 'Gehienezko puntuazioa lortu ondoren, ez da balioesten',
        'ca-ES': 'Aconseguida la puntuació màxima no es continua valorant',
        'gl-ES': 'Alcanzada a puntuación máxima non se segue valorando',
        'pt-PT': 'Aconseguida la puntuació màxima no es continua valorant'
    };

    public constructor(data: any, availableLanguages: string[]) {
        super(data);
        this.allowUserOficio = data.oficio;
        this.userScore = data.autoScore;
        this._oficialScore = data.oficialScore;
        this._documents = data.files ? data.files.map(document => new MeritOepDocument(document, this.id, this.ratingType)) : [];
        this._amount = data.amount;
        this._rejectionReason = new MultiLanguage(data.rejectionReason);
        this._isValid = false;
        this._active = false;
        this.setIsMaximumScoreReached();
        this.manageIsValid(availableLanguages);
        if (this.isMaximumScoreReached) {
            this.rejectAllDocuments();
            this.markMeritAsMaximumScoreReached();
        }
    }

    public setRejectionReason(rejectionReason: MultiLanguage) {
        this._rejectionReason = rejectionReason;
    }

    public getMeritInGroup() {
        const meritInArray: ProcessMerit[] = [];
        meritInArray.push(this);
        return meritInArray;
    }

    public addFilesToViewer(meritGroup) {
        let filesToViewer: any[] = [];
        if (this.documents.length < 1 && this.allowUserOficio) {
            const newMeritDoc = new MeritOepDocument({ id: this.id + meritGroup.id, localUrl: 'actuar-de-oficio.png' }, this.id, this.ratingType);
            filesToViewer.push(newMeritDoc);
            this.documents.push(newMeritDoc);
        } else if (this.documents.length < 1) {
            const newMeritDoc = new MeritOepDocument({ id: this.id + meritGroup.id, localUrl: 'sin-archivos.png' }, this.id, this.ratingType);
            filesToViewer.push(newMeritDoc);
            this.documents.push(newMeritDoc);
        } else {
            filesToViewer = filesToViewer.concat(this.documents);
        }
        return filesToViewer;
    }

    public setActive() {
        this.active = false;
    }

    public getTotal() {
        if (this.maxScore != null && this.ratingType !== MeritRatingType.enum.FIXED_SCORE && this.oficialScore !== null && this.oficialScore > this.maxScore) {
            return Number(this.maxScore);
        } else {
            return Number(this.oficialScore);
        }
    }

    public manageIsValid(availableLanguages) {
        if (this.oficialScore as any === '' || this.oficialScore !== null && isNaN(this.oficialScore) || this.oficialScore !== null && this.oficialScore < 0) {
            (this.isValid as any) = false;
            return;
        }

        (this.isValid as any) = this.maxScore ? this.compareOficialScoreMaxScore() && this.compareUserScoreOfficialScore(availableLanguages) : this.compareUserScoreOfficialScore(availableLanguages);

        if (this.ratingType === MeritRatingType.enum.FIXED_SCORE) {
            (this.isValid as any) = this.isValid && (Number(this.oficialScore) === 0 || Number(this.oficialScore) === this.userScore);
        }
    }
    
    public get documents() {
        return this._documents;
    }
    public set documents(documents: MeritOepDocument[]) {
        this._documents = documents;
    }

    public get active() {
        return this._active;
    }
    public set active(active: boolean) {
        this._active = active;
    }

    public get rejectionReason() {
        return this._rejectionReason;
    }
    public set rejectionReason(rejectionReason: MultiLanguage) {
        this._rejectionReason = rejectionReason;
    }

    public get isValid() {
        return this._isValid;
    }
    public set isValid(isValid: boolean) {
        this._isValid = isValid;
    }

    public get oficialScore(): number {
        return this._oficialScore;
    }
    public set oficialScore(oficialScore: number) {
        this._oficialScore = oficialScore;
    }

    public get amount(): number {
        return this._amount;
    }
    public set amount(amount: number) {
        this._amount = amount;
    }

    public get isMaximumScoreReached(): boolean {
        return this._isMaximumScoreReached;
    }
    public set isMaximumScoreReached(isMaximumScoreReached: boolean) {
        this._isMaximumScoreReached = isMaximumScoreReached;
    }

    public get maximumScoreReachedRejectionReason(): object {
        return this._maximumScoreReachedRejectionReason;
    }

    public markMeritAsMaximumScoreReached() { 
        this.oficialScore = 0;
        this.rejectionReason = new MultiLanguage(this.maximumScoreReachedRejectionReason);
        this.setIsMaximumScoreReached();
    }

    public markMeritToUnReachedMaxScore() {
        this.isMaximumScoreReached = false;
        (this.oficialScore as any) = '';
    }

    public rejectAllDocuments() {
        this.documents.forEach(doc => doc.status = StatusDocuments.enum.REJECTEDTOTAL);
    }

    public setIsMaximumScoreReached() {
        this.isMaximumScoreReached = this.rejectionReason[this._spanishKey] === this._maximumScoreReachedRejectionReason[this._spanishKey] && this.oficialScore === 0;
    }

    public toServer() {
        return {
            id: this.id,
            meritGroupId: this.meritGroupId,
            meritSubGroupId: this.meritSubGroupId,
            oficialScore: this.oficialScore,
            rejectionReason: this.rejectionReason
        };
    }

    public toServerAddDocumentOtherMerit() {
        return {
            meritId: this.id,
            meritGroupId: this.meritGroupId,
            meritSubGroupId: this.meritSubGroupId,
            allowUserOficio: false,
            userScore: this.userScore
        };
    }

    private compareUserScoreOfficialScore(availableLanguages) {
        return this.oficialScore !== null && (Number(this.userScore) === Number(this.oficialScore) || this.rejectionReason.allHasValue(availableLanguages));
    }

    private compareOficialScoreMaxScore() {
        return Number(this.oficialScore) <= Number(this.maxScore);
    }
}
