import { ProcessConfigGroup } from '@/Domain/Entities';

export const ProcessConfigStore = {
    namespaced: true,
    state: {
        processConfigGroups: []
    },
    mutations: {
        setProcessConfigGroups(state, processConfigGroups: ProcessConfigGroup[]) {
            state.processConfigGroups = processConfigGroups;
        }
    },
    getters: {
        getProcessConfigGroups(state) {
            return state.processConfigGroups;
        }
    }
};
