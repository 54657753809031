export default class TabFilterProvisional {
    private _isSelectedAll: boolean;
    private _position: number | null;
    private _score: number | null;

    constructor() {
        this._isSelectedAll = false;
        this._position = null;
        this._score = null;
    }

    public get isSelectedAll() {
        return this._isSelectedAll;
    }
    public set isSelectedAll(isSelectedAll: boolean) {
        this._isSelectedAll = isSelectedAll;
    }

    public get position() {
        return this._position;
    }
    public set position(position: number | null) {
        this._position = position;
    }

    public get score() {
        return this._score;
    }
    public set score(score: number | null) {
        this._score = score;
    }
}
