import Enum from '../interfaces/IEnum';

export const AuthorizationTypes: Enum = {
    translations: {
        1: 'lang.authorizations.stateAgency',
        2: 'lang.authorizations.townHall',
        4: 'lang.authorizations.largeFamily',
        8: 'lang.authorizations.disability',
        16: 'lang.authorizations.police',
        32: 'lang.authorizations.traffic',
        64: 'lang.authorizations.education',
        128: 'lang.authorizations.registeredEmployment',
        256: 'lang.authorizations.laboralLife',
        512: 'lang.authorizations.ministryOfJustice',
        1024: 'lang.authorizations.criminalRecord',
        2048: 'Junta Qualificadora de Coneixements de Valencià (JQCV)'
    }
};
