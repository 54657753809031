import Enum from '../interfaces/IEnum';

export const MassiveNotificationReceiversType: Enum = {
    translations: {
        0: 'lang.replacement.massiveNotificationForm.selectAll',
        1: 'lang.replacement.massiveNotificationForm.selectRange'
    },
    enum: {
        ALL: 0,
        FILTERED: 1
    }
};
