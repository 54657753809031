import Enum from '../interfaces/IEnum';

export const OriginDocument: Enum = {
    translations: {
        0: 'lang.inscription.inscriptionProof',
        1: 'lang.inscription.correctionProof',
        2: 'lang.inscription.inscriptionReceipt',
        3: 'lang.inscription.claimReceipt',
        4: 'lang.inscription.attachMeritsProof',
        5: 'lang.inscription.attachMeritsReceipt',
        6: 'lang.inscription.paymentReceipt'
    },
    enum: {
        JUSTIFY: 0,
        CORRECTION: 1,
        JUSTIFYRECEIPT: 2,
        CORRECTIONRECEIPT: 3,
        JUSTIFYATTACHMERITS: 4,
        ATTACHMERITSRECEIPT: 5,
        PAYMENTDOCUMENT: 6
    }
};
