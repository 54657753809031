import { Component, Prop, Vue } from 'vue-property-decorator';

interface SdRadioData {
    text: string;
    value: number;
}

@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                const newValue = isNaN(value) ? value : Number(value);
                this.$emit('input', newValue);
                this.$data.dirty = true;
                (this.$refs.radio as any).classList.remove('error');
            }
        },
        isRequired() {
            return this.$props.required && (this.$props.submitted || this.$data.dirty) && this.$props.value == null;
        }
    }
})
export default class SdRadio extends Vue {
    @Prop() value: any;
    @Prop() options!: any | SdRadioData[];
    @Prop() name!: string;
    @Prop() label?: string;
    @Prop() required?: boolean;
    @Prop() disabled?: boolean;
    @Prop() submitted?: boolean;
    @Prop() positionItem?: string;
    dirty: boolean = false;

    $refs!: {
        radio: HTMLFormElement
    };
}
