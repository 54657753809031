import { Component, Prop, Vue } from 'vue-property-decorator';
import * as imageCompressor from 'saviafront/lib/js/compiled/imageCompressor';
import { Constants } from '@/Domain/enum';
import moment from 'moment';
import DocumentService from '@/Application/Services/DocumentService';

/**
 * Component to import files
 */
@Component({
    computed: {
        hasRequiredError() {
            return this.$props.required && (this.$data.dirty || this.$props.submitted) && this.$props.documentsToManage.length < 1;
        }
    }
})
export default class SdFileImporter extends Vue {
    /** Indicates whether it is mandatory to import at least one file */
    @Prop() required?: string;
    /** Indicates whether 'fileImporter' is disabled */
    @Prop() disabled?: boolean;
    /** Text that acts as title */
    @Prop() label?: string;
    /** Indicates if the form has been submitted */
    @Prop() submitted?: boolean;
    /** Indicates if multiple files can be uploaded */
    @Prop() multipleFiles?: boolean;
    /** Array of documents to manage */
    @Prop() documentsToManage?: any[];
    /** Indicates whether the component should have a smaller vertical offset */
    @Prop() xsScroll?: boolean;
    /** Indicates whether the component should be hidden when having a file */
    @Prop() disableOneFile?: boolean;    
    /** Indicates that the component only supports csv */
    @Prop() onlyCsv?: boolean;

    dragging: boolean = false;
    dirty: boolean = false;
    maximumFilesExceeded: boolean = false;
    filesError: any[] = [];
    isFileEncrypted: boolean = false;
    isAPortfolioFile: boolean = false;

    $refs!: {
        importer: HTMLFormElement;
    };

    /**
     * @public
     * Sets the 'dirty' property to false
     */
    resetProps() {
        this.dirty = false;
    }

    /**
     * @public
     * Removes a file error from the list of files with error
     * @param {any} fileErrorToRemove - The file error to be removed
     */
    removeFileError(fileErrorToRemove: any) {
        const index = this.filesError.findIndex((file: any) => file.name === fileErrorToRemove.name);
        this.filesError.splice(index, 1);
    }

    /**
     * @public
     * Raise 'removeFile' event to delete a document
     * @param {any} document - Document to be removed
     */
    removeFile(document: any) {
        this.$emit('removeFile', document);
    }

    /**
     * @public
     * Raise 'downloadFile' event to download a document
     * @param {any} document - Document to be downloaded
     */
    downloadFile(document: any) {
        this.$emit('downloadFile', document);
    }

    /**
     * @public
     * Receives and handles the event with one or more files, emitting those files that pass validation 
     * and those that do not are added to an array of files with errors
     * @param {any} $event - The event object containing information about the file or files
     */
    async onFileChange($event: any) {
        this.filesError = [];
        this.maximumFilesExceeded = false;
        this.dirty = true;

        if (this.$props.disabled) {
            return;
        }
        let files: any = [];
        if ($event.dataTransfer) {
            this.multipleFiles ? files = [... $event.dataTransfer.files] : files.push($event.dataTransfer.files[0]);
        } else {
            this.multipleFiles ? files = [... this.$refs.importer.files] : files.push(this.$refs.importer.files[0]);
        }

        if (files.length > 10) {
            this.dragging = false;
            this.maximumFilesExceeded = true;
            return;
        }
        for (const element of files) {
            const fileExtension = '.' + element.name.split('.').pop();
            if (fileExtension.toLowerCase() === Constants.fileImporter.PDF) {
                this.isFileEncrypted = await DocumentService.isFileEncrypted(element);
                this.isAPortfolioFile = await DocumentService.isPdfAPortfolioFileType(element);
            }
            this.validateFile(element, fileExtension);
            this.dragging = false;
            if (element.errors.length) {
                element.extension = fileExtension;
                this.filesError.push(element);
                continue;
            }
            if (fileExtension.toLowerCase() !== Constants.fileImporter.PDF && fileExtension.toLowerCase() !== Constants.fileImporter.CSV) {
                await imageCompressor.compress(element);
            }
            element.extension = fileExtension;
            element.fileDate = moment();
            this.$emit('input', element);
        }
        $event.target.value = null;
    }

    /**
     * Validates a file based on its properties
     * 
     * @private
     * @param {any} file - The file to validate
     * @param {string} fileExtension - The file extension to validate against
     */
    private validateFile(file: any, fileExtension: string) {
        file.errors = [];
        if (Constants.fileImporter.INVALID_FILE_NAME.some((validFormat: string) => file.name.includes(validFormat))) {
            file.errors.push('lang.fileImporter.fileName');
        }
        if (!this.onlyCsv && !Constants.fileImporter.VALID_EXTENSIONS.includes(fileExtension.toLowerCase())) {
            file.errors.push('lang.fileImporter.fileType');
        }
        if (this.onlyCsv && Constants.fileImporter.CSV !== fileExtension.toLowerCase()) {
            file.errors.push('lang.fileImporter.onlyCsv');
        }
        if (file.size > Constants.fileImporter.MAX_SIZE) {
            file.errors.push('lang.fileImporter.sizeFile');
        }
        if (this.isFileEncrypted) {
            file.errors.push('lang.fileImporter.fileEncrypted');
        }
        if (this.isAPortfolioFile) {
            file.errors.push('lang.fileImporter.isAPortfolioFile');
        }
    }
}
