import { Component, Prop, Vue } from 'vue-property-decorator';
import i18n from '../../lang';

@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isRequired() {
            return this.$props.required && !this.$props.value && !this.$data.focused && (this.$data.dirty || this.$props.submitted);
        },
        optionalText() {
            return !this.$props.required && this.$props.label !== undefined ? i18n.t('lang.input.optional') : '';
        }
    }
})
export default class SdInputBase extends Vue {
    @Prop() value: any;
    @Prop() icon?: string;
    @Prop() label?: string;
    @Prop() placeholder?: string;
    @Prop() required?: string;
    @Prop() disabled?: boolean;
    @Prop() submitted?: boolean;
    @Prop() hideError?: boolean;
    @Prop() iconInvest?: boolean;

    dirty: boolean = false;
    focused: boolean = false;

    $refs!: {
        input: HTMLInputElement
    };
    
    onBlur($event: Event) {
        this.dirty = true;
        this.focused = false;
        this.$emit('blur', $event);
    }

    onChange($event: Event) {
        this.$emit('change', $event);
    }

    onFocus($event: Event) {
        this.focused = true;
        this.$emit('focus', $event);
    }
}
