import moment from 'moment';

export default class InscriptionContact {
    private _id: string;
    private _annotations: string;
    private _date: moment.Moment | null;
    private _time: any | null;
    private _isEditing: boolean;

    public constructor(data: any) {
        this._id = data.id;
        this._annotations = data.annotations;
        this._date = data.date ? moment.utc(data.date).local() : null;
        this._time = data.date ? moment.utc(data.date).local().format('HH:mm') : null;
        this._isEditing = false;
    }

    public get id() {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get annotations() {
        return this._annotations;
    }
    public set annotations(value: string) {
        this._annotations = value;
    }

    public get date() {
        return this._date;
    }
    public set date(value: moment.Moment | null) {
        this._date = value;
    }

    public get time() {
        return this._time;
    }
    public set time(value: any | null) {
        this._time = value;
    }

    public get timeString() {
        return this._date ? this._date.local().format('HH:mm') : null;
    }

    public get isEditing() {
        return this._isEditing;
    }
    public set isEditing(value: boolean) {
        this._isEditing = value;
    }

    public setTime() {
        this._date = this._date ? this._date.local().hour(this._time.substr(0, 2)) : null;
        this._date = this._date ? this._date.local().minute(this._time.substr(3, 5)) : null;
    }

    public toServer() {
        this.setTime();
        return {
            replacementContactId: this._id,
            annotations: this._annotations,
            date:  this._date ? this._date.toISOString() : null
        };
    }
}
