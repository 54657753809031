import { Merit, MultiLanguage } from '@/Domain/Entities';
import { BaseMeritsType } from '../../enum';

export default class MeritSubGroup {
    protected _merits: Merit[];
    protected _title: MultiLanguage;
    protected _description: MultiLanguage;
    private _id: string;
    private _meritGroupId: string;
    private _maxScore: number;
    private _order: number;
    private _type: number;
    private _isEditing: boolean;
    private _userTotalScore: null;
    private _isCreatingMerit: boolean;
    private _isDropped: boolean;

    public constructor(data: any) {
        this._id = data.id;
        this._meritGroupId = data.meritGroupId;
        this._title = new MultiLanguage(data.title);
        this._description = new MultiLanguage(data.description);
        this._maxScore = data.maxScore;
        this._order = data.order;
        this._merits = data.merits ? data.merits.map(merit => new Merit(merit, data.meritGroupId, data.order)) : [];
        this._type = data.type || BaseMeritsType.enum.MERITSUBGROUP;
        this._isEditing = false;
        this._userTotalScore = null;
        this._isCreatingMerit = false;
        this._isDropped = false;
    }

    public get id() {
        return this._id;
    }

    public get meritGroupId() {
        return this._meritGroupId;
    }

    public set meritGroupId(meritGroupId: string) {
        this._meritGroupId = meritGroupId;
    }

    public get title() {
        return this._title;
    }

    public set title(title: MultiLanguage) {
        this._title = title;
    }

    public get description() {
        return this._description;
    }

    public set description(description: MultiLanguage) {
        this._description = description;
    }

    public get maxScore() {
        return this._maxScore;
    }

    public set maxScore(maxScore: number) {
        this._maxScore = maxScore;
    }

    public get order() {
        return this._order;
    }

    public set order(order: number) {
        this._order = order;
    }

    public get merits() {
        return this._merits;
    }

    public set merits(merits: Merit[]) {
        this._merits = merits;
    }

    public get type() {
        return this._type;
    }

    public set type(type: number) {
        this._type = type;
    }

    public get isEditing() {
        return this._isEditing;
    }

    public set isEditing(isEditing: boolean) {
        this._isEditing = isEditing;
    }

    public get userTotalScore() {
        return this._userTotalScore;
    }

    public get isCreatingMerit() {
        return this._isCreatingMerit;
    }

    public set isCreatingMerit(isCreatingMerit: boolean) {
        this._isCreatingMerit = isCreatingMerit;
    }
    
    public get isDropped() {
        return this._isDropped;
    }

    public set isDropped(isDropped: boolean) {
        this._isDropped = isDropped;
    }

    public totalSubGroupScore(): number {
        let totalSubGroupScore = 0;
        this._merits.forEach(element => {
            if (element.userTotalScore) {
                totalSubGroupScore = totalSubGroupScore + element.userTotalScore;
            }
        });
        if ( this._maxScore && totalSubGroupScore > this._maxScore ) {
            totalSubGroupScore = this._maxScore;
        }
        return Number(totalSubGroupScore.toFixed(6));
    }

    public setPropsAfterEdit(meritSubGroupToEdit) {
        this.title = new MultiLanguage(meritSubGroupToEdit.title);
        this.description = new MultiLanguage(meritSubGroupToEdit.description);
        this.maxScore = meritSubGroupToEdit.maxScore;
    }

    public infoToServer() {
        return {
            id: this.id,
            meritGroupId: this.meritGroupId,
            title: this.title,
            description: this.description,
            maxScore: this.maxScore
        };
    }

    public toServerToInscription() {
        return {
            id: this._id,
            meritGroupId: this._meritGroupId
        };
    }

}
