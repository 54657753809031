import Enum from '../interfaces/IEnum';

export const PaymentsPlanStatusTypes: Enum = {
    enum: {
        OK: 0,
        WARNING: 1,
        EXCEED: 2
    },
    color: {
        0: '#1976d2',
        1: '#F5902F',
        2: '#ed6870'
    }
};
