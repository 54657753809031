import Enum from '../interfaces/IEnum';

export const MeritGroupType: Enum = {
    translations: {
        0: 'lang.shared.managementExperience',
        1: 'lang.shared.privateExperience',
        2: 'lang.shared.training',
        3: 'lang.shared.seniority',
        4: 'lang.shared.approvedExercises',
        5: 'lang.shared.academicRecord',
        6: 'lang.shared.personalGrade',
        7: 'lang.shared.specific',
        8: 'lang.shared.personalScale',
        9: 'lang.shared.otherMerits',
        10: 'lang.shared.expertice',
        11: 'lang.shared.furtherTraining',
        12: 'lang.shared.qualifications',
        13: 'lang.shared.languages',
        14: 'lang.shared.unemploymentSituation'
    },
    enum: {
        MANAGEMENT_EXPERIENCIE: 0,
        PRIVATE_EXPERIENCIE: 1,
        TRAINING: 2,
        SENIORITY: 3,
        APPROVED_EXERCISES: 4,
        ACADEMIC_RECORD: 5,
        PERSONAL_GRADE: 6,
        SPECIFIC: 7,
        PERSONAL_SCALE: 8,
        OTHER: 9,
        EXPERTICE: 10,
        FURTHERTRAINING: 11,
        QUALIFICATIONS: 12,
        LANGUAGES: 13,
        UNEMPLOYMENT_SITUATION: 14
    }
};
