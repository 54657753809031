import { FilterTypes } from '@/Domain/enum';

const filters = [
{
    type: FilterTypes.INPUT,
    label: 'lang.shared.search',
    param: ['title'],
    icon: 'fa-search'
}];

export default filters;
