import { CriteriaTypes } from '@/Domain/enum';

export default class Criteria {
    private _criteria: number;
    private _examId: string;
    private _numExams: number | null;
    private _showOnlyApproved: boolean;
    private _orderCriteria: number;

    public constructor(data: any) {
        this._criteria = !data.criteria && data.criteria !== 0 ? CriteriaTypes.enum.NOCRITERIA : data.criteria;
        this._examId = data.examId || data.examId === 0 ? data.examId : '0';
        this._numExams = data.numExams ? data.numExams : null;
        this._showOnlyApproved = data.showOnlyApproved || false;
        this._orderCriteria = data.orderCriteria || 0;
    }

    public get criteria() {
        return this._criteria;
    }
    public set criteria(value: number) {
        this._criteria = value;
    }

    public get examId() {
        return this._examId;
    }
    public set examId(value: string) {
        this._examId = value;
    }

    public get numExams() {
        return this._numExams;
    }
    public set numExams(value: number | null) {
        this._numExams = value;
    }

    public get showOnlyApproved() {
        return this._showOnlyApproved;
    }
    public set showOnlyApproved(value: boolean) {
        this._showOnlyApproved = value;
    }

    public get orderCriteria() {
        return this._orderCriteria;
    }
    public set orderCriteria(orderCriteria: number) {
        this._orderCriteria = orderCriteria;
    }

    public toServer() {
        if (this._numExams) {
            this.examId = '0';
        }
        return {
            criteria: this._criteria,
            examId: this._examId !== undefined && this._examId !== '0' ? this._examId : '',
            numExams: this._numExams ? this._numExams : 0,
            showOnlyApproved: this._showOnlyApproved,
            orderCriteria: this.orderCriteria
        };
    }
}
