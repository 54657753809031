import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidityError } from '@/Domain/enum/index';
import { MultiLanguage } from '@/Domain/Entities';
import ValidationService from '@/Application/Services/ValidationService';

/**
 * Text type input component
 */
@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                if (this.$props.type === 'number' && !this.$props.step) {
                    this.$emit('input', parseInt(value, 10));
                } else {
                    this.$emit('input', value);
                }
            }
        }
    }
})
export default class MdInput extends Vue {
    @Prop() value: any;
    @Prop() type?: string;
    @Prop() icon?: string;
    @Prop() label?: string;
    @Prop() required?: string;
    @Prop() max?: number;
    @Prop() min?: number;
    @Prop() maxlength?: number;
    @Prop() hasArrows?: boolean;
    @Prop() disabled?: boolean;
    @Prop() step?: any;
    @Prop() validateNIF?: boolean;
    @Prop({ default: false }) validateEmail!: boolean;
    @Prop() validatePhone?: boolean;
    @Prop() validatePassword?: boolean;
    @Prop() validatePostCode?: boolean;
    @Prop() multiRequired?: boolean;
    @Prop() percentage?: boolean;
    @Prop() money?: boolean;
    /** Implements the translate button */
    @Prop() hasAutoTranslate?: boolean;
    /** Multilanguage object to translate */
    @Prop() contentToTranslate?: MultiLanguage;

    dirty: boolean = false;
    focused: boolean = false;
    showingPassword: boolean = false;
    validity = null;
    ValidityError = ValidityError;

    $refs!: {
        input: HTMLInputElement
    };

    /**
     * @public
     * Fill the input with the translated content
     * @param {$event} - Translated content
     */
    translatedContent($event) {
        this.$emit('input', $event);
    }

    showingPass() {
        this.showingPassword = !this.showingPassword;
        (this.$refs.input as any).type = this.showingPassword ? 'text' : 'password';
    }

    mounted() {
        (this.validity as any) = this.$refs.input;
    }

    isValidDni() {
        return (ValidationService.isValidNif(this.value) || ValidationService.isValidNie(this.value));
    }

    isValidEmail() {
        return (ValidationService.isValidMail(this.value));
    }

    isValidPostCode() {
        return (ValidationService.isValidPostCode(this.value));
    }

    isValidPassword() {
        return ValidationService.isValidPassword(this.value);
    }

    isValidinput() {
        if (this.$refs.input && this.dirty && this.validatePassword) {
            return this.$refs.input.validity.valid && (ValidationService.isValidPassword(this.value));
        }

        if (this.$refs.input && this.dirty && this.validateNIF) {
            return this.$refs.input.validity.valid && (ValidationService.isValidNif(this.value) || ValidationService.isValidNie(this.value));
        }

        if (this.$refs.input && this.dirty && this.validateEmail) {
            return this.$refs.input.validity.valid && (ValidationService.isValidMail(this.value));
        }

        if (this.$refs.input && this.dirty && !this.multiRequired) {
            return this.$refs.input.validity.valid;
        }

        if (this.$refs.input && this.dirty && this.multiRequired) {
            return !(this.required && !this.$refs.input.value.length);
        }

        if (this.$refs.input && this.dirty && this.validatePostCode) {
            return this.$refs.input.validity.valid && (ValidationService.isValidPostCode(this.value));
        }

        return true;
    }

    getValidityError() {
        if (this.$refs.input && this.$refs.input.validity.patternMismatch && this.validatePostCode) {
            return ValidityError.enum.INVALIDPOSTCODE;
        }
        if (this.$refs.input && this.$refs.input.validity.patternMismatch && this.validateEmail) {
            return ValidityError.enum.INVALIDEMAIL;
        }
        if (this.$refs.input && this.$refs.input.validity.patternMismatch) {
            return ValidityError.enum.TYPEMISMATCH;
        }
        if (this.$refs.input && this.$refs.input.validity.rangeUnderflow && !this.validatePhone) {
            return ValidityError.enum.RANGEUNDERFLOW;
        }
        if (this.$refs.input && this.$refs.input.validity.rangeOverflow) {
            return ValidityError.enum.RANGEOVERFLOW;
        }
        if (this.$refs.input && this.$refs.input.validity.stepMismatch) {
            return this.$props.step && !Number.isInteger(this.$props.step) ? ValidityError.enum.STEPMISMATCHWITHSTEP : ValidityError.enum.STEPMISMATCH;
        }
        if (this.$refs.input && this.$refs.input.validity.valueMissing) {
            return ValidityError.enum.VALUESMISSING;
        }
        if (this.$refs.input && this.validateNIF && (!ValidationService.isValidNif(this.value) || !ValidationService.isValidNie(this.value))) {
            return ValidityError.enum.INVALIDDNI;
        }
        if (this.$refs.input && this.validatePhone && this.$refs.input.validity.rangeUnderflow) {
            return ValidityError.enum.INVALIDPHONE;
        }
        if (this.$refs.input && this.validatePassword && (!ValidationService.isValidPassword(this.value))) {
            return ValidityError.enum.INVALIDPASSWORD;
        }
        if (this.$refs.input && this.multiRequired) {
            return ValidityError.enum.VALUESMISSING;
        }
    }

    onlyNumber($event: any) {
        const keyCode = ($event.which ? $event.which : $event.keyCode);
        const pattern = /^[0-9]$/;
        if (!pattern.test(String.fromCharCode(keyCode))) {
            $event.preventDefault();
        }
    }
}
