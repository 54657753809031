import { Component, Vue } from 'vue-property-decorator';
import { Filter, EntityOep, SorterNew, ProcessInfo, FilterElement } from '@/Domain/Entities';
import { ProcessStatus, ProcessType, ProcessPhases, OppositionType, TurnsTypesTranslations, CountryTypesTranslations, ProvincesTypesTranslations, StreetTypesTranslations } from '@/Domain/enum';
import { SdFilter, DoughnutChart, SdSlideOver } from '@/Components';
import EntityService from '@/Services/EntityService';
import ProcessesService from '@/Services/ProcessesService';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../lang';
import InscriptionService from '@/Services/InscriptionService';
import CsvService from '@/Services/CsvService';

@Component({
    components: {
        SdFilter,
        DoughnutChart
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        ...mapGetters('listManagementListStore', { filters: 'getFilters' }),
        orderedProcess() {
            return (this as ListManagementList).sorter.sortBy((this as ListManagementList).filter.filteredData);
        }
        // enrolledProcessIds() {
        //     return this.$store.state.userStore.enrolledProcessIds || [];
        // }
    },
    methods: {
        ...mapMutations('listManagementListStore', ['setFilters']),
        ...mapActions('listManagementListStore', ['setEntities'])
    },
    watch: {
        filter() {
            (this as ListManagementList).setFilters((this as ListManagementList).filter.filterElements);
        }
    }
})
export default class ListManagementList extends Vue {
    currentLanguage!: string;
    dateFormat!: string;
    filters!: FilterElement[];

    filter = new Filter();
    entityOepList: EntityOep[] = [];
    processList: ProcessInfo[] = [];
    currentProcess: ProcessInfo = new ProcessInfo({});
    chartLabels = ['Pendientes', 'Admitidos', 'Excluidos'];
    chartColors = ['#DADFE3', '#6ACF80', '#ED6870'];
    ProcessStatus = ProcessStatus;
    ProcessPhases = ProcessPhases;
    OppositionType = OppositionType;
    ProcessType = ProcessType;
    TurnsTypesTranslations = TurnsTypesTranslations;
    CountryTypesTranslations = CountryTypesTranslations;
    ProvincesTypesTranslations = ProvincesTypesTranslations;
    StreetTypesTranslations = StreetTypesTranslations;
    loading = true;
    sorter = new SorterNew(['bopDate', 'title'], ['desc', 'asc']);
    showDesertProcessModal: boolean = false;
    processDesertToDelete: ProcessInfo = new ProcessInfo({});
    showSlideOver: boolean = false;
    showModalFieldEncoding: boolean = false;
    csvToUpload: any[] = [];
    file: any = null;    
    showModalError: boolean = false;
    candidatesSuccess: null | number = null;
    candidatesError: null | number = null;
    urlDownloadCsv: string = '';
    showFilter: boolean = true;

    setFilters!: (arg1: FilterElement[]) => void;
    setEntities!: (arg1: Array<{id: string, name: string}>) => void;

    $refs!: {
        sdSlideOver: SdSlideOver
    };

    closeModalError() {
        this.showModalError = false;
    }

    downloadCsvErrors() {
        CsvService.getCsvFromBase64(this.urlDownloadCsv, i18n.t('lang.shared.inscriptionsWithErrors') as string);
    }
    
    sortBy(key: string) {
        this.sorter.updateKeys([key]);
    }

    getCsvTemplate() {
        InscriptionService.getCSVTemplate();
    }
    
    setLoadCandidates(process: ProcessInfo) {
        if (process.phase > ProcessPhases.enum.INSCRIPTIONS) {
            ToasterService.showError(i18n.t('lang.listManagement.loadCandidatesOnlyInscriptionPhase') as string);
            return;
        }
        this.currentProcess = process;
        this.showSlideOver = true;
    }

    toggleSlideOver() {
        this.removeFile();
        this.showSlideOver = !this.showSlideOver;
    }

    showFieldEncoding() {
        this.showModalFieldEncoding = true;
    }
    
    closeModalFieldEncoding() {
        this.showModalFieldEncoding = false;
    }

    removeFile() {
        this.file = null;
        this.csvToUpload = [];
    }

    addFile(file: any) {
        file.fileName = file.name;
        file.allowRemove = true;
        this.csvToUpload.push(file);

        const data: any = {
            processId: this.currentProcess.id,
            formFile: file
        };
        
        this.file = data;
    }

    importCsvLoadCandidates() {
        InscriptionService.importCSVLoadCandidates(this.file).then((response: any) => {
            this.candidatesSuccess = response.inscriptionsLoaded.length;
            if (response.errorCsv) {
                this.candidatesError = response.errorCount;
                this.urlDownloadCsv = response.errorCsv; 
                this.showModalError = true;
            } else {
                ToasterService.showSuccess(i18n.t('lang.toaster.successCandidatesLoad', { candidatesNumber: this.candidatesSuccess}) as any);
            }
            this.initList();
            this.toggleSlideOver();
            this.$refs.sdSlideOver.setOverflowAutoToBody();
        });
    }

    getEntityList() {
        EntityService.getEntityList()
            .then(response => {
                this.entityOepList = response.map(elem => new EntityOep(elem));
                this.processList.forEach(process => process.setEntityName(this.entityOepList));
                this.setEntities(this.entityOepList);
                this.showFilter = false;
                this.filter.setFilterElements(this.filters);
                this.filter.setData(this.processList);
                this.loading = false;
                this.$nextTick(() => {
                    this.showFilter = true;
                });
            })
            .catch(error => console.log(error));
    }

    getProcessList() {
        ProcessesService.getProcessListInscription()
            .then(response => {
                this.processList = response.map(processInfo => new ProcessInfo(processInfo));
                this.getEntityList();
            })
            .catch(error => console.log(error));
    }

    finishDesertProcess(processId: string) {
        ProcessesService.finishEmptyProcess(processId)
            .then(() => {
                ToasterService.showSuccess(i18n.t('lang.toaster.processFinishedSuccess') as string);
                this.manageFinishDesertProcessModal();
                this.getProcessList();
            })
            .catch(error => {
                console.log(error);
            });
    }

    manageFinishDesertProcessModal(processToDelete: ProcessInfo = new ProcessInfo({})) {
        if (!processToDelete.id) {
            this.showDesertProcessModal = false;
            this.processDesertToDelete = new ProcessInfo({});
            return;
        }
        this.processDesertToDelete = processToDelete;
        this.showDesertProcessModal = true;
    }

    initList() {
        this.loading = true;
        this.getProcessList();
    }

    mounted() {
        this.initList();
    }
}
