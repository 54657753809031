import { ReplacementState, ReplacementListRelated } from '..';

export default class ReplacementInscriptionData {
    private _replacementStatus: number;
    private _statusList: ReplacementState[];
    private _replacementListRelatedList: ReplacementListRelated[] | null;

    public constructor(data: any) {
        this._replacementStatus = data.replacementStatus;
        this._statusList = data.replacementStates.map(replacementState => new ReplacementState(replacementState));
        this._replacementListRelatedList = data.replacementListRelated && data.replacementListRelated.length > 0 ? data.replacementListRelated.map(replacementList => new ReplacementListRelated(replacementList)) : null;
    }

    public get replacementStatus() {
        return this._replacementStatus;
    }

    public set replacementStatus(replacementStatus: number) {
        this._replacementStatus = replacementStatus;
    }

    public get statusList() {
        return this._statusList;
    }

    public get replacementListRelatedList() {
        return this._replacementListRelatedList;
    }
}
