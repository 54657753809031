import { OepDocument } from '@/Domain/Entities';
import { EditionType, FileTypes, InscriptionStatus, ProcessPhases } from '@/Domain/enum';

export default class InscriptionFile extends OepDocument {
    private _requirementId: string;
    private _evaluableConditionType: number;
    private _loading: boolean;
    private _meritGroupId: string;
    private _meritSubGroupId: string;
    private _meritId: string;
    private _examId: string;
    private _allowRemove: boolean = false;

    constructor(data: any) {
        super(data);
        this._evaluableConditionType = data.evaluableConditionType;
        this._requirementId = data.requirementId;
        this._loading = data.loading;
        this.fileName = data.fileName || data.name;
        this._meritGroupId = data.meritGroupId;
        this._meritSubGroupId = data.meritSubGroupId;
        this._meritId = data.meritId;
        this._examId = data.examId;
    }

    public get requirementId(): string {
        return this._requirementId;
    }

    public get evaluableConditionType(): number {
        return this._evaluableConditionType;
    }
    public get loading(): boolean {
        return this._loading;
    }
    public set loading(loading: boolean) {
        this._loading = loading;
    }
    public get meritGroupId(): string {
        return this._meritGroupId;
    }
    public get meritSubGroupId(): string {
        return this._meritSubGroupId;
    }
    public get meritId(): string {
        return this._meritId;
    }
    public get examId(): string {
        return this._examId;
    }
    public get allowRemove(): boolean {
        return this._allowRemove;
    }
    public set allowRemove(allowRemove: boolean) {
        this._allowRemove = allowRemove;
    }

    public setAllowRemove(processPhase: number, inscriptionStatus: number) {
        if (this.editionType === EditionType.enum.ADMININSCRIPTION) {
            if (this.evaluableConditionType === FileTypes.enum.REQUIREMENT || this.evaluableConditionType === FileTypes.enum.CREDITABLEEXAMS) {
                this.allowRemove = processPhase === ProcessPhases.enum.INSCRIPTIONS;
                return;
            }
            if (this.evaluableConditionType === FileTypes.enum.MERIT && inscriptionStatus === InscriptionStatus.enum.DRAFT) {
                this.allowRemove = true;
                return;
            }
        }
    }
}
