import { Component, Prop, Vue } from 'vue-property-decorator';
import { MdInput } from '@/Components';

@Component({
    components: {
        MdInput
    },
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
})
export default class MdTimePicker extends Vue {
    @Prop() value: any;
    @Prop() disabled?: boolean;
    focused: boolean = false;

    setFocusedOn() {
        this.focused = true;
    }

    setFocusedOff() {
        this.focused = false;
    }
}
