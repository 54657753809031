import { Component, Prop, Vue } from 'vue-property-decorator';
import i18n from '../../lang';
import { TranslateResult } from 'vue-i18n';

@Component({
    computed: {
        content: {
            get() {
                return this.$props.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isRequired() {
            return this.$props.required && ( typeof this.$props.value !== 'number' && !this.$props.value || typeof this.$props.value === 'number' && isNaN(Number(this.$props.value))) && (this.$data.dirty || this.$props.submitted);
        },
        optionsToManage() {
            if (Array.isArray(this.$props.options) && typeof this.$props.options[0] === 'object') {
                return this.$props.options.map( element => ({name: i18n.t(element.name), id: element.id, title: i18n.t(element.title) || i18n.t(element.name), disabled: element.disabled}));
            }
            if (Array.isArray(this.$props.options) && typeof this.$props.options[0] !== 'object') {
                return this.$props.options.map( element => ({name: element, disabled: element.disabled}));
            }
            if (!Array.isArray(this.$props.options)) {
                const optionsToManage: any[] = [];
                for (const element in this.$props.options) {
                    if (this.$props.options[element]) {
                        optionsToManage.push({name: i18n.t(this.$props.options[element]), id: element, title: i18n.t(this.$props.options[element]), disabled: this.$props.options[element].disabled});
                    }
                }
                return optionsToManage.sort((a, b) =>  a.name.localeCompare(b.name));
            }
        }
    }
})
export default class SdSelect extends Vue {
    @Prop() value: any;
    @Prop() label?: string;
    @Prop() options!: any | any[];
    @Prop() required?: string;
    @Prop() select?: string;
    @Prop() disabled?: boolean;
    @Prop() submitted?: boolean;
    dirty: boolean = false;
    focused: boolean = false;
    dataReady: boolean = false;
    optionalText: TranslateResult | string = '';

    mounted() {
        this.$nextTick(() => {
            this.$data.dataReady = true;
        });
        this.setOptionalText();
    }

    private setOptionalText(): void {
        this.optionalText = !this.$props.required && this.$props.label !== undefined ? i18n.t('lang.input.optional') : '';
    }
}
