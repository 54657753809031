import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/dashboard/';

export default class DashboardService {

    public static async getCandidatesYear(year: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('candidates/' + year, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessesReplacementListsYear(year: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('processesReplacementLists/' + year, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessesInscriptionPhaseYear(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('/processes/inscriptionPhase', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getProcessesEvaluationPhaseYear(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('/processes/evaluationPhase', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getReplacementListsActive(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('/replacementLists/active', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

}
