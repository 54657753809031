import Enum from '../interfaces/IEnum';

export const ReasonChange: Enum = {
    translations: {
        0: 'lang.reasonChange.refused',
        1: 'lang.reasonChange.excluded'
    },
    enum: {
        REFUSED : 0,
        REJECTED : 1
    }
};
