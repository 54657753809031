
import { ManagementTabs } from '../../enum';
import TabsHash from './TabsHash';

export default class TabsHashWithActiveTab extends TabsHash {

    selectElementWithRouteHash($route) {
        return ManagementTabs.name[$route.hash];
    }
    selectElementWithParamsHash($route, $router) {
        $router.push({ name: 'ListManagementEdit', hash: $route.params.hash });
        return ManagementTabs.name[$route.params.hash];
    }
    selectElementWithoutHash() {
        return {list: null, elementActive: null};
    }

}
