import Vue from 'vue';
import App from './App.vue';
import ProgressBar from 'vuejs-progress-bar';
import { router } from './router';
import i18n from './lang';
import ResponseIntercerptor from '@/Services/Interceprtors/Response.Interceptor';
import { store } from './Store';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import CookiesService from './Services/CookiesService';

Vue.config.productionTip = false;

ResponseIntercerptor();

const urlAPM = process.env.VUE_APP_APM_SERVER_URL;
const configAPM = {
  serviceName: 'admin',
  serverUrl: urlAPM,
  serviceVersion: '',
  logLevel: 'debug',
  instrument: true,
  environment: process.env.VUE_APP_APM_ENVIRONMENT,
  active: true
};

CookiesService.manageCookies();

Vue.use(ApmVuePlugin, {
  router,
  config: urlAPM !== '' && process.env.VUE_APP_APM_ENVIRONMENT !== 'localhostApm' ? configAPM : { active: false }
});

Vue.use(ProgressBar);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
