import { BaseMeritsType, GradeType, MeritGroupType, RuleCriteriaType } from '@/Domain/enum';
import { RuleTieBreakCriteriaGroup, RuleTieBreakCriteria, MeritGroup, Exam, MeritSubGroup, Merit } from '@/Domain/Entities';

export default class RuleTieBreak {
    private _ruleId: string;
    private _name: string;
    private _ruleTieBreakCriteriaGroups: RuleTieBreakCriteriaGroup[];
    private _meritGroups!: MeritGroup[];
    private _exams!: Exam[];
    private _meritElements: Array<{ id: string, description: any, type: number | null}> = [];

    public constructor(data: any) {
        this._ruleId = data.ruleId;
        this._name = data.name;
        this._ruleTieBreakCriteriaGroups = data.ruleTieBreakCriteriaGroups ? data.ruleTieBreakCriteriaGroups.map((ruleTieBreakGroup: RuleTieBreakCriteriaGroup) => new RuleTieBreakCriteriaGroup(ruleTieBreakGroup)) : [new RuleTieBreakCriteriaGroup({})];
    }

    public get ruleId() {
        return this._ruleId;
    }

    public set ruleId(ruleId: string) {
        this._ruleId = ruleId;
    }

    public get name() {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get ruleTieBreakCriteriaGroups() {
        return this._ruleTieBreakCriteriaGroups;
    }

    public set ruleTieBreakCriteriaGroups(ruleTieBreakCriteriaGroups: RuleTieBreakCriteriaGroup[]) {
        this._ruleTieBreakCriteriaGroups = ruleTieBreakCriteriaGroups;
    }

    public get meritGroups() {
        return this._meritGroups;
    }

    public set meritGroups(meritGroups: MeritGroup[]) {
        this._meritGroups = meritGroups;
        this.prepareMerits();
    }

    public get meritElements() {
        return this._meritElements;
    }

    public get exams() {
        return this._exams;
    }

    public set exams(exams: Exam[]) {
        const examsFiltered = exams.filter(exam => exam.gradeType === GradeType.enum.SCORE);
        this._exams = examsFiltered;
    }

    anyFieldIsEmpty() {
        const criterias = this.getCriterias();
        return !this.name || !criterias.length || this.anyRuleTieBreakCriteriaIsEmpty();
    }

    anyRuleTieBreakCriteriaIsEmpty() {
        const criterias = this.getCriterias();
        return criterias.some(cr => cr.ruleType === undefined) || criterias.some(cr => cr.ruleDescription === null || cr.ruleDescription === '');
    }

    allTypeSelectIsUsed() {
        const criterias = this.getCriterias();
        const enumKeys = Object.values(RuleCriteriaType).filter(value => (typeof value === 'number' && value !== RuleCriteriaType.MANUAL) && (value <= RuleCriteriaType.NAME || value > RuleCriteriaType.MERITSUBGROUP));
        const exams = this.exams;
        const meritElements = this.meritElements;
        return criterias.length === exams.length + meritElements.length + enumKeys.length;
    }

    getCriterias() {
        const ruleTieBreakCriterias: RuleTieBreakCriteria[] = [];
        this._ruleTieBreakCriteriaGroups.forEach(group => {
            group.ruleTieBreakCriterias.forEach(criteria => {
                ruleTieBreakCriterias.push(criteria);
            });
        });
        return ruleTieBreakCriterias;
    }

    optionIsUsed(type: number) {
        const criterias = this.getCriterias();
        return criterias.some(criteria => !criteria.isCriteriaExamOrMeritType() && criteria.ruleType === type);
    }

    setRuleType(ruleTieBreakCriteria: RuleTieBreakCriteria, type: number) {
        if (this.isRuleCriteriaTypeDisabled(type)) {
            return;
        }
        ruleTieBreakCriteria.ruleType = type;
        ruleTieBreakCriteria.ruleDescription = null;
        this.orderCriteriaGroups();
    }

    manageCriteriasGrouped(ruleTieBreakCriteria: RuleTieBreakCriteria) {
        const originGroupOfRuleTieBreakCriteria = this.ruleTieBreakCriteriaGroups[ruleTieBreakCriteria.groupPosition];
        const targetGroupOfRuleTieBreakCriteria = this.ruleTieBreakCriteriaGroups[ruleTieBreakCriteria.groupPosition - 1];

        if (ruleTieBreakCriteria.isGrouped) {
            targetGroupOfRuleTieBreakCriteria.ruleTieBreakCriterias = targetGroupOfRuleTieBreakCriteria.ruleTieBreakCriterias.concat(originGroupOfRuleTieBreakCriteria.ruleTieBreakCriterias);
            this.removeGroup(originGroupOfRuleTieBreakCriteria);
        } else {
            const criterias = originGroupOfRuleTieBreakCriteria.ruleTieBreakCriterias.splice(ruleTieBreakCriteria.order);
            this.ruleTieBreakCriteriaGroups.splice(ruleTieBreakCriteria.groupPosition + 1, 0, new RuleTieBreakCriteriaGroup({position: ruleTieBreakCriteria.groupPosition + 1, ruleTieBreakCriterias: criterias}));
        }
        
        this.orderCriteriaGroups();
    }

    orderCriteriaGroups() {
        this.ruleTieBreakCriteriaGroups.forEach((group, groupIndex) => {
            group.position = groupIndex;
            group.ruleTieBreakCriterias.forEach((ruleTieBreak, indexRuleTieBreak) => {
                ruleTieBreak.groupPosition = groupIndex;
                ruleTieBreak.order = indexRuleTieBreak;
                this.setRuleTieBreakCriteriaGroupedAndGroupable(ruleTieBreak);
            });
        });
    }

    setRuleTieBreakCriteriaGroupedAndGroupable(ruleTieBreakCriteria: RuleTieBreakCriteria) {
        const indexRuleTieBreakCriteria = this.getCriterias().indexOf(ruleTieBreakCriteria);
        ruleTieBreakCriteria.isGrouped = this.ruleTieBreakCriteriaGroups[ruleTieBreakCriteria.groupPosition].ruleTieBreakCriterias.length > 1 && ruleTieBreakCriteria.order > 0;
        ruleTieBreakCriteria.isGroupable = this.getCriterias().length >= 2 && (ruleTieBreakCriteria.groupPosition !== 0 || ruleTieBreakCriteria.order !== 0) && ruleTieBreakCriteria.isCriteriaExamOrMeritType() && this.getCriterias()[indexRuleTieBreakCriteria - 1].isCriteriaExamOrMeritType();
    }

    removeGroup(groupToRemove: RuleTieBreakCriteriaGroup) {
        this.ruleTieBreakCriteriaGroups.splice(groupToRemove.position, 1);
    }

    addNewRuleTieBreakCriteriaGroup() {
        this.ruleTieBreakCriteriaGroups.push(new RuleTieBreakCriteriaGroup({ position: this.ruleTieBreakCriteriaGroups.length }));
    }

    removeCriteria(ruleTieBreakCriteriaGroup: RuleTieBreakCriteriaGroup, ruleTieBreakCriteria: RuleTieBreakCriteria) {
        if (this.getCriterias().length === 1) {
            return;
        }

        ruleTieBreakCriteriaGroup.ruleTieBreakCriterias = ruleTieBreakCriteriaGroup.ruleTieBreakCriterias.filter(criteria => criteria.ruleTieBreakCriteriaId !== ruleTieBreakCriteria.ruleTieBreakCriteriaId);

        if (ruleTieBreakCriteriaGroup.ruleTieBreakCriterias.length === 0) {
            const indexToDelete = this.ruleTieBreakCriteriaGroups.indexOf(ruleTieBreakCriteriaGroup);
            this.ruleTieBreakCriteriaGroups.splice(indexToDelete, 1);
        }

        this.orderCriteriaGroups();
    }

    prepareMerits() {
        this.meritGroups.forEach(group => {
            this._meritElements.push({ id: group.id, description: MeritGroupType.translations[group.groupType], type: null });
            group.meritChilds.forEach(child => {
                if (child instanceof MeritSubGroup) {
                    this._meritElements.push({ id: child.id , description: child.title, type: child.type });
                    child.merits.forEach(meritIntoSubGroup => {
                        this._meritElements.push({ id: meritIntoSubGroup.id , description: meritIntoSubGroup.description, type: meritIntoSubGroup.type });
                    });
                }
                if (child instanceof Merit) {
                    this._meritElements.push({ id: child.id , description: child.description, type: child.type });
                }
            });
        });
    }

    addExam(criteria, examId) {
        if (this.isExamDisabled(criteria, examId)) {
            return;
        }
        criteria.ruleDescription = examId;
    }

    addAge(criteria, typeAge) {
        criteria.ruleDescription = typeAge;
    }

    isExamDisabled(criteria, examId) {
        const examsCriterias = this.getCriterias().filter(examCriteria => examCriteria.isCriteriaExamType());
        const indexIterationCriteria = examsCriterias.indexOf(criteria);
        if (indexIterationCriteria !== -1) {
            examsCriterias.splice(indexIterationCriteria, 1);
        }
        return examsCriterias.some(examCriteria => examCriteria.ruleDescription && examCriteria.ruleDescription === examId);
    }

    addMerit(criteria, meritId) {
        if (this.isMeritDisabled(criteria, meritId)) {
            return;
        }
        criteria.ruleDescription = meritId;
    }

    isMeritDisabled(criteria, meritId) {
        const meritsCriterias = this.getCriterias().filter(meritCriteria => meritCriteria.isCriteriaMeritType());
        const indexIterationCriteria = meritsCriterias.indexOf(criteria);
        if (indexIterationCriteria !== -1) {
            meritsCriterias.splice(indexIterationCriteria, 1);
        }
        return meritsCriterias.some(meritCriteria => meritCriteria.ruleDescription && meritCriteria.ruleDescription === meritId);
    }

    isRuleCriteriaTypeDisabled(type) {
        if (this._meritGroups && this._meritGroups.length === 0 && (type === RuleCriteriaType.MERITGROUP || type === RuleCriteriaType.MERITSUBGROUP || type === RuleCriteriaType.MERITS)) {
            return true;
        }
        if (this._meritGroups && this._meritGroups.length > 0 && type === RuleCriteriaType.MERITSUBGROUP && this._meritGroups.every(meritGroup => meritGroup.meritChilds.every(child => child.type === BaseMeritsType.enum.MERIT))) {
            return true;
        }
        if (this._meritGroups && this._meritGroups.length > 0 && type === RuleCriteriaType.MERITGROUP) {
            return this.getCriterias().filter(criteria => criteria.ruleType === RuleCriteriaType.MERITGROUP).length === this.meritGroups.length;
        }
        if (this._meritGroups && this._meritGroups.length > 0 && type === RuleCriteriaType.MERITSUBGROUP && this._meritGroups.some(meritGroup => meritGroup.meritChilds.some(child => child.type === BaseMeritsType.enum.MERITSUBGROUP))) {
            return this.getCriterias().filter(criteria => criteria.ruleType === RuleCriteriaType.MERITSUBGROUP).length === this.meritElements.filter(element => element.type === BaseMeritsType.enum.MERITSUBGROUP).length;
        }
        if (this._meritGroups && this._meritGroups.length > 0 && type === RuleCriteriaType.MERITS) {
            return this.getCriterias().filter(criteria => criteria.ruleType === RuleCriteriaType.MERITS).length === this.meritElements.filter(element => element.type === BaseMeritsType.enum.MERIT).length;
        }
        if (this._exams && this._exams.length === 0 && type === RuleCriteriaType.EXAMS) {
            return true;
        }
        if (this._exams && this._exams.length > 0 && type === RuleCriteriaType.EXAMS) {
            return this.getCriterias().filter(criteria => criteria.ruleType === RuleCriteriaType.EXAMS).length === this.exams.length;
        }

        return this.optionIsUsed(type);
    }

    public toServer() {
        const ruleTieBreakCriteriaGroupsToServer = this.ruleTieBreakCriteriaGroups.map(criteriaGroups => criteriaGroups.toServer());
        return {
            ruleId: this._ruleId,
            name: this._name,
            ruleTieBreakCriteriaGroups: ruleTieBreakCriteriaGroupsToServer
        };
    }
}
