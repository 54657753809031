import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/processes';

export default class ExamService {

    public static async getExam(processId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(processId + '/exams', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addExam(processId: string, exam: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.post(processId + '/exams', exam, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async editExam(processId: string, exam: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/exams/' + exam.id, exam, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeExam(processId: string, exam: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(processId + '/exams/' + exam.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async setExamOrder(processId: string, examOrder: string[]): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/exams', examOrder, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async setPercentageExams(processId: string, percentageExam: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch(processId + '/percentageExams', percentageExam, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
