import { Contract } from '@/Domain/Entities';
import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/replacementlistcandidate';

export default class ReplacementContractsService {
    public static async getReplacementContracts(replacementListCandidateId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(replacementListCandidateId + '/ReplacementContracts', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async createReplacementContract(replacementListCandidateId: string, contract: Contract): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(replacementListCandidateId + '/ReplacementContracts', contract.toServer(), { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateReplacementContract(replacementListCandidateId: string, contract: Contract): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(replacementListCandidateId + '/ReplacementContracts/' + contract.id, contract.toServer(), { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeReplacementContract(replacementListCandidateId: string, contract: Contract): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(replacementListCandidateId + '/ReplacementContracts/' + contract.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
