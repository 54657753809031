import Tab from './Tab';
import TabFilterProvisional from './TabFilterProvisional';

export default class TabProvisional extends Tab {
    private _count: number;
    private _totalInscriptions: number;
    private _filter: TabFilterProvisional;

    constructor(data: any) {
        super(data.turn);
        this._count = data.inListCount || 0;
        this._totalInscriptions = data.count;
        this._filter = new TabFilterProvisional();
    }

    public get count() {
        return this._count;
    }
    public set count(count: number) {
        this._count = count;
    }

    public get totalInscriptions() {
        return this._totalInscriptions;
    }
    public set totalInscriptions(totalInscriptions: number) {
        this._totalInscriptions = totalInscriptions;
    }

    public get filter() {
        return this._filter;
    }
    public set filter(filter: TabFilterProvisional) {
        this._filter = filter;
    }

    public addCount() {
        this.count++;
    }

    public subtractCount() {
        this.count--;
    }

    public resetCount() {
        this.count = 0;
    }
}
