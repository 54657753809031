import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SdTag extends Vue {
    @Prop() text!: string;
    @Prop() icons?: string[];
    @Prop() hoverTrigger?: boolean;
    @Prop() mode?: string;
    @Prop() backgroundColor?: string;
    @Prop() color?: string;
}
