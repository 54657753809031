import { Component, Prop, Vue } from 'vue-property-decorator';

enum IconPosition {
    left = 'left',
    right = 'right'
}
interface IconWithoutTooltip {
    name: string;
    position: IconPosition.left | IconPosition.right;
}
type IconWithToolip = IconWithoutTooltip & {
    hasTooltip: boolean
};
type Icon = IconWithoutTooltip | IconWithToolip;

@Component
export default class SdButton extends Vue {
    @Prop() disabled?: boolean;
    @Prop() icons!: Icon[];
    @Prop() colorIcon?: string;
    @Prop() size?: string;
    @Prop() mode?: string;
    @Prop() label?: string;
    @Prop() type?: string;
    @Prop() refElement?: string;
    @Prop() titleTooltip?: string;
    @Prop() textTooltip?: string;
    rigthIcons: Icon[] = [];
    leftIcons: Icon[] = [];

    setIcons() {
        if (!this.icons) {
            return;
        }
        this.leftIcons = this.icons.filter(icon => icon.position === IconPosition.left);
        this.rigthIcons = this.icons.filter(icon => icon.position === IconPosition.right);
    }

    mounted() {
        this.setIcons();
    }
}
