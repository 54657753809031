import OepDocument from '../OepDocument';
import moment from 'moment';

export default class JustificationDocument {
    private _date: moment.Moment | null;
    private _time: any | null;
    private _registryReference: string | null;
    private _origin: number;
    private _loading: boolean;
    private _isNewDocument: boolean;
    private _document: OepDocument | any;
    private _justificationDocumentId: string | null;
    private _isEditing: boolean;
    private _registryErrorDate: moment.Moment | null;

    constructor(file: any) {
        this._date = file.registryDate ? moment.utc(file.registryDate).local() : null;
        this._time = file.registryDate ? moment.utc(file.registryDate).local().format('HH:mm') : null;
        this._registryReference = file.registryReference || null;
        this._origin = file.origin;
        this._loading = false;
        this._isNewDocument = false;
        this._document = file.id ? new OepDocument(file) : null;
        this._justificationDocumentId = file.id || null;
        this._isEditing = false;
        this._registryErrorDate = file.registryErrorDate ? moment.utc(file.registryErrorDate).local() : null;
    }

    public get date() {
        return this._date;
    }

    public set date(value: moment.Moment | null) {
        this._date = value;
    }

    public get time(): any {
        return this._time;
    }

    public set time(value: any) {
        this._time = value;
    }

    public get timeString() {
        return this._date ? this._date.local().format('HH:mm') : null;
    }

    public get registryReference(): string | null {
        return this._registryReference;
    }
    public set registryReference(value: string | null) {
        this._registryReference = value;
    }

    public get origin(): number {
        return this._origin;
    }
    public set origin(value: number) {
        this._origin = value;
    }

    public get loading(): boolean {
        return this._loading;
    }

    public set loading(value: boolean) {
        this._loading = value;
    }

    public get isNewDocument(): boolean {
        return this._isNewDocument;
    }

    public set isNewDocument(value: boolean) {
        this._isNewDocument = value;
    }
    
    public get document(): OepDocument | any {
        return this._document;
    }
    
    public set document(value: OepDocument | any) {
        this._document = value;
    }
    
    public get justificationDocumentId(): string | null {
        return this._justificationDocumentId;
    }
    
    public set justificationDocumentId(value: string | null) {
        this._justificationDocumentId = value;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    public set isEditing(value: boolean) {
        this._isEditing = value;
    }

    public get registryErrorDate(): moment.Moment | null {
        return this._registryErrorDate;
    }

    public set registryErrorDate(value: moment.Moment | null) {
        this._registryErrorDate = value;
    }

    public setTime() {
        this._date = this._date ? this._date.local().hour(this._time.substr(0, 2)) : null;
        this._date = this._date ? this._date.local().minute(this._time.substr(3, 5)) : null;
    }

    public info() {
        this.setTime();
        return {
            registryDate: this._date ? this._date.toISOString() : null,
            registryReference: this._registryReference,
            origin: this._origin,
            id: this._document ? this._document.id : null
        };
    }
}
