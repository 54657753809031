import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/processes';

export default class MeritsService {
    public static async editMerit(processId: string, merit: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/MeritGroups/' + merit.meritGroupId + '/merits/' + merit.id, merit, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addMerit(processId: string, merit: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(processId + '/MeritGroups/' + merit.meritGroupId + '/merits', merit, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeMerit(processId: string, merit: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(processId + '/MeritGroups/' + merit.meritGroupId + '/merits/' + merit.id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async setMeritsOrder(processId: string, meritsOrder: string[], merit: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(processId + '/MeritGroups/' + merit.meritGroupId + '/merits', meritsOrder, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

}
