import { MultiLanguage } from '@/Domain/Entities';

export default class EntityOep {
    private _id: string;
    private _name: string;
    private _authorizationLegalText: MultiLanguage;
    private _dataProtectionText: MultiLanguage;
    private _legalConsentText: MultiLanguage;
    private _refuseAuthorizationText: MultiLanguage;

    constructor(entity: any) {
        this._id = entity.id;
        this._name = entity.name;
        this._authorizationLegalText = new MultiLanguage(entity.authorizationLegalText);
        this._dataProtectionText = new MultiLanguage(entity.dataProtectionText);
        this._legalConsentText = new MultiLanguage(entity.legalConsentText);
        this._refuseAuthorizationText = new MultiLanguage(entity.refuseAuthorizationText);
    }

    public get id() {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get name() {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    public get authorizationLegalText() {
        return this._authorizationLegalText;
    }
    public set authorizationLegalText(value: MultiLanguage) {
        this._authorizationLegalText = value;
    }
    public get dataProtectionText() {
        return this._dataProtectionText;
    }
    public set dataProtectionText(value: MultiLanguage) {
        this._dataProtectionText = value;
    }
    public get legalConsentText() {
        return this._legalConsentText;
    }
    public set legalConsentText(value: MultiLanguage) {
        this._legalConsentText = value;
    }
    public get refuseAuthorizationText() {
        return this._refuseAuthorizationText;
    }
    public set refuseAuthorizationText(value: MultiLanguage) {
        this._refuseAuthorizationText = value;
    }

    public toServer() {
        return {
            id: this._id,
            name: this._name,
            authorizationLegalText: this._authorizationLegalText,
            dataProtectionText: this._dataProtectionText,
            legalConsentText: this._legalConsentText,
            refuseAuthorizationText: this._refuseAuthorizationText
        };
    }
}
