export default class AuthorizationProcess {
    private _refuseAuthorization: boolean;
    
    public constructor(data: any) {
        this._refuseAuthorization = !data.acceptsAuthorization;
    }

    public get refuseAuthorization() {
        return this._refuseAuthorization;
    }

    public set refuseAuthorization(refuseAuthorization: boolean) {
        this._refuseAuthorization = refuseAuthorization;
    }

    public toServer(inscriptionId?) {
        return {
            inscriptionId,
            acceptsAuthorization: !this._refuseAuthorization
        };
    }
}
