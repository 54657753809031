import Address from './InscriptionStructure/Address';
import ContactData from './InscriptionStructure/ContactData';
import DisabilityData from './InscriptionStructure/DisabilityData';
import PersonalDataDetails from './InscriptionStructure/PersonalDataDetails';

export default class ReplacementCandidate {
    _replacementListCandidateId!: string;
    _personalDataDetails!: PersonalDataDetails;
    _contactData!: ContactData;
    _turn!: number;
    _disabilityData!: DisabilityData;
    _approvedExams!: number;
    _totalScore!: number;
    _totalGradeScore!: number;
    _address!: Address;

    constructor(data: any) {
        this._replacementListCandidateId = data.replacementListCandidateId ? data.replacementListCandidateId : '';
        this._personalDataDetails = data.personalDataDetails ? new PersonalDataDetails(data.personalDataDetails) : new PersonalDataDetails({});
        this._contactData = data.contactData ? new ContactData(data.contactData) : new ContactData({});
        this._turn = data.turn !== undefined && data.turn !== null ? data.turn : null;
        this._disabilityData = data.disabilityData ? new DisabilityData(data.disabilityData) : new DisabilityData({});
        this._approvedExams = data.approvedExams ? data.approvedExams : 0;
        this._totalScore = data.totalScore ? data.totalScore : 0;
        this._totalGradeScore = data.totalGradeScore ? data.totalGradeScore : 0;
        this._approvedExams = data.approvedExams ? data.approvedExams : 0;
        this._address = data.address ? new Address(data.address) : new Address({});
    }

    get replacementListCandidateId(): string {
        return this._replacementListCandidateId;
    }
    
    set replacementListCandidateId(value: string) {
        this._replacementListCandidateId = value;
    }
    
    get personalDataDetails(): PersonalDataDetails {
        return this._personalDataDetails;
    }
    
    set personalDataDetails(value: PersonalDataDetails) {
        this._personalDataDetails = value;
    }
    
    get contactData(): ContactData {
        return this._contactData;
    }
    
    set contactData(value: ContactData) {
        this._contactData = value;
    }
    
    get turn(): number {
        return this._turn;
    }
    
    set turn(value: number) {
        this._turn = value;
    }
    
    get disabilityData(): DisabilityData {
        return this._disabilityData;
    }
    
    set disabilityData(value: DisabilityData) {
        this._disabilityData = value;
    }
    
    get approvedExams(): number {
        return this._approvedExams;
    }
    
    set approvedExams(value: number) {
        this._approvedExams = value;
    }
    
    get totalScore(): number {
        return this._totalScore;
    }
    
    set totalScore(value: number) {
        this._totalScore = value;
    }
    
    get totalGradeScore(): number {
        return this._totalGradeScore;
    }
    
    set totalGradeScore(value: number) {
        this._totalGradeScore = value;
    }
    
    get address(): Address {
        return this._address;
    }
    
    set address(value: Address) {
        this._address = value;
    }

    public isValid(): boolean {
        return this.personalDataDetails.isValid() && this.contactData.isValid() 
            && this.address.isValid() && this.disabilityData.isValid()
            && this.turn !== undefined && this.turn !== null;
    }

    public toServer() {
        return {
            replacementListCandidateId: this.replacementListCandidateId,
            contactData: this.contactData.toServer(),
            personalDataDetails: this.personalDataDetails.toServerInscription(),
            disabilityData: this.disabilityData.toServerInscription(),
            address: this.address.toServerInscription(),
            turn: this.turn
        };
    }
}
