import { MultiLanguage } from '@/Domain/Entities';

export default class Requirement {
    private _title: MultiLanguage;
    private _description: MultiLanguage;
    private _allowsDocument: boolean;
    private _documentDescription: MultiLanguage;
    private _order: number;
    private _id: string;
    private _isEditing: boolean;
    private _isOptional: boolean;

    constructor(data: any) {
        this._title = new MultiLanguage(data.title);
        this._description = new MultiLanguage(data.description);
        this._allowsDocument = data.allowsDocument;
        this._documentDescription = new MultiLanguage(data.documentDescription);
        this._order = data.order;
        this._id = data.id || data.requirementId || '';
        this._isEditing = false;
        this._isOptional = data.isOptional ? data.isOptional : false;
    }

    public get id() {
        return this._id;
    }

    public get order() {
        return this._order;
    }
    public set order(order: number) {
        this._order = order;
    }

    public get title() {
        return this._title;
    }
    public set title(title: MultiLanguage) {
        this._title = title;
    }

    public get description() {
        return this._description;
    }
    public set description(description: MultiLanguage) {
        this._description = description;
    }

    public get allowsDocument() {
        return this._allowsDocument;
    }
    public set allowsDocument(allowsDocument: boolean) {
        this._allowsDocument = allowsDocument;
    }

    public get documentDescription() {
        return this._documentDescription;
    }
    public set documentDescription(documentDescription: MultiLanguage) {
        this._documentDescription = documentDescription;
    }

    public get isEditing() {
        return this._isEditing;
    }
    public set isEditing(isEditing: boolean) {
        this._isEditing = isEditing;
    }
        
    public get isOptional() {
        return this._isOptional;
    }
    public set isOptional(isOptional: boolean) {
        this._isOptional = isOptional;
    }

    public infoToServer() {
        return {
            allowsDocument: this.allowsDocument,
            documentDescription: this.allowsDocument ?  this.documentDescription : new MultiLanguage({}),
            title: this.title,
            description: this.description,
            order: this.order,
            id: this.id,
            isOptional: this.isOptional
        };

    }
}
