import { Component, Vue } from 'vue-property-decorator';
import { Replacement, ProcessInfo, Filter, SorterNew } from '@/Domain/Entities';
import { OppositionType, ProcessType, GroupTypes } from '@/Domain/enum';
import { SdFilter } from '@/Components';
import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import ProcessesService from '@/Services/ProcessesService';
import filters from './ReplacementEdit.filter';
import { mapState } from 'vuex';
import moment from 'moment';

@Component({
    components: {
        SdFilter
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        orderedProcess() {
            return (this as ReplacementEdit).sorter.sortBy((this as ReplacementEdit).filter.filteredData);
        },
        enrolledProcessIds() {
            // return this.$store.state.userStore.enrolledProcessIds || [];
            return [];
        }
    }
})
export default class ReplacementEdit extends Vue {
    currentLanguage!: string;
    dateFormat!: string;
    // enrolledProcessIds!: string[];

    replacementList: Replacement = new Replacement({});
    filter = new Filter();
    sorter = new SorterNew(['year', 'title'], ['desc', 'asc']);
    OppositionType = OppositionType;
    ProcessType = ProcessType;
    GroupTypes = GroupTypes;
    waitingResponse: boolean = false;
    selectProcessError = false;
    processList: ProcessInfo[] = [];
    isEditing: boolean = false;
    submitted: boolean = false;
    moment = moment;

    $refs!: {
        replacementForm: HTMLFormElement
    };

    goToListReplacementProvisional() {
        this.submitted = true;
        if (this.waitingResponse || this.$refs.replacementForm.querySelectorAll(':invalid').length) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        if (!this.replacementList.process.id) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.selectProcessError = true;
            return;
        }
        this.$router.push({
            name: 'ListReplacementProvisional',
            params: {
                replacementList: (this.replacementList as any)
            }
        });
        this.waitingResponse = true;
        this.selectProcessError = false;
    }

    setProcessList() {
        this.processList = (this as ReplacementEdit).sorter.sortBy((this as ReplacementEdit).filter.filteredData);
        this.replacementList.process = new ProcessInfo({});
    }

    selectProcess(process) {
        if (this.replacementList.id /*|| this.enrolledProcessIds.includes(process.id)*/) {
            return;
        }
        if (process.active) {
            this.replacementList.process = new ProcessInfo({});
            process.active = false;
            return;
        }

        this.processList.forEach(element => {
            element.active = false;
        });

        process.active = true;
        this.replacementList.process = new ProcessInfo(process);
    }
    
    getProcessesFinished() {
        ProcessesService.getProcessesFinished().then(
            response => {
                this.processList = response.map(process => new ProcessInfo(process, this.$store.state.processConfigStore.processConfigGroups));
                this.filter.setData(this.processList);          
                this.filter.setFilterElements(filters);
            })
            .catch(error => console.log(error));
        }
        
    mounted() {
        if (this.$route.params.replacement && (this.$route.params.replacement as any).id) {
            this.replacementList = new Replacement(this.$route.params.replacement, this.$store.state.processConfigStore.processConfigGroups);
            this.isEditing = true;
            this.replacementList.process.active = true;
            this.processList.push(this.replacementList.process);
            this.filter.setData(this.processList);
        } else {
            this.$router.push({
                name: 'ReplacementNew'
            });
            this.getProcessesFinished();
        }
        if (this.$route.params.cloneCriteria) {
            this.replacementList.criteria = (this.$route.params.cloneCriteria as any);
        }
        if (this.$route.params.cloneSelectedReplacementListCandidateIds) {
            this.replacementList.selectedReplacementListCandidateIds = (this.$route.params.cloneSelectedReplacementListCandidateIds as any);
        }
        this.$nextTick(() => {
            notification.initialize();
        });
    }
}
