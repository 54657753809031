import RuleTieBreakCriteria from './RuleTieBreakCriteria';

export default class RuleTieBreakCriteriaGroup {
    private _ruleTieBreakCriterias: RuleTieBreakCriteria[];
    private _position: number;

    constructor(data: any) {
        this._position = data.position || 0;
        this._ruleTieBreakCriterias = data.ruleTieBreakCriterias ? data.ruleTieBreakCriterias.map((ruleTieBreak: any) => new RuleTieBreakCriteria(ruleTieBreak, this._position)) : [new RuleTieBreakCriteria({}, this._position)];
    }

    get ruleTieBreakCriterias() {
        return this._ruleTieBreakCriterias;
    }

    set ruleTieBreakCriterias(criterias: RuleTieBreakCriteria[]) {
        this._ruleTieBreakCriterias = criterias;
    }

    get position() {
        return this._position;
    }

    set position(position: number) {
        this._position = position;
    }

    public toServer() {
        const ruleTieBreakCriteriasToServer = this._ruleTieBreakCriterias.map(criteria => criteria.toServer());
        return {
            position: this._position,
            ruleTieBreakCriterias: ruleTieBreakCriteriasToServer
        };
    }
}
