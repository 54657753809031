import { ToasterService } from '@/Services/ToasterService';
import { Constants } from '@/Domain/enum';
import i18n from '../../lang';
import $ from 'jquery';
/**
 * Service for translate texts.
 */
export default class TranslationService {
  /**
   * Returns a translated text @returns {string}.
   * @param {string} text - Text to translate.
   * @param {string} language - Language to translate.
   * @public
   */
  public static async translate(text: string, language: string) {
    const targetLanguage = Constants.translation[language];
    const apiKey = Constants.savia.apiKey;
    const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}&q=${encodeURIComponent(text)}&target=${targetLanguage}`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        dataType: 'jsonp',
        success(data) {
          if (!data || !data.data) {
            ToasterService.showError(i18n.t('lang.toaster.errorTranslate') as string);
            reject();
            return;
          }
          const translation = TranslationService.decodeHtml(data.data.translations[0].translatedText);
          resolve(translation);
        },
        error() {
          ToasterService.showError(i18n.t('lang.toaster.errorTranslate') as string);
          reject();
        }
      });
    });
  }

  /**
   * Returns a decoded text @returns {string}.
   * @param {string} html - html processed text that google apis returns.
   * This method is used to decode the html entities that google apis returns.
   * To convert ascii chars to utf-8 encoding.
   * @public
   */
  private static decodeHtml(html: string): string {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    const decoded = textArea.value;
    textArea.remove();
    return decoded;
  }
}
