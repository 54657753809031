import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/replacementlistcandidate';

export default class ReplacementContactsService {
    public static async getReplacementContacts(replacementListCandidateId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(replacementListCandidateId + '/ReplacementContacts', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addReplacementContact(replacementListCandidateId: string, ReplacementContact: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(replacementListCandidateId + '/ReplacementContacts', ReplacementContact, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateReplacementContact(replacementListCandidateId: string, ReplacementContact: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(replacementListCandidateId + '/ReplacementContacts/' + ReplacementContact.replacementContactId, ReplacementContact, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeReplacementContact(replacementListCandidateId: string, ReplacementContact: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(replacementListCandidateId + '/ReplacementContacts/' + ReplacementContact.replacementContactId, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
