import { MeritOepDocument, ProcessMerit } from '@/Domain/Entities';
import { TabsStatusScaling, MeritRatingType } from '@/Domain/enum';
import MeritSubGroup from '../Merit/MeritSubGroup';

export default class ProcessMeritSubGroup extends MeritSubGroup {
    protected _merits: ProcessMerit[];
    private _status: number;
    private _total: number;

    public constructor(data: any, availableLanguages: string[]) {
        super(data);
        this._status = this.manageStatus(availableLanguages);
        this._total = data.total;
        this._merits = data.merits ? data.merits.map(merit => new ProcessMerit(merit, availableLanguages)) : [];
        this.manageTotal();
    }

    public getMeritInGroup() {
        return this.merits;
    }

    public findMerit(processMeritClone: ProcessMerit) {
        (this.merits.find(element => element.id === processMeritClone.id) as ProcessMerit).setRejectionReason(processMeritClone.rejectionReason);
    }

    public addFilesToViewer(meritGroup) {
        let filesToViewer: any[] = [];
        this.merits.forEach(merit => {
            if ((merit as ProcessMerit).documents.length < 1 && merit.allowUserOficio) {
                const newMeritDoc = new MeritOepDocument({id: merit.id + meritGroup.id, localUrl: 'actuar-de-oficio.png'}, merit.id, merit.ratingType);
                filesToViewer.push(newMeritDoc);
                (merit as ProcessMerit).documents.push(newMeritDoc);
            } else if ((merit as ProcessMerit).documents.length < 1) {
                const newMeritDoc = new MeritOepDocument({id: merit.id + meritGroup.id, localUrl: 'sin-archivos.png'}, merit.id, merit.ratingType);
                filesToViewer.push(newMeritDoc);
                (merit as ProcessMerit).documents.push(newMeritDoc);
            } else {
                filesToViewer = filesToViewer.concat((merit as ProcessMerit).documents);
            }
        });
        return filesToViewer;
    }

    public setActive() {
        this.merits.forEach(merit => {
            (merit as ProcessMerit).active = false;
        });
    }

    public getTotal() {
        let totalSubGroupScore = 0;
        this.merits.forEach(merit => {
            if ( merit.maxScore != null && merit.ratingType !== MeritRatingType.enum.FIXED_SCORE && Number((merit as ProcessMerit).oficialScore) > merit.maxScore ) {
                totalSubGroupScore += Number(merit.maxScore);
            } else if ((merit as ProcessMerit).oficialScore) {
                totalSubGroupScore = totalSubGroupScore + Number((merit as ProcessMerit).oficialScore);
            }
            if (totalSubGroupScore > this.maxScore) {
                totalSubGroupScore = Number(this.maxScore);
            }
        });
        return totalSubGroupScore;
    }

    public get status(): number {
        return this._status;
    }

    public setstatus(availableLanguages) {
        this._status = this.manageStatus(availableLanguages);
    }

    public get total() {
        return this._total;
    }

    public set total(total: number ) {
        this._total = total;
    }

    public get merits() {
        return this._merits;
    }

    public set merits(merits: ProcessMerit[] ) {
        this._merits = merits;
    }

    public manageTotal() {
        let total = 0;
        this.merits.forEach(element => {
            if ( element.maxScore != null && element.ratingType !== MeritRatingType.enum.FIXED_SCORE && Number((element as ProcessMerit).oficialScore) > element.maxScore ) {
                total += Number(element.maxScore);
            } else {
                total += Number((element as ProcessMerit).oficialScore);
            }
        });
        if ( this.maxScore != null && total > this.maxScore ) {
            this._total = Number(this.maxScore);
        } else {
            this._total = Number(total.toFixed(6));
        }
    }

    rejectNonAssesedMerits() {
        this.getUnscoredMerits().forEach(merit => {
            merit.rejectAllDocuments();
            merit.markMeritAsMaximumScoreReached();
        });
    }

    maxScoreHasBeenReached() {
        let score = 0;
        const scoredMerits = (this.merits as ProcessMerit[]).filter(merit => merit.oficialScore);
        scoredMerits.forEach(merit => score += Number(merit.oficialScore));
        return score >= this.maxScore;
    }

    isAnyUnScoredMerit() {
        return this.getUnscoredMerits().length > 0;
    }

    isMoreThanOneMerit() {
        return this.merits.length > 1;
    }
    
    public toServer() {
        return {
            description: this._description,
            merits: this._merits.map(element => element.toServer())
        };
    }

    private getUnscoredMerits() {
        return (this.merits as ProcessMerit[]).filter(merit => merit.oficialScore as any === '');
    }

    private manageStatus(availableLanguages) {
        if (this.merits.length === 0) {
            return TabsStatusScaling.enum.DISABLED;
        }
        if (this.merits.findIndex(element => !(element as ProcessMerit).isValid) === -1) {
            return TabsStatusScaling.enum.SUCCESS;
        }

        if (this.merits.some(element => element.ratingType !== MeritRatingType.enum.FIXED_SCORE ?
                                            (element as ProcessMerit).oficialScore as any && Number((element as ProcessMerit).oficialScore) !== Number((element as ProcessMerit).userScore) && !(element as ProcessMerit).rejectionReason.allHasValue(availableLanguages) :
                                            (element as ProcessMerit).oficialScore as any && (((element as ProcessMerit).oficialScore as any && Number((element as ProcessMerit).oficialScore) !== 0) && ((element as ProcessMerit).oficialScore as any && Number((element as ProcessMerit).oficialScore) !== (element as ProcessMerit).userScore) || (Number((element as ProcessMerit).oficialScore) !== Number((element as ProcessMerit).userScore) && !(element as ProcessMerit).rejectionReason.allHasValue(availableLanguages))))) {
            return TabsStatusScaling.enum.ERROR;
        }

        return TabsStatusScaling.enum.DEFAULT;
    }

}
